import { IFeedbackQuestion } from '@@/_new_src_/types/feedback';
import { FEEDBACK_TYPE, FEEDBACK_VERSION } from '@@/_new_src_/constants/feedback';
import DOMPurify from 'dompurify';
import React from 'react';
import locale from '@@/_new_src_/local/myCycles/en_US';
import { isEmpty } from 'lodash';
import { formatInputFun } from '@@/_new_src_/utils';
import cls from 'classnames';
import './index.less';

const {
  feedbackPreview: { noInput, content: contentLocale, context: contextLocale },
} = locale;

interface IFeedbackQuestionsProps {
  context: string;
  questions: IFeedbackQuestion[];
  type: number;
  content: string;
  version?: number;
  moreContent?: boolean;
}
const FeedbackQuestions = (props: IFeedbackQuestionsProps) => {
  const { context, questions, content, version, type, moreContent } = props;

  const renderFeedbackContext = () => {
    return (
      <div className="context">
        <div className="label">{contextLocale}</div>
        <div
          className={cls('body', {
            'no-input': isEmpty(context),
          })}
          dangerouslySetInnerHTML={{
            __html: !isEmpty(context) ? context : noInput,
          }}
        ></div>
      </div>
    );
  };

  const renderFeedbackContent = () => {
    return (
      <div className="content">
        <div className="label">{contentLocale}</div>
        <div
          className={cls('body', {
            'no-input': isEmpty(content),
          })}
          dangerouslySetInnerHTML={{
            __html: !isEmpty(content) ? DOMPurify.sanitize(content) : noInput,
          }}
        ></div>
      </div>
    );
  };

  const renderFeedbackQuestions = () => {
    return (
      <div className="question-modal">
        {questions.map((item, index) => (
          <div key={index}>
            <div
              className="question"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.htmlTagQuestion) }}
            ></div>
            {item.content ? (
              <div
                className="answer"
                dangerouslySetInnerHTML={{ __html: formatInputFun(item.content) }}
              />
            ) : (
              <div className="no-input">{noInput}</div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={'cycle-feedback-info'}>
      {type === FEEDBACK_TYPE.INTERNAL_FEEDBACK &&
        moreContent &&
        version === FEEDBACK_VERSION.V2 &&
        renderFeedbackContext()}
      {version === FEEDBACK_VERSION.V2 && renderFeedbackQuestions()}
      {(type === FEEDBACK_TYPE.CLIENT_FEEDBACK || version === FEEDBACK_VERSION.V1) &&
        renderFeedbackContent()}
    </div>
  );
};

export default FeedbackQuestions;
