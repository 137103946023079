import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { TableProps } from 'antd/es';
import dayjs from 'dayjs';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import AddPeopleIcon from '@@/assets/images/admin-manage-add-icon.svg';
import LeadershipIcon from '@@/assets/images/icon_leadship_team.svg';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getPerformancePath } from '@@/common/utils';
import { UserInfoContext } from '@@/context/userInfo.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
} from '@@/_new_src_/constants/table';
import { V2SUPERVISOR } from '@@/_new_src_/constants/adminEnum';
import { CUSTOMIZED_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import {
  GroupsListColumnSortColumnsName,
  SortParamsForManageGroupListColumns,
} from '@@/types/antdComponent';
import { IPageParams } from '@@/_new_src_/types/common';
import { UserInfo } from '@@/types/user';
import { GroupsListColumn, GroupsListResponse } from '../../type';
import { deleteGroupInSpecificSuperOrg, deleteGroupInSpecificUnit } from '@@/_new_src_/api/admin';
import CreateEditGroupModal from '../CreateEditGroupModal';
import CreateGlobalFunctionTBPGroupModal from '../CreateGlobalFunctionTBPGroupModal';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import './index.less';

interface ManageRegionalGroupTabProps {
  loading: boolean;
  listData: GroupsListResponse | undefined;
  hierarchyId: number;
  unitName: string;
  getGroupsInSpecificUnit: (
    SortParams: SortParamsForManageGroupListColumns,
    PageParams: IPageParams,
  ) => Promise<GroupsListResponse>;
  isSupervisor: boolean;
  hierarchyType: string | undefined;
}

const ManageTBPGroupsList = (props: ManageRegionalGroupTabProps) => {
  const { trackEvent } = useMatomo();
  const { updateUserInfo } = useContext(UserInfoContext) as UserInfo;
  const history = useHistory();
  const { loading, listData, getGroupsInSpecificUnit, hierarchyId, isSupervisor, hierarchyType } =
    props;
  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<SortParamsForManageGroupListColumns>({
    sortColumn: GroupsListColumnSortColumnsName.default,
    sortOrder: SORT_DIRECTION_FOR_BE.descend,
  });
  const [createNewGroupModalVisible, onOpenGroupModal, onCloseCreateNewGroupModal] =
    usePerfModal() as [boolean, () => void, () => void];

  const [
    createGlobalFunctionTBPGroupModalVisible,
    onOpenGlobalFunctionTBPGroupModal,
    onCloseCreateGlobalFunctionTBPGroupModal,
  ] = usePerfModal() as [boolean, () => void, () => void];

  const localeResource = useSelector(selectLocaleResource) as unknown;

  const {
    admin: {
      manageGroupsOfTBP: { totalGroups, table: locale, removeGroup: removeGroupLocale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        totalGroups: (total: number) => string;
        table: GroupsListColumn & { actions: string; createGroup: string; remove: string };
        removeGroup: {
          cancelText: string;
          okText: string;
          content: string;
          successMessage: string;
        };
      };
    };
  };
  const onGotoGroupDetailPage = useCallback(
    (record: { id: number }, uId: number) => {
      history.push(getPerformancePath(`/admin/group/${record.id}`), { uId, isSupervisor });
    },
    [history, isSupervisor],
  );

  const createColumns = (
    tableLocale: GroupsListColumn & { actions: string; remove: string },
    removeGroup: (groupId: number) => void,
  ) => {
    return [
      {
        title: tableLocale.groupName,
        dataIndex: 'groupName',
        key: 'groupName',
        width: 280,
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (name: string) => {
          return (
            <div className="name">
              <Tooltip className="name" placement="topLeft" title={name}>
                {name}
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: tableLocale.groupLeaders,
        dataIndex: 'groupLeaders',
        key: 'groupLeaders',
        width: 350,
        ellipsis: { showTitle: false },
        render: (groupLeaders: string) => {
          return (
            <div className="name">
              <Tooltip className="name" placement="topLeft" title={groupLeaders}>
                {groupLeaders}
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: tableLocale.createdBy,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 240,
        ellipsis: { showTitle: false },
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (addedBy: string) => (
          <Tooltip placement="topLeft" title={addedBy}>
            {addedBy}
          </Tooltip>
        ),
      },
      {
        title: tableLocale.createdAt,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 240,
        ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
        render: (addedAt: number) => dayjs(addedAt).format('YYYY-MM-DD HH:mm'),
      },
      {
        title: tableLocale.actions,
        dataIndex: 'actions',
        width: 200,
        render: (text: string, record: { id: number; groupName: string }) => (
          <>
            <PerfTextButton
              color="blue"
              className="operation-edit"
              onClick={() => onGotoGroupDetailPage(record, hierarchyId)}
            >
              Edit
            </PerfTextButton>
            <PerfTextButton
              color="red"
              className="operation-delete"
              onClick={() => removeGroup(record.id)}
            >
              Remove
            </PerfTextButton>
          </>
        ),
      },
    ];
  };

  useEffect(() => {
    const getGroupsList: () => void = async () => {
      await getGroupsInSpecificUnit(currentSorter, currentPagination);
    };
    getGroupsList();
  }, [currentPagination, currentSorter, getGroupsInSpecificUnit, hierarchyId]);

  const handleTableChange: TableProps<GroupsListColumn>['onChange'] = (pagination, _, sorter) => {
    const pageNumberAndSize: IPageParams = {
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    };
    setCurrentPagination(pageNumberAndSize);
    const { columnKey, order } = sorter as SorterResult<GroupsListColumn>;
    const columnsNameAndOrder = {
      sortColumn: order
        ? GroupsListColumnSortColumnsName[columnKey as keyof typeof GroupsListColumnSortColumnsName]
        : GroupsListColumnSortColumnsName.default,
      sortOrder: order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.descend,
    };
    setCurrentSorter(columnsNameAndOrder);
  };

  const removeGroup = (groupId: number) => {
    const closeModal = perfModalConfirm({
      cancelText: removeGroupLocale.cancelText,
      okText: removeGroupLocale.okText,
      className: 'removePeopleConfirm',
      content: <div className="delete-modal-content">{removeGroupLocale.content}</div>,
      onOk: async () => {
        const pageNumber =
          listData?.content.length === 1
            ? Math.max(currentPagination.pageNumber - 1, DEFAULT_TABLE_PAGINATION.pageNumber)
            : currentPagination.pageNumber;
        const newPagination = { ...currentPagination, pageNumber };

        trackEvent({
          category: CUSTOMIZED_GROUP_MANAGEMENT.category,
          action: CUSTOMIZED_GROUP_MANAGEMENT.action.TBP_REMOVES_CUSTOMIZED_GROUP,
        });

        if (hierarchyType === V2SUPERVISOR) {
          await deleteGroupInSpecificSuperOrg(hierarchyId, groupId).finally(() => closeModal());
        } else {
          await deleteGroupInSpecificUnit(hierarchyId, groupId).finally(() => closeModal());
        }
        perfMessage.success(removeGroupLocale.successMessage);
        setCurrentPagination(newPagination);
        updateUserInfo();
      },
      centered: true,
      onCancel: () => {},
    });
  };
  const columns: ColumnsType<GroupsListColumn> = createColumns(locale, removeGroup);

  function onCreateGlobalFunctionTBPGroup() {
    onOpenGlobalFunctionTBPGroupModal();
  }
  function onCreateGroupModal() {
    onOpenGroupModal();
  }

  const handleCreateGroupSuccess = useCallback(() => {
    onCloseCreateNewGroupModal();
    const newPagination = {
      ...currentPagination,
      pageNumber: DEFAULT_TABLE_PAGINATION.pageNumber,
    };
    setCurrentPagination(newPagination);
    updateUserInfo();
  }, [currentPagination, onCloseCreateNewGroupModal, updateUserInfo]);

  const handleCreateGlobalFunctionTBPGroupSuccess = useCallback(() => {
    onCloseCreateGlobalFunctionTBPGroupModal();
    const newPagination = {
      ...currentPagination,
      pageNumber: DEFAULT_TABLE_PAGINATION.pageNumber,
    };
    setCurrentPagination(newPagination);
    updateUserInfo();
  }, [currentPagination, onCloseCreateGlobalFunctionTBPGroupModal, updateUserInfo]);
  return (
    <div className="group-member-container">
      <div className="group-member-operation">
        <div className="total">
          <img className="leadership-icon" src={LeadershipIcon} alt="leadership-icon" />
          <div className="total-groups">{totalGroups(listData?.total || 0)}</div>
        </div>
        <button
          type="button"
          className="create-group"
          onClick={isSupervisor ? onCreateGlobalFunctionTBPGroup : onCreateGroupModal}
        >
          <img src={AddPeopleIcon} alt="create-group" />
          <span>{locale.createGroup}</span>
        </button>
      </div>
      <div className="list">
        <Table<GroupsListColumn>
          columns={columns}
          loading={loading}
          dataSource={listData?.content}
          rowKey={record => record.id}
          onChange={handleTableChange}
          pagination={
            (listData?.total as number) > 10
              ? {
                  ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                  total: listData?.total,
                  current: currentPagination.pageNumber,
                  pageSize: currentPagination.pageSize,
                }
              : false
          }
        />
      </div>
      {createNewGroupModalVisible && (
        <CreateEditGroupModal
          visible={createNewGroupModalVisible}
          onSuccess={handleCreateGroupSuccess}
          onCancel={onCloseCreateNewGroupModal}
          unitId={hierarchyId}
        />
      )}

      {createGlobalFunctionTBPGroupModalVisible && (
        <CreateGlobalFunctionTBPGroupModal
          visible={createGlobalFunctionTBPGroupModalVisible}
          onSuccess={handleCreateGlobalFunctionTBPGroupSuccess}
          onCancel={onCloseCreateGlobalFunctionTBPGroupModal}
          unitId={hierarchyId}
        />
      )}
    </div>
  );
};

export default ManageTBPGroupsList;
