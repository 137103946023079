import React, { memo } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import './index.less';

function ClearIcon() {
  return (
    <div className="component-clear-icon">
      <CloseOutlined />
    </div>
  );
}

export default memo(ClearIcon);
