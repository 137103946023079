import { CheckOutlined, CloseCircleFilled, WarningFilled } from '@ant-design/icons';
import { message } from 'antd';
import { ArgsProps } from 'antd/es/message';
import React from 'react';
import './index.less';

const perfMessage = {
  success: (text: string, duration = 3) => {
    // eslint-disable-next-line prefer-const
    let perfMessageInstance: () => void;
    const handleClose = () => {
      perfMessageInstance();
    };
    perfMessageInstance = message.open({
      content: (
        <div className="perf-message success">
          <div>
            <CheckOutlined />
            <span className="text">{text}</span>
          </div>
          <div>
            <CloseCircleFilled onClick={handleClose} style={{ fontSize: '24px' }} />
          </div>
        </div>
      ),
      duration,
    } as ArgsProps);
  },
  error: (text: string, duration = 5) => {
    // eslint-disable-next-line prefer-const
    let perfMessageInstance: () => void;
    const handleClose = () => {
      perfMessageInstance();
    };
    perfMessageInstance = message.open({
      content: (
        <div className="perf-message error">
          <div>
            <WarningFilled style={{ fontSize: '22px' }} />
          </div>
          <div className="pert-message-text-content">
            <span className="prefix">Oops!</span>
            <span>{text}</span>
          </div>
          <div>
            <CloseCircleFilled onClick={handleClose} style={{ fontSize: '24px' }} />
          </div>
        </div>
      ),
      duration,
    } as ArgsProps);
  },
};
export default perfMessage;
