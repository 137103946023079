import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import cls from 'classnames';
import { get } from 'lodash';
import perfModalConfirm from '../PerfModalConfirm';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';

export default function PerfModal({ children, ...props }) {
  const { className, onCancel, needConfirm, visible, ...restProps } = props;
  const [showModal, setShowModal] = useState(true);
  const [isModifyTheModalPosition, setIsModifyTheModalPosition] = useState(false);
  const theHeightOfModalToTop = 75;

  const { confirmCancelModal: locale } = useSelector(selectLocaleResource);
  const onClose = () => {
    if (!needConfirm) {
      onCancel();
      return;
    }
    setShowModal(false);
    perfModalConfirm({
      content: locale.confirmMessage,
      okText: locale.okText,
      cancelText: locale.cancelText,
      onOk: () => {
        onCancel();
        setShowModal(true);
      },
      onCancel: () => {
        setShowModal(true);
      },
      centered: true,
    });
  };

  const windowHeight = document.documentElement.clientHeight;

  useEffect(() => {
    if (visible) {
      const modal = document.getElementsByClassName('perf-modal');

      setTimeout(() => {
        const clientHeight = get(modal, ['0', 'clientHeight']);

        const isModifyTheModal = clientHeight < windowHeight - 2 * theHeightOfModalToTop;

        setIsModifyTheModalPosition(isModifyTheModal);
      });
    }
  }, [isModifyTheModalPosition, visible, windowHeight]);
  return (
    <Modal
      style={{ display: showModal ? '' : 'none' }}
      closeIcon={<CloseOutlined />}
      className={cls('perf-modal', className, { centered: isModifyTheModalPosition })}
      centered={isModifyTheModalPosition}
      open={visible}
      {...restProps}
      onCancel={onClose}
      autoFocusButton={null}
    >
      {children}
    </Modal>
  );
}
