import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { CYCLE_STATUS } from '@@/_new_src_/constants/CycleStatus';
import { ICycleStatusProps } from '@@/_new_src_/types/common';
import './index.less';

function CycleStatus(cycleStatusProps: ICycleStatusProps) {
  const { needSuffix, status } = cycleStatusProps;
  const [currentStatus, setCurrentStatus] = useState('');

  useEffect(() => {
    if (status === 'UNREAD') {
      setCurrentStatus('DONE');
    } else {
      setCurrentStatus(status);
    }
  }, [status]);

  const statusLabel = get(CYCLE_STATUS, currentStatus);
  const description = get(statusLabel, 'des');
  return (
    <div className={`status-label ${statusLabel?.className || ''}`}>
      {status && <label>{`${description}${needSuffix ? ' Cycle' : ''}`}</label>}
    </div>
  );
}

export default CycleStatus;
