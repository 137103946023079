import { Select } from 'antd';
import React, { useEffect } from 'react';
import './index.less';
import {
  FEEDBACK_SORT_COLUMNS_NAME,
  isPastCyclesPage,
  RequestFeedbackListOptions,
} from '@@/_new_src_/constants/feedback';
import { CaretDownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  cycleInformation,
  pastCyclesPageStore,
  setPastCyclesPageCycleFilterValue,
  setPastCyclesPageSelectedCycle,
  setPastCyclesPagesSelectedYear,
} from '@@/_new_src_/store/myCyclesSlice';
import { get } from 'lodash';
import { getCurrentCycleFeedbackListAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { getInputInSelectDom, updateAriaForSelect } from '@@/_new_src_/utils/common/axe';
import cls from 'classnames';
import { SORT_DIRECTION_FOR_BE } from '@@/_new_src_/constants/table';

interface IProps {
  className?: string;
}

const CycleSelector = (props: IProps) => {
  const { className = 'v2-feedback-select' } = props;
  const { cycleFilterValue } = useSelector(pastCyclesPageStore);
  const cycleInfo = useSelector(cycleInformation);
  const cycleId = get(cycleInfo, 'id') as number;
  const dispatch = useDispatch();

  const onListSelectChange = (value: string) => {
    dispatch(setPastCyclesPageCycleFilterValue(value));
    if (isPastCyclesPage(value)) {
      dispatch(setPastCyclesPageSelectedCycle(''));
      dispatch(setPastCyclesPagesSelectedYear(''));
    } else {
      cycleId &&
        dispatch(
          getCurrentCycleFeedbackListAsync({
            cycleId: cycleId,
            sortColumnName: FEEDBACK_SORT_COLUMNS_NAME.default,
            sortOrder: SORT_DIRECTION_FOR_BE.ascend,
            pageSize: 10,
            pageNumber: 1,
          }),
        );
    }
  };

  const cycleSelectorDom = getInputInSelectDom(className);
  useEffect(() => {
    updateAriaForSelect({
      inputWrapper: cycleSelectorDom,
      ariaLabelText: 'selector label',
    });
  }, [cycleSelectorDom]);

  return (
    <div className="feedback-cycle-selector">
      <Select
        size="large"
        value={cycleFilterValue}
        onChange={onListSelectChange}
        options={RequestFeedbackListOptions}
        className={cls('v2-feedback-select', className)}
        suffixIcon={<CaretDownOutlined style={{ color: '#2f3542', fontSize: '16px' }} />}
      />
    </div>
  );
};

export default CycleSelector;
