import React from 'react';
import { useSelector } from 'react-redux';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { cycleDisplayPart } from '@@/_new_src_/store/myCyclesSlice';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { cycleDetailData, isUpdateInformationSuccess } from '@@/_new_src_/store/myCyclesSlice';
import CycleInformationFormV2 from '../CycleInformationFormV2';
import CycleInformationForm from '../CycleInformationForm';

const CycleInformationPage = () => {
  const currentPart = useSelector(cycleDisplayPart);
  const { loading, data: { createBySystem } = { createBySystem: false } } =
    useSelector(cycleDetailData);
  const { loading: cycleInformationLoading } = useSelector(isUpdateInformationSuccess);

  return (
    <>
      {currentPart === CYCLE_DISPLAY_PART.INFORMATION_FORM && (
        <>
          <LoadingWrapper loading={loading || cycleInformationLoading}>
            <div className="cycle-information-page">
              {createBySystem ? <CycleInformationFormV2 /> : <CycleInformationForm />}
            </div>
          </LoadingWrapper>
        </>
      )}
    </>
  );
};

export default CycleInformationPage;
