export const REVIEWEE_SORT_FIELD = {
  PROJECT_NAME: 'PROJECT_NAME',
  OVERDUE: 'OVERDUE',
  REVIEWEE_NAME: 'REVIEWEE_NAME',
  REVIEW_DURATION: 'REVIEW_DURATION',
  RATING: 'RATING',
  STATUS: 'STATUS',
  ROLE: 'ROLE',
  DEFAULT: 'DEFAULT',
};

export const TALENT_INFORMATION_KEY = {
  HIGH_POTENTIAL: 'High Potential',
  POTENTIAL_FOR_GRADE_CHANGE: 'Potential for Grade Change',
  NO_TALENT_INFORMATION: 'No Talent Information',
};

export const TABS_KEY = {
  REVIEW_DETAIL: 'detail',
  REVIEW_INPUT: 'assessment',
  FEEDBACK: 'feedback',
};

export const EMPLOYEE_STATUS = {
  EXITED: 'EXITED',
  ACTIVE: 'ACTIVE',
  UNDER_PROBATION: 'UNDER PROBATION',
  NOT_UNDER_PROBATION: 'NOT UNDER PROBATION',
};

export const EMPLOYEE_STATUS_DISPLAY = {
  [EMPLOYEE_STATUS.EXITED]: 'Exited',
  [EMPLOYEE_STATUS.ACTIVE]: 'Active',
  [EMPLOYEE_STATUS.UNDER_PROBATION]: 'Under probation',
  [EMPLOYEE_STATUS.NOT_UNDER_PROBATION]: 'Not under probation',
};
