import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileSize from 'filesize';
import { Button, Spin, Tooltip, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { LoadingOutlined } from '@ant-design/icons';
import { get, isEmpty } from 'lodash';

import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IMyCyclesLocale, IUploadAttachmentProps } from '@@/_new_src_/types/myCycles';
import {
  EXPECTATION_ATTACHMENT_ACCEPT,
  EXPECTATION_ATTACHMENT_STANDARD,
} from '@@/_new_src_/constants/myCycles';
import './index.less';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PreviewModal from '@@/_new_src_/features/PreviewModal';
import useLocalStorageCycle from '@@/_new_src_/hooks/useLocalStorageCycle';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { postUploadAttachmentAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { setAttachment } from '@@/_new_src_/store/myCyclesSlice';
import { useLocation } from 'react-router';
import { CURRENT_PAGE } from '@@/_new_src_/constants/pagePath';
import { IContext } from '@@/_new_src_/store/cycleDetailSlice/interface';

const loadingFileIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UploadAttachment = (props: IUploadAttachmentProps) => {
  const { attachmentType, defaultInfo = {}, attachmentClassName = '', attachmentInfo } = props;

  const dispatch = useDispatch();

  const defaultAttachmentName = get(defaultInfo, 'attachmentName', '');
  const defaultAttachmentStorageUrl = get(defaultInfo, 'attachmentStorageUrl', '');
  const { trackEvent } = useMatomo();
  const { maxNameLength, maxSize } = EXPECTATION_ATTACHMENT_STANDARD;
  const { setContextAttachmentState } = useLocalStorageCycle();
  const location = useLocation();
  const {
    myCycles: { cycleInformationForm: locale },
  } = (useSelector(selectLocaleResource) as IMyCyclesLocale) || {};

  const { url, success, filename, loading, reject } = attachmentInfo;
  const [isShow, setIsShow] = useState(false);

  const [file, setFile] = useState<UploadFile>({} as UploadFile);

  const [curAttachment, setCurAttachment] = useState({
    filename: '',
    fileUrl: '',
  });

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const isInMyCycles = useMemo(
    () => CURRENT_PAGE(location.pathname).isMyCyclesFormPage,
    [location],
  );

  useEffect(() => {
    setUploadSuccess(success);
  }, [success]);

  useEffect(() => {
    setCurAttachment({
      fileUrl: '',
      filename: file.name,
    });
  }, [file]);

  useEffect(() => {
    if (!isEmpty(defaultInfo)) {
      setCurAttachment({
        filename: defaultAttachmentName || '',
        fileUrl: defaultAttachmentStorageUrl || '',
      });
    }
  }, [defaultInfo]);

  const initialFileValue = () => {
    setFile({} as UploadFile);
    dispatch(setAttachment({ type: attachmentType, data: { isInitialValue: true } }));
    setContextAttachmentState(null);
  };

  const beforeUploadAttachment = (file: RcFile) => {
    initialFileValue();
    if (file.size > maxSize) {
      perfMessage.error(locale.attachment.maxSize(fileSize(maxSize)));
      return false;
    }
    if (file.name?.length > maxNameLength) {
      perfMessage.error(locale.attachment.maxNameLength(maxNameLength));
      return false;
    }
    return true;
  };

  const onChangeAttachment: UploadProps['onChange'] = (info: UploadChangeParam) => {
    if (info?.file) {
      setFile(info.file);
    }
  };

  const onRemoveAttachment = () => {
    initialFileValue();
  };

  const onPreviewAttachment = () => {
    setIsShow(true);
  };

  const uploadAttachment = () => {
    if (file && get(file, 'originFileObj')) {
      trackEvent({
        category: MY_CYCLE_LIST_API.category,
        action: MY_CYCLE_LIST_API.action.TWER_UPLOADS_V2_CYCLE_ATTACHMENT,
      });
      dispatch(
        postUploadAttachmentAsync({
          file: get(file, 'originFileObj') as RcFile,
          type: attachmentType,
        }),
      );
    }
  };

  useEffect(() => {
    if (uploadSuccess) {
      dispatch(
        setAttachment({ type: attachmentType, data: { ...attachmentInfo, success: false } }),
      );
      !isEmpty(url) &&
        !isEmpty(filename) &&
        isInMyCycles &&
        setContextAttachmentState({
          attachmentName: filename,
          attachmentStorageUrl: url,
        } as IContext);
      setCurAttachment({
        filename: filename,
        fileUrl: url,
      });
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (reject) {
      initialFileValue();
    }
  }, [reject]);

  const displayUploadedFile = () => {
    return (
      <Tooltip
        overlayClassName="add-attachment-tooltip"
        arrow={true}
        title={curAttachment.filename}
      >
        {curAttachment.filename && (
          <div className="uploaded-files">
            {!loading && <i className="ri-attachment-2 uploaded-files-icon" />}
            <Spin indicator={loadingFileIcon} spinning={loading}>
              <span className="uploaded-files-content" onClick={onPreviewAttachment}>
                {curAttachment.filename}
              </span>
            </Spin>
            {!loading && (
              <i
                className="ri-close-circle-line uploaded-files-close"
                onClick={onRemoveAttachment}
              />
            )}
          </div>
        )}
      </Tooltip>
    );
  };

  return (
    <div className="upload-attachment-wrapper">
      <Upload
        name="file"
        maxCount={1}
        accept={EXPECTATION_ATTACHMENT_ACCEPT}
        beforeUpload={beforeUploadAttachment}
        onPreview={onPreviewAttachment}
        onChange={onChangeAttachment}
        customRequest={uploadAttachment}
        itemRender={displayUploadedFile}
        fileList={file ? [file] : []}
        disabled={!!curAttachment.fileUrl}
      >
        <Tooltip
          overlayClassName={`add-attachment-tooltip ${attachmentClassName}`}
          placement="bottomLeft"
          title={
            curAttachment.fileUrl
              ? locale.attachment.onlyAttachOneFile
              : locale.attachment.tooltip(fileSize(maxSize), EXPECTATION_ATTACHMENT_ACCEPT)
          }
        >
          <Button className="add-attachment-btn" disabled={!!curAttachment.fileUrl}>
            <i className="ri-attachment-2 add-attachment-btn-icon" />
            {locale.attachment.label}
          </Button>
        </Tooltip>
      </Upload>
      <PreviewModal
        url={curAttachment.fileUrl}
        title={filename}
        isShow={isShow}
        updateIsShow={setIsShow}
      />
    </div>
  );
};

export default memo(UploadAttachment);
