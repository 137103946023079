import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { isEqual, pick } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CUSTOMIZED_GROUP_MANAGEMENT } from '@@/common/constant/matomo';
import { ERROR_CODE } from '@@/common/constant/http';
import { userListTypeForSearch } from '@@/types/user';
import { SelectOptionValueType } from '@@/types/antdComponent';
import useDisabledForm from '@@/hooks/useDisabledForm';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getUserList } from '@@/api/user';
import { putUpdateGroup } from '@@/_new_src_/api/admin';
import AutoComplete from '@@/common/component/AutoComplete';
import PerfModal from '@@/common/component/PerfModal';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import './index.less';

export interface GroupModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  groupLeaders: groupLeaderInfoType[] | undefined;
  groupName: string | undefined;
  unitId: number;
  groupId: string;
}

export interface groupLeaderInfoType {
  value: string;
  key: string;
  label: string;
}

interface formValuesType {
  groupName: string;
  groupLeaders: Array<groupLeaderInfoType>;
}

interface CreateGroupErrorType {
  code: number;
  message: string;
}

function EditGroupNameOrLeads(props: GroupModalProps) {
  const { trackEvent } = useMatomo();
  const { visible, onCancel, onSuccess } = props;
  const [submitting, setSubmitting] = useState(false);
  const [createGroupError, setCreateGroupError] = useState<CreateGroupErrorType>(
    {} as CreateGroupErrorType,
  );
  const { form, isDisabled } = useDisabledForm();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageGroupsOfTBP: {
        editGroupForTBP: { editGroupModal: locale },
      },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        editGroupForTBP: {
          editGroupModal: {
            title: string;
            okText: string;
            cancelText: string;
            successMessage: string;
            groupName: {
              label: string;
              placeholder: string;
              requiredMessage: string;
            };
            groupLead: {
              label: string;
              placeholder: string;
              requiredMessage: string;
              notFoundContent: string;
            };
          };
        };
      };
    };
  };

  const onSubmit = useCallback(async () => {
    const values = (await form.validateFields()) as formValuesType;
    const { groupId } = props;
    setSubmitting(true);
    const groupName = values.groupName.trim();
    const groupLeaders = values.groupLeaders.map(({ value }) => value).join();

    try {
      trackEvent({
        category: CUSTOMIZED_GROUP_MANAGEMENT.category,
        action: CUSTOMIZED_GROUP_MANAGEMENT.action.TBP_EDITS_NAME_AND_GROUP_LEAD,
      });
      await putUpdateGroup(groupId, groupName, groupLeaders);
      perfMessage.success(locale.successMessage);
      form.resetFields();
      onSuccess();
    } catch (error) {
      const { errorCode, message } = pick(error, ['message', 'errorCode']) as {
        errorCode: number;
        message: string;
      };
      setCreateGroupError({
        code: errorCode,
        message: `* ${message}`,
      });
    } finally {
      setSubmitting(false);
    }
  }, [form, locale.successMessage, onSuccess, props]);

  const renderNameOptions = useCallback(
    (list: Array<userListTypeForSearch>, Option: typeof Select.Option) => {
      return list.map(({ userId, name, email }) => {
        const selectedUserInfoInCurrentForm = form.getFieldValue(
          'groupLeaders',
        ) as Array<SelectOptionValueType>;
        const isAlreadySelectedInCurrentForm = selectedUserInfoInCurrentForm
          ?.map((item: SelectOptionValueType) => item.value)
          .includes(email);
        return (
          <Option
            key={String(userId)}
            label={name}
            value={email}
            disabled={isAlreadySelectedInCurrentForm}
          >
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form],
  );

  useEffect(() => {
    const fieldsMap = {
      [ERROR_CODE.INVALID_GROUP_NAME]: 'groupName',
      [ERROR_CODE.INVALID_GROUP_LEADERS]: 'groupLeaders',
    };
    const createFieldValue = (name: string) => ({
      name,
      value: form.getFieldValue(name),
      errors: [createGroupError.message],
      touched: true,
    });
    form.setFields([createFieldValue(fieldsMap[createGroupError.code])]);
  }, [createGroupError, form]);

  useEffect(() => {
    form.setFieldsValue({
      groupLeaders: props.groupLeaders,
      groupName: props.groupName,
    });
  }, [form, props.groupLeaders, props.groupName]);

  const getIsSaveBtnDisabled = () => isDisabled();

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <PerfModal
      title={locale.title}
      visible={visible}
      style={{ top: 180 }}
      className="edit-group-modal"
      forceRender
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <Form
        className="group-form"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              name="groupName"
              label={locale.groupName.label}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: locale.groupName.requiredMessage,
                },
              ]}
            >
              <Input placeholder={locale.groupName.placeholder} maxLength={100} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              label={locale.groupLead.label}
              name="groupLeaders"
              rules={[
                {
                  required: true,
                  message: locale.groupLead.requiredMessage,
                },
              ]}
            >
              <AutoComplete
                mode="multiple"
                placeholder={locale.groupLead.placeholder}
                notFoundContent={locale.groupLead.notFoundContent}
                fetchApi={getUserList}
              >
                {renderNameOptions}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <div className="group-modal-footer">
          <Form.Item shouldUpdate={(prevValues, curValues) => isEqual(prevValues, curValues)}>
            {() => (
              <>
                <Button onClick={handleCancel}>{locale.cancelText}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={getIsSaveBtnDisabled()}
                  loading={submitting}
                >
                  {locale.okText}
                </Button>
              </>
            )}
          </Form.Item>
        </div>
      </Form>
    </PerfModal>
  );
}

export default memo(EditGroupNameOrLeads);
