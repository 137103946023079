import { ERROR_CODE } from '@@/common/constant/http';

export const getApiPath = path => '/api' + path;
export const getApiV2Path = path => '/api/v2' + path;

export const groupApiErrorWrapperOptions = {
  ignoreMessage: errorCode =>
    [
      ERROR_CODE.INVALID_MEMBER,
      ERROR_CODE.INVALID_GROUP_LEADERS,
      ERROR_CODE.INVALID_GROUP_NAME,
    ].includes(errorCode),
};

export const adminApiErrorWrapperOptions = {
  ignoreMessage: errorCode => [ERROR_CODE.ADDING_ADMIN_NAME_ERROR].includes(errorCode),
};
