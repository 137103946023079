import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import EditCircleButton from '../../EditCircleButton';
import FieldBlockInfo from '../../FieldBlockInfo';
import './index.less';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import WarningText from '@@/_new_src_/components/WarningText';
import AttachmentBtn from '@@/_new_src_/features/AttachmentBtn';
dayjs.extend(utc);
const dateHMS = 'YYYY-MM-DD HH:mm:ss';
const SelfReviewPreview = ({ isExpand, setIsExpand, showEditButton, onClickEditSelfReview }) => {
  const {
    currentCycleDetail: {
      selfAssessment = {
        updatedAt: '',
        createdAt: '',
        content: '',
        attachmentStorageUrl: '',
        attachmentName: '',
      },
    },
  } = useSelector(cycleDetailByVersion);

  const {
    reviewList: { selfReviewPreview: locale },
  } = useSelector(selectLocaleResource);

  useEffect(() => {
    !showEditButton && localStorage.removeItem('SELF_REVIEW');
  }, [showEditButton]);

  const onToggleExpand = useCallback(() => {
    setIsExpand(!isExpand);
  }, [isExpand, setIsExpand]);

  if (!selfAssessment) {
    return null;
  }

  const currentUpdatedTime = useMemo(() => {
    let currentTime = selfAssessment.updatedAt;
    if (isEmptyOrNil(selfAssessment.updatedAt)) {
      currentTime = selfAssessment.createdAt;
    }
    return dayjs.utc(currentTime).local().format(dateHMS);
  }, [selfAssessment.createdAt, selfAssessment.updatedAt]);

  return (
    <div className="self-review-preview">
      <div className="title">
        <WarningText
          warningText={locale.updateDescription + currentUpdatedTime}
          className={'update-time'}
        />

        <div className="operation-icon">
          {showEditButton && <EditCircleButton onClick={onClickEditSelfReview} tabIndex={0} />}
          <div className="expand" onClick={onToggleExpand} tabIndex={0}>
            <CaretDownOutlined rotate={isExpand ? 180 : 0} className="expand-icon" />
          </div>
        </div>
      </div>
      <div className="content">
        <FieldBlockInfo
          isExpand={isExpand}
          className="brief-content"
          label={locale.title}
          content={selfAssessment.content}
          emptyPlaceholder=""
          collapsible
        />
      </div>
      {selfAssessment.attachmentName && selfAssessment.attachmentStorageUrl && (
        <AttachmentBtn
          title={selfAssessment.attachmentName}
          url={selfAssessment.attachmentStorageUrl}
          isSelfAssessment={true}
        />
      )}
    </div>
  );
};

export default SelfReviewPreview;
