import React from 'react';
import locale from '@@/_new_src_/local/feedback/en_US';
import { useSelector } from 'react-redux';
import { feedbackDetail } from '@@/_new_src_/store/V2FeedbackSlice';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import './index.less';
import { useHistory } from 'react-router-dom';
import FeedbackSwitcher from '../FeedbackSwitcher';
import {
  formatRangeDuration,
  formatTimeFun,
  getNewDateFormatByProbation,
} from '@@/_new_src_/utils/common/date';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';

const ClientDetail = () => {
  const history = useHistory();
  const {
    feedback: {
      clientFeedbackDetail: { title, project, cycleDuration, lastUpdateAt, getBackTitle, content },
    },
  } = locale;

  const {
    cycle: { projectName, duration, probation },
    feedback: { providerName, updatedAt, content: feedbackContent },
  } = useSelector(feedbackDetail);

  const goBackPage = () => {
    history.push(FEEDBACK_PAGE_LINK.INDEX);
  };

  return (
    <div className={'client-detail-container'}>
      <GoBackBanner title={getBackTitle} goToBackFun={goBackPage} onKeyPress={goBackPage} />
      <div className={'title'}>
        <b>{title}</b>
        <span>{providerName}</span>
      </div>
      <div className={'basic-group'}>
        <div className={'project'}>
          <b>{project}</b>
          <span className={'project-name'}>{projectName}</span>
          <span className={'slash'}>|</span>
        </div>
        <div className={'duration'}>
          <b>{cycleDuration}</b>
          <span className={'cycle-duration'}>
            {formatRangeDuration(duration.startTime, duration.endTime, {
              format: getNewDateFormatByProbation(probation),
            })}
          </span>
        </div>
      </div>
      <div className={'update-banner'}>
        <i className="ri-history-line"></i>
        <span className={'update-at'}>
          {lastUpdateAt}
          {formatTimeFun(updatedAt, dateWithYearMonthTime)}
        </span>
      </div>
      <div className={'feedback-detail'}>
        <div className={'feedback-content'}>{content}</div>
        <div
          className={'feedback-content-detail'}
          dangerouslySetInnerHTML={{ __html: feedbackContent }}
        />
      </div>
      <FeedbackSwitcher />
    </div>
  );
};
export default ClientDetail;
