import AdminManageAddIcon from '@@/assets/images/admin-manage-add-icon.svg';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { localAdmin } from '../../../common/interface';
import './index.less';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { IAdminColumn, IAdminColumType } from '@@/types/admin';

const listTypeSwitchOptions = [
  {
    label: 'OPs Admins',
    value: USER_ROLE_ENUM.OPS_ADMIN,
  },
  {
    label: 'PS Admins',
    value: USER_ROLE_ENUM.PS_ADMIN,
  },
  {
    label: 'System Admins',
    value: USER_ROLE_ENUM.GLOBAL_ADMIN,
  },
];

interface ISystemAdminHeader {
  onListSelectChange: (value: IAdminColumType) => Promise<IAdminColumn[]>;
  totalText: string;
  onAddButtonClick: () => void;
  selectAdmin: IAdminColumType;
}

const SystemAdminHeader = (props: ISystemAdminHeader) => {
  const { selectAdmin, onListSelectChange, totalText, onAddButtonClick } = props;

  const [selectAdminType, setSelectAdminType] = useState<IAdminColumType>(selectAdmin);

  const localeResource = useSelector(selectLocaleResource) as localAdmin;
  const {
    admin: { manageAdminRole: adminLocale },
  } = localeResource;

  const addButtonText = {
    GLOBAL_ADMIN: adminLocale.addGlobalAdminAction,
    PS_ADMIN: adminLocale.addRegionalAdminAction,
    'OPs Admin': adminLocale.addGlobalFunctionOrServiceLineAdminAction,
  };

  const onChange = async (value: IAdminColumType) => {
    setSelectAdminType(value);
    await onListSelectChange(value);
  };

  return (
    <div className="total-and-operation">
      <div className="select-and-total">
        <Select
          size="large"
          value={selectAdminType}
          onChange={onChange}
          options={listTypeSwitchOptions}
          className="admin-list-type-select"
          suffixIcon={
            <CaretDownOutlined style={{ color: '#2f3542', fontSize: '16px', marginTop: '1px' }} />
          }
        />

        <div className="total">{totalText}</div>
      </div>
      <button type="button" className="add-regional-admin" onClick={onAddButtonClick}>
        <img src={AdminManageAddIcon} alt="add-regional-admin" />
        {addButtonText[selectAdminType]}
      </button>
    </div>
  );
};

export default SystemAdminHeader;
