import { CheckOutlined, CloseCircleFilled, WarningFilled } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';
import './perfMessage.less';

const perfMessage = {
  success: (text, duration = 3) => {
    let perfMessageInstance;
    const handleClose = () => {
      perfMessageInstance();
    };
    perfMessageInstance = message.open({
      content: (
        <div className="perf-message success">
          <div>
            <CheckOutlined />
            <span className="text">{text}</span>
          </div>
          <div>
            <CloseCircleFilled onClick={handleClose} style={{ fontSize: '24px' }} />
          </div>
        </div>
      ),
      duration,
    });
  },
  error: (text, duration = 5) => {
    let perfMessageInstance;
    const handleClose = () => {
      perfMessageInstance();
    };
    perfMessageInstance = message.open({
      content: (
        <div className="perf-message error">
          <div>
            <WarningFilled style={{ fontSize: '22px' }} />
          </div>
          <div className="pert-message-text-content">
            <span className="prefix">Oops!</span>
            <span>{text}</span>
          </div>
          <div>
            <CloseCircleFilled onClick={handleClose} style={{ fontSize: '24px' }} />
          </div>
        </div>
      ),
      duration,
    });
  },
};
export default perfMessage;
