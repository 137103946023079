import PeopleIcon from '@@/assets/images/icon_leadship_team.svg';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import PerfTextButton from '@@/common/component/PerfTextButton';
import { getPerformancePath } from '@@/common/utils';
import { Collapse } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ManageGroupListProps } from '../../type';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionalGroupsAsync } from '@@/redux/slice/adminSlice/asyncThunk';
import { regionalGroups } from '@@/redux/slice/adminSlice';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';

const { Panel } = Collapse;

const ManageGroupsList = (props: ManageGroupListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { regionId } = props;
  const { loading, success, rejected, data: listData } = useSelector(regionalGroups);

  useEffect(() => {
    regionId && dispatch(getRegionalGroupsAsync(regionId));
  }, [regionId]);

  useEffect(() => {
    if ((success || rejected) && listData.length === 0) {
      history.push(ERROR_PAGE.IS_403);
    }
  }, [success, rejected, listData]);

  const renderPanelHeader = (name: string) => {
    return (
      <>
        <img className="user-filled-icon" src={PeopleIcon} alt="" />
        <span className="header-text">{name}</span>
      </>
    );
  };

  const handleEditButtonClick = (groupId: string) => {
    history.push(getPerformancePath(`/admin/regional-level-group/${groupId}`));
  };

  const renderButtonEdit = (groupId: string) => {
    return (
      <>
        <PerfTextButton
          color="blue"
          className="operation-edit"
          onClick={() => handleEditButtonClick(groupId)}
        >
          Edit
        </PerfTextButton>
      </>
    );
  };
  return (
    <LoadingWrapper loading={loading} wrapperClassName="modal-loading">
      {!listData && <div style={{ height: '100px', backgroundColor: '#f5f6f9' }}> </div>}
      {listData?.map(groupItem => (
        <>
          <Panel
            className="custom-panel"
            header={renderPanelHeader(groupItem.groupName)}
            key={`group-item-${groupItem.id}`}
            showArrow={false}
            extra={renderButtonEdit(groupItem.id.toString())}
          />
        </>
      ))}
    </LoadingWrapper>
  );
};

export default ManageGroupsList;
