import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    nameList: undefined,
    dueDate: undefined,
    clientName: undefined,
    clientContent: undefined,
    clientContentHtml: undefined,
  },
  reducers: {
    setNameList: (state, action) => {
      state.nameList = action.payload;
    },
    setDueDate: (state, action) => {
      state.dueDate = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },
    setClientContent: (state, action) => {
      state.clientContent = action.payload;
    },
    setClientContentHtml: (state, action) => {
      state.clientContentHtml = action.payload;
    },
  },
});

export const { setNameList, setDueDate, setClientName, setClientContent, setClientContentHtml } =
  feedbackSlice.actions;

export const selectNameList = state => state.feedback.nameList;

export const selectDueDate = state =>
  state.feedback.dueDate ? dayjs.unix(state.feedback.dueDate) : state.feedback.dueDate;

export const selectClientName = state => state.feedback.clientName;

export const selectClientContent = state => state.feedback.clientContent;

export const selectClientContentHtml = state => state.feedback.clientContentHtml;

export default feedbackSlice.reducer;
