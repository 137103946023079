import { HOME_COUNTRY } from '@@/_new_src_/constants/userEnum';

export const EMPTY_CONTENT_PLACEHOLDER = 'N/A';
export const EMPTY_PROJECT_PLACEHOLDER = EMPTY_CONTENT_PLACEHOLDER;

export const REVIEW_FEEDBACK_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSc11YOp9kMGROhHoipFFrF6BKm9XIgV-m5nF1VMsAxzK3BCrw/viewform?usp=sf_link';
export const PLAYBOOK_LINK =
  'https://docs.google.com/presentation/d/1NzvdEbE5UAJDor5g8cCSVv1nTi3B8ejR2v1O1ov_q5U/edit#slide=id.ge0d2666f43_2_202';
export const EMAIL_LINK =
  'https://mail.google.com/mail/?ui=2&view=cm&to=summit-support@thoughtworks.com&fs=1&tf=1';

export const REVIEW_EXPECTATION_LINK = {
  [HOME_COUNTRY.BRAZIL]:
    'https://sites.google.com/thoughtworks.com/people-hub/brazil/empowering-twer-growth/expectations',
  [HOME_COUNTRY.CHINA]:
    'https://docs.google.com/spreadsheets/d/1JUNMkapuZkzePK0lLJHc-mWavxce_550Q1EqNHSH8BA/edit#gid=0',
  [HOME_COUNTRY.HK]:
    'https://docs.google.com/spreadsheets/d/1JUNMkapuZkzePK0lLJHc-mWavxce_550Q1EqNHSH8BA/edit#gid=0',
  [HOME_COUNTRY.INDIA]: 'https://drive.google.com/drive/folders/1aqRx9uI-cSm-xfA3W0EsCu-Z3_Z2FcHP',
  [HOME_COUNTRY.SPAIN]:
    'https://docs.google.com/document/d/15YXHan38reLx8wa6FTTTlwUYGsBUnFosfUxHPh-jujE',
  [HOME_COUNTRY.OTHER]:
    'https://sites.google.com/thoughtworks.com/empowering-twer-growth/grade-expectations',
};

export const EXPECTATION_MAX_LENGTH = 8000;
export const EXPECTATION_TITLE_MAX_LENGTH = 130;
export const EXPECTATION_DETAILS_MAX_LENGTH = 8000;
export const NOTES_MAX_LENGTH = 5000;
export const SUMMARY_MAX_LENGTH = 5000;
export const ACTIONS_MAX_LENGTH = 5000;
export const GIVE_FEEDBACK_MAX_LENGTH = 5000;

export const FEEDBACK_MAX_LENGTH = 2000;
export const ASSESSMENT_INPUT_MAX_LENGTH = 5000;

export const REVIEWER_TYPE = {
  ALL: '',
  REVIEWER: 'REVIEWER',
  CONTRIBUTOR: 'CONTRIBUTOR',
};
export const REVIEW_CHANGE_HISTORY_RECORD_TYPE = {
  ASSESSMENT: 'ASSESSMENT',
  REVIEW: 'REVIEW',
};

export const PARTNER_DEFINITION_DOC = {
  [HOME_COUNTRY.CHINA]:
    'https://docs.google.com/document/d/1tGOTHBSvNndUhVjrc5MCBmUBOY_Iexm_JfedQJcj3oI#heading=h.paz0qpxptbc1',
  [HOME_COUNTRY.HK]:
    'https://docs.google.com/document/d/1tGOTHBSvNndUhVjrc5MCBmUBOY_Iexm_JfedQJcj3oI#heading=h.paz0qpxptbc1',
  [HOME_COUNTRY.INDIA]:
    'https://docs.google.com/document/d/1tGOTHBSvNndUhVjrc5MCBmUBOY_Iexm_JfedQJcj3oI#heading=h.lir252d38qzq',
  [HOME_COUNTRY.OTHER]:
    'https://docs.google.com/document/d/1tGOTHBSvNndUhVjrc5MCBmUBOY_Iexm_JfedQJcj3oI',
};

export const WORDING_CHANGE_VIDEO_LINK =
  'https://drive.google.com/file/d/1iVLkSczqR02CigezjG2W5A8w3V2mkeVw/view';

export const LIMIT_PARTNER_NUM = 2;

export const GET_CYCLE_DETAIL_API_PATH = {
  isPerformanceReviews: '/performance_reviews/${id}',
  isReviewee: '/performance-reviews/reviewee/${id}',
  isReviewer: '/performance-reviews/reviewer/${id}',
};
