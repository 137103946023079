import React, { useMemo, useState } from 'react';
import locale from '@@/_new_src_/local/myCycles/en_US';
import './index.less';
import ElasticCollapse from '@@/_new_src_/components/ElasticCollapse';
import { IGetDetailFeedback } from '@@/_new_src_/store/V2FeedbackSlice/interface';
import FeedbackQuestions from '@@/_new_src_/page/myCycles/component/FeedbackQuestions';
import DOMPurify from 'dompurify';
import { FEEDBACK_TYPE, FEEDBACK_VERSION } from '@@/_new_src_/constants/feedback';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import ExpectationLabel from '@@/_new_src_/features/ExpectationLabel';

const FeedbackPreviewDetail = (props: { feedback: IGetDetailFeedback }) => {
  const { feedback } = props;
  const {
    feedbackPreview: {
      title: { name, role, project },
      expectationLabel,
    },
    feedbackType: { internal, client },
  } = locale;
  const [showMoreContent, setShowMoreContent] = useState(false);

  const isInternalFeedback = useMemo(
    () => feedback.type === FEEDBACK_TYPE.INTERNAL_FEEDBACK,
    [feedback],
  );

  return (
    <div
      className={cls('feedback-preview-details', {
        'internal-type-background': isInternalFeedback,
      })}
    >
      <div className="feedback-preview-container">
        <span className="feedback-type-description">{isInternalFeedback ? internal : client}</span>
        <div className="feedback-preview-header">
          <div className="brief-content">
            <span>{name}</span>
            <span className="name">{feedback.providerName}</span>
          </div>
          <div className="brief-content">
            <span>{role}</span>
            <span className="role">{feedback.providerRole}</span>
          </div>
          {feedback.projectName && (
            <div className="brief-content">
              <span>{project}</span>
              <span>{feedback.projectName}</span>
            </div>
          )}
        </div>
        <ElasticCollapse
          isAlwaysShowMore={isInternalFeedback && feedback.version === FEEDBACK_VERSION.V2}
          callback={() => setShowMoreContent(prevState => !prevState)}
          content={
            <div className="feedback-details-wrapper">
              <FeedbackQuestions
                context={DOMPurify.sanitize(feedback.context)}
                questions={feedback.questions}
                type={feedback.type}
                content={feedback.content}
                version={feedback.version}
                moreContent={showMoreContent}
              />
              {showMoreContent && !isEmpty(feedback.expectations) && (
                <div className="expectation-label-wrapper">
                  <span className="title">{expectationLabel}</span>
                  {feedback.expectations.map((item, index) => (
                    <ExpectationLabel
                      key={index}
                      title={item.title}
                      categoryIds={item.categoryIds}
                    />
                  ))}
                </div>
              )}
            </div>
          }
          maxHeight={'256'}
        />
      </div>
    </div>
  );
};

export default FeedbackPreviewDetail;
