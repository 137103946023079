import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  CYCLE_STEP,
  CYCLE_STEP_ORDER,
  CYCLE_STEP_ORDER_FOR_PROBATION,
} from '@@/_new_src_/constants/myCycles';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cls from 'classnames';
import { get, map, size } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import './index.less';

const StepsBar = () => {
  const {
    myCycles: { cycleStepsBar: locale, cycleStepsBarForProbation: cycleStepsBarForProbationLocale },
  } = useSelector(selectLocaleResource);

  const {
    currentCycleDetail: { isNewCycle, isOpenCycle, isUnreadCycle, isCalibratingCycle, isProbation },
  } = useSelector(cycleDetailByVersion);

  const [currentStep, setCurrentStep] = useState<string>(CYCLE_STEP.CYCLE_INFORMATION);
  const [isShowBar, setShowBar] = useState<boolean>(false);

  const getStepInfo = useMemo(() => {
    if (isProbation) {
      return {
        cycleStepsBarLocale: cycleStepsBarForProbationLocale,
        cycleStepOrder: CYCLE_STEP_ORDER_FOR_PROBATION,
      };
    } else {
      return {
        cycleStepsBarLocale: locale,
        cycleStepOrder: CYCLE_STEP_ORDER,
      };
    }
  }, [isProbation]);

  useEffect(() => {
    setShowBar(false);
    if (isNewCycle) {
      setCurrentStep(CYCLE_STEP.EXPECTATION_ALIGNMENT);
      setShowBar(true);
    }
    if (isOpenCycle) {
      setCurrentStep(CYCLE_STEP.CHECKIN_FEEDBACK);
      setShowBar(true);
    }
    if (isCalibratingCycle) {
      setCurrentStep(CYCLE_STEP.CALIBRATING);
      setShowBar(true);
    }
    if (isUnreadCycle) {
      setCurrentStep(CYCLE_STEP.GET_ASSESSMENT);
      setShowBar(true);
    }
  }, [isNewCycle, isOpenCycle, isUnreadCycle, isCalibratingCycle]);

  const currentStepOrder = get(getStepInfo.cycleStepOrder, `${currentStep}`) as number;

  const stepStateInference = (step: string) => {
    const stepOrder = get(getStepInfo.cycleStepOrder, `${step}`);
    const isLastStep = stepOrder === size(getStepInfo.cycleStepOrder);
    const isActive = currentStep === step;
    const isDone = currentStepOrder > stepOrder || (isActive && isLastStep);

    return {
      isActive: isActive,
      isDone: isDone,
      isLastStep: isLastStep,
    };
  };

  return (
    <>
      {isShowBar && (
        <div
          className={cls('cycle-steps', {
            'cycle-steps-for-five': Object.keys(getStepInfo.cycleStepsBarLocale).length === 5,
          })}
        >
          {map(getStepInfo.cycleStepsBarLocale, ({ label, desc }, step) => {
            const stepOrder = get(getStepInfo.cycleStepOrder, `${step}`) as number;

            const { isActive, isDone, isLastStep } = stepStateInference(step);

            return (
              <div
                className={cls('cycle-steps-item', {
                  'cycle-steps-item-active': isActive,
                  'cycle-steps-item-done': isDone,
                })}
                key={label}
              >
                <div className="cycle-steps-item-dot">
                  {isDone ? <i className="ri-check-line cycle-steps-icon-done" /> : null}
                </div>
                <div className="cycle-steps-item-title">
                  <span>{label}</span>
                  <Tooltip
                    arrow={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
                    title={desc}
                    placement={
                      isLastStep || step === CYCLE_STEP.CALIBRATING ? 'topRight' : 'topLeft'
                    }
                    align={{
                      offset:
                        isLastStep || step === CYCLE_STEP.CALIBRATING ? [12, -10] : [-12, -10],
                    }}
                    overlayClassName={cls({ 'not-first-step': stepOrder !== 1 })}
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default memo(StepsBar);
