export const getInputInSelectDom = (classname: string) => {
  const selectWrappers = document.getElementsByClassName(classname);
  const inputWrapperArray =
    ([] as Array<{
      setAttribute: (arg0: string, arg1: string) => void;
      removeAttribute: (arg0: string) => void;
    }>) || [];
  if (selectWrappers.length > 0) {
    Array.from(selectWrappers).forEach(selectItem => {
      const inputWrappers = selectItem.getElementsByClassName('ant-select-selection-search-input');
      if (inputWrappers && inputWrappers.length > 0) {
        inputWrapperArray.push(inputWrappers[0]);
      }
    });
  }
  return inputWrapperArray;
};

export const updateAriaForSelect = ({
  inputWrapper,
  ariaLabelText,
}: {
  inputWrapper: Array<{
    setAttribute: (arg0: string, arg1: string) => void;
    removeAttribute: (arg0: string) => void;
  }>;
  ariaLabelText: string;
}) => {
  if (inputWrapper && inputWrapper.length > 0) {
    inputWrapper.forEach(inputItem => {
      inputItem.setAttribute('aria-expanded', 'false');
      inputItem.setAttribute('aria-label', ariaLabelText);
      inputItem.removeAttribute('aria-activedescendant');
      inputItem.removeAttribute('aria-owns');
      inputItem.removeAttribute('aria-controls');
    });
  }
};
