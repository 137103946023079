export const trigSurvey = event => {
  window._kiq?.push([
    'set',
    {
      clicked_button: event,
    },
  ]);
};

export const trigAutoSurvey = items => {
  let item = items[Math.floor(Math.random() * items.length)];
  window._kiq?.push(['set', { clicked_button: `${item}` }]);
};
