import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IMyCyclesLocale, IPerformanceCategoryItem } from '@@/_new_src_/types/myCycles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCategoryConfig } from './categoryConfig';
import './index.less';

const PerformanceCategoryItem = (props: IPerformanceCategoryItem) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isSelect, setIsSelect] = useState<boolean>(props.isSelected);

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: { performanceCategory: locale },
  } = localeResource as IMyCyclesLocale;
  const categoryConfig = getCategoryConfig(locale);
  const categoryItem = categoryConfig[props.categoryId];

  const handleExpand = (e: React.MouseEvent<HTMLElement>) => {
    e.target.tagName !== 'A' && setIsExpand(!isExpand);
  };
  const handleSelect = () => {
    setIsSelect(!isSelect);
    props.handleSelectCategory(props.categoryId, !isSelect);
  };
  return (
    <div className="category">
      <div className="category-checkbox">
        {!isSelect ? (
          <div>
            <i className="ri-checkbox-blank-line" onClick={handleSelect} />
          </div>
        ) : (
          <i className="ri-checkbox-fill" onClick={handleSelect} />
        )}
      </div>

      <div
        className="category-icon"
        style={{
          backgroundColor: isSelect
            ? categoryItem.iconBackGroundColor
            : categoryItem.iconBackgroundColorUnSelect,
        }}
      >
        {categoryItem.icon}
      </div>
      <div
        className="category-content"
        style={{
          backgroundColor: categoryItem.contentBackGroundColor,
        }}
        onClick={handleExpand}
      >
        <div className="category-content-header">
          <div className="category-content-header-text">{categoryItem.title}</div>
          <i
            style={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)' }}
            className="ri-arrow-down-s-line category-content-header-collapse-icon"
          />
        </div>
        {isExpand && (
          <div
            className="category-content-description "
            dangerouslySetInnerHTML={{ __html: categoryItem.description }}
          />
        )}
      </div>
    </div>
  );
};

export default PerformanceCategoryItem;
