import React from 'react';
import './index.less';
import cls from 'classnames';

interface IProps {
  wording: string;
  className: string;
}
const Tip = (props: IProps) => {
  const { wording, className = '' } = props;

  return (
    <>
      <div className={cls('tip', className)}>
        <i className="ri-lightbulb-flash-line" />
        <div
          className={'wording-content'}
          dangerouslySetInnerHTML={{
            __html: wording,
          }}
        />
      </div>
    </>
  );
};

export default Tip;
