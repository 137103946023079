import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { isEqual, pick } from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { getUserList } from '@@/api/user';
import AutoComplete from '@@/common/component/AutoComplete';
import PerfModal from '@@/common/component/PerfModal';
import useDisabledForm from '@@/hooks/useDisabledForm';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import { userListTypeForSearch } from '@@/types/user';
import { PermissionItem } from '@@/types/admin';
import { ERROR_CODE } from '@@/common/constant/http';
import './index.less';
import { PermissionsItem } from '../../PermissionsModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN } from '@@/common/constant/matomo';
import { putUpdateRegionalGroupsAsync } from '@@/redux/slice/adminSlice/asyncThunk';
import {
  editRegionalLevelGroupByRegionalAdminData,
  setEditRegionalLevelGroupByRegionalAdminData,
} from '@@/redux/slice/adminSlice';

export interface GroupModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  groupLeaders: groupLeaderInfoType | undefined;
  groupName: string | undefined;
  groupId: number;
  permissions: PermissionItem[];
}

export interface groupLeaderInfoType {
  value: string;
  key: string;
  label: string;
}

interface formValuesType {
  groupName: string;
  groupLeaders: groupLeaderInfoType;
}

interface CreateGroupErrorType {
  code: number;
  message: string;
}

interface AdminLocale {
  admin: {
    manageGroups: {
      editRegionalLevelGroupModal: {
        title: string;
        okText: string;
        cancelText: string;
        successMessage: string;
        groupName: {
          label: string;
          placeholder: string;
          requiredMessage: string;
        };
        groupLead: {
          label: string;
          placeholder: string;
          requiredMessage: string;
          notFoundContent: string;
        };
        showPermissions: string;
        hidePermissions: string;
      };
    };
  };
}

function EditRegionalLevelGroupNameOrManager(props: GroupModalProps) {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const { visible, onCancel, onSuccess, permissions } = props;
  const { Panel } = Collapse;
  const [createGroupError, setCreateGroupError] = useState<CreateGroupErrorType>(
    {} as CreateGroupErrorType,
  );
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const { form, isDisabled } = useDisabledForm();
  // eslint-disable-next-line
  const [, forceUpdate] = useState<any>();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageGroups: { editRegionalLevelGroupModal: locale },
    },
  } = localeResource as AdminLocale;

  const { loading: isEditLoading, success: isEditSuccess } = useSelector(
    editRegionalLevelGroupByRegionalAdminData,
  );

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = () => {
    const isActive = activeKey?.includes('permission-panel');
    return (
      <>
        <span className="header-text">
          {isActive ? locale.hidePermissions : locale.showPermissions}
        </span>
        <DownOutlined rotate={isActive ? 180 : 0} />
      </>
    );
  };

  useEffect(() => {
    if (isEditSuccess) {
      perfMessage.success(locale.successMessage);
      form.resetFields();
      onSuccess && onSuccess();
      dispatch(setEditRegionalLevelGroupByRegionalAdminData({ success: false }));
    }
  }, [isEditSuccess]);

  const onSubmit = useCallback(() => {
    const values = form.getFieldsValue() as formValuesType;
    const { groupId } = props;
    const groupName = values.groupName.trim();
    const groupLeaders = values.groupLeaders?.value;
    trackEvent({
      category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.category,
      action:
        REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.action.REGIONAL_ADMIN_EDITS_REGIONAL_LEVEL_GROUP,
    });
    try {
      dispatch(
        putUpdateRegionalGroupsAsync({
          groupId,
          groupName,
          groupManagers: groupLeaders,
        }),
      );
    } catch (error) {
      const { errorCode, message } = pick(error, ['message', 'errorCode']) as {
        errorCode: number;
        message: string;
      };
      setCreateGroupError({
        code: errorCode,
        message: `* ${message}`,
      });
    }
  }, [form, locale.successMessage, onSuccess, props]);

  const onReset = useCallback(() => {
    form.resetFields();
    setActiveKey([]);
  }, [form]);

  const renderNameOptions = useCallback((list: Array<userListTypeForSearch>, Option) => {
    return list.map(({ userId, name, email }) => {
      return (
        <Option key={String(userId)} label={name} value={email}>
          {`${name}, ${userId}`}
        </Option>
      );
    });
  }, []);

  useEffect(() => {
    const fieldsMap = {
      [ERROR_CODE.INVALID_GROUP_NAME]: 'groupName',
      [ERROR_CODE.INVALID_GROUP_LEADERS]: 'groupLeaders',
    };
    const createFieldValue = (name: string) => ({
      name,
      value: form.getFieldValue(name) as string,
      errors: [createGroupError.message],
      touched: true,
    });
    form.setFields([createFieldValue(fieldsMap[createGroupError.code])]);
  }, [createGroupError, form]);

  useEffect(() => {
    form.setFieldsValue({
      groupLeaders: props.groupLeaders,
      groupName: props.groupName,
    });
    forceUpdate({});
  }, [form, props.groupLeaders, props.groupName]);

  const getIsSaveBtnDisabled = () => isDisabled();

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <PerfModal
      title={locale.title}
      visible={visible}
      style={{ top: 180 }}
      className="edit-group-modal"
      forceRender
      destroyOnClose
      afterClose={onReset}
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <Form
        className="group-form"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              name="groupName"
              label={locale.groupName.label}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: locale.groupName.requiredMessage,
                },
              ]}
            >
              <Input placeholder={locale.groupName.placeholder} maxLength={100} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              label={locale.groupLead.label}
              name="groupLeaders"
              rules={[
                {
                  required: true,
                  message: locale.groupLead.requiredMessage,
                },
              ]}
            >
              <AutoComplete
                className="autocomplete"
                showArrow={false}
                showSearch
                placeholder={locale.groupLead.placeholder}
                notFoundContent={locale.groupLead.notFoundContent}
                fetchApi={getUserList}
              >
                {renderNameOptions}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <div className="permission-section">
          <Collapse
            className="permission-collapse"
            ghost
            onChange={onCollapseChange}
            activeKey={activeKey}
          >
            <Panel
              key="permission-panel"
              className="permission-panel"
              showArrow={false}
              header={renderPanelHeader()}
            >
              {permissions?.map(item => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                <PermissionsItem key={item.scope.user} permission={item} />
              ))}
            </Panel>
          </Collapse>
        </div>
        <div className="group-modal-footer">
          <Form.Item shouldUpdate={(prevValues, curValues) => isEqual(prevValues, curValues)}>
            {() => (
              <>
                <Button onClick={handleCancel}>{locale.cancelText}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={getIsSaveBtnDisabled()}
                  loading={isEditLoading}
                >
                  {locale.okText}
                </Button>
              </>
            )}
          </Form.Item>
        </div>
      </Form>
    </PerfModal>
  );
}

export default memo(EditRegionalLevelGroupNameOrManager);
