import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { IUserInfo } from '@@/_new_src_/store/userSlice/interface';
import './index.less';

const UserProfile = (props: { userInfo: IUserInfo }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleOnFocus = () => {
    setIsTooltipVisible(true);
  };

  const handleOnBlur = () => {
    setIsTooltipVisible(false);
  };

  const TooltipText = (
    <div className={'tooltip-info'}>
      <span className={'tooltip-name'}>{props.userInfo.name}</span>
      <span>{props.userInfo.role}</span>
    </div>
  );

  return (
    <Tooltip placement={'bottom'} title={TooltipText} open={isTooltipVisible}>
      <div
        className={'name-block'}
        tabIndex={0}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        aria-label={'User name'}
      >
        <div className={'char'}>{props.userInfo.name.charAt(0)}</div>
      </div>
    </Tooltip>
  );
};

export default UserProfile;
