import { useRequest } from 'ahooks';
import React, { useMemo } from 'react';
import { GroupsListResponse } from '../../type';
import ManageTBPGroupsList from '../ManageTBPGroupsList';
import { V2SUPERVISOR } from '@@/_new_src_/constants/adminEnum';
import {
  getGroupsInSpecificUnitForGlobal,
  getGroupsInSpecificUnitForUnit,
} from '@@/_new_src_/api/admin';

interface UseRequestResponse {
  data: GroupsListResponse;
}

interface ManageGroupTabProps {
  hierarchyId: number;
  unitName: string;
  hierarchyType: string;
}

const ManageTBPGroupsTab = (props: ManageGroupTabProps) => {
  const { hierarchyId, unitName, hierarchyType } = props;

  const isSupervisor = useMemo(() => {
    return hierarchyType === V2SUPERVISOR;
  }, [hierarchyType]);

  const {
    loading: loadingTBP,
    data: listDataTBP,
    run: runGetGroupsInSpecificUnitTBP,
  } = useRequest(
    (columnsNameAndOrder, pageNumberAndSize) =>
      getGroupsInSpecificUnitForUnit({ ...columnsNameAndOrder, ...pageNumberAndSize }, hierarchyId),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );
  const {
    loading: loadingGlobalFunctionTBP,
    data: listDataGlobalFunctionTBP,
    run: runGetGroupsInSpecificUnitGlobalFunctionTBP,
  } = useRequest(
    (columnsNameAndOrder, pageNumberAndSize) =>
      getGroupsInSpecificUnitForGlobal(
        { ...columnsNameAndOrder, ...pageNumberAndSize },
        hierarchyId,
      ),
    {
      manual: true,
      formatResult: (res: UseRequestResponse) => res.data,
    },
  );

  const loading = isSupervisor ? loadingGlobalFunctionTBP : loadingTBP;
  const listData = isSupervisor ? listDataGlobalFunctionTBP : listDataTBP;
  const runGetGroupsInSpecificUnit = isSupervisor
    ? runGetGroupsInSpecificUnitGlobalFunctionTBP
    : runGetGroupsInSpecificUnitTBP;

  return (
    <ManageTBPGroupsList
      hierarchyId={hierarchyId}
      unitName={unitName}
      loading={loading}
      listData={listData}
      getGroupsInSpecificUnit={runGetGroupsInSpecificUnit}
      isSupervisor={isSupervisor}
      hierarchyType={hierarchyType}
    />
  );
};

export default ManageTBPGroupsTab;
