import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const RouteChange = props => {
  useEffect(
    prevProps => {
      if (props?.location.pathname !== prevProps?.location.pathname) {
        window.scrollTo(0, 0);
      }
    },
    [props],
  );
  return props.children;
};

export default withRouter(RouteChange);
