import cls from 'classnames';
import propTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import './index.less';

function PerfTextButton(props) {
  const { className, children, icon, color = '', centered, style, ...restProps } = props;

  const hexColor = useMemo(() => {
    return color.startsWith('#') ? { color } : null;
  }, [color]);

  return (
    <button
      type="button"
      className={cls(
        'perf-text-button',
        {
          [`perf-text-button-${color}`]: color,
          [`perf-text-button-centered`]: centered,
        },
        className,
      )}
      {...restProps}
      style={{ ...style, ...hexColor }}
    >
      {icon && <span className="perf-text-button-icon">{icon}</span>}
      <span className="perf-text-button-text">{children}</span>
    </button>
  );
}

PerfTextButton.defaultProps = {
  color: 'blue',
  centered: false,
  children: '',
  onClick: () => {},
  className: '',
};

PerfTextButton.propTypes = {
  color: propTypes.oneOfType([propTypes.string, propTypes.oneOf(['blue', 'red', 'green'])]),
  centered: propTypes.bool,
  children: propTypes.string,
  onClick: propTypes.func,
  className: propTypes.string,
  icon: propTypes.element,
};

export default memo(PerfTextButton);
