import BoxIcon from '@@/assets/images/box.svg';
import UserIcon from '@@/assets/images/user.svg';
import { CaretDownOutlined } from '@ant-design/icons';
import { useMount } from 'ahooks';
import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { selectRoleInfoType } from '../../type';
import './index.less';
import { AdminManageMapListType } from '@@/features/performance/v2/admin/common/interface';
import { isEmpty } from 'lodash';
const { Option } = Select;

export interface AdminInfoProps {
  name: string;
  role: string;
  handleRoleChange: (roleKey: string) => void;
  adminManageList: Array<AdminManageMapListType> | [];
}

const AdminInfo = (props: AdminInfoProps) => {
  const { name, role, handleRoleChange, adminManageList } = props;

  const history = useHistory();
  const location = useLocation();
  const initDefaultValue = (history.location.state as { forceRefresh: boolean; init: boolean })
    ?.init;

  const [selectRoleKey, setSelectRoleKey] = useState(
    initDefaultValue ? adminManageList[0]?.value : '',
  );

  const getCurrentRoleItem = (roleKey: string) => {
    let currentRoleKey = roleKey;
    if (isEmpty(roleKey)) {
      currentRoleKey = adminManageList[0]?.value;
    }
    const filterRoleItem = adminManageList.filter(item => item.key === currentRoleKey);
    const currentRoleItem = filterRoleItem[0];
    sessionStorage.setItem(
      'selectedRoleInAdminPage',
      JSON.stringify({
        optionValue: roleKey,
        selectRole: currentRoleItem?.role,
        selectHierarchyId: currentRoleItem?.hierarchyId,
        selectHierarchyName: currentRoleItem?.hierarchyName,
        hierarchyType: currentRoleItem?.roleHierarchy,
      }),
    );
    setSelectRoleKey(currentRoleKey);
    handleRoleChange(currentRoleKey);
  };

  useMount(() => {
    const roleInfoInSession = JSON.parse(
      sessionStorage.getItem('selectedRoleInAdminPage') as string,
    ) as selectRoleInfoType;
    const currentRoleKey = roleInfoInSession?.optionValue || '';
    getCurrentRoleItem(currentRoleKey);
  });

  const replaceHistory = useCallback(() => {
    history.replace({ ...location, state: { forceRefresh: true } });
  }, [history, location]);

  const roleChange = (optionValue: string) => {
    getCurrentRoleItem(optionValue);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', replaceHistory);
    return () => {
      window.removeEventListener('beforeunload', replaceHistory);
    };
  }, [replaceHistory]);

  return (
    <div className="admin-info-container">
      <div className="admin-info">
        <div className="info-row">
          <img src={UserIcon} className="user-icon" alt="" />
          <div className="bold-text">{name}</div>
          <div className="bold-text">&nbsp;·&nbsp;</div>
          <div className="bold-text">{role}</div>
        </div>
        <div className="info-row">
          <img src={BoxIcon} className="box-icon" alt="" />
          <Select
            value={selectRoleKey}
            bordered={false}
            suffixIcon={
              <CaretDownOutlined
                style={{ color: '#1A73E8', fontSize: '16px', marginTop: '2.5px' }}
              />
            }
            className="admin-role-select"
            onChange={roleChange}
          >
            {adminManageList?.map(item => {
              return (
                <Option key={item.key} value={item.value}>
                  {item.display}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      {/*<div className="switch-select">*/}
      {/*  <SwitchNewOleGlobalAdminPage />*/}
      {/*</div>*/}
    </div>
  );
};

export default AdminInfo;
