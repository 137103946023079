import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import {
  currentCycleStatus,
  expectationList,
  isOperationCycle,
  myCycles,
  setCurrentUpdatedExpectation,
  setCycleDisplayPart,
} from '@@/_new_src_/store/myCyclesSlice';
import ExpectationsList from '@@/_new_src_/page/myCycles/component/ExpectationsList';
import AddAnotherExpectationModal from '../AddAnotherExpectationModal';
import AddFirstExpectationModal from '../AddFirstExpectationModal';
import RecordIcon from '../../../../../assets/images/record.svg';
import { getCycleStatus, goToCycleFormPage } from '@@/_new_src_/utils/feature/cycle';
import './index.less';

const CycleExpectations = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: {
      cycleExpectation: { title, addExpectation, empty },
    },
  } = localeResource as IMyCyclesLocale;

  const { cycleVersion = NaN, currentCycleId = NaN } = useSelector(myCycles);
  const showOperation = useSelector(isOperationCycle);
  const expectations = useSelector(expectationList);
  const currentStatus = useSelector(currentCycleStatus);

  const addExpectationOnClick = () => {
    dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.EXPECTATION_FORM));
    dispatch(setCurrentUpdatedExpectation(null));
    history.push(goToCycleFormPage({ cycleVersion, id: currentCycleId }));
  };

  const expectationsIsEmpty = isEmpty(expectations);
  const { isNewCycle, isCancelledCycle } = getCycleStatus(currentStatus);

  return (
    <div className="cycle-expectations">
      <header className="expectations-title">{title}</header>
      <div className="expectation-board">
        <AddAnotherExpectationModal />
        <AddFirstExpectationModal />
        <ExpectationsList />
        {showOperation && !expectationsIsEmpty && !isNewCycle && (
          <div className="expectation-operation" onClick={addExpectationOnClick}>
            <div className="add-expectation">{addExpectation}</div>
            <div className="ri-add-circle-line" />
          </div>
        )}
        {expectationsIsEmpty && isCancelledCycle && (
          <div className="expectation-empty">
            <div>
              <img src={RecordIcon} className="expectation-empty-icon" alt="" />
              <p className="expectation-empty-content">{empty}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CycleExpectations;
