import { getApiPath } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import { IGetPPListParams } from '@@/_new_src_/store/userSlice/interface';

export const getUserList = (name: string, isRequireExited: boolean) =>
  ajax.get(getApiPath('/users/search'), {
    params: {
      name: name.trim(),
      isRequireExited,
    },
  });

export const getUserInfo = () => ajax.get(getApiPath('/users'));

// review - Cycle
// reviewer - PP
// reviewee - Twer

export const getPPList = (data: IGetPPListParams) =>
  ajax.get(getApiPath('/performance-reviews/reviewer'), {
    params: data,
  });
