import { Select } from 'antd';
import React, { useEffect } from 'react';
import locale from '@@/_new_src_/local/feedback/en_US';
import { useDispatch, useSelector } from 'react-redux';
import {
  pastCyclesPageStore,
  pastYearStore,
  setPastCyclesPageSelectedCycle,
  setPastCyclesPagesSelectedYear,
} from '@@/_new_src_/store/myCyclesSlice';
import { getPastCyclesByYearAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';

const YearSelector = () => {
  const {
    pastCycles: { year },
  } = locale;

  const dispatch = useDispatch();

  const {
    pastYear: { reviewHistoryYear },
  } = useSelector(pastYearStore);

  const { selectedYear } = useSelector(pastCyclesPageStore);

  const onSelectedYearChange = (value: string) => {
    dispatch(setPastCyclesPagesSelectedYear(value));
    dispatch(setPastCyclesPageSelectedCycle(''));
  };

  useEffect(() => {
    dispatch(getPastCyclesByYearAsync(selectedYear === '' ? reviewHistoryYear[0] : selectedYear));
  }, [selectedYear]);

  return (
    <div className="year-select-container">
      <span>{year}</span>
      <Select
        value={selectedYear === '' ? reviewHistoryYear[0] : selectedYear}
        onChange={onSelectedYearChange}
        className="year-select"
        tabIndex={0}
      >
        {reviewHistoryYear.map(historyYear => (
          <Select.Option value={historyYear} key={String(historyYear)}>
            {historyYear}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default YearSelector;
