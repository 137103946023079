export const REVIEW_STEP_ORDER = {
  expectationAlignment: 1,
  regularCheckins: 2,
  performanceAssessment: 3,
};

export const REVIEW_STEP = {
  EXPECTATION_ALIGNMENT: 'expectationAlignment',
  REGULAR_CHECKINS: 'regularCheckins',
  PERFORMANCE_ASSESSMENT: 'performanceAssessment',
};
