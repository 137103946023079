import React from 'react';
import cls from 'classnames';
import './index.less';
import { CYCLE_STATUS_DESCRIPTION } from '@@/_new_src_/constants/CycleStatus';
import myCyclesLocale from '@@/_new_src_/local/myCycles/en_US';
import { ICycleStatusBadgeProps } from '@@/_new_src_/types/myCycles';

const CycleStatusBadge = ({ status = '', isShortStatus = false }: ICycleStatusBadgeProps) => {
  const { cycleBadge: cycleBadgeLocale } = myCyclesLocale.cycleStatus;

  return (
    <>
      {status && (
        <span
          className={cls(
            'cycle-status-badge',
            `cycle-status-badge-${status.toLowerCase()}`,
            `${isShortStatus && 'cycle-status-badge-short'}`,
          )}
        >
          {isShortStatus
            ? CYCLE_STATUS_DESCRIPTION[status]
            : cycleBadgeLocale(CYCLE_STATUS_DESCRIPTION[status])}
        </span>
      )}
    </>
  );
};

export default CycleStatusBadge;
