import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export default function useDisabledForm() {
  const [, forceUpdate] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    forceUpdate({});
  }, []);

  const isDisabled = useCallback(
    () =>
      Object.values(form.getFieldsValue()).some(field => !field) ||
      !form.isFieldsTouched(true) ||
      !isEmpty(form.getFieldsError().filter(({ errors }) => errors.length)),
    [form],
  );

  return {
    isDisabled,
    form,
  };
}
