import { Tooltip, Select, Spin } from 'antd';
import { useDebounce, useRequest } from 'ahooks';
import cls from 'classnames';
import { get, isFunction, isEmpty } from 'lodash';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import lightBulb from '../../../assets/images/light-bulb.svg';
import chinaFlag from '../../../assets/images/china-flag.png';
import indiaFlag from '../../../assets/images/india-flag.png';
import { PARTNER_DEFINITION_DOC, REVIEWER_TYPE } from '../../constant/review';
import { UserInfoContext } from '@@/context/userInfo.context';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getInputInSelectDom, updateAriaForSelect } from '@@/_new_src_/utils/common/axe';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import { HOME_COUNTRY } from '@@/_new_src_/constants/userEnum';

function AutoComplete(props, ref) {
  const {
    limitNum,
    placeholder,
    keywordMinLength = 3,
    debounceDuration = 800,
    fetchApi,
    children,
    hasTooltip = false,
    isEditDoneReviewByReviewee,
    tooltipType,
    adminRegionId,
    filterType,
    customRender,
    ariaLabel = '',
    ...attr
  } = props;
  const [fetching, setFetching] = useState(false);
  const [selectedValue, setSelectedValue] = useState(() =>
    props.mode === 'multiple' ? [] : undefined,
  );
  const [list, setList] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { setReview: { form: { reviewer, contributors, tooltipReadMore } } = {} } =
    useSelector(selectLocaleResource);
  const { homeCountry } = useContext(UserInfoContext);
  const debounceTooltipVisible = useDebounce(tooltipVisible, { wait: 200 });

  const toolTipText = {
    [REVIEWER_TYPE.REVIEWER]: reviewer.tooltipText,
    [REVIEWER_TYPE.CONTRIBUTOR]: contributors.tooltipText,
  };

  const readMoreFlag = {
    [HOME_COUNTRY.CHINA]: chinaFlag,
    [HOME_COUNTRY.HK]: chinaFlag,
    [HOME_COUNTRY.INDIA]: indiaFlag,
  };

  const readMoreText = {
    [HOME_COUNTRY.CHINA]: {
      commonText: tooltipReadMore.chinaReadMore.commonText,
      boldText: {
        [REVIEWER_TYPE.REVIEWER]: tooltipReadMore.chinaReadMore.reviewer,
        [REVIEWER_TYPE.CONTRIBUTOR]: tooltipReadMore.chinaReadMore.contributor,
      },
    },
    [HOME_COUNTRY.HK]: {
      commonText: tooltipReadMore.chinaReadMore.commonText,
      boldText: {
        [REVIEWER_TYPE.REVIEWER]: tooltipReadMore.chinaReadMore.reviewer,
        [REVIEWER_TYPE.CONTRIBUTOR]: tooltipReadMore.chinaReadMore.contributor,
      },
    },
    [HOME_COUNTRY.INDIA]: {
      commonText: tooltipReadMore.indiaReadMore.commonText,
      boldText: {
        [REVIEWER_TYPE.REVIEWER]: tooltipReadMore.indiaReadMore.reviewer,
        [REVIEWER_TYPE.CONTRIBUTOR]: tooltipReadMore.indiaReadMore.contributor,
      },
    },
  };

  useImperativeHandle(ref, () => ({
    tooltipVisible,
    setTooltipVisible,
  }));

  const selectRef = useRef(null);

  const isMultiple = useMemo(() => props.mode === 'multiple', [props.mode]);

  const onChange = useCallback(
    (values, options = []) => {
      let newValues = [];
      if (isMultiple) {
        newValues =
          values &&
          values.map(item => {
            const optionItem = options.find(optItem => optItem.value === item.value);
            const initialValue = get(props, 'value', []).find(
              initialItem => initialItem.value === item.value,
            );
            return { ...initialValue, ...item, ...optionItem };
          });
      }

      setSelectedValue(isMultiple ? newValues : values);

      setFetching(false);
      setSearchVal('');
      if (props.onChange) {
        props.onChange(isMultiple ? newValues : values);
        setList([]);
      }
    },
    [props],
  );

  useEffect(() => {
    if (isMultiple) {
      setSelectedValue(get(props, 'value', []));
    } else {
      setSelectedValue(props.value);
    }
  }, [props.value, isMultiple]);

  useEffect(() => {
    !isEmptyOrNil(props.initialInputValue) && onChange(props.initialInputValue);
  }, []);

  const blurSelect = () => {
    selectRef.current.blur();
  };

  const { run: runFetchApi } = useRequest(name => fetchApi(name), {
    manual: true,
    formatResult: res => res.data,
    debounceInterval: debounceDuration,
    onSuccess: res => {
      setFetching(false);
      setList(res);
    },
    onError: () => {
      setFetching(false);
    },
  });
  const { run: runFetchApiForRegionType } = useRequest((name, number) => fetchApi(name, number), {
    manual: true,
    formatResult: res => res.data,
    debounceInterval: debounceDuration,
    onSuccess: res => {
      setFetching(false);
      setList(res);
    },
    onError: () => {
      setFetching(false);
    },
  });

  const { run: runFetchApiForMultiPlyParams } = useRequest(
    ({ name }) => fetchApi({ keyword: name, filterType }),
    {
      manual: true,
      formatResult: res => res.data,
      debounceInterval: debounceDuration,
      onSuccess: res => {
        setFetching(false);
        setList(res);
      },
      onError: () => {
        setFetching(false);
      },
    },
  );

  const onSearch = useCallback(
    value => {
      const name = value.trim();
      setSearchVal(name);
      setList([]);

      setFetching(true);
      if (value.length) {
        setTooltipVisible(false);
      }

      if (name.length < keywordMinLength) {
        return;
      }
      if (!isEmpty(filterType)) {
        runFetchApiForMultiPlyParams({ name });
        return;
      }
      if (isEmpty(adminRegionId)) {
        runFetchApiForRegionType(name, adminRegionId);
        return;
      }
      runFetchApi(name);
    },
    [
      filterType,
      keywordMinLength,
      adminRegionId,
      runFetchApiForMultiPlyParams,
      runFetchApiForRegionType,
      runFetchApi,
    ],
  );

  const onFocus = useCallback(() => {
    if (searchVal.length === 0 && isEmpty(selectedValue)) {
      setTooltipVisible(true);
    }

    if (limitNum && selectedValue.length + 1 > limitNum) {
      blurSelect();
    }
  }, [limitNum, searchVal.length, selectedValue]);

  const onBlur = useCallback(() => {
    setList([]);
    setSearchVal('');
    setTooltipVisible(false);
  }, []);

  function onSelect() {
    if (limitNum && selectedValue.length + 1 >= limitNum) {
      blurSelect();
    }
  }

  const fetchStateContent = fetching ? <Spin /> : props.notFoundContent;

  const onClear = () => {
    setTooltipVisible(false);
  };

  const renderTooltipContent = () => {
    return (
      <div className="tooltip-content">
        <div className="tooltip-title">
          <img src={lightBulb} className="light-bulb" alt="" />
          <div className="tooltip-text">{toolTipText[tooltipType]}</div>
        </div>
        <div className="tooltip-read-more">
          {(homeCountry === HOME_COUNTRY.CHINA ||
            homeCountry === HOME_COUNTRY.HK ||
            homeCountry === HOME_COUNTRY.INDIA) && (
            <>
              <img className="flag-icon" src={readMoreFlag[homeCountry]} alt="" />
              <div className="country-tip">
                <span>{readMoreText[homeCountry].commonText}</span>
                <span style={{ fontWeight: 'bold' }}>
                  {readMoreText[homeCountry].boldText[tooltipType]}
                </span>
                <span>.</span>
              </div>
            </>
          )}
          <a
            href={PARTNER_DEFINITION_DOC[homeCountry] || PARTNER_DEFINITION_DOC[HOME_COUNTRY.OTHER]}
            style={{ textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            {tooltipReadMore.linkText}
          </a>
        </div>
      </div>
    );
  };

  const tagRender = tagRenderProps => {
    if (tagRenderProps && customRender) {
      return customRender(tagRenderProps, selectedValue);
    }
  };

  const autoCompleteSelectDom = getInputInSelectDom('auto-complete');
  useEffect(() => {
    updateAriaForSelect({
      inputWrapper: autoCompleteSelectDom,
      ariaLabelText: ariaLabel,
    });
  }, [autoCompleteSelectDom]);

  return (
    <Tooltip
      overlayClassName={cls('tooltip-container', {
        'done-review-tooltip-container': isEditDoneReviewByReviewee,
      })}
      placement="bottomLeft"
      title={renderTooltipContent}
      open={hasTooltip && debounceTooltipVisible}
    >
      <Select
        ref={selectRef}
        labelInValue
        allowClear
        placeholder={placeholder}
        filterOption={false}
        onSearch={onSearch}
        suffixIcon={<></>}
        onBlur={onBlur}
        onFocus={onFocus}
        onClear={onClear}
        getPopupContainer={trigger => trigger.parentNode}
        style={{
          width: '100%',
        }}
        className="auto-complete"
        {...attr}
        onSelect={onSelect}
        value={selectedValue}
        onChange={onChange}
        optionLabelProp="label"
        notFoundContent={searchVal.length < keywordMinLength ? null : fetchStateContent}
        tagRender={customRender ? tagRender : null}
        tabIndex={0}
      >
        {children && isFunction(children) && children(list, Select.Option)}
      </Select>
    </Tooltip>
  );
}

export default memo(forwardRef(AutoComplete));
