import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { useMount, useRequest } from 'ahooks';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { localAdmin } from '../../common/interface';
import './index.less';
import SystemAdminHeader from './SystemAdminHeader';
import SystemAdminModal from './SystemAdminModal';
import SystemAdminTable from './SystemAdminTable';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { IAdminColumn, IAdminColumType } from '@@/types/admin';
import { getV2AdminByType } from '@@/_new_src_/api/admin';

interface UseRequestResponse {
  data: Array<IAdminColumn>;
}

const ManageAdminsTab = () => {
  const [adminListType, setAdminListType] = useState<IAdminColumType>(USER_ROLE_ENUM.GLOBAL_ADMIN);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const localeResource = useSelector(selectLocaleResource);
  const {
    admin: { manageAdminRole: adminLocale },
  } = localeResource as localAdmin;

  const {
    data: adminList,
    loading: isLoadingAdminListData,
    run: runGetAdminList,
  } = useRequest(
    (currentAdminType: IAdminColumType) => getV2AdminByType({ adminType: currentAdminType }),
    {
      formatResult: (res: UseRequestResponse) => res.data,
      manual: true,
    },
  );

  useMount(() => {
    const getAdminList: () => void = async () => {
      await runGetAdminList(adminListType);
    };
    getAdminList();
  });

  const onListSelectChange = useCallback(
    (value: IAdminColumType) => {
      setAdminListType(value);
      return runGetAdminList(value);
    },
    [runGetAdminList],
  );

  const onAddButtonClick = () => {
    setIsOpenModal(true);
  };

  return (
    <div className="new-role-manage">
      <SystemAdminHeader
        onListSelectChange={onListSelectChange}
        onAddButtonClick={onAddButtonClick}
        totalText={adminLocale.totalForAdmin(adminList?.length || 0)}
        selectAdmin={adminListType}
      />
      <SystemAdminTable
        adminList={adminList}
        runGetAdminList={runGetAdminList}
        isLoadingAdminListData={isLoadingAdminListData}
        adminListType={adminListType}
      />
      <SystemAdminModal
        adminList={adminList}
        runGetAdminList={runGetAdminList}
        adminListType={adminListType}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </div>
  );
};

export default memo(ManageAdminsTab);
