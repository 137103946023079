import { createSlice } from '@reduxjs/toolkit';
import { IPersistence } from '@@/_new_src_/store/persistenceSlice/interface';
import { IState } from '@@/_new_src_/store/persistenceSlice/interface';

const initialState: IPersistence = {
  feedbackNotificationVisible: true,
  cycleInfoNotificationVisible: true,
};
export const persistenceSlice = createSlice({
  name: 'persistence',
  initialState: { ...initialState },
  reducers: {
    setFeedbackNotificationVisible: (state, action) => {
      state.feedbackNotificationVisible = action.payload as boolean;
    },
    setCycleInfoNotificationVisible: (state, action) => {
      state.cycleInfoNotificationVisible = action.payload as boolean;
    },
  },
});

export const { setFeedbackNotificationVisible, setCycleInfoNotificationVisible } =
  persistenceSlice.actions;

export const feedbackNotificationVisible = (state: IState) =>
  state.persistence.feedbackNotificationVisible;

export const cycleInfoNotificationVisible = (state: IState) =>
  state.persistence.cycleInfoNotificationVisible;

export default persistenceSlice.reducer;
