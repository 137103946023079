import { ajax } from '@@/common/ajax';
import { getApiPath } from '@@/api/utils';

export const getVersionCheck = versionTime =>
  ajax.get(getApiPath('/version/versionCheck'), {
    params: {
      versionTime,
    },
  });

export const setVersionCheck = versionTime =>
  ajax.post(getApiPath(`/version/versionCheck?versionTime=${versionTime}`));
