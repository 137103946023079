import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import LogoBlue from '@@/assets/images/logoBlue.svg';
import LogoWhite from '@@/assets/images/logoWhite.svg';
import UserProfile from '@@/_new_src_/features/UserProfile';
import { hasAccessToMenuTab } from '@@/_new_src_/utils/common/auth';
import { CURRENT_PAGE, DOCUMENT_LINK, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { userStore } from '@@/_new_src_/store/userSlice';
import { removeAllV2DashboardsLocalStorage } from '@@/hooks/useLocalStorageV2Dashboard';
import { getMenu, PATH_KEY } from '@@/_new_src_/constants/userEnum';
import commonLocale from '@@/_new_src_/local/common/en_US';
import CycleManagementSelect from '@@/_new_src_/features/CycleManagementSelect';
import BreadCrumb from '@@/_new_src_/components/BreadCrumb';
import './index.less';

const HeaderMenu = () => {
  const { menu: menuLocale } = commonLocale;
  const {
    userInfo,
    PPList: { total },
  } = useSelector(userStore);

  const resetStorage = () => {
    localStorage.removeItem('requestFeedbackListType');
    localStorage.removeItem('selectedYear');
    removeAllV2DashboardsLocalStorage();
  };

  const {
    isLandingPage,
    isCycleManagementPage,
    isDashboardPage,
    isNonEngagedCyclesPage,
    isCalibrateIndicatorsPage,
  } = CURRENT_PAGE(location.pathname);

  const isPP = useMemo(() => {
    return total !== 0;
  }, [total]);

  const renderMenuItem = (pathname: string, key: string, text: string, path: string[]) => {
    const isActive = path.some(item => pathname.toLowerCase().startsWith(item.toLowerCase()));
    return (
      <li
        className={cls('menu-item', {
          active: isActive,
        })}
        key={key}
      >
        {key === PATH_KEY.CYCLE_MANAGEMENT ? (
          <CycleManagementSelect isActive={isActive} pathname={pathname} onChange={resetStorage} />
        ) : (
          <Link
            to={{ pathname: path[0], state: { forceRefresh: true, init: key === PATH_KEY.ADMIN } }}
            onClick={resetStorage}
          >
            {text}
          </Link>
        )}
      </li>
    );
  };

  const renderMenuByRole = useCallback(() => {
    const currentPathname = location.pathname;
    return getMenu().map(menu => {
      const { key, role, text, path } = menu;

      if (key === PATH_KEY.TWER_I_SUPPORT && isPP) {
        return renderMenuItem(currentPathname, key, text, path);
      }
      if (key !== PATH_KEY.TWER_I_SUPPORT && isEmpty(role)) {
        return renderMenuItem(currentPathname, key, text, path);
      }
      if (!isEmpty(role) && hasAccessToMenuTab(role, userInfo, key)) {
        return renderMenuItem(currentPathname, key, text, path);
      }
    });
  }, [userInfo]);

  const getFirstLevelMenu = useMemo(() => {
    if (isCycleManagementPage) {
      return menuLocale.cycleManagement;
    }
    return '';
  }, [isCycleManagementPage]);

  const getSecondLevelMenu = useMemo(() => {
    if (isNonEngagedCyclesPage) {
      return menuLocale.nonEngagedCycles;
    }
    if (isDashboardPage) {
      return menuLocale.dashboard;
    }
    if (isCalibrateIndicatorsPage) {
      return menuLocale.calibrateIndicators;
    }
    return '';
  }, [isDashboardPage, isNonEngagedCyclesPage, isCalibrateIndicatorsPage]);

  return (
    <div
      className={cls('performance-layout-header-container', {
        'performance-layout-header-cycleManagement-page': isCycleManagementPage,
      })}
    >
      <header
        className={cls('performance-layout-header', {
          'performance-layout-header-landing-page': isLandingPage,
        })}
      >
        <div className="left">
          <div className="logo">
            <h1 className="heading-1">Summit</h1>
            <Link to={MENU_PAGE_LINK.LANDING_PAGE} onClick={resetStorage}>
              <img src={isLandingPage ? LogoBlue : LogoWhite} alt="summit" />
            </Link>
          </div>
          <ul className="performance-layout-header-menu">{renderMenuByRole()}</ul>
        </div>
        <div className="right">
          <div className="performance-FAQ">
            <a href={DOCUMENT_LINK.FAQ_LINK} target="_blank" rel="noreferrer">
              FAQ
            </a>
          </div>
          <UserProfile userInfo={userInfo} />
        </div>
      </header>
      <BreadCrumb firstLevelMenu={getFirstLevelMenu} secondLevelMenu={getSecondLevelMenu} />
    </div>
  );
};

export default HeaderMenu;
