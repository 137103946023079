import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';

export const ErrorComponent = props => {
  const { oktaError: locale } = useSelector(selectLocaleResource);
  const { error } = props;
  if (error) {
    const { name, errorSummary } = error;

    const AUTH_SDK_ERROR = 'AuthSdkError';
    const JWT_TOKEN_EXPIRED = 'The JWT expired and is no longer valid';
    const JWT_TOKEN_ISSUED_IN_FUTURE = 'The JWT was issued in the future';

    const OAUTH_ERROR = 'OAuthError';
    const ACCESS_DENY = 'User is not assigned to the client application.';

    if (name === AUTH_SDK_ERROR) {
      if (errorSummary === JWT_TOKEN_EXPIRED || errorSummary === JWT_TOKEN_ISSUED_IN_FUTURE) {
        return <div>{locale.deviceTimeNotCorrect}</div>;
      }
    }

    if (name === OAUTH_ERROR && errorSummary === ACCESS_DENY) {
      return <div>{locale.notAssigned}</div>;
    }

    return (
      <div>
        <p>
          {name}: {errorSummary}
        </p>
      </div>
    );
  }

  return null;
};
