import React, { memo, useEffect } from 'react';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { isEmpty } from 'lodash';

const DarkSelect = props => {
  const {
    value,
    onChange,
    disabled = false,
    showArrow = true,
    optionList,
    needSelect,
    defaultValue,
  } = props;

  const {
    dashboardV2: {
      selectGroup: { noResultsFound },
    },
  } = useSelector(selectLocaleResource);

  const addInputAttribute = () => {
    const selectWrapper = document.getElementsByClassName('component-dark-select');
    const inputWrapper =
      selectWrapper.length > 0 &&
      selectWrapper[0].getElementsByClassName('ant-select-selection-search-input');
    if (inputWrapper.length > 0) {
      inputWrapper[0].setAttribute('aria-expanded', 'false');
      inputWrapper[0].setAttribute('aria-label', 'dashboard role selection');
      inputWrapper[0].removeAttribute('aria-activedescendant');
    }
  };

  useEffect(() => {
    if (!isEmpty(optionList)) {
      addInputAttribute();
    }
  }, [value]);

  return (
    <Select
      showSearch={needSelect}
      size="large"
      labelInValue
      value={value}
      virtual={false}
      disabled={disabled}
      onChange={onChange}
      showArrow={showArrow}
      defaultValue={defaultValue}
      notFoundContent={noResultsFound}
      className="component-dark-select"
      popupClassName="component-dark-select-dropdown"
      getPopupContainer={trigger => trigger.parentNode}
      options={optionList}
      suffixIcon={<CaretDownOutlined style={{ color: '#fff', fontSize: '16px' }} />}
      filterOption={(input, option) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
      }}
    />
  );
};

export default memo(DarkSelect);
