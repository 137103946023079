import { PATHNAME_AND_PAGE_NAME } from '@@/_new_src_/constants/pagePath';

export const getConfig = () => {
  // eslint-disable-next-line no-underscore-dangle
  return process.env;
};

export const getBaseUrl = () => getConfig().REACT_APP_BACKEND_URL;

export const getPerformancePath = path => '/performance/v2' + path;

export const getTabTitle = pathname => {
  const currentRoute =
    PATHNAME_AND_PAGE_NAME.filter(item => pathname.includes(item.pathName)) || [];
  const documentTitle = document.title;

  if (currentRoute.length > 0 && currentRoute[0]?.pageName !== documentTitle) {
    document.title = currentRoute[0].pageName;
  }
};
