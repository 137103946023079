import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import './index.less';
import { isNil } from 'lodash';

const TokenExpireModal = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    let status = false;
    setInterval(() => {
      const expireAt = JSON.parse(localStorage.getItem('okta-token-storage') as string)?.accessToken
        ?.expiresAt;
      if (isNil(expireAt)) {
        return;
      }
      const now = Math.floor(new Date().getTime() / 1000);
      if (expireAt - now <= 900) {
        if (!status) {
          status = true;
          setShowNotification(true);
        }
      } else {
        status = false;
      }
    }, 1000 * 60);
  }, []);
  return (
    <>
      <Modal
        className="token-expire-modal"
        centered={true}
        open={showNotification}
        maskClosable={true}
        closeIcon={<i className="ri-close-line" style={{ width: 40 }} />}
        onOk={() => {
          setShowNotification(false);
        }}
        onCancel={() => {
          setShowNotification(false);
        }}
        width={900}
        title={'Session expiry alert: Save Your Input'}
        okText={
          <>
            <i className={'ri-checkbox-circle-fill'} />
            <p>Okay, I got it</p>
          </>
        }
        cancelButtonProps={{ rootClassName: 'cancle' }}
        destroyOnClose={true}
      >
        <p>
          The current web session will be expired in 15 mins. All unsaved input will lost after
          expiration. Please save or backup your input before session expired.
        </p>
      </Modal>
    </>
  );
};

export default TokenExpireModal;
