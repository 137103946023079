import { useLocalStorageState } from 'ahooks';

export const V2_CURRENT_GROUP_ID = 'V2_CURRENT_GROUP_ID';
export const V2_CURRENT_ROLE_INFO = 'V2_CURRENT_ROLE_INFO';
export const V2_DASHBOARD_TABLE_FILTER = 'V2_DASHBOARD_TABLE_FILTER';
export const V2_SEND_EMAIL_SELECTED_ROW_KEYS = 'V2_SEND_EMAIL_SELECTED_ROW_KEYS';
export const V2_SEND_EMAIL_SELECTED_ROW_INFO = 'V2_SEND_EMAIL_SELECTED_ROW_INFO';
export const V2_SEND_EMAIL_LOADING = 'V2_SEND_EMAIL_LOADING';
export const V2_SHOW_SEND_EMAIL_ERROR = 'V2_SHOW_SEND_EMAIL_ERROR';

export const setV2CurrentGroupId = groupId =>
  localStorage.setItem(V2_CURRENT_GROUP_ID, JSON.stringify(groupId));
export const setV2CurrentRoleInfo = roleName =>
  localStorage.setItem(V2_CURRENT_ROLE_INFO, JSON.stringify(roleName));

const getLocalStorageItem = itemKey => {
  const value = localStorage.getItem(itemKey);
  if (value && value !== 'undefined') {
    return value;
  }
  return '""';
};
export const getV2CurrentGroupId = () => JSON.parse(getLocalStorageItem(V2_CURRENT_GROUP_ID));
export const getV2CurrentRoleInfo = () => JSON.parse(getLocalStorageItem(V2_CURRENT_ROLE_INFO));

export const removeAllV2DashboardsLocalStorage = () => {
  // eslint-disable-next-line no-plusplus
  for (let i = localStorage.length - 1; i >= 0; i--) {
    if (
      localStorage.key(i).includes(V2_SEND_EMAIL_SELECTED_ROW_KEYS) ||
      localStorage.key(i).includes(V2_SEND_EMAIL_SELECTED_ROW_INFO) ||
      localStorage.key(i).includes(V2_DASHBOARD_TABLE_FILTER) ||
      localStorage.key(i).includes(V2_SEND_EMAIL_LOADING)
    ) {
      localStorage.removeItem(localStorage.key(i));
    }
  }
};

export function useLocalStorageV2Dashboard() {
  const [tableFilter, setTableFilter] = useLocalStorageState(
    `${getV2CurrentRoleInfo()}_${getV2CurrentGroupId()}_${V2_DASHBOARD_TABLE_FILTER}`,
    {},
  );

  const [selectedRowKeys, setSelectedRowKeys] = useLocalStorageState(
    `${getV2CurrentRoleInfo()}_${getV2CurrentGroupId()}_${V2_SEND_EMAIL_SELECTED_ROW_KEYS}`,
    [],
  );

  const [selectedRevieweesInfo, setSelectedRevieweesInfo] = useLocalStorageState(
    `${getV2CurrentRoleInfo()}_${getV2CurrentGroupId()}_${V2_SEND_EMAIL_SELECTED_ROW_INFO}`,
    [],
  );

  const [sendEmailLoading, setSendEmailLoading] = useLocalStorageState(
    `${getV2CurrentRoleInfo()}_${getV2CurrentGroupId()}_${V2_SEND_EMAIL_LOADING}`,
    false,
  );

  const [showSendEmailError, setShowSendEmailError] = useLocalStorageState(
    `${getV2CurrentRoleInfo()}_${getV2CurrentGroupId()}_${V2_SHOW_SEND_EMAIL_ERROR}`,
    false,
  );

  return {
    tableFilter,
    setTableFilter,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRevieweesInfo,
    setSelectedRevieweesInfo,
    sendEmailLoading,
    setSendEmailLoading,
    showSendEmailError,
    setShowSendEmailError,
  };
}
