import AutoComplete from '@@/common/component/AutoComplete';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import PerfModal from '@@/common/component/PerfModal';
import UploadFiles from '@@/common/component/UploadFile';
import { UserInfoContext } from '@@/context/userInfo.context';
import { PermissionsItem } from '@@/features/performance/v2/admin/components/PermissionsModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { SelectOptionValueType } from '@@/types/antdComponent';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Col, Collapse, Form, Input, Row, Select } from 'antd';
import { get, isArray, isEmpty, isNumber } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.less';
import {
  AddOrEditModalProps,
  formValuesType,
  SearchedPeopleInfo,
  SearchInputValueType,
} from './type';
import WarningText from '@@/_new_src_/components/WarningText';

const AddOrEditModal = (props: AddOrEditModalProps) => {
  const {
    modalClassName,
    modalTitle,
    dropDownListInput,
    textInput,
    searchInput,
    warningTips,
    permissionList,
    fileUploadInput,
    customButtons,
    loading,
    addEventMatomo,
  } = props;
  const currentSearchInputValues = get(searchInput, 'currentValues', []);
  const isExistSearchValues = get(searchInput, 'isExistCurrentValues');
  const isShowSearchInputInfoMessage = get(searchInput, 'isShowSearchInputInfoMessage');
  const searchInputInfoTips = get(searchInput, 'searchInputInfoTips');

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const userInfo = useContext(UserInfoContext);

  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const localeResource = useSelector(selectLocaleResource) as unknown;

  const { Panel } = Collapse;

  const {
    admin: {
      manageAdminRole: { addAdminModal: locale },
    },
  } = localeResource as {
    admin: {
      manageAdminRole: {
        addAdminModal: {
          nameLabel: string;
          nameNotFound: string;
          cancel: string;
          add: string;
          title: (adminType: string) => string;
          namePlaceholder: (adminType: string) => string;
          errorMessage: (adminType: string) => string;
          showPermissions: string;
          hidePermissions: string;
        };
      };
    };
  };

  const inputs = [
    { inputName: 'searchInput', inputObject: searchInput },
    { inputName: 'dropDownListInput', inputObject: dropDownListInput },
    { inputName: 'textInput', inputObject: props.textInput },
  ];

  const isValidRequireFields = () => {
    for (let index = 0; index < inputs.length; index++) {
      const inputItem = inputs[index];
      if (inputItem.inputObject && inputItem.inputObject.isRequire) {
        const valueInput = form.getFieldValue(inputItem.inputName) as number | string | string[];
        const isEmptyValue = !isNumber(valueInput) && isEmpty(valueInput);
        if (!valueInput || isEmptyValue) {
          return false;
        }
      }
    }
    return true;
  };

  const checkDisableSave = () => {
    const fieldKeys = Object.keys(form.getFieldsValue());
    return (
      !isValidRequireFields() ||
      !isEmpty(form.getFieldsError(fieldKeys).filter(({ errors }) => errors.length))
    );
  };

  useEffect(() => {
    if (!props.visible) {
      return;
    }
    form.setFieldsValue({
      searchInput: currentSearchInputValues,
    });

    if (isExistSearchValues && searchInput && searchInput.checkShowSearchInputTips) {
      searchInput.checkShowSearchInputTips(currentSearchInputValues);
    }

    const isDisableSave = checkDisableSave();
    setSubmitDisabled(isDisableSave);
  }, [isExistSearchValues, props.visible]);

  useEffect(() => {
    if (!props.visible) {
      return;
    }
    const isDisableSave = checkDisableSave();
    setSubmitDisabled(isDisableSave);
  }, [form, currentSearchInputValues, props.visible]);

  const onReset = useCallback(() => {
    form.resetFields();
    setSubmitDisabled(true);
    setActiveKey([]);
  }, [form]);

  const handleCancel = () => {
    onReset();
    if (customButtons?.cancelButton?.handleClick) {
      customButtons.cancelButton.handleClick();
    }
  };

  const onSubmit = async () => {
    try {
      const handleOk = customButtons?.okButton?.handleClick;
      if (handleOk) {
        const formFields = (await form.validateFields()) as formValuesType;

        const searchInput = get(formFields, 'searchInput', '') as string | string[];
        const searchInputValues = isArray(searchInput)
          ? searchInput.map(item => get(item, 'value', '') as string)
          : [get(searchInput, 'value', '') as string];

        const dropdownInputValue = get(formFields, 'dropDownListInput', NaN) as number;
        await handleOk({ searchInputValues, dropdownInputValue, addEventMatomo });
      }
    } catch (error) {
      setSubmitDisabled(true);
    }
  };

  const onDropdownListChange = (value: number) => {
    dropDownListInput &&
      dropDownListInput.handleChangeDropdownList &&
      dropDownListInput.handleChangeDropdownList(value);
    form.setFields([
      {
        name: 'dropdownListInput',
        value: value,
        touched: true,
      },
    ]);
  };

  const renderNameOptions = useCallback(
    (list: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      const fieldNameValue = get(searchInput, 'fieldNameValue') || 'email';
      const selectedValues = (form.getFieldValue('searchInput') ||
        []) as Array<SelectOptionValueType>;

      if (searchInput && searchInput.formatSearchData) {
        const listData = searchInput.formatSearchData(list, selectedValues) || [];

        return listData.map(item => {
          const itemInputValue = get(item, fieldNameValue) as string;
          return (
            <Option
              key={String(item.email)}
              label={item.name}
              value={itemInputValue}
              disabled={item.isDisabled}
              {...item}
            >
              {item.displayLabel}
            </Option>
          );
        });
      }

      return list.map(item => {
        const itemInputValue = get(item, fieldNameValue) as string;
        const id = item.userId || item.employeeId || 0;
        const alreadySelectedUsers = (form.getFieldValue('searchInput') || []) as
          | Array<SelectOptionValueType>
          | SelectOptionValueType;
        const isAlreadySelected =
          isArray(alreadySelectedUsers) &&
          alreadySelectedUsers
            .map((item: SelectOptionValueType) => item.value)
            .includes(itemInputValue);

        const shouldDisable =
          (searchInput &&
            searchInput?.disableSearchInputItem &&
            searchInput?.disableSearchInputItem(get(item, 'userId', 0))) ||
          isAlreadySelected;
        return (
          <Option
            key={String(item.email)}
            label={item.name}
            value={itemInputValue}
            disabled={shouldDisable}
          >
            {`${get(item, 'name', '')}, ${id}`}
          </Option>
        );
      });
    },
    [userInfo.userId],
  );

  const onFormFieldsChange = useCallback(() => {
    const isDisabled = checkDisableSave();
    setSubmitDisabled(isDisabled);
  }, [form]);

  const getFileList = () => {
    //do st
  };
  const clearCreateGroupError = () => {
    //do st
  };
  const getExcelData = () => {
    //do st
  };

  const renderDropdownList = () => {
    return (
      dropDownListInput?.listData &&
      dropDownListInput?.listData.map(groupItem => (
        <Select.OptGroup key={groupItem.groupName} label={groupItem.groupName}>
          {groupItem.groupMemberList &&
            groupItem.groupMemberList.map(({ id, displayValue, disable }) => {
              return (
                <Select.Option key={id} value={id} label={displayValue} disabled={disable}>
                  {displayValue}
                </Select.Option>
              );
            })}
        </Select.OptGroup>
      ))
    );
  };

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = () => {
    const isActive = activeKey?.includes('permission-panel');

    return (
      <>
        <span className="header-text">
          {isActive ? locale.hidePermissions : locale.showPermissions}
        </span>
        <DownOutlined rotate={isActive ? 180 : 0} />
      </>
    );
  };

  const onChange = (selectedValues: Array<SearchInputValueType> | string) => {
    if (searchInput && searchInput.onChange && isArray(selectedValues)) {
      searchInput.onChange(selectedValues);
    }
  };

  return (
    <PerfModal
      title={modalTitle}
      centered
      className={`add-edit-modal ${modalClassName || ''}`}
      maskClosable={false}
      okText={customButtons?.okButton?.label || locale.add}
      onOk={onSubmit}
      okButtonProps={{
        disabled: submitDisabled,
        loading: customButtons?.okButton?.loading,
      }}
      cancelText={customButtons?.cancelButton?.label || locale.cancel}
      afterClose={onReset}
      onCancel={handleCancel}
      destroyOnClose
      {...props}
    >
      <LoadingWrapper wrapperClassName="modal-loading" loading={loading || false}>
        <Form
          className="add-edit-form-modal"
          form={form}
          layout="vertical"
          onFieldsChange={onFormFieldsChange}
        >
          {dropDownListInput && (
            <Row gutter={30}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="dropDownListInput"
                  label={
                    <>
                      {dropDownListInput.label}
                      {dropDownListInput.isRequire && <span className="required-mark">*</span>}
                    </>
                  }
                >
                  <Select
                    className="select"
                    suffixIcon={<CaretDownOutlined className="select-icon" />}
                    placeholder={dropDownListInput.placeholder}
                    popupClassName="add-edit-modal-select-option"
                    onChange={onDropdownListChange}
                  >
                    {renderDropdownList()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {textInput && (
            <Row gutter={30}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="textInputName"
                  label={
                    <>
                      {textInput.label}
                      {textInput.isRequire && <span className="required-mark">*</span>}
                    </>
                  }
                  rules={[
                    {
                      required: textInput.isRequire || false,
                      whitespace: true,
                      message: '',
                    },
                  ]}
                >
                  <Input placeholder={textInput.placeholder} maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {searchInput && (
            <Row gutter={30}>
              <Col md={24} xs={24}>
                <Form.Item
                  label={
                    <>
                      {searchInput.label}
                      {searchInput.isRequire && <span className="required-mark">*</span>}
                    </>
                  }
                  name="searchInput"
                  rules={[
                    {
                      required: searchInput.isRequire || false,
                      message: searchInput.errorMessage?.require || '',
                    },
                  ]}
                >
                  <AutoComplete
                    className="autocomplete"
                    showArrow={false}
                    showSearch
                    placeholder={searchInput.placeholder}
                    notFoundContent={searchInput.notFoundText}
                    fetchApi={searchInput.fetchApi}
                    mode={searchInput.mode || ''}
                    limitNum={searchInput.limitNumber?.numberOfAssignable}
                    customRender={get(searchInput, 'customRender')}
                    onChange={onChange}
                  >
                    {renderNameOptions}
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowSearchInputInfoMessage && searchInputInfoTips}

          <div className="tip-permission-section">
            {warningTips && (
              <WarningText warningText={warningTips} className={'permission-section-warning'} />
            )}
            {permissionList && (
              <div>
                <Collapse className="permission-collapse" ghost onChange={onCollapseChange}>
                  <Panel
                    key="permission-panel"
                    className="permission-panel"
                    showArrow={false}
                    header={renderPanelHeader()}
                  >
                    {permissionList?.map((item, index) => (
                      <PermissionsItem key={index} permission={item} />
                    ))}
                  </Panel>
                </Collapse>
              </div>
            )}
          </div>

          {fileUploadInput && (
            <Row gutter={30}>
              <Col md={24} xs={24}>
                <Form.Item className="dragger-wrapper">
                  <UploadFiles
                    getFileList={getFileList}
                    clearCreateGroupError={clearCreateGroupError}
                    getExcelData={getExcelData}
                  />
                </Form.Item>

                {/* <Form.Item
                validateStatus={
                  ERROR_CODE.INVALID_MEMBER === createGroupError.code ? 'error' : 'success'
                }
              >
                {isInvalidMembers ? (
                  <div className="import-members-error-msg">
                    <div>{textErrorMessage}</div>
                    <div>{listEmployeeIds} </div>
                  </div>
                ) : null}
              </Form.Item> */}
              </Col>
            </Row>
          )}
        </Form>
      </LoadingWrapper>
    </PerfModal>
  );
};

export default memo(AddOrEditModal);
