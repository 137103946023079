import React, { memo, useCallback, useRef, useState } from 'react';
import * as Api from '../../../api/review';
import perfMessage from '../PerfMessage/perfMessage';
import PerfModal from '../PerfModal';
import PerfTextArea from '../PerfTextArea';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { MY_CYCLE_DETAIL_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function SendEditRequestModal(props) {
  const { trackEvent } = useMatomo();
  const {
    currentCycleDetail: { id: cycleId, isTwer, isPerformancePartner },
  } = useSelector(cycleDetailByVersion);
  const {
    reviewList: {
      requestEdit: { modal: locale = {} },
    },
  } = useSelector(selectLocaleResource);
  const [reason, setReason] = useState('');
  const [sendLoading, setSendLoading] = useState(false);
  const isModalClosing = useRef(false);

  const onSendEditRequest = useCallback(async () => {
    // fix quick click send request button :)
    if (isModalClosing.current) {
      return;
    }
    setSendLoading(true);

    isTwer &&
      trackEvent({
        category: MY_CYCLE_DETAIL_API.category,
        action: MY_CYCLE_DETAIL_API.action.TWER_SENDS_CYCLE_EDITING_REQUEST,
      });

    isPerformancePartner &&
      trackEvent({
        category: TWER_I_SUPPORT_BY_PP_API.category,
        action: TWER_I_SUPPORT_BY_PP_API.action.PP_SENDS_CYCLE_EDITING_REQUEST,
      });

    await Api.sendEditRequest({
      reason: reason?.trim(),
      reviewId: cycleId,
    }).finally(() => {
      setSendLoading(false);
    });
    isModalClosing.current = true;
    perfMessage.success(locale.successMessage);
    if (props.onSuccess) {
      props.onSuccess();
    }
  }, [locale, props, cycleId, reason]);

  const onResetReason = () => {
    isModalClosing.current = false;
    setReason('');
  };

  return (
    <PerfModal
      title={locale.title}
      okText={locale.okText}
      destroyOnClose
      centered
      className="request-edit-modal"
      onOk={onSendEditRequest}
      okButtonProps={{
        loading: sendLoading,
        disabled: !reason?.trim().length,
      }}
      afterClose={onResetReason}
      maskClosable={false}
      {...props}
    >
      <h3 className="request-edit-modal-title">{locale.textarea.label}</h3>
      <PerfTextArea
        maxLength={250}
        placeholder={locale.textarea.placeholder}
        inputCount
        onChange={setReason}
      />
    </PerfModal>
  );
}

export default memo(SendEditRequestModal);
