import React, { useState } from 'react';
import { Tooltip } from 'antd';
import './index.less';
import { IAttachmentBtnProps } from '@@/_new_src_/types/features';
import PreviewModal from '../PreviewModal';

const AttachmentBtn = (props: IAttachmentBtnProps) => {
  const { title = '', url = '' } = props;
  const [isShow, setIsShow] = useState(false);

  const onAttachmentClick = () => {
    setIsShow(true);
  };

  return (
    <div className="attachment-btn">
      <i className="ri-attachment-2 component-attachment-icon" />
      <Tooltip title={title}>
        <span onClick={onAttachmentClick} className="component-attachment-content">
          {title}
        </span>
      </Tooltip>
      <PreviewModal url={url} title={title} isShow={isShow} updateIsShow={setIsShow} />
    </div>
  );
};

export default AttachmentBtn;
