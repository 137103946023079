import cls from 'classnames';
import React, { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import usePerfModal from '../../../hooks/usePerfModal';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import PerfModal from '../PerfModal';
import './index.less';

const Attachment = props => {
  const { remoteLink, name, closable, onClick, localFile } = props;
  const [loading, setLoading] = useState(true);
  const [visible, onOpenModal, onCloseModal] = usePerfModal();
  const [localFileLink, setLocalFileLink] = useState('');
  const linkRef = useRef();
  const isImage = useMemo(
    () => /^https?:\/\/.*\.(gif|png|jpg|jpeg|webp|svg)/i.test(remoteLink),
    [remoteLink],
  );

  useEffect(() => {
    if (localFile) {
      setLocalFileLink(URL.createObjectURL(localFile));
    }
  }, [localFile]);

  const showImage = useMemo(() => {
    if (localFile) {
      return localFile.type !== 'application/pdf';
    }
    return isImage;
  }, [localFile, isImage]);

  const onAttachmentClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (!showImage) {
      linkRef.current.click();
    } else {
      onOpenModal();
    }
  }, [onClick, showImage, onOpenModal]);

  const onClose = useCallback(
    e => {
      e.stopPropagation();
      if (props.onClose) {
        props.onClose();
      }
    },
    [props],
  );

  const onLoadAttachmentSuccess = () => {
    setLoading(false);
  };

  const onLoadAttachmentError = () => {
    setLoading(false);
  };

  if (!name) {
    return null;
  }

  return (
    <>
      <div
        className={cls('component-attachment', {
          'component-attachment-has-link': localFileLink || remoteLink,
        })}
        onClick={onAttachmentClick}
      >
        <i className="ri-attachment-2 component-attachment-icon" />
        <span className="component-attachment-content">{name}</span>
        {closable && (
          <span className="component-attachment-close" onClick={onClose}>
            <i className="ri-close-circle-line" />
          </span>
        )}
      </div>
      {!showImage ? (
        <a href={localFileLink || remoteLink} target="_blank" rel="noreferrer" ref={linkRef} hidden>
          {name}
        </a>
      ) : (
        <PerfModal
          className="component-attachment-modal"
          visible={visible}
          footer={null}
          onCancel={onCloseModal}
        >
          <LoadingWrapper loading={loading}>
            <div style={{ minHeight: 200 }}>
              <img
                src={localFileLink || remoteLink}
                alt={name}
                onLoad={onLoadAttachmentSuccess}
                onError={onLoadAttachmentError}
              />
            </div>
          </LoadingWrapper>
        </PerfModal>
      )}
    </>
  );
};

Attachment.defaultProps = {
  closable: false,
};

export default memo(Attachment);
