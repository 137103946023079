import {
  getCurrentCycleFeedbackListAsync,
  putCancelEditFeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import {
  setClientFeedback,
  setInternalFeedback,
  setRequestFeedbacks,
  setUpdateFeedbackInfo,
  V2FeedbackStore,
} from '@@/_new_src_/store/V2FeedbackSlice';
import {
  FEEDBACK_TYPE,
  isCurrentCyclesPage,
  OPERATION_TYPE,
} from '@@/_new_src_/constants/feedback';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import { setConfirmModalLoading, setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import perfMessage from '@@/_new_src_/components/perfMessage';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import {
  getOngoingCycleAsync,
  getPastCycleAvailableYearAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  cycleDetailData,
  cycleInformation,
  pastCyclesPageStore,
  pastCycleStore,
} from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import RequestList from '@@/_new_src_/page/feedback/component/RequestList';
import Notification from '@@/_new_src_/components/Notification';
import EmptyContainer from '../EmptyContainer';
import NoCycleContainer from '../NoCycleContainer';

import {
  feedbackNotificationVisible,
  setFeedbackNotificationVisible,
} from '@@/_new_src_/store/persistenceSlice';
import { REQUEST_FEEDBACK_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { userStore } from '@@/_new_src_/store/userSlice';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { getNewDateFormatByProbation } from '@@/_new_src_/utils/common/date';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

const RequestFeedbackTab = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();

  const {
    feedback: {
      clientFeedbackForm: {
        cancelSuccessMessage,
        confirmModalInfo: { title, content, cancel, OK },
        cancelModalInfo: { cancelContent, no, yes },
      },
    },
    Notification: { bannerTitle, bannerContent },
  } = FeedbackLocale;

  const {
    requestFeedbacks: requestFeedbacksStore,
    requestFeedbacks: {
      loading: feedbacksLoading,
      success: requestFeedbackSuccess,
      content: feedbackList,
      tableConfig,
    },
    clientFeedback: { cancelingFeedbackId: cancelClientFeedbackId },
    internalFeedback: { cancelingFeedbackId: cancelInternalFeedbackId, status },
    updateFeedbackInfo: {
      operationType,
      type: updateFeedbackInfoType,
      success: updateFeedbackInfoSuccess,
      rejected: updateFeedbackInfoRejected,
    },
  } = useSelector(V2FeedbackStore);
  const { isPendingFeedback } = getFeedbackStatus(status);

  const {
    userInfo: { currentCycleVersion, futureCycleVersion },
  } = useSelector(userStore);

  const cycleInformationStore = useSelector(cycleInformation);
  const cycleId = get(cycleInformationStore, 'id') as number;
  const cycleStatus = get(cycleInformationStore, 'status', '');
  const isProbation = get(cycleInformationStore, 'isProbation', false);
  const { name: projectName } = get(cycleInformationStore, 'project', { name: '' });
  const { endTime, startTime } = get(cycleInformationStore, 'duration', {
    endTime: '',
    startTime: '',
  });

  const durationFormatter = getNewDateFormatByProbation(isProbation);
  const durationStartTime = dayjs(startTime).format(durationFormatter);
  const durationEndTime = dayjs(endTime).format(durationFormatter);

  const { isOpenOrDraftCycle } = getCycleStatus(cycleStatus);

  const { loading: onGoingLoading } = useSelector(cycleDetailData);

  const { getPastCyclesLoading } = useSelector(pastCycleStore);
  const { cycleFilterValue } = useSelector(pastCyclesPageStore);

  const visible = useSelector(feedbackNotificationVisible);

  useEffect(() => {
    (currentCycleVersion === CYCLE_VERSION.OLD ||
      (isEmpty(currentCycleVersion) && futureCycleVersion === CYCLE_VERSION.OLD)) &&
      dispatch(setFeedbackNotificationVisible(false));
    dispatch(getOngoingCycleAsync());
    dispatch(setRequestFeedbacks({ ...requestFeedbacksStore, tableConfig: tableConfig }));
    dispatch(getPastCycleAvailableYearAsync());
  }, []);

  useEffect(() => {
    if (cycleId && isCurrentCyclesPage(cycleFilterValue)) {
      dispatch(
        getCurrentCycleFeedbackListAsync({
          cycleId: cycleId,
          sortColumnName: tableConfig.sortColumnName,
          sortOrder: tableConfig.sortOrder,
          pageSize: tableConfig.pageSize,
          pageNumber: tableConfig.pageNumber,
        }),
      );
    }
  }, [cycleId]);

  useEffect(() => {
    if (
      cycleId &&
      updateFeedbackInfoSuccess &&
      operationType === OPERATION_TYPE.CANCEL &&
      (updateFeedbackInfoType === FEEDBACK_TYPE.CLIENT_FEEDBACK ||
        updateFeedbackInfoType === FEEDBACK_TYPE.INTERNAL_FEEDBACK)
    ) {
      perfMessage.success(cancelSuccessMessage);
      dispatch(
        getCurrentCycleFeedbackListAsync({
          cycleId: cycleId,
          ...tableConfig,
        }),
      );
      dispatch(setUpdateFeedbackInfo({}));
    }
    dispatch(setConfirmModalLoading(false));
    dispatch(setConfirmModalStatus(false));
    dispatch(setClientFeedback({}));
    dispatch(setInternalFeedback({}));
  }, [operationType, updateFeedbackInfoSuccess, updateFeedbackInfoType]);

  useEffect(() => {
    if (updateFeedbackInfoRejected) {
      dispatch(setConfirmModalStatus(false));
      dispatch(setUpdateFeedbackInfo({}));
    }
  }, [updateFeedbackInfoRejected]);

  const onCancelCloseAddFeedbackModal = () => {
    dispatch(setConfirmModalStatus(false));
  };

  const onConfirmCloseAddFeedbackModal = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setClientFeedback({}));
    dispatch(setInternalFeedback({}));
  };

  const onCloseCancelFeedback = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setClientFeedback({}));
  };

  const onConfirmCancelFeedback = () => {
    if (cancelClientFeedbackId) {
      const param = {
        feedbackId: cancelClientFeedbackId,
        type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
      };
      dispatch(putCancelEditFeedbackAsync(param));

      trackEvent({
        category: REQUEST_FEEDBACK_MANAGEMENT.category,
        action: REQUEST_FEEDBACK_MANAGEMENT.action.CANCEL_FEEDBACK,
      });

      dispatch(setConfirmModalStatus(false));
      dispatch(setConfirmModalLoading(false));
    }
  };

  const handleConfirmOkFunc = () => {
    if (cancelInternalFeedbackId) {
      const param = {
        feedbackId: cancelInternalFeedbackId,
        type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
      };
      dispatch(setConfirmModalLoading(true));
      dispatch(putCancelEditFeedbackAsync(param));

      trackEvent({
        category: REQUEST_FEEDBACK_MANAGEMENT.category,
        action: REQUEST_FEEDBACK_MANAGEMENT.action.CANCEL_FEEDBACK,
      });
    }
  };
  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setInternalFeedback({}));
  };

  const hideNotification = () => {
    dispatch(setFeedbackNotificationVisible(false));
  };

  const confirmModalProps = () => {
    const {
      title: internalCancelTitle,
      pendingContent,
      draftedContent,
      cancelText,
      okText,
    } = FeedbackLocale.feedback.confirmModalInfo;

    if (cancelInternalFeedbackId) {
      return {
        title: internalCancelTitle,
        content: isPendingFeedback ? pendingContent : draftedContent,
        cancelText: cancelText,
        okText: okText,
        handleOkFunc: handleConfirmOkFunc,
        handleCancelFunc: handleConfirmCancelFunc,
        modalClassName: 'feedback-modal',
      };
    }

    if (cancelClientFeedbackId) {
      return {
        title: title,
        content: cancelContent,
        cancelText: cancel,
        okText: yes,
        handleOkFunc: onConfirmCancelFeedback,
        handleCancelFunc: onCloseCancelFeedback,
        modalClassName: 'feedback-modal',
      };
    }

    return {
      title: title,
      content: content,
      cancelText: no,
      okText: OK,
      handleOkFunc: onConfirmCloseAddFeedbackModal,
      handleCancelFunc: onCancelCloseAddFeedbackModal,
      modalClassName: 'feedback-modal',
    };
  };

  const renderFeedbackContent = () => {
    if (isCurrentCyclesPage(cycleFilterValue)) {
      if (!isOpenOrDraftCycle) {
        return <NoCycleContainer />;
      }
      if (isEmpty(feedbackList) && requestFeedbackSuccess) {
        return (
          <EmptyContainer
            projectName={projectName}
            startTime={durationStartTime}
            endTime={durationEndTime}
          />
        );
      }
    }
    return <RequestList cycleId={cycleId} />;
  };

  return (
    <LoadingWrapper loading={feedbacksLoading || onGoingLoading || getPastCyclesLoading}>
      <div className={'request-feedback-tab'}>
        <Notification
          content={bannerContent}
          title={bannerTitle}
          visible={visible}
          hideNotification={hideNotification}
        />
        <div className={'request-feedback-tab-container'}>{renderFeedbackContent()}</div>
        <ConfirmModal {...confirmModalProps()} />
      </div>
    </LoadingWrapper>
  );
};

export default RequestFeedbackTab;
