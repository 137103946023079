import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCalibrationHistoriesByCycleId,
  getEditRequestByRequestId,
  getRequestToEditByCycleId,
  patchAssessmentByPP,
  patchEditRequestByRequestId,
  postRequestToEdit,
  postUnmarkAssessmentNonEngagedByTbp,
  putPPSaveAssessment,
} from '@@/_new_src_/api/cycleDetail';
import {
  IAssessment,
  ICalibrationHistory,
  IGetEditRequestByRequestIdResponse,
  IGetRequestToEditData,
  IPatchRequestParam,
  IPutPPSaveAssessment,
} from './interface';

export const postRequestToEditAsync = createAsyncThunk(
  'postRequestToEdit',
  async (data: { cycleId: number; reason: string }) => {
    await postRequestToEdit(data.cycleId, data.reason);
  },
);

export const getRequestToEditByCycleIdAsync = createAsyncThunk(
  'getRequestToEditByCycleId',
  async (cycleId: number) => {
    const response = await getRequestToEditByCycleId(cycleId);
    return response.data as IGetRequestToEditData;
  },
);
export const getEditRequestByRequestIdAsync = createAsyncThunk(
  'getEditRequestByRequestId',
  async (requestId: number) => {
    const response = await getEditRequestByRequestId(requestId);
    return response.data as IGetEditRequestByRequestIdResponse;
  },
);
export const patchEditRequestByRequestIdAsync = createAsyncThunk(
  'patchEditRequestByRequestId',
  async (params: IPatchRequestParam) => {
    await patchEditRequestByRequestId(params);
  },
);

export const patchAssessmentByPPAsync = createAsyncThunk(
  'updateAssessmentByPP',
  async (data: { cycleId: number; assessment: IAssessment }) => {
    await patchAssessmentByPP(data.cycleId, data.assessment);
  },
);

export const postUnmarkAssessmentNonEngagedByTbpAsync = createAsyncThunk(
  'unmarkAssessmentNonEngagedByTbp',
  async (data: { cycleId: number; assessment: IAssessment }) => {
    await postUnmarkAssessmentNonEngagedByTbp(data.cycleId, data.assessment);
  },
);

export const putPPSaveAssessmentAsync = createAsyncThunk(
  'putPPSaveAssessment',
  async (data: IPutPPSaveAssessment) => {
    await putPPSaveAssessment(data);
  },
);

export const getCalibrationHistoriesByCycleIdAsync = createAsyncThunk(
  'getCalibrationHistoriesByCycleId',
  async (cycleId: number) => {
    const response = await getCalibrationHistoriesByCycleId(cycleId);
    return response.data as ICalibrationHistory[];
  },
);
