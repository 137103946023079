import { useMount } from 'ahooks';
import { Table, Tag, Tooltip } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { REQUEST_STATUS } from '@@/common/constant/requests';
import {
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_UPPER_CASE,
  SORT_DIRECTION_FOR_BE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { sourceCategoryJudgement } from '@@/common/utils/dashboardUtils/sourceCategory';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  requestListData,
  setRequestPageStatus,
  setSearchFilter,
} from '@@/redux/slice/dashboardSlice';
import { getPerformancePath } from '@@/common/utils';
import { setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import cls from 'classnames';
import { getRequestListBySourceCategoryAsync } from '@@/redux/slice/dashboardSlice/asyncThunk';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { formatRangeDuration, formatTimeFun } from '@@/_new_src_/utils/common/date';
import './index.less';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import { getSortOrder } from '@@/_new_src_/utils';
import { SORT_FIELD_DASHBOARD } from '@@/features/performance/v2/dashboardPage/common/constants';

const DashboardRequestList = () => {
  const history = useHistory();
  const [operationStatus] = useState({ loading: false, requestId: '' });
  const {
    dashboardV2: {
      requests: { list: locale },
    },
  } = useSelector(selectLocaleResource);

  const dispatch = useDispatch();
  const { count: openRequestCount } = useSelector(state => state.dashboard.openRequest);
  const searchFilter = useSelector(state => state.dashboard.searchFilter);
  const currentRoleSelection = useSelector(state => state.dashboard.currentRoleSelection);
  const currentGroupSelection = useSelector(state => state.dashboard.currentGroupSelection);
  const { roleId, roleName, roleInfo, roleType } = currentRoleSelection;
  const { groupType, groupId } = currentGroupSelection;
  const containerRef = useRef(null);

  const { loading, total, content } = useSelector(requestListData);
  const {
    tag: { nonEngagedCycleByTBPTag },
  } = commonLocale;

  const getStatusTooltip = (status, locale) => {
    switch (status) {
      case REQUEST_STATUS.OPEN: {
        return locale.pendingTooltip;
      }
      case REQUEST_STATUS.APPROVED: {
        return locale.approvedTooltip;
      }
      case REQUEST_STATUS.REJECTED: {
        return locale.rejectedTooltip;
      }
    }
  };

  const getStatusName = (status, locale) => {
    switch (status) {
      case REQUEST_STATUS.OPEN: {
        return locale.open;
      }
      case REQUEST_STATUS.APPROVED: {
        return locale.approved;
      }
      case REQUEST_STATUS.REJECTED: {
        return locale.rejected;
      }
    }
  };

  const createColumns = (locale, searchFilter) => [
    {
      title: locale.requestDate,
      dataIndex: 'requestDate',
      width: 160,
      ellipsis: { showTitle: false },
      ...getSortOrder(searchFilter, 'REQUEST_DATE'),
      render: requestDate => (
        <Tooltip placement="topLeft" title={requestDate}>
          {formatTimeFun(requestDate)}
        </Tooltip>
      ),
    },
    {
      title: locale.requester,
      width: 210,
      ellipsis: { showTitle: false },
      ...getSortOrder(searchFilter, 'REQUESTER'),
      render: ({ requester, noneEngaged }) => (
        <div className="tag-and-name">
          <Tooltip placement="topLeft" title={nonEngagedCycleByTBPTag}>
            {noneEngaged && (
              <Tag className="none-cycle-item" key="cycle-opened-by-TBP">
                {nonEngagedCycleByTBPTag}
              </Tag>
            )}
          </Tooltip>
          <Tooltip placement="topLeft" title={requester}>
            <div className="name">{requester}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: locale.reviewee,
      width: 200,
      ellipsis: { showTitle: false },
      ...getSortOrder(searchFilter, 'REVIEWEE'),
      render: ({ reviewee, probationStatus }) => {
        return (
          <div className="reviewee">
            <Tooltip className="reviewee-name" placement="topLeft" title={reviewee}>
              {reviewee}
            </Tooltip>
            {probationStatus === PROBATION_STATUS.UNDER_PROBATION && (
              <div className="probation-tip">{locale.probationTip}</div>
            )}
          </div>
        );
      },
    },
    {
      title: locale.reviewer,
      dataIndex: 'reviewer',
      width: 200,
      ellipsis: { showTitle: false },
      ...getSortOrder(searchFilter, 'REVIEWER'),
      render: reviewer => (
        <Tooltip placement="topLeft" title={reviewer}>
          {reviewer}
        </Tooltip>
      ),
    },
    {
      title: locale.cycleDuration,
      key: 'duration',
      width: 180,
      ellipsis: { showTitle: false },
      render: ({ startTime, endTime }) => {
        return (
          <Tooltip placement="topLeft" title={locale.cycleDuration}>
            {formatRangeDuration(startTime, endTime)}
          </Tooltip>
        );
      },
    },
    {
      title: locale.status,
      dataIndex: 'status',
      width: 105,
      ellipsis: { showTitle: false },
      ...getSortOrder(searchFilter, 'STATUS'),
      render: status => (
        <Tooltip
          className={cls(status, true)}
          title={getStatusTooltip(status, locale)}
          getPopupContainer={() => containerRef.current}
        >
          {getStatusName(status, locale)}
        </Tooltip>
      ),
    },
  ];

  const INIT_SEARCH_FILTER = {
    pageNumber: 1,
    pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
    sortColumn: SORT_FIELD_DASHBOARD.DEFAULT,
    sortOrder: SORT_DIRECTION_UPPER_CASE.DESC,
  };

  const columns = useMemo(() => createColumns(locale, searchFilter), [locale, searchFilter]);

  const getRequestListFromApi = useCallback(
    async sortAndPaginationParams => {
      const sourceCategory = sourceCategoryJudgement(
        roleName,
        groupId,
        groupType,
        roleType,
        roleId,
      );

      dispatch(
        getRequestListBySourceCategoryAsync({
          sourceCategory,
          roleId,
          groupId,
          sortAndPaginationParams,
          groupType,
          roleName,
        }),
      );
    },
    [roleName, roleType, roleId, groupType],
  );

  useMount(() => {
    let params;
    if (isEmpty(searchFilter)) {
      params = { ...INIT_SEARCH_FILTER };
    } else {
      params = { ...INIT_SEARCH_FILTER, ...searchFilter };
    }
    getRequestListFromApi(params);
  });

  const onTableChange = useCallback(
    (pagination, _, sorter) => {
      const newSearchFilter = {
        sortColumn:
          (sorter.column && SORT_FIELD_DASHBOARD[sorter.columnKey]) || SORT_FIELD_DASHBOARD.DEFAULT,
        sortOrder: SORT_DIRECTION_FOR_BE[sorter.order] || SORT_DIRECTION_UPPER_CASE.DESC,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
      };
      dispatch(setSearchFilter(newSearchFilter));
      getRequestListFromApi(newSearchFilter);
    },
    [getRequestListFromApi],
  );

  const onGotoCycleDetailPage = useCallback(
    record => () => {
      const { reviewId: cycleId, requestId, cycleVersion = CYCLE_VERSION.OLD } = record;
      if (operationStatus.loading) {
        return;
      }
      // update context before go to detail page
      dispatch(setRequestPageStatus({ list: { content, total }, filter: searchFilter }));
      let requestsDetailUrl = `/dashboard/requests/${cycleId}/${requestId}?selectedGroupId=${groupId}&selectedRole=${roleInfo}`;
      if (!isEmpty(groupType)) {
        requestsDetailUrl += `&selectedGroupType=${groupType}`;
      }
      dispatch(setCycleVersion(cycleVersion));
      history.push(`${getPerformancePath(requestsDetailUrl)}`);
    },
    [operationStatus.loading, content, total, searchFilter, history, groupId, roleInfo],
  );

  const addInputAttribute = () => {
    const tableWrapper = document.getElementsByClassName('dashboard-request-list-table');
    const paginationWrapper =
      tableWrapper.length > 0 && tableWrapper[0].getElementsByClassName('ant-pagination-options');
    const inputWrapper =
      paginationWrapper.length > 0 &&
      paginationWrapper[0].getElementsByClassName('ant-select-selection-search-input');
    if (inputWrapper.length > 0) {
      inputWrapper[0].setAttribute('aria-expanded', 'false');
      inputWrapper[0].setAttribute('aria-label', 'dashboard request list table pagination');
    }
  };

  useEffect(() => {
    Number(total) > TABLE_DEFAULT_INFO.PAGE_SIZE && addInputAttribute();
  }, [total]);

  return (
    <div className="dashboard-request-list" ref={containerRef}>
      <div className="dashboard-request-list-total">
        <span>{locale.total(openRequestCount)}</span>
      </div>
      <Table
        className={'dashboard-request-list-table'}
        rowKey="requestId"
        columns={columns}
        dataSource={content}
        loading={loading}
        onChange={onTableChange}
        pagination={
          Number(total) > TABLE_DEFAULT_INFO.PAGE_SIZE
            ? {
                ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                total,
                current: searchFilter.pageNumber,
                pageSize: searchFilter.pageSize,
              }
            : false
        }
        onRow={record => ({
          onClick: onGotoCycleDetailPage(record),
        })}
      />
    </div>
  );
};

export default memo(DashboardRequestList);
