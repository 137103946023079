import { getApiV2Path } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import {
  ALL_ACCOUNT,
  ALL_ACCOUNT_GROUPS_ID,
  ALL_ACCOUNT_UNIT,
  ALL_GROUPS_ID,
  ALL_GROUPS_UNIT,
  ALL_PEOPLE,
  ALL_PEOPLE_UNIT,
  REGIONAL_LEVEL_GROUPS,
} from '@@/common/constant/dashboard';
import { isEmpty, isNumber } from 'lodash';
import {
  ALL_ACCOUNT_GROUPS_TITLE,
  ALL_ACCOUNT_TITLE,
  ALL_GROUPS_TITLE,
  ALL_PEOPLE_TITLE,
  CUSTOMIZED_GROUPS_TITLE,
  SINGLE_ACCOUNT,
} from './constants';
import {
  OTHER_GLOBAL_FUNCTION_ID,
  V2GLOBALFUNCTION,
  V2REGION,
  V2SUPERVISOR,
  V2UNIT,
} from '@@/_new_src_/constants/adminEnum';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { getRegionalLevelGroups } from '@@/_new_src_/api/admin';

const commonGroupsFun = param => {
  const { id, roleHierarchy } = param;
  const isSuperVisorInfo = roleHierarchy === V2SUPERVISOR;
  let apiPath = isSuperVisorInfo
    ? getApiV2Path(`/supervisor/${id}/common-groups`)
    : getApiV2Path(`/units/${id}/common-groups`);

  return { apiPath };
};

const groupLeader = () => {
  return {
    allPeople: false,
    allGroup: false,
    singleGroup: { apiPath: getApiV2Path(`/groups/common-groups`) },
  };
};

const musl = ({ param }) => {
  const { apiPath } = commonGroupsFun(param);
  return {
    allPeople: true,
    allGroup: true,
    groupList: {
      apiPath,
    },
  };
};

const regionalLevelGroupManager = () => {
  return {
    allPeople: false,
    allGroup: false,
  };
};

const talentBp = ({ param }) => {
  const { apiPath } = commonGroupsFun(param);
  const { id, roleHierarchy } = param;
  return {
    allPeople: true,
    allGroup: !(roleHierarchy === V2SUPERVISOR && id === OTHER_GLOBAL_FUNCTION_ID),
    groupList: {
      apiPath,
    },
    AUTOMATED_GROUPS: {
      isShow: roleHierarchy === V2UNIT,
      title: ALL_ACCOUNT_TITLE,
      selectable: true,
      apiPath: getApiV2Path(
        `/accountHierarchy?display=true&hierarchyId=${id}&hierarchyType=${roleHierarchy}`,
      ),
    },
  };
};
const regionalTalentBp = ({ param }) => {
  return {
    allPeopleGroupList: { apiPath: getApiV2Path(`/regions/${param.id}/common-groups`) },
    REGIONAL_LEVEL_GROUPS: {
      title: REGIONAL_LEVEL_GROUPS,
      selectable: false,
      apiFun: () => getRegionalLevelGroups({ id: param.id }),
    },
  };
};
const globalFunctionServiceLineLeadership = ({ param }) => {
  const { id } = param;
  return {
    allPeople: true,
    allGroup: true,
    groupList: {
      apiPath: getApiV2Path(`/supervisor/${id}/common-groups`),
    },
  };
};

const chiefTalentOfficer = () => {
  return {
    allPeople: false,
    allGroup: false,
  };
};

const clientLeadershipTeam = () => {
  return {
    allPeople: false,
    allGroup: false,
  };
};

const globalTalentBp = () => {
  return {
    allPeopleGroupList: { apiPath: getApiV2Path(`/global/common-groups`) },
  };
};

export const getSecondSelectionApi = api => ajax.get(api);

const allSecondSelection = ({ param }) => {
  return {
    GROUP_LEADER: groupLeader(),
    MU_SL_Ops: musl({ param }),
    'Talent Business Partner': talentBp({ param }),
    'Regional Level Group Manager': regionalLevelGroupManager({ param }),
    'Regional Talent Business Partner': regionalTalentBp({ param }),
    'Global Function/Market/DEC/Service Line Leadership': globalFunctionServiceLineLeadership({
      param,
    }),
    'Global Head of People': chiefTalentOfficer(),
    [USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM]: clientLeadershipTeam(),
    [USER_ROLE_ENUM.GLOBAL_TALENT_BP]: globalTalentBp(),
  };
};

const formatApiData = ({ apiData, type }) => {
  const formatData = [];
  const keyList = [];

  let valueType = type ? `-_${type}` : '';

  apiData.map(item => {
    const id = item.id || item.groupId || item.value;
    const newKey = isNumber(id) ? `${id.toString()}${valueType}` : `${id}${valueType}`;
    keyList.push(newKey);
    return formatData.push({
      title: item.groupName || item.title || item.name,
      value: newKey,
    });
  });
  return { formatData, keyList };
};

const allPeopleAndAllGroupList = ({ apiData, roleHierarchy, roleHierarchyId = NaN }) => {
  const allPeopleUnitTreeList = [];
  let keyList = [];
  const newGroupTreeList = apiData.map(item => {
    const allGroupsUnitValueString = `${item.value.toString()}-_${ALL_GROUPS_UNIT}`;
    const allPeopleUnitValueString = `${item.value.toString()}-_${ALL_PEOPLE_UNIT}`;
    const allPeopleUnitItem = {
      title: `${ALL_PEOPLE_TITLE} - ${item.title}`,
      value: allPeopleUnitValueString,
    };
    allPeopleUnitTreeList.push(allPeopleUnitItem);
    keyList.push(allPeopleUnitValueString);
    let newChildren = [];
    if (item.children && item.children.length) {
      newChildren = item.children.map(childItem => {
        const childValueString = childItem.value.toString();
        keyList.push(childValueString);
        return {
          ...childItem,
          value: childValueString,
        };
      });
    }
    keyList.push(allGroupsUnitValueString);
    return {
      ...item,
      value: allGroupsUnitValueString,
      children: newChildren,
    };
  });

  const isGlobalTbp = roleHierarchy === V2GLOBALFUNCTION && roleHierarchyId === -2;

  const groupsTreeData = [
    { title: ALL_PEOPLE_TITLE, value: ALL_PEOPLE, children: allPeopleUnitTreeList },
    {
      title: roleHierarchy === V2REGION || isGlobalTbp ? CUSTOMIZED_GROUPS_TITLE : ALL_GROUPS_TITLE,
      value: ALL_GROUPS_ID,
      children: newGroupTreeList,
    },
  ];
  keyList.push(ALL_PEOPLE, ALL_GROUPS_ID);
  return { groupsTreeData, keyList };
};

export const getSecondSelectionData = async roleItem => {
  const { roleId: roleHierarchyId, roleName, secondList = [], roleType } = roleItem;
  const allSecondSelectionData = allSecondSelection({
    param: { id: roleHierarchyId, roleHierarchy: roleType },
  });
  const secondSelectionForRole = allSecondSelectionData[roleName];
  // 获取secondList（allPeople REGIONAL_LEVEL_GROUPS singleGroup allGroup groupList ）
  let groupOptionList = [];
  let groupKeyList = [];
  if (!isEmpty(secondSelectionForRole.allPeopleGroupList)) {
    const { apiPath, apiFun } = secondSelectionForRole.allPeopleGroupList;
    const apiData = apiFun ? await apiFun : await getSecondSelectionApi(apiPath);
    const { data = [] } = apiData;
    const { groupsTreeData, keyList } = allPeopleAndAllGroupList({
      apiData: data,
      roleHierarchy: roleType,
      roleHierarchyId: roleHierarchyId,
    });
    groupOptionList = [].concat(groupsTreeData);
    groupKeyList = [].concat(keyList);
  }

  if (secondSelectionForRole.allPeople) {
    groupOptionList.push({ title: ALL_PEOPLE_TITLE, value: ALL_PEOPLE });
    groupKeyList.push(ALL_PEOPLE);
  }

  if (!isEmpty(secondSelectionForRole.REGIONAL_LEVEL_GROUPS)) {
    const { apiPath, apiFun, selectable, title } = secondSelectionForRole.REGIONAL_LEVEL_GROUPS;
    const apiData = apiFun ? await apiFun() : await getSecondSelectionApi(apiPath);
    const { data = [] } = apiData;
    const { formatData, keyList } = formatApiData({ apiData: data });

    groupOptionList.splice(1, 0, {
      title,
      value: REGIONAL_LEVEL_GROUPS,
      children: formatData,
      selectable,
    });
    groupKeyList.push(REGIONAL_LEVEL_GROUPS);
    groupKeyList = groupKeyList.concat(keyList);
  }

  if (secondSelectionForRole.singleGroup) {
    const { apiPath, apiFun } = secondSelectionForRole.singleGroup;
    const apiData = apiFun ? await apiFun : await getSecondSelectionApi(apiPath);
    const { data = [] } = apiData;
    const { formatData, keyList } = formatApiData({ apiData: data });
    groupOptionList = groupOptionList.concat(formatData);
    groupKeyList = groupKeyList.concat(keyList);
  }

  if (!isEmpty(secondList)) {
    let { formatData: groupList, keyList } = formatApiData({ apiData: secondList });

    if (roleName === USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM) {
      const allAccountGroups = {
        title: ALL_ACCOUNT_GROUPS_TITLE,
        value: ALL_ACCOUNT_GROUPS_ID,
        children: [],
      };
      allAccountGroups.children = groupList;
      groupList = allAccountGroups;
      keyList = keyList.concat(ALL_ACCOUNT_GROUPS_ID);
    }

    groupOptionList = groupOptionList.concat(groupList);
    groupKeyList = groupKeyList.concat(keyList);
  }

  if (secondSelectionForRole.allGroup) {
    let allGroups = { title: ALL_GROUPS_TITLE, value: ALL_GROUPS_ID, children: [] };
    if (roleName === USER_ROLE_ENUM.TALENT_BP) {
      allGroups = { title: CUSTOMIZED_GROUPS_TITLE, value: ALL_GROUPS_ID };
    }
    if (!isEmpty(secondSelectionForRole.groupList)) {
      const { apiPath, apiFun } = secondSelectionForRole.groupList;
      const apiData = apiFun ? await apiFun : await getSecondSelectionApi(apiPath);
      const { data = [] } = apiData;
      const { formatData, keyList } = formatApiData({ apiData: data });
      allGroups.children = formatData;
      groupKeyList = groupKeyList.concat(keyList);
    }
    groupOptionList.push(allGroups);
    groupKeyList.push(ALL_GROUPS_ID);
  }

  if (!isEmpty(secondSelectionForRole.AUTOMATED_GROUPS)) {
    const { apiPath, apiFun, selectable, title, isShow } = secondSelectionForRole.AUTOMATED_GROUPS;
    if (isShow) {
      const apiData = apiFun ? await apiFun : await getSecondSelectionApi(apiPath);
      const { data = [] } = apiData;

      const { formatData, keyList } = formatApiData({
        apiData: data.content,
        type: SINGLE_ACCOUNT,
      });
      const allAccountValueString = `${ALL_ACCOUNT}-_${ALL_ACCOUNT_UNIT}`;
      groupOptionList.splice(1, 0, {
        title,
        value: allAccountValueString,
        children: formatData,
        selectable,
      });
      groupKeyList.push(allAccountValueString);
      groupKeyList = groupKeyList.concat(keyList);
    }
  }
  return { groupOptionList, groupKeyList };
};
