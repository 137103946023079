import { createSlice } from '@reduxjs/toolkit';
import {
  getCurrentCycleFeedbackListAsync,
  getFeedbackDetailAsync,
  getFeedbackTemplatesAsync,
  getGivenFeedbackRequestsAsync,
  patchGivenFeedbackAsync,
  postAddFeedbackAsync,
  postV1AddClientFeedbackAsync,
  postV1FeedbackAsync,
  postV1SendFeedbackRequestAsync,
  putCancelEditFeedbackAsync,
  putEditFeedbackAsync,
  putV1EditClientFeedbackAsync,
  putV1EditFeedbackRequestAsync,
} from './asyncThunk';
import {
  IClientFeedbackInfo,
  IV2Feedback,
  IFeedbackDetail,
  IPastFeedbackSorts,
  IFeedbackTemplate,
  IInternalFeedbackInfo,
  IRequestFeedbacks,
  IState,
  IUpdateFeedbackInfo,
  IV1FeedbackModalInfo,
} from './interface';
import {
  FEEDBACK_TAB_KEY,
  FEEDBACK_TYPE,
  FEEDBACK_SORT_COLUMNS_NAME,
  FeedbackStatusLabel,
  OPERATION_TYPE,
  RequestFeedbackListOptions,
} from '@@/_new_src_/constants/feedback';
import { SORT_DIRECTION_UPPER_CASE, TABLE_DEFAULT_INFO } from '@@/_new_src_/constants/table';
import DOMPurify from 'dompurify';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { IAsyncStatus } from '@@/_new_src_/types/common';

const initialState: IV2Feedback = {
  activeTabKey: FEEDBACK_TAB_KEY.REQUEST_FEEDBACK,
  requestFeedbacks: {
    ...INITIAL_ASYNC_STATUS,
    total: TABLE_DEFAULT_INFO.TOTAL,
    content: [],
    tableConfig: {
      sortColumnName: FEEDBACK_SORT_COLUMNS_NAME.default,
      sortOrder: SORT_DIRECTION_UPPER_CASE.ASC,
      pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
      pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
    },
    doneFeedbackIds: [],
  },
  pastFeedbacks: {},
  pastFeedbackSorts: {},
  givenFeedbacksStore: {
    ...INITIAL_ASYNC_STATUS,
    givenFeedbackRes: {
      total: TABLE_DEFAULT_INFO.TOTAL,
      pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
      pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
      pendingNumber: NaN,
      content: [],
    },
    tableConfig: {
      status: Object.keys(FeedbackStatusLabel),
      givenFeedbackSortColumn: FEEDBACK_SORT_COLUMNS_NAME.default,
      sortOrder: SORT_DIRECTION_UPPER_CASE.ASC,
      pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
      pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
    },
  },
  feedbackDetail: {
    ...INITIAL_ASYNC_STATUS,
    cycle: {
      probation: false,
      projectName: '',
      duration: {
        startTime: '',
        endTime: '',
      },
      cycleStatus: '',
    },
    feedback: {
      overDueDays: NaN,
      updatedAt: '',
      requestName: '',
      providerName: '',
      providerId: NaN,
      type: NaN,
      content: '',
      providerRole: '',
      projectName: '',
      projectAccount: '',
      projectId: '',
      context: '',
      dueDate: '',
      questionTemplateId: 1,
      questions: [],
      feedbackStatus: '',
      requestId: NaN,
      requestRole: '',
      expectations: [],
      version: NaN,
    },
    project: {
      assignmentId: '',
      name: '',
      account: '',
    },
    selectedExpectations: [],
    formatContext: '',
  },
  internalFeedback: {
    cancelingFeedbackId: NaN,
    status: '',
    feedbackIds: [],
    requestToIds: [],
    dueTime: '',
    context: '',
    expectations: [],
    question: {
      templateId: 0,
      customizedQuestions: [],
    },
  },
  clientFeedback: {
    cancelingFeedbackId: NaN,
    initialClientContent: '',
    initialClientName: '',
    updateClientText: '',
    updateClientHtml: '',
    updatedAt: '',
    clientName: '',
  },
  isShowConfirmModal: false,
  feedbackTemplates: {},
  patchGivenFeedbackResponse: {
    ...INITIAL_ASYNC_STATUS,
  },
  postV1Feedback: {
    ...INITIAL_ASYNC_STATUS,
  },
  V1FeedbackModalInfo: {
    ...INITIAL_ASYNC_STATUS,
    isClientFeedbackModalVisible: false,
    isFeedbackRequestModalVisible: false,
    isWriteFeedbackModalVisible: false,
    isSubmittedFeedbackDetailModalVisible: false,
    isCurrentCycle: false,
    isEdit: false,
    cycleId: NaN,
    feedbackId: NaN,
    type: NaN,
  },
  updateFeedbackInfo: {
    ...INITIAL_ASYNC_STATUS,
    type: NaN,
    operationType: '',
  },
};

const V2FeedbackSlice = createSlice({
  name: 'v2Feedback',
  initialState: { ...initialState },
  reducers: {
    setActiveTabKey: (state, action) => {
      state.activeTabKey = (action.payload as string) || FEEDBACK_TAB_KEY.REQUEST_FEEDBACK;
    },
    setRequestFeedbacks: (state, action) => {
      state.requestFeedbacks = {
        ...initialState.requestFeedbacks,
        ...action.payload,
      } as IRequestFeedbacks;
    },
    setPastFeedbackSorts: (state, action) => {
      state.pastFeedbackSorts = {
        ...initialState.pastFeedbackSorts,
        ...action.payload,
      } as IPastFeedbackSorts;
    },
    setFeedbackDetail: (state, action) => {
      state.feedbackDetail = {
        ...initialState.feedbackDetail,
        ...action.payload,
      } as IFeedbackDetail;
    },
    setClientFeedback: (state, action) => {
      state.clientFeedback = {
        ...initialState.clientFeedback,
        ...action.payload,
      } as IClientFeedbackInfo;
    },
    setInternalFeedback: (state, action) => {
      state.internalFeedback = {
        ...initialState.internalFeedback,
        ...action.payload,
      } as IInternalFeedbackInfo;
    },
    setPatchGivenFeedbackResponse: (state, action) => {
      state.patchGivenFeedbackResponse = {
        ...initialState.patchGivenFeedbackResponse,
        ...action.payload,
      } as IAsyncStatus;
    },
    setV1FeedbackModalInfo: (state, action) => {
      state.V1FeedbackModalInfo = {
        ...initialState.V1FeedbackModalInfo,
        ...action.payload,
      } as IV1FeedbackModalInfo;
    },
    setPostV1Feedback: (state, action) => {
      state.postV1Feedback = {
        ...initialState.postV1Feedback,
        ...action.payload,
      } as IAsyncStatus;
    },
    setUpdateFeedbackInfo: (state, action) => {
      state.updateFeedbackInfo = {
        ...initialState.updateFeedbackInfo,
        ...action.payload,
      } as IUpdateFeedbackInfo;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrentCycleFeedbackListAsync.pending, state => {
        state.requestFeedbacks = {
          ...initialState.requestFeedbacks,
          loading: true,
        };
      })
      .addCase(getCurrentCycleFeedbackListAsync.fulfilled, (state, action) => {
        const { type, cycleId, sortColumnName, sortOrder, pageSize, pageNumber } = action.meta.arg;
        const { content, doneFeedbackIds, total } = action.payload;

        if (type === RequestFeedbackListOptions[1].value) {
          const pastFeedbackMapKey = cycleId.toString();
          state.pastFeedbacks[pastFeedbackMapKey] = action.payload.content;
        }

        state.requestFeedbacks = {
          ...initialState.requestFeedbacks,
          success: true,
          content: type !== RequestFeedbackListOptions[1].value ? content : [],
          total: total,
          tableConfig: {
            ...initialState.requestFeedbacks.tableConfig,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortColumnName: sortColumnName,
            sortOrder: sortOrder,
          },
          doneFeedbackIds: doneFeedbackIds,
        };
      })
      .addCase(getCurrentCycleFeedbackListAsync.rejected, state => {
        state.requestFeedbacks = {
          ...initialState.requestFeedbacks,
          rejected: true,
        };
      })
      .addCase(postAddFeedbackAsync.pending, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          loading: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(postAddFeedbackAsync.fulfilled, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          success: true,
          type: action.meta.arg.type,
          operationType: OPERATION_TYPE.ADD,
        };
      })
      .addCase(postAddFeedbackAsync.rejected, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          rejected: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(putEditFeedbackAsync.pending, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          loading: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(putEditFeedbackAsync.fulfilled, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          success: true,
          type: action.meta.arg.type,
          operationType: OPERATION_TYPE.EDIT,
        };
      })
      .addCase(putEditFeedbackAsync.rejected, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          rejected: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(putCancelEditFeedbackAsync.pending, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          loading: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(putCancelEditFeedbackAsync.fulfilled, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          success: true,
          type: action.meta.arg.type,
          operationType: OPERATION_TYPE.CANCEL,
        };
      })
      .addCase(putCancelEditFeedbackAsync.rejected, (state, action) => {
        state.updateFeedbackInfo = {
          ...initialState.updateFeedbackInfo,
          rejected: true,
          type: action.meta.arg.type,
        };
      })
      .addCase(getFeedbackTemplatesAsync.pending, state => {
        state.feedbackTemplates = initialState.feedbackTemplates;
      })
      .addCase(getFeedbackTemplatesAsync.fulfilled, (state, action) => {
        const payload = action.payload as { [key: number]: IFeedbackTemplate[] };
        payload[Object.keys(payload).length + 1] = [
          {
            id: -1,
            templateId: -1,
            templateName: 'Customized questions',
            htmlTagQuestion: '',
            description: '',
          },
        ];
        state.feedbackTemplates = payload;
      })
      .addCase(getFeedbackTemplatesAsync.rejected, state => {
        state.feedbackTemplates = initialState.feedbackTemplates;
      })
      .addCase(getGivenFeedbackRequestsAsync.pending, (state, action) => {
        state.givenFeedbacksStore = {
          ...initialState.givenFeedbacksStore,
          loading: true,
          tableConfig: action.meta.arg,
        };
      })
      .addCase(getGivenFeedbackRequestsAsync.fulfilled, (state, action) => {
        state.givenFeedbacksStore = {
          ...initialState.givenFeedbacksStore,
          success: true,
          givenFeedbackRes: action.payload,
          tableConfig: action.meta.arg,
        };
      })
      .addCase(getGivenFeedbackRequestsAsync.rejected, (state, action) => {
        state.givenFeedbacksStore = {
          ...initialState.givenFeedbacksStore,
          rejected: true,
          tableConfig: action.meta.arg,
        };
      })
      .addCase(getFeedbackDetailAsync.pending, state => {
        state.feedbackDetail = {
          ...initialState.feedbackDetail,
          loading: true,
        };
      })
      .addCase(getFeedbackDetailAsync.fulfilled, (state, action) => {
        const { feedback } = action.payload;
        state.feedbackDetail = {
          ...initialState.feedbackDetail,
          ...action.payload,
          success: true,
          feedback: {
            ...feedback,
            questionTemplateId:
              feedback.questionTemplateId === -1 ? 3 : feedback.questionTemplateId,
          },
          project: {
            assignmentId: feedback.projectId,
            name: feedback.projectName,
            account: feedback.projectAccount,
          },
          formatContext: DOMPurify.sanitize(feedback.context),
          selectedExpectations: feedback.expectations?.filter(item => item.selected),
        };
      })
      .addCase(getFeedbackDetailAsync.rejected, state => {
        state.feedbackDetail = {
          ...initialState.feedbackDetail,
          rejected: true,
        };
      })
      .addCase(patchGivenFeedbackAsync.pending, state => {
        state.patchGivenFeedbackResponse = {
          ...initialState.patchGivenFeedbackResponse,
          loading: true,
        };
      })
      .addCase(patchGivenFeedbackAsync.fulfilled, state => {
        state.patchGivenFeedbackResponse = {
          ...initialState.patchGivenFeedbackResponse,
          success: true,
        };
      })
      .addCase(patchGivenFeedbackAsync.rejected, state => {
        state.patchGivenFeedbackResponse = {
          ...initialState.patchGivenFeedbackResponse,
        };
      })
      .addCase(postV1FeedbackAsync.pending, state => {
        state.postV1Feedback = {
          ...initialState.postV1Feedback,
          loading: true,
        };
      })
      .addCase(postV1FeedbackAsync.fulfilled, state => {
        state.postV1Feedback = {
          ...initialState.postV1Feedback,
          success: true,
        };
      })
      .addCase(postV1FeedbackAsync.rejected, state => {
        state.postV1Feedback = {
          ...initialState.postV1Feedback,
        };
      })
      .addCase(postV1AddClientFeedbackAsync.pending, state => {
        // eslint-disable-next-line max-lines
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          loading: true,
        };
      })
      .addCase(postV1AddClientFeedbackAsync.fulfilled, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          success: true,
        };
      })
      .addCase(postV1AddClientFeedbackAsync.rejected, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          rejected: true,
        };
      })
      .addCase(putV1EditClientFeedbackAsync.pending, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          loading: true,
        };
      })
      .addCase(putV1EditClientFeedbackAsync.fulfilled, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          success: true,
        };
      })
      .addCase(putV1EditClientFeedbackAsync.rejected, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          rejected: true,
        };
      })
      .addCase(postV1SendFeedbackRequestAsync.pending, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          loading: true,
        };
      })
      .addCase(postV1SendFeedbackRequestAsync.fulfilled, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          success: true,
        };
      })
      .addCase(postV1SendFeedbackRequestAsync.rejected, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          rejected: true,
        };
      })
      .addCase(putV1EditFeedbackRequestAsync.pending, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          loading: true,
        };
      })
      .addCase(putV1EditFeedbackRequestAsync.fulfilled, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          success: true,
        };
      })
      .addCase(putV1EditFeedbackRequestAsync.rejected, state => {
        state.V1FeedbackModalInfo = {
          ...state.V1FeedbackModalInfo,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          rejected: true,
        };
      });
  },
});

export const {
  setActiveTabKey,
  setRequestFeedbacks,
  setPastFeedbackSorts,
  setFeedbackDetail,
  setClientFeedback,
  setInternalFeedback,
  setUpdateFeedbackInfo,
  setPatchGivenFeedbackResponse,
  setV1FeedbackModalInfo,
  setPostV1Feedback,
} = V2FeedbackSlice.actions;

export const V2FeedbackStore = (state: IState) => state.v2Feedback;

export const requestFeedbacks = (state: IState) => state.v2Feedback.requestFeedbacks;

export const givenFeedbacksStore = (state: IState) => state.v2Feedback.givenFeedbacksStore;

export const feedbackDetail = (state: IState) => state.v2Feedback.feedbackDetail;

export const clientFeedback = (state: IState) => state.v2Feedback.clientFeedback;

export const internalFeedback = (state: IState) => state.v2Feedback.internalFeedback;

export const isShowConfirmModal = (state: IState) => state.v2Feedback.isShowConfirmModal;

export const feedbackTemplates = (state: IState) => state.v2Feedback.feedbackTemplates;

export const patchGivenFeedbackResponse = (state: IState) =>
  state.v2Feedback.patchGivenFeedbackResponse;

export const postV1Feedback = (state: IState) => state.v2Feedback.postV1Feedback;

export const V1FeedbackModalInfo = (state: IState) => state.v2Feedback.V1FeedbackModalInfo;

export const updateFeedbackInfo = (state: IState) => state.v2Feedback.updateFeedbackInfo;
export default V2FeedbackSlice.reducer;
