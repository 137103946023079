import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import { CurrentTableProps, ICurrentTableSort } from './interface';

import { get, isEmpty } from 'lodash';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { AutomatedGroupsList } from '@@/features/performance/v2/admin/newComponent/AutomatedGroups/interface';
import {
  ADMIN_TABLE_PAGE,
  ADMIN_TABLE_SORTER,
  ADMIN_VISIBLE_STATE,
} from '@@/_new_src_/constants/adminEnum';
import {
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { IPageParams } from '@@/_new_src_/types/common';

const TableRender = (props: CurrentTableProps) => {
  const {
    loading,
    column,
    rowSelection,
    listData,
    onChange,
    onRowClick,
    currentSort,
    keepTableStatus = false,
  } = props;

  const content = get(listData, 'content') || [];
  const total = get(listData, 'total') || 0;

  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<ICurrentTableSort>({
    sortColumnName: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (isEmpty(listData)) {
      return;
    }
    if (!keepTableStatus) {
      return;
    }
    const localSorter = JSON.parse(
      localStorage.getItem(ADMIN_TABLE_SORTER) as string,
    ) as ICurrentTableSort;
    setCurrentSorter(localSorter);
    currentSort && currentSort(localSorter);

    const localPage = JSON.parse(localStorage.getItem(ADMIN_TABLE_PAGE) as string) as IPageParams;
    const localPageSize = get(localPage, 'pageSize') || TABLE_DEFAULT_INFO.PAGE_SIZE;
    const localPageNumber = get(localPage, 'pageNumber') || TABLE_DEFAULT_INFO.PAGE_NUMBER;

    let currentPageNumber = localPageNumber;
    if (Math.ceil(total / localPageSize) < localPageNumber) {
      currentPageNumber = TABLE_DEFAULT_INFO.PAGE_NUMBER;
    }

    setCurrentPagination({
      pageSize: localPageSize,
      pageNumber: currentPageNumber,
    });

    localStorage.removeItem(ADMIN_TABLE_PAGE);
    localStorage.removeItem(ADMIN_TABLE_SORTER);
    localStorage.removeItem(ADMIN_VISIBLE_STATE);
  }, [listData, keepTableStatus]);

  const onTableChange: TableProps<AutomatedGroupsList>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    const pageNumber = get(pagination, 'current') || TABLE_DEFAULT_INFO.PAGE_NUMBER;
    const pageSize = get(pagination, 'pageSize') || TABLE_DEFAULT_INFO.PAGE_SIZE;
    const pageInfo = { pageNumber: +pageNumber, pageSize: +pageSize };
    setCurrentPagination(pageInfo);

    const { columnKey, order, column } = sorter as SorterResult<AutomatedGroupsList>;

    const columnsNameAndOrder = {
      sortColumnName: (column && columnKey) || '',
      sortOrder: order || null,
    };
    setCurrentSorter(columnsNameAndOrder);

    currentSort && currentSort(columnsNameAndOrder);
    onChange && onChange(pageInfo, columnsNameAndOrder);
  };

  const onRowClickFun = (record: AutomatedGroupsList) => {
    onRowClick && onRowClick(record);
    if (keepTableStatus) {
      localStorage.setItem(ADMIN_TABLE_SORTER, JSON.stringify(currentSorter));
      localStorage.setItem(ADMIN_TABLE_PAGE, JSON.stringify(currentPagination));
    }
  };

  const currentPageNumber = get(currentPagination, 'pageNumber');
  const currentPageSize = get(currentPagination, 'pageSize');

  return (
    <Table
      loading={loading}
      columns={column}
      rowSelection={rowSelection}
      dataSource={content}
      rowKey={record => record.id}
      onChange={onTableChange}
      onRow={record => ({
        onClick: () => onRowClickFun(record),
      })}
      pagination={
        +total > TABLE_DEFAULT_INFO.PAGE_SIZE
          ? {
              ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
              total: total,
              current: currentPageNumber,
              pageSize: currentPageSize,
            }
          : false
      }
    />
  );
};

export default TableRender;
