export const ALL_GROUPS_ID = 'all-groups';

export const ALL_PEOPLE = 'all-people';

export const ALL_ACCOUNT = 'all-account';

export const ALL_ACCOUNT_GROUPS_ID = 'all-account-group';

export const REGIONAL_LEVEL_GROUPS = 'Regional Level Groups';

export const UNIT = 'unit';

export const ALL_PEOPLE_UNIT = `${ALL_PEOPLE}-unit`;

export const ALL_GROUPS_UNIT = `${ALL_GROUPS_ID}-unit`;

export const ALL_ACCOUNT_UNIT = `${ALL_ACCOUNT}-unit`;

export const V2_SUPERVISOR = `V2SUPERVISOR`;

export const V2_ACCOUNT = `V2ACCOUNT`;

export const UPDATE_VERSION_TIME = '2021-04-02';
