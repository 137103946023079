import { RegionsType } from '@@/features/performance/v2/admin/type';
import { UserInfo } from '@@/types/user';
import { get } from 'lodash';
import React from 'react';
import ManageAdminsTab from '../components/ManageAdminsTab';
import ManageGlobalFunctionSLGroupsTab from '../components/ManageGlobalFunctionSLGroupsTab';
import ManageGlobalFunctionSLRolesTab from '../components/ManageGlobalFunctionSLRolesTab';
import ManageRegionalGroupTab from '../components/ManageRegionalGroupTab';
import ManageRegionalRolesTab from '../components/ManageRegionalRolesTab';
import ManageTBPGroupsTab from '../components/ManageTBPGroupsTab';
import AutomatedGroups from '@@/features/performance/v2/admin/newComponent/AutomatedGroups';
import {
  AdminManageMapListType,
  TabChildrenComponentProps,
} from '@@/features/performance/v2/admin/common/interface';
import ManageGroupsList from '@@/features/performance/v2/admin/components/ManageGroupsList';
import { AdminTabKey, CLASSIFY_TITLE_KEY, V2UNIT } from '@@/_new_src_/constants/adminEnum';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';

export const AdminRoleAndTabInfo = (
  displayHierarchyName?: string,
  tabChildrenComponentProps?: TabChildrenComponentProps,
) => {
  const activeTabKey = get(tabChildrenComponentProps, 'activeTabKey');
  const hierarchyId = get(tabChildrenComponentProps, 'hierarchyId');
  const hierarchyType = get(tabChildrenComponentProps, 'hierarchyType');
  const hierarchyName = get(tabChildrenComponentProps, 'hierarchyName', '');
  return [
    {
      manageName: USER_ROLE_ENUM.OPS_ADMIN,
      displayManageName: UserRoleInfo[USER_ROLE_ENUM.OPS_ADMIN].display,
      tabList: [AdminTabKey.MANAGE_ROLES, AdminTabKey.MANAGE_GROUPS],
      classifyTitle: {
        [AdminTabKey.MANAGE_ROLES]: [
          CLASSIFY_TITLE_KEY.GLOBAL_LEVEL,
          CLASSIFY_TITLE_KEY.FUNCTION_LEVEL,
        ],
      },
      contentList: [
        <ManageGlobalFunctionSLRolesTab key="0" />,
        <ManageGlobalFunctionSLGroupsTab key="1" activeTabKey={activeTabKey || ''} />,
      ],
    },
    {
      manageName: USER_ROLE_ENUM.PS_ADMIN,
      displayManageName: displayHierarchyName
        ? `${UserRoleInfo[USER_ROLE_ENUM.PS_ADMIN].display} · ${displayHierarchyName}`
        : '',
      tabList: [AdminTabKey.MANAGE_REGIONAL_ROLES, AdminTabKey.MANAGE_REGIONAL_GROUPS],
      classifyTitle: {
        [AdminTabKey.MANAGE_REGIONAL_ROLES]: [
          CLASSIFY_TITLE_KEY.REGION_LEVEL,
          CLASSIFY_TITLE_KEY.UNIT_LEVEL,
        ],
        [AdminTabKey.MANAGE_REGIONAL_GROUPS]: [CLASSIFY_TITLE_KEY.REGIONAL_LEVEL_GROUPS],
      },
      contentList: [
        <ManageRegionalRolesTab
          regionId={hierarchyId as number}
          regionName={hierarchyName as string}
          key="0"
        />,
        <ManageRegionalGroupTab regionId={hierarchyId as number} key="0" />,
      ],
    },
    {
      manageName: USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER,
      displayManageName: displayHierarchyName
        ? `${
            UserRoleInfo[USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER].display
          } · ${displayHierarchyName}`
        : '',
      tabList: [AdminTabKey.MANAGE_GROUPS],
      contentList: [<ManageGroupsList regionId={hierarchyId as number} key="0" />],
    },
    {
      manageName: USER_ROLE_ENUM.GLOBAL_ADMIN,
      displayManageName: UserRoleInfo[USER_ROLE_ENUM.GLOBAL_ADMIN].display,
      tabList: [AdminTabKey.MANAGE_ADMINS],
      contentList: [<ManageAdminsTab key="0" />],
    },
    {
      manageName: USER_ROLE_ENUM.TALENT_BP,
      displayManageName: displayHierarchyName
        ? `${UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].display} · ${displayHierarchyName}`
        : '',
      noAutomatedTabList: [AdminTabKey.CUSTOMIZED_GROUPS],
      haveAutomatedTabList: [AdminTabKey.CUSTOMIZED_GROUPS, AdminTabKey.AUTOMATED_GROUPS],
      contentList: [
        <ManageTBPGroupsTab
          hierarchyId={hierarchyId as number}
          unitName={hierarchyName as string}
          hierarchyType={hierarchyType as string}
          key="0"
        />,
        <AutomatedGroups
          key="1"
          hierarchyId={hierarchyId as number}
          hierarchyType={hierarchyType as string}
        />,
      ],
    },
  ];
};

export const AdminManageListFun = ({
  regionsForGroupManager,
  userInfo,
}: {
  regionsForGroupManager: RegionsType;
  userInfo: UserInfo | null;
}) => {
  const userRolesInfoInV2 = get(userInfo, 'userRolesInfoInV2') || [];

  const filteredUserRolesInfoInV2 = userRolesInfoInV2?.filter(value => {
    return value.role !== USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER;
  });

  const list = [...filteredUserRolesInfoInV2];

  Object.keys(regionsForGroupManager).forEach(item => {
    list.push(regionsForGroupManager[item]);
  });
  const AdminManageMapList = [] as AdminManageMapListType[];

  list.forEach(item => {
    const role = item.role;
    AdminRoleAndTabInfo(item.hierarchyName)?.forEach(manageItem => {
      const manageName = manageItem.manageName;

      if (role === manageName) {
        let manageKey: string = role;

        if (item.hierarchyName) {
          manageKey += item.hierarchyName;
        }
        if (item.hierarchyId > 0) {
          manageKey += item.hierarchyId;
        }
        AdminManageMapList.push({
          ...item,
          key: manageKey,
          value: manageKey,
          display: manageItem.displayManageName,
        });
      }
    });
  });
  return AdminManageMapList?.sort((a, b) => a.display.localeCompare(b.display));
};

export const TabListFun = ({
  selectRole,
  hierarchyType,
}: {
  selectRole: string;
  hierarchyType: string;
}) => {
  let currentTabList: AdminTabKey[] = [];
  if (selectRole) {
    const selectedRoleInfo = AdminRoleAndTabInfo()?.filter(value => {
      return value.manageName === selectRole;
    });
    currentTabList =
      selectedRoleInfo[0].tabList ||
      (hierarchyType === V2UNIT && selectedRoleInfo[0].haveAutomatedTabList) ||
      selectedRoleInfo[0].noAutomatedTabList;
  }
  return currentTabList;
};

export const TabChildrenComponentsFun = (
  selectRole: string,
  tabChildrenComponentProps?: TabChildrenComponentProps,
) => {
  let currentTabComponentList = null;
  if (selectRole) {
    const selectedRoleInfo = AdminRoleAndTabInfo('', tabChildrenComponentProps)?.filter(value => {
      return value.manageName === selectRole;
    });

    currentTabComponentList = selectedRoleInfo[0].contentList;
  }
  return currentTabComponentList;
};
