import { RolesOfManagementContext } from '@@/context/rolesOfManagement.context';
import {
  AdminManageListFun,
  TabChildrenComponentsFun,
} from '@@/features/performance/v2/admin/common';
import {
  AdminManageMapListType,
  TabChildrenComponentProps,
} from '@@/features/performance/v2/admin/common/interface';
import TabInfo from '@@/features/performance/v2/admin/components/TabInfo';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { RolesOfManagement } from '@@/types/admin';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminInfo from './components/AdminInfo';
import './index.less';
import { RegionsType, selectRoleInfoType } from './type';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { getGroupManagersWithRegion } from '@@/_new_src_/api/admin';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';

export interface GroupManagerItemType {
  groupId: number;
  regionCode: string;
  regionId: number;
  regionName: string;
}
export interface GroupManagerType {
  [key: string]: GroupManagerItemType;
}

const AdminPage = () => {
  const history = useHistory();
  const {
    userInfo,
    globalAdminRoleInfo,
    regionalAdminRoleInfo,
    unitTalentBusinessPartnerRoleInfos,
    groupManagerInfos,
    regionalLevelGroupManagerInfos,
    globalFunctionSlAdminRoleInfo,
  } = useUserRoleInfo();

  const [selectedRole, setSelectedRole] = useState('');
  const [hierarchyId, setHierarchyId] = useState<number>();
  const [hierarchyName, setHierarchyName] = useState<string | null>();
  const [hierarchyType, setHierarchyType] = useState<string | null | undefined>();
  const [selectRoleKey, setSelectRoleKey] = useState('');
  const [rolesOfManagement, setRolesOfManagement] = useState<RolesOfManagement | undefined>(
    {} as RolesOfManagement,
  );
  const [regionsForGroupManager, setRegionsForGroupManager] = useState<RegionsType>({});
  const [loadedregionsForGroupManager, setLoadedregionsForGroupManager] = useState<boolean>(false);
  const [adminManageList, setAdminManageList] = useState<AdminManageMapListType[]>([]);

  const [activeTabKey, setActiveTabKey] = useState<string>('');
  const [activeComponentList, setActiveComponentList] =
    useState<Array<React.ReactElement> | null>();

  const [tabChildComponentProps, setTabChildComponentProps] = useState<TabChildrenComponentProps>(
    {},
  );

  useEffect(() => {
    const tabProps = {
      hierarchyName: hierarchyName,
      hierarchyId: hierarchyId,
      hierarchyType: hierarchyType,
      activeTabKey: activeTabKey,
    };
    const tabChildComponents = TabChildrenComponentsFun(selectedRole, tabProps);
    setActiveComponentList(tabChildComponents);
    setTabChildComponentProps(tabProps);
  }, [activeTabKey, selectedRole, hierarchyId, hierarchyName, hierarchyType]);

  useEffect(() => {
    const getRegions: () => void = async () => {
      const { data } = (await getGroupManagersWithRegion()) as { data: GroupManagerType };

      const regions: RegionsType = {};
      Object.values(data).forEach(item => {
        if (!regions[item.regionId]) {
          regions[item.regionId] = {
            hierarchyId: item.regionId,
            hierarchyName: item.regionName,
            role: USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER,
            roleHierarchy: USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER,
          };
        }
      });
      setRegionsForGroupManager(regions);
    };
    if (!isEmpty(groupManagerInfos) && !loadedregionsForGroupManager) {
      getRegions();
      setLoadedregionsForGroupManager(true);
    }
  }, [groupManagerInfos, loadedregionsForGroupManager]);

  useEffect(() => {
    if (
      (!isEmpty(groupManagerInfos) && loadedregionsForGroupManager) ||
      isEmpty(groupManagerInfos)
    ) {
      const adminManageList = AdminManageListFun({ regionsForGroupManager, userInfo });
      setAdminManageList(adminManageList);
    }
  }, [regionsForGroupManager, userInfo]);

  const updateLoadedRegionsForGroupManager = () => {
    setLoadedregionsForGroupManager(false);
  };

  useEffect(() => {
    if (
      !globalAdminRoleInfo &&
      regionalAdminRoleInfo?.length === 0 &&
      unitTalentBusinessPartnerRoleInfos?.length === 0 &&
      regionalLevelGroupManagerInfos?.length === 0 &&
      !globalFunctionSlAdminRoleInfo
    ) {
      history.push(ERROR_PAGE.IS_403);
    }
  }, [
    history,
    globalAdminRoleInfo,
    regionalAdminRoleInfo,
    unitTalentBusinessPartnerRoleInfos,
    regionalLevelGroupManagerInfos,
    globalFunctionSlAdminRoleInfo,
  ]);

  if (!userInfo || (!regionalAdminRoleInfo && !unitTalentBusinessPartnerRoleInfos)) {
    return null;
  }
  const handleRoleChange = (roleKey: string): void => {
    if (selectRoleKey !== roleKey) {
      const selectRoleInfo = JSON.parse(
        sessionStorage.getItem('selectedRoleInAdminPage') as string,
      ) as selectRoleInfoType;

      if (selectRoleInfo) {
        setSelectedRole(selectRoleInfo.selectRole);
        setSelectRoleKey(selectRoleInfo.optionValue);
        setHierarchyId(selectRoleInfo.selectHierarchyId);
        setHierarchyName(selectRoleInfo.selectHierarchyName);
        setHierarchyType(selectRoleInfo.hierarchyType);
      }
    }
  };

  const updateRolesOfManagement = (roles: RolesOfManagement | undefined): void => {
    setRolesOfManagement(roles);
  };

  const isLoadedRegionsGroupManager =
    !isEmpty(groupManagerInfos) && !isEmpty(regionsForGroupManager);
  const isFinishedLoadingGroupManagerData =
    isLoadedRegionsGroupManager || isEmpty(groupManagerInfos);

  return (
    <div className="admin-page-container">
      {isFinishedLoadingGroupManagerData && adminManageList.length > 0 ? (
        <AdminInfo
          name={userInfo.name}
          role={userInfo.role}
          handleRoleChange={handleRoleChange}
          adminManageList={adminManageList}
        />
      ) : null}
      <div className="admin-tabs-container">
        <RolesOfManagementContext.Provider
          value={{
            rolesOfManagement,
            updateRolesOfManagement,
            updateLoadedRegionsForGroupManager: updateLoadedRegionsForGroupManager,
          }}
        >
          <TabInfo
            updateActiveTabKey={(state: string) => {
              setActiveTabKey(state);
            }}
            hierarchyId={hierarchyId}
            selectRole={selectedRole}
            tabChildrenComponent={activeComponentList}
            tabChildComponentProps={tabChildComponentProps}
          />
        </RolesOfManagementContext.Provider>
      </div>
    </div>
  );
};

export default AdminPage;
