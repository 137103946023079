import React from 'react';
import './index.less';
import EmptyFeedbackIcon from '@@/assets/images/empty-feedback.svg';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import CycleSelector from '../CycleSelector';
import HelperDrawer from '@@/_new_src_/components/HelperDrawer';
import HelperTabs from '@@/_new_src_/page/feedback/component/HelperTabs';
import AddFeedbackBanner from '../AddFeedbackBanner';

interface IEmptyContainerProps {
  projectName: string;
  startTime: string;
  endTime: string;
}

function EmptyContainer(props: IEmptyContainerProps) {
  const { projectName, startTime, endTime } = props;
  const {
    feedback: {
      emptyFeedback: { hint, cycleInfo, helpButton, drawerTitle },
    },
  } = FeedbackLocale;

  return (
    <div className="feedback-empty-container">
      <div className="cycle-selector-area">
        <CycleSelector />
      </div>
      <div className="info">
        <img src={EmptyFeedbackIcon} className="empty-icon" alt="" />
        <p className="hint">{hint}</p>
        <p className="cycle-info">{cycleInfo(projectName, startTime, endTime)}</p>
      </div>
      <div className="button-container">
        <AddFeedbackBanner />
      </div>
      <div className="help-button">
        <HelperDrawer
          buttonContent={
            <>
              <i className="ri-question-line btn-icon" />
              <span className="btn-text">{helpButton}</span>
            </>
          }
          drawerTitle={drawerTitle}
          drawerContent={
            <>
              <HelperTabs />
            </>
          }
        />
      </div>
    </div>
  );
}

export default EmptyContainer;
