import { Spin } from 'antd';
import React, { ReactNode } from 'react';

interface ILoadingWrapperProps {
  loading: boolean;
  children: ReactNode;
  wrapperClassName?: string;
}

const LoadingWrapper = ({ loading, children, ...rest }: ILoadingWrapperProps) => (
  <Spin spinning={loading} {...rest} size="large">
    {children}
  </Spin>
);

export default LoadingWrapper;
