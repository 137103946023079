import React, { memo } from 'react';
import { omit } from 'lodash';
import cls from 'classnames';
import './index.less';

const EditCircleButton = props => {
  const { disabled } = props;
  return (
    <div
      className={cls('edit-circle-button', { 'edit-circle-button-disabled': disabled })}
      {...omit(props, 'disabled')}
    >
      <i className="ri-edit-2-line edit-icon" />
    </div>
  );
};

export default memo(EditCircleButton);
