import React from 'react';
import { CommonModal } from '@@/common/component/CommonModal';
import InformationIcon from '@@/assets/images/information-yellow.svg';

const LimitMultiPartnerModal = props => {
  const { isModalVisible, modalLocale, onCloseClick, onClickConfirm } = props;

  function renderMultiPartnerModal() {
    return (
      <div className="reviewees-multi-partner-modal-content content">
        <div className="title">
          {modalLocale.title}
          <img src={InformationIcon} alt="" className="tips-icon" />
        </div>
        <div className="desc">{modalLocale.desc}</div>
      </div>
    );
  }

  return (
    <CommonModal
      visible={isModalVisible}
      width={777}
      content={renderMultiPartnerModal()}
      onCloseClick={onCloseClick}
      onClickConfirm={onClickConfirm}
    />
  );
};
export default LimitMultiPartnerModal;
