import React from 'react';
import { get } from 'lodash';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getLocaleResource } from '@@/locale/helper';
import './index.less';
import { onEnter } from '@@/_new_src_/utils';

const { confirm } = Modal;
const { perModal } = getLocaleResource();
const PerfModalConfirm = ({ content, onOk, onCancel, ...other }) => {
  let modal;
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    modal.destroy();
  };
  const className = `${get(other, 'className', '')} perf-modal-confirm`;
  modal = confirm({
    ...other,
    content,
    onOk,
    onCancel,
    maskClosable: true,
    title: perModal.confirmTitle,
    className,
    icon: (
      <CloseOutlined
        className="close-icon"
        tabIndex="0"
        onClick={handleClose}
        onKeyDown={e => {
          onEnter(e, handleClose);
        }}
      />
    ),
    autoFocusButton: null,
    okButtonProps: { tabIndex: 0 },
    cancelButtonProps: { tabIndex: 0 },
  });
  return handleClose;
};
export default PerfModalConfirm;
