import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

export interface ITabPaneComponent {
  tabList: string[] | React.ReactElement[];
  children?: React.ReactElement[] | null;
  activeKey?: string;
  updateActiveKey?: (key: string) => void;
  tabPosition?: 'left' | 'right' | 'top' | 'bottom';
  type?: 'line' | 'card' | 'editable-card';
  tabBarGutter?: number;
  centered?: boolean;
  className?: string;
  size?: 'small' | 'middle' | 'large' | undefined;
}

const DEFAULT_ACTIVE_KEY = '0';

const TabPaneComponent = (props: ITabPaneComponent) => {
  const {
    activeKey = DEFAULT_ACTIVE_KEY,
    tabList = [],
    children = [],
    type = 'line',
    updateActiveKey,
    tabPosition = 'top',
    tabBarGutter,
    centered = false,
    className,
    size,
  } = props;

  const [currentActiveKey, setCurrentActiveKey] = useState<string>(activeKey);

  const onTabsChange = (key: string) => {
    setCurrentActiveKey(key);
    updateActiveKey && updateActiveKey(key);
  };

  useEffect(() => {
    if (currentActiveKey !== activeKey) {
      onTabsChange(activeKey);
    }
  }, [activeKey]);

  const tabPaneDom = document.getElementsByClassName('ant-tabs-tabpane');

  useEffect(() => {
    Array.from(tabPaneDom).forEach(item => {
      item?.removeAttribute('tabindex');
    });
  }, [tabPaneDom.length, currentActiveKey]);

  const formatTabListToTabItems = (tabList: React.ReactNode[] | string[]) => {
    return tabList?.map((item, index) => {
      return {
        key: index.toString(),
        label: item,
        children: children ? children[index] : null,
      };
    }) as TabsProps['items'];
  };

  return (
    <>
      {tabList && (
        <Tabs
          size={size}
          items={formatTabListToTabItems(tabList)}
          activeKey={currentActiveKey}
          onChange={onTabsChange}
          tabPosition={tabPosition}
          type={type}
          tabBarGutter={tabBarGutter}
          centered={centered}
          className={className}
        ></Tabs>
      )}
    </>
  );
};

export default TabPaneComponent;
