import { get, intersection, intersectionWith, isArray, isEmpty, isEqual, isNil } from 'lodash';
import {
  CYCLE_PAGE_LINK,
  DASHBOARD_PAGE_LINK,
  MENU_PAGE_LINK,
} from '@@/_new_src_/constants/pagePath';
import { IS_FIRST_LOGIN, LAST_USER_EMAIL, TW_USER_INFO } from '@@/_new_src_/constants/adminEnum';
import { IUserInfo } from '@@/_new_src_/store/userSlice/interface';
import { dashboardAccessibleRoles, getMenu, PATH_KEY } from '@@/_new_src_/constants/userEnum';

export const setUserInfo = (data: IUserInfo) =>
  localStorage.setItem(TW_USER_INFO, JSON.stringify(data));

export const getUserInfoFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(TW_USER_INFO) as string) || {};
  } catch (error) {
    return {};
  }
};

export const setUserLoggedIn = () => {
  localStorage.setItem(IS_FIRST_LOGIN, 'false');
};

export const getUserId = () => {
  try {
    const { userId = '' } = getUserInfoFromStorage();
    return userId;
  } catch (error) {
    return '';
  }
};

export const setOriginalUriAndSignIn = (
  oktaAuth: { setOriginalUri: (arg0: string) => void; signInWithRedirect: () => void },
  url = window.location.href,
) => {
  const twUserInfo = JSON.parse(localStorage.getItem(TW_USER_INFO) as string);
  localStorage.setItem(LAST_USER_EMAIL, get(twUserInfo, 'email', ''));
  oktaAuth.setOriginalUri(url);
  oktaAuth.signInWithRedirect();
};

export const hasAccessToMenuTab = (
  menuTabAccessibleRoles: (string | { role: string; roleHierarchy: string })[],
  userInfo: IUserInfo,
  type?: string,
) => {
  if (type && type === PATH_KEY.NON_ENGAGED_CYCLES) {
    const roles = get(userInfo, 'userRolesInfoInV2', [])?.map(item => {
      return { role: item.role, roleHierarchy: item.roleHierarchy };
    });
    return intersectionWith(menuTabAccessibleRoles, roles, isEqual).length !== 0;
  }
  const roles = get(userInfo, 'userRolesInfoInV2', [])?.map(item => item.role);
  return intersection(menuTabAccessibleRoles, roles).length !== 0;
};

export const getModulePathArray = () => {
  const array = [];
  getMenu().forEach(item => {
    array.push(item.path);
  });
  array.push(MENU_PAGE_LINK.LANDING_PAGE);
  return array;
};

export const getHomePageUrl = (defaultUserInfo: IUserInfo) => {
  if (hasAccessToMenuTab(dashboardAccessibleRoles, defaultUserInfo)) {
    return DASHBOARD_PAGE_LINK.CYCLES_PAGE;
  }
  return CYCLE_PAGE_LINK.MY_CYCLES;
};

export const getPerformanceRoleInV2 = (defaultUserInfo: IUserInfo) => {
  const userInfo = defaultUserInfo || getUserInfoFromStorage();
  try {
    return get(userInfo, 'userRolesInfoInV2', [])?.map(item => item.role);
  } catch (error) {
    return [];
  }
};

export const hasV2Auth = (role: string, defaultUserInfo: IUserInfo) => {
  if (isEmpty(role)) {
    return false;
  }
  const userAuthorities = getPerformanceRoleInV2(defaultUserInfo);
  return !isEmpty(intersection(isArray(role) ? role : [role], userAuthorities));
};

export const isFirstLogin = () => {
  try {
    const result = localStorage.getItem(IS_FIRST_LOGIN);
    if (isNil(result)) {
      return true;
    }
    return JSON.parse(result);
  } catch (error) {
    return true;
  }
};
