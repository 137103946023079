import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UserInfoContext } from '@@/context/userInfo.context';
import { hasV2Auth } from '@@/_new_src_/utils/common/auth';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';

export const getDisplayName = component => component.displayName || component.name || 'Component';

const AuthV2Guard = roles => WrappedComponent => {
  class Component extends PureComponent {
    static contextType = UserInfoContext;

    static displayName = `AuthGuard(${getDisplayName(WrappedComponent)})`;

    render() {
      const userInfo = this.context;
      if (isEmpty(userInfo)) {
        return null;
      }
      if (hasV2Auth(roles, userInfo)) {
        return <WrappedComponent {...this.props} />;
      }
      return <Redirect to={ERROR_PAGE.IS_403} />;
    }
  }
  return Component;
};

export default AuthV2Guard;
