import React, { useEffect, useState } from 'react';
import { TabListFun } from '@@/features/performance/v2/admin/common';
import { TabInfoProps } from '@@/features/performance/v2/admin/common/interface';
import { get } from 'lodash';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import { ADMIN_TAB_INDEX } from '@@/_new_src_/constants/adminEnum';

const TabInfo = (props: TabInfoProps) => {
  const [tabList, setTabList] = useState<Array<string>>([]);

  const localTabIndex = localStorage.getItem(ADMIN_TAB_INDEX) || '0';
  const [activeKey, setActiveKey] = useState<string>(localTabIndex);

  const {
    selectRole,
    hierarchyId,
    updateActiveTabKey,
    tabChildrenComponent,
    tabChildComponentProps,
  } = props;

  const hierarchyType = get(tabChildComponentProps, 'hierarchyType') || '';

  const onTabsChange = (key: string) => {
    setActiveKey(key);
    updateActiveTabKey && updateActiveTabKey(key);
  };

  useEffect(() => {
    const currentTabList = TabListFun({ selectRole, hierarchyType });
    setTabList(currentTabList);
    onTabsChange(localTabIndex);
  }, [selectRole, hierarchyType, hierarchyId]);

  useEffect(() => {
    if (activeKey != localTabIndex) {
      localStorage.removeItem(ADMIN_TAB_INDEX);
    }
  }, [activeKey]);

  return (
    <TabPaneComponent
      tabList={tabList}
      activeKey={activeKey}
      updateActiveKey={setActiveKey}
      children={tabChildrenComponent}
      size={'large'}
    />
  );
};

export default TabInfo;
