import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { setOriginalUriAndSignIn } from '@@/_new_src_/utils/common/auth';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const DefaultPage = () => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const login = useCallback(async () => {
    const isAuthenticated = await oktaAuth.isAuthenticated();
    if (!isAuthenticated) {
      setOriginalUriAndSignIn(oktaAuth, MENU_PAGE_LINK.INDEX_PAGE);
    } else {
      history.replace(MENU_PAGE_LINK.INDEX_PAGE);
    }
  }, [history, oktaAuth]);

  useEffect(() => {
    login();
  }, [login]);

  return <div className="home-default-page"> </div>;
};

export default DefaultPage;
