import RevieweeDetail from '@@/features/performance/reviewees/detail/index';
import TwerISupport from '@@/features/performance/reviewees/index';
import RevieweesRoot from '@@/features/performance/reviewees/root';
import Reviews from '@@/features/performance/reviews/index';
import ReviewHistoryDetail from '@@/features/performance/reviews/reviewHistoryDetail/index';
import ReviewRoot from '@@/features/performance/reviews/root';
import SetReview from '@@/features/performance/reviews/setReview/index';
import AdminPage from '@@/features/performance/v2/admin/index';
import RegionalLevelGroupManage from '@@/features/performance/v2/admin/RegionalLevelGroupManage/index';
import TBPGroupManage from '@@/features/performance/v2/admin/TBPGroupManage/index';
import NonEngagedCycleDetail from '@@/features/performance/v2/cycleManagement/NonEngagedCycleDetail/index';
import DashboardCycles from '@@/features/performance/v2/dashboardPage/Cycles/index';
import DashboardCycleDetailContainer from '@@/features/performance/v2/dashboardPage/DashboardCycleDetailContainer';
import DashboardPage from '@@/features/performance/v2/dashboardPage/index';
import DashboardRequestContainer from '@@/features/performance/v2/dashboardPage/requests/index';
import NotFoundPageForRouteError from 'common/component/NotFoundPage/NotFoundPageForRouteError';
import AutomatedGroupsDetail from '@@/features/performance/v2/admin/newComponent/AutomatedGroupsDetail';
import ForbiddenPage from '@@/common/component/ForbiddenPage';
import landingPage from '@@/_new_src_/page/landing';
import Layout from './Layout';

import NewMyCyclePage from '@@/_new_src_/page/myCycles';
import NewCycleDetail from '@@/_new_src_/page/cycleDetail';
import SendForm from '@@/_new_src_/page/feedback/subPage/SendForm';
import GivenFeedbackForm from '@@/_new_src_/page/feedback/subPage/GivenFeedbackForm';
import Detail from '@@/_new_src_/page/feedback/subPage/Detail';
import SendClientFeedbackForm from '@@/_new_src_/page/feedback/subPage/SendClientFeedbackForm';
import FeedbackPage from '@@/_new_src_/page/feedback';
import NonEngagedCyclesContainer from '@@/_new_src_/page/nonEngagedCycles';
import CalibrateIndicators from '@@/_new_src_/page/calibrateIndicators';

export default {
  path: 'performance',
  name: 'Performance',
  component: Layout,
  childRoutes: [
    {
      path: 'index',
      name: 'Landing page',
      component: landingPage,
    },
    {
      path: 'twerisupport',
      name: 'twerisupport',
      component: RevieweesRoot,
      childRoutes: [
        { path: '', component: TwerISupport },
        { path: ':id', component: RevieweeDetail },
      ],
    },
    {
      path: 'cycles',
      name: 'cycles',
      component: ReviewRoot,
      childRoutes: [
        { path: '', component: Reviews },
        { path: 'setCycle', component: SetReview },
        { path: 'setCycle/:id', component: SetReview },
        { path: 'cycleHistoryDetail/:id', component: ReviewHistoryDetail },
        { path: '*', name: 'Not Found Page', component: NotFoundPageForRouteError },
      ],
    },
    {
      path: 'feedback',
      name: 'feedback',
      childRoutes: [
        { path: '', component: FeedbackPage },
        { path: 'creation', component: SendForm },
        { path: 'creation/:id', component: SendForm },
        { path: 'given/:id', component: GivenFeedbackForm },
        { path: 'detail/:id', component: Detail },
        { path: 'client/', component: SendClientFeedbackForm },
        { path: 'client/:id', component: SendClientFeedbackForm },
      ],
    },
    {
      path: 'v2',
      name: 'v2',
      childRoutes: [
        {
          path: 'admin',
          name: 'admin',
          component: AdminPage,
        },
        {
          path: 'dashboard',
          name: 'newDashBoard',
          component: DashboardPage,
          childRoutes: [
            { path: 'cycles', component: DashboardCycles },
            { path: 'cycles/:cycleId', component: DashboardCycleDetailContainer },
            { path: 'requests', component: DashboardRequestContainer },
            {
              path: 'requests/:cycleId/:requestId',
              component: DashboardCycleDetailContainer,
            },
          ],
        },
        {
          path: 'admin',
          name: 'admin',
          childRoutes: [
            {
              path: 'group/:groupId',
              name: 'group',
              component: TBPGroupManage,
            },
            {
              path: 'regional-level-group/:groupId',
              name: 'regional-level-group',
              component: RegionalLevelGroupManage,
            },
            { path: 'automatedGroupsDetail/:id', component: AutomatedGroupsDetail },
          ],
        },
        {
          path: 'nonEngagedCycles',
          name: 'nonEngagedCycles',
          childRoutes: [
            { path: '', component: NonEngagedCyclesContainer },
            { path: ':cycleId', component: NonEngagedCycleDetail },
          ],
        },
      ],
    },
    {
      path: 'myCycles',
      name: 'myCycles',
      childRoutes: [
        { path: '', component: NewMyCyclePage },
        { path: ':cycleId', component: NewMyCyclePage },
      ],
    },
    {
      path: 'cycleDetail',
      name: 'cycleDetail',
      childRoutes: [{ path: '', component: NewCycleDetail }],
    },
    {
      path: 'calibrate',
      name: 'calibrate',
      childRoutes: [{ path: '', component: CalibrateIndicators }],
    },
    { path: '403', name: 'Forbidden page', component: ForbiddenPage },
    { path: '404', name: '404 Page for param error', component: NotFoundPageForRouteError },
    { path: '**', name: 'NotFound page', component: NotFoundPageForRouteError },
  ],
};
