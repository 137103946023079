import { createSlice } from '@reduxjs/toolkit';
import {
  getRegionalGroupsAsync,
  getRegionalLevelGroupDetailAsync,
  putUpdateRegionalGroupsAsync,
} from '@@/redux/slice/adminSlice/asyncThunk';
import {
  IAdminSliceState,
  IAdminState,
  IEditRegionalLevelGroupByRegionalAdminDataState,
  IRegionalGroups,
  IRegionalLevelGroupDetailDataState,
} from '@@/types/admin';

const initialState: IAdminSliceState = {
  regionalLevelGroupDetailData: {
    loading: false,
    groupId: NaN,
    groupName: '',
    managerName: '',
    managerEmail: '',
    regionGroupMembers: [],
    regionGroupMembersCount: NaN,
  },
  editRegionalLevelGroupByRegionalAdminData: {
    loading: false,
    success: false,
  },
  regionalGroups: {
    loading: false,
    success: false,
    rejected: false,
    data: [],
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: { ...initialState },
  reducers: {
    setRegionalLevelGroupDetailData: (state, action) => {
      state.regionalLevelGroupDetailData = {
        ...initialState.regionalLevelGroupDetailData,
        ...action.payload,
      } as IRegionalLevelGroupDetailDataState;
    },
    setEditRegionalLevelGroupByRegionalAdminData: (state, action) => {
      state.editRegionalLevelGroupByRegionalAdminData = {
        ...initialState.editRegionalLevelGroupByRegionalAdminData,
        ...action.payload,
      } as IEditRegionalLevelGroupByRegionalAdminDataState;
    },
    setRegionalGroups: (state, action) => {
      state.regionalGroups = {
        ...initialState.regionalGroups,
        ...action.payload,
      } as IRegionalGroups;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRegionalLevelGroupDetailAsync.pending, state => {
        state.regionalLevelGroupDetailData = {
          ...initialState.regionalLevelGroupDetailData,
          loading: true,
        };
      })
      .addCase(getRegionalLevelGroupDetailAsync.fulfilled, (state, action) => {
        state.regionalLevelGroupDetailData = {
          ...initialState.regionalLevelGroupDetailData,
          loading: false,
          ...action.payload.data,
        } as IRegionalLevelGroupDetailDataState;
      })
      .addCase(getRegionalLevelGroupDetailAsync.rejected, state => {
        state.regionalLevelGroupDetailData = {
          ...initialState.regionalLevelGroupDetailData,
          loading: false,
        };
      })
      .addCase(putUpdateRegionalGroupsAsync.pending, state => {
        state.editRegionalLevelGroupByRegionalAdminData = {
          ...initialState.editRegionalLevelGroupByRegionalAdminData,
          loading: true,
          success: false,
        };
      })
      .addCase(putUpdateRegionalGroupsAsync.fulfilled, state => {
        state.editRegionalLevelGroupByRegionalAdminData = {
          ...initialState.editRegionalLevelGroupByRegionalAdminData,
          loading: false,
          success: true,
        };
      })
      .addCase(putUpdateRegionalGroupsAsync.rejected, state => {
        state.editRegionalLevelGroupByRegionalAdminData = {
          ...initialState.editRegionalLevelGroupByRegionalAdminData,
          loading: false,
          success: false,
        };
      })
      .addCase(getRegionalGroupsAsync.pending, state => {
        state.regionalGroups = {
          ...initialState.regionalGroups,
          loading: true,
          success: false,
          rejected: false,
        };
      })
      .addCase(getRegionalGroupsAsync.fulfilled, (state, action) => {
        state.regionalGroups = {
          ...initialState.regionalGroups,
          ...action.payload,
          loading: false,
          rejected: false,
          success: true,
        } as IRegionalGroups;
      })
      .addCase(getRegionalGroupsAsync.rejected, state => {
        state.regionalGroups = {
          ...initialState.regionalGroups,
          loading: false,
          success: false,
          rejected: true,
        };
      });
  },
});

export const {
  setRegionalLevelGroupDetailData,
  setEditRegionalLevelGroupByRegionalAdminData,
  setRegionalGroups,
} = adminSlice.actions;

export const admin = (state: IAdminState) => state.admin;
export const regionalLevelGroupDetailData = (state: IAdminState) =>
  state.admin.regionalLevelGroupDetailData;
export const editRegionalLevelGroupByRegionalAdminData = (state: IAdminState) =>
  state.admin.editRegionalLevelGroupByRegionalAdminData;
export const regionalGroups = (state: IAdminState) => state.admin.regionalGroups;

export default adminSlice.reducer;
