import React, { useMemo, useContext, useState, useCallback, useEffect } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { Select } from 'antd';
import {
  getMyHistoryCycleAsync,
  getCycleHistoryAvailableYearAsync,
  reviewState,
} from '@@/redux/slice/reviewSlice';
import ReviewHistoryCard from '../ReviewHistoryCard';
import cls from 'classnames';
import { UserInfoContext } from '@@/context/userInfo.context';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import { myCycles, setCancelStatus } from '@@/_new_src_/store/myCyclesSlice';
import { setCurrentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';
import { getInputInSelectDom, updateAriaForSelect } from '@@/_new_src_/utils/common/axe';

const CycleHistoryList = () => {
  const dispatch = useDispatch();
  const { reviewHistory: locale = {} } = useSelector(selectLocaleResource);
  const location = useLocation();
  const [year, setYear] = useState('');
  const useInfo = useContext(UserInfoContext);
  const { Option } = Select;
  const {
    historyCycleList: { loading: reviewHistoryLoading, data: reviewHistory },
    cycleHistoryYearList: {
      data: { reviewHistoryYear = [] },
    },
    updateUnreadStatus: { success },
  } = useSelector(reviewState);

  const {
    cancelStatus: { success: cancelSuccess },
  } = useSelector(myCycles);

  const showHistory = useMemo(() => !isEmpty(reviewHistory), [reviewHistory]);

  useEffect(() => {
    dispatch(getCycleHistoryAvailableYearAsync());
  }, []);

  useEffect(() => {
    year && dispatch(getMyHistoryCycleAsync(year));
  }, [year]);

  useEffect(() => {
    if (success || cancelSuccess) {
      dispatch(setCurrentCycleDetail({}));
      year && dispatch(getMyHistoryCycleAsync(year));
      dispatch(getCycleHistoryAvailableYearAsync());
    }
    dispatch(setCancelStatus({ success: false, loading: false }));
  }, [success, cancelSuccess]);

  const availableHistoryYears = useMemo(
    () => reviewHistoryYear.map(Number).filter(y => y <= dayjs().year()),
    [reviewHistoryYear],
  );

  const getRecentOneYearReviewHistory = useCallback(() => {
    if (!isEmpty(availableHistoryYears)) {
      const maxAvailableHistoryYear = Math.max(...availableHistoryYears);
      setYear(maxAvailableHistoryYear);
    }
  }, [availableHistoryYears]);

  useEffect(() => {
    getRecentOneYearReviewHistory();
  }, [availableHistoryYears]);

  useEffect(() => {
    const windowPosition = get(location, 'state.windowPosition');
    const selectedYear = get(location, 'state.selectedYear');
    if (windowPosition !== undefined && !reviewHistoryLoading && year) {
      window.scrollTo(0, windowPosition);
      setYear(selectedYear);
    }
  }, [location]);

  const onHistoryYearChange = useCallback(
    selectedYear => {
      setYear(selectedYear);
    },
    [setYear],
  );
  const yearSelectDom = getInputInSelectDom('year-select');

  useEffect(() => {
    if (!isEmpty(availableHistoryYears)) {
      updateAriaForSelect({
        inputWrapper: yearSelectDom,
        ariaLabelText: 'change history year selection',
      });
    }
  }, [availableHistoryYears, yearSelectDom]);
  return (
    <>
      <div className="review-history">
        <div className="title-and-filter">
          <div className="title">{locale.title}</div>
          <div className="filter" hidden={!showHistory}>
            <span className="label">{`${locale.yearSelectText}:`}</span>
            <Select value={year} className="year-select" onChange={onHistoryYearChange}>
              {availableHistoryYears.map(historyYear => (
                <Option value={historyYear} key={String(historyYear)}>
                  {historyYear}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={cls('content-wrapper', { 'no-history': !showHistory })}>
          {showHistory ? (
            reviewHistory.map(review => (
              <ReviewHistoryCard review={review} key={review.id} selectedYear={year} />
            ))
          ) : (
            <div className="empty-wrapper">
              <div className="empty">{`${useInfo.name ? `${useInfo.name},` : ''} ${
                locale.empty
              }`}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CycleHistoryList;
