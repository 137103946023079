import { useEffect, useRef } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { cycleInformation } from '@@/_new_src_/store/myCyclesSlice';
import { PATHNAME_AND_PAGE_NAME } from '@@/_new_src_/constants/pagePath';
import { getUserInfoFromStorage } from '@@/_new_src_/utils/common/auth';

const MatomoPageTracker = () => {
  const { trackPageView } = useMatomo();
  const location = useLocation();
  const trackedPathname = useRef('');
  const cycleInfo = useSelector(cycleInformation);
  const cycleId = cycleInfo?.id;
  useEffect(() => {
    const { pathname } = location;
    const userInfo = getUserInfoFromStorage();
    // eslint-disable-next-line no-underscore-dangle
    const paq = window._paq || [];
    // if (isNil(cycleId)) {
    //   cycleId = 'no cycle';
    // }
    if (!isEmpty(userInfo)) {
      const userId = userInfo.userId?.toString();
      paq.push(['setUserId', userId]);
      paq.push(['setCustomDimension', 1, userInfo.role]);
      paq.push(['setCustomDimension', 2, userInfo.departmentId]);
      paq.push(['setCustomDimension', 3, userInfo.homeCountry]);
      paq.push(['setCustomDimension', 5, userInfo.email]);
      paq.push(['setCustomVariable', 1, 'Grade', userInfo.grade, 'visit']);
      paq.push(['setCustomVariable', 2, 'TW experience', userInfo.twExperience, 'visit']);
      paq.push([
        'setCustomVariable',
        3,
        'Poh-name',
        userInfo.primaryOrganizationalHome?.name,
        'visit',
      ]);
      paq.push([
        'setCustomVariable',
        4,
        'Poh-type',
        userInfo.primaryOrganizationalHome?.type,
        'visit',
      ]);
      if (!isNil(cycleId)) {
        paq.push(['setCustomVariable', 5, 'CycleId', cycleId, 'visit']);
      }

      if (trackedPathname.current !== pathname) {
        trackedPathname.current = pathname;

        const currentPageTitle = PATHNAME_AND_PAGE_NAME.find(
          item => pathname === item.pathName,
        )?.pageName;
        trackPageView({ documentTitle: currentPageTitle });
      }
    }
  }, [location]);

  return null;
};

export default MatomoPageTracker;
