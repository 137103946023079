import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import cls from 'classnames';
import { get } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EMPTY_PROJECT_PLACEHOLDER } from '../../constant/review';
import './index.less';

const MIN_HEIGHT = '320';

const FieldBlockInfo = ({
  label,
  content,
  collapsible,
  className,
  emptyPlaceholder = EMPTY_PROJECT_PLACEHOLDER,
  isExpand,
}) => {
  const [collapse, setCollapse] = useState(true);
  const [operation, setShowOperation] = useState(false);
  const localeResource = useSelector(selectLocaleResource);
  const contentRef = React.createRef();

  const toggleCollapse = useCallback(() => {
    setCollapse(!collapse);
  }, [collapse]);

  useEffect(() => {
    if (collapsible && (content || emptyPlaceholder !== '')) {
      const element = contentRef.current;
      const height = element.clientHeight;

      if (height > MIN_HEIGHT) {
        setShowOperation(true);
      } else {
        setShowOperation(false);
        setCollapse(true);
      }
    } else {
      setShowOperation(false);
    }
  }, [content]);

  const readMoreText = get(localeResource, 'collapsibleDescription.readMore');
  const readLessText = get(localeResource, 'collapsibleDescription.readLess');

  return (
    <div className={cls('component-field-block', className)}>
      {label && <div className="label">{label}</div>}
      {(content || emptyPlaceholder !== '') && (
        <>
          <div
            ref={contentRef}
            className={`global-rich-text content-wrapper`}
            style={{ height: operation && collapse && isExpand ? MIN_HEIGHT + 'px' : 'auto' }}
            dangerouslySetInnerHTML={{ __html: content || emptyPlaceholder }}
          />
          {isExpand && collapse && operation && <div className="ellipsis">...</div>}
        </>
      )}
      {isExpand && operation && (
        <div className="content-toggle" onClick={toggleCollapse}>
          {collapse ? readMoreText : readLessText}
        </div>
      )}
    </div>
  );
};
FieldBlockInfo.defaultProps = {
  collapsible: false,
};

export default memo(FieldBlockInfo);
