import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { helperModalOptions } from '@@/_new_src_/store/myCyclesSlice';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { get, isEmpty } from 'lodash';
import './index.less';
import { HELPER_MODAL_LEARN_MORE_LINK } from '@@/_new_src_/constants/myCycles';

const HelperModal = () => {
  const helperModalParams = useSelector(helperModalOptions);

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: { helperModalMenu },
  } = localeResource as IMyCyclesLocale;
  const helperContent = get(helperModalMenu, helperModalParams, []) as string[];

  const learnMoreLink = () => {
    if (helperModalParams === 'PERFORMANCE PARTNER INTRO') {
      return HELPER_MODAL_LEARN_MORE_LINK.PERFORMANCE_PARTNER;
    }
    if (helperModalParams === 'ADDITIONAL PARTNER INTRO') {
      return HELPER_MODAL_LEARN_MORE_LINK.ADDITIONAL_PARTNER;
    }
  };

  const isShowLearnMoreLink =
    helperModalParams === 'PERFORMANCE PARTNER INTRO' ||
    helperModalParams === 'ADDITIONAL PARTNER INTRO';
  return (
    <>
      {!isEmpty(helperContent) && (
        <div className={'helper-modal'}>
          <div className={'helper-icon'}></div>
          <div className={'helper-content'}>
            {helperContent.map((item, index) => (
              <div
                className={'content-item'}
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
            {isShowLearnMoreLink && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={learnMoreLink()}
                className={'learn-more-link'}
              >
                <div className={'learn-more-text'}>Learn more</div>
                <i className="ri-share-box-line" />
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HelperModal;
