import {
  getCurrentCycleFeedbackList,
  getFeedbackDetail,
  getFeedbackTemplates,
  getGivenFeedbackRequests,
  patchGivenFeedback,
  postAddFeedback,
  postV1AddClientFeedback,
  postV1Feedback,
  postV1SendFeedbackRequest,
  putCancelEditFeedback,
  putEditFeedback,
  putV1EditClientFeedback,
  putV1EditFeedbackRequest,
} from '@@/_new_src_/api/feedback';
import {
  IAddFeedback,
  IEditFeedback,
  IGetFeedbacksParams,
  IGetGiveFeedbacksParams,
  IUpdateFeedbackQuestion,
} from '@@/_new_src_/types/feedback';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IFeedbackResponse,
  IFeedbackTemplate,
  IGetFeedbackDetailResponse,
  IGivenFeedbackResponse,
  IPostV1AddClientFeedback,
  IPostV1SendFeedbackRequest,
  IPutV1EditClientFeedback,
  IPutV1EditFeedbackRequest,
  IV1PostFeedbackParams,
} from './interface';

export const getCurrentCycleFeedbackListAsync = createAsyncThunk(
  'getCurrentCycleFeedbackListAsync',
  async (param: IGetFeedbacksParams) => {
    const response = await getCurrentCycleFeedbackList(param);
    return response.data as IFeedbackResponse;
  },
);

export const getGivenFeedbackRequestsAsync = createAsyncThunk(
  'getGivenFeedbackRequests',
  async (param: IGetGiveFeedbacksParams) => {
    const response = await getGivenFeedbackRequests(param);
    return response.data as IGivenFeedbackResponse;
  },
);

export const postAddFeedbackAsync = createAsyncThunk(
  'postAddFeedbackAsync',
  async (param: IAddFeedback) => {
    const response = await postAddFeedback(param);
    return response.data as number[];
  },
);

export const putEditFeedbackAsync = createAsyncThunk(
  'putEditFeedbackAsync',
  async (param: IEditFeedback) => {
    await putEditFeedback(param);
  },
);

export const putCancelEditFeedbackAsync = createAsyncThunk(
  'putCancelEditFeedbackAsync',
  async (param: IEditFeedback) => {
    await putCancelEditFeedback(param);
  },
);

export const getFeedbackTemplatesAsync = createAsyncThunk('getFeedbackTemplatesAsync', async () => {
  const response = await getFeedbackTemplates();
  return response.data as { [key: number]: IFeedbackTemplate[] };
});

export const getFeedbackDetailAsync = createAsyncThunk('getFeedbackDetail', async (id: number) => {
  const response = await getFeedbackDetail(id);
  return response.data as IGetFeedbackDetailResponse;
});

export const patchGivenFeedbackAsync = createAsyncThunk(
  'patchGivenFeedbackAsync',
  async ({ id, params }: { id: number; params: IUpdateFeedbackQuestion }) => {
    await patchGivenFeedback(id, params);
  },
);

export const postV1FeedbackAsync = createAsyncThunk(
  'postV1Feedback',
  async (data: IV1PostFeedbackParams) => {
    await postV1Feedback(data);
  },
);

export const postV1AddClientFeedbackAsync = createAsyncThunk(
  'postV1AddClientFeedback',
  async (data: IPostV1AddClientFeedback) => {
    await postV1AddClientFeedback(data);
  },
);
export const putV1EditClientFeedbackAsync = createAsyncThunk(
  'putV1EditClientFeedback',
  async ({ feedbackId, params }: { feedbackId: number; params: IPutV1EditClientFeedback }) => {
    await putV1EditClientFeedback(feedbackId, params);
  },
);

export const postV1SendFeedbackRequestAsync = createAsyncThunk(
  'postV1SendFeedbackRequest',
  async (data: IPostV1SendFeedbackRequest) => {
    await postV1SendFeedbackRequest(data);
  },
);

export const putV1EditFeedbackRequestAsync = createAsyncThunk(
  'putV1EditFeedbackRequest',
  async (params: IPutV1EditFeedbackRequest) => {
    await putV1EditFeedbackRequest(params);
  },
);
