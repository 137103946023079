import React, { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// import '../../../reviewees/detail/index.less';
import { DASHBOARD_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import DashboardHeaderGroupSelect from '../DashboardHeaderGroupSelect';
import DashboardHeaderMenu from '../DashboardHeaderMenu';
import DashboardHeaderRoleSelect from '../DashboardHeaderRoleSelect';
import './index.less';

const DashboardHeader = () => {
  const location = useLocation();

  const isCycleOrRequestList = useMemo(() => {
    const currentPathname = location.pathname;
    return (
      currentPathname === DASHBOARD_PAGE_LINK.CYCLES_PAGE ||
      currentPathname === DASHBOARD_PAGE_LINK.REQUESTS_PAGE
    );
  }, [location]);

  return (
    isCycleOrRequestList && (
      <div className={'dashboard-header-container'}>
        <div className="dashboard-header">
          <div className="role-and-group">
            <DashboardHeaderRoleSelect />
            <DashboardHeaderGroupSelect />
          </div>
          <DashboardHeaderMenu />
        </div>
      </div>
    )
  );
};

export default memo(DashboardHeader);
