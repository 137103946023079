import {
  GLOBAL_MANAGEMENT_TEAM,
  GLOBAL_PEOPLE_TEAM,
} from '@@/features/performance/v2/dashboardPage/common/constants';
import { get, isEmpty } from 'lodash';
import { useContext } from 'react';
import {
  getGlobalAdminRoleInfo,
  getOpsAdminRoleInfo,
  getGroupManagerInfos,
  getNormalUserInfo,
  getRegionalAdminRoleInfo,
  getRegionalLevelGroupManager,
  getUnitTalentBusinessPartnerRoleInfos,
} from '../common/utils/userRole';
import { UserInfoContext } from '../context/userInfo.context';
import { UserInfo } from '../types/user';

export default function useUserRoleInfo() {
  const userInfo = useContext(UserInfoContext) as UserInfo;
  const normalUserInfo = getNormalUserInfo(userInfo);
  const globalAdminRoleInfo = getGlobalAdminRoleInfo(userInfo);
  const regionalAdminRoleInfo = getRegionalAdminRoleInfo(userInfo);
  const unitTalentBusinessPartnerRoleInfos = getUnitTalentBusinessPartnerRoleInfos(userInfo);
  const groupManagerInfos = getGroupManagerInfos(userInfo);
  const regionalLevelGroupManagerInfos = getRegionalLevelGroupManager(userInfo);
  const globalFunctionSlAdminRoleInfo = getOpsAdminRoleInfo(userInfo);

  const { userRoleInfoInV2GlobalFunctionAdmin } = userInfo;

  const extendedGlobalLeadership =
    userRoleInfoInV2GlobalFunctionAdmin?.length &&
    userRoleInfoInV2GlobalFunctionAdmin.find(item => item.hierarchyName === GLOBAL_MANAGEMENT_TEAM);
  const globalManagementTeamId = get(extendedGlobalLeadership, 'hierarchyId', NaN) as number;
  const globalPeopleTeam =
    userRoleInfoInV2GlobalFunctionAdmin?.length &&
    userRoleInfoInV2GlobalFunctionAdmin.find(item => item.hierarchyName === GLOBAL_PEOPLE_TEAM);
  const globalPeopleTeamId = get(globalPeopleTeam, 'hierarchyId', NaN) as number;

  if (isEmpty(userInfo)) {
    return {
      userInfo: null,
      normalUserInfo: null,
      globalAdminRoleInfo: null,
      regionalAdminRoleInfo: null,
      unitTalentBusinessPartnerRoleInfos: null,
      groupManagerInfos: null,
      regionalLevelGroupManagerInfos: null,
      globalFunctionSlAdminRoleInfo: null,
    };
  }

  return {
    userInfo,
    normalUserInfo,
    globalAdminRoleInfo,
    regionalAdminRoleInfo,
    unitTalentBusinessPartnerRoleInfos: unitTalentBusinessPartnerRoleInfos,
    groupManagerInfos,
    regionalLevelGroupManagerInfos,
    globalFunctionSlAdminRoleInfo,
    groupIdsForGlobalFunctionAdmin: {
      globalManagementTeamId,
      globalPeopleTeamId,
    },
  };
}
