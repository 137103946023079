import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { Collapse, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PermissionIcon from '@@/assets/images/permission.svg';
import UserFilled from '@@/assets/images/user-filled.svg';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN } from '@@/common/constant/matomo';
import { CLASSIFY_TITLE_KEY, PanelType } from '@@/_new_src_/constants/adminEnum';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';
import {
  People,
  PeopleInUnitOrRegionLevel,
  RolesOfManagement,
  Unit,
  UnitGroup,
  UserPermissionInfoListType,
} from '@@/types/admin';
import { UserInfo } from '@@/types/user';
import { DisplayGroupItem, FormDataModal } from '../../common/AddOrEditModal/type';
import usePerfModal from '@@/hooks/usePerfModal';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { ModalInfoParams } from '../../type';
import {
  deletePeopleFromRegionalLevelOrUnitLevel,
  getUnitsByRegion,
  postAddRegionalLevel,
  postAddUnitByRegional,
} from '@@/_new_src_/api/admin';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import AddOrEditModal from '../../common/AddOrEditModal';
import { RoleInfoMessage } from '../ManageGlobalFunctionSLRolesTab/RolesPanelForGlobalFunctionSL';
import { UnitGroupListResponse } from '../ManageRegionalRolesTab';
import PermissionsModal from '../PermissionsModal';
import modalInfo from './modalInfo';
import './index.less';

const { Panel } = Collapse;

interface LevelPanelProps {
  getUnitGroupListByRegion?: (unitLevelCategory: string) => Promise<UnitGroup[]>;
  rolesOfManagement: RolesOfManagement;
  unitGroupList?: UnitGroup[] | undefined;
  unitGroupLoading?: boolean;
  regionName: string;
  getRolesOfManagement: () => void;
  regionId: number;
}

interface UnitRoleType {
  name: string;
  list: People[] | PeopleInUnitOrRegionLevel[] | undefined;
  hideUnit: boolean;
  openPermissionsModal: () => void;
  openAssignPeopleModal: () => void;
  removePeopleMatomoAction: string;
}

const LevelPanel = (props: LevelPanelProps) => {
  const { trackEvent } = useMatomo();
  const { rolesOfManagement, regionName, getRolesOfManagement } = props;
  const [activeKey, setActiveKey] = useState<string | string[]>();
  const [isPermissionsModalVisible, setIsPermissionsModalVisible] = useState<boolean>(false);
  const [permissionsModalInfo, setPermissionsModalInfo] = useState<UserPermissionInfoListType>(
    {} as UserPermissionInfoListType,
  );

  const [assignPeopleModalVisible, onOpenAssignPeopleModal, onCloseAssignPeopleModal] =
    usePerfModal();

  const [assignRoleName, setAssignRoleName] = useState<string>('');

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { userInfo } = useUserRoleInfo();
  const { updateUserInfo } = userInfo as UserInfo;

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    roleAccessAssignedByRegionalAdmin: {
      talentBusinessPartnerAccess,
      muSlOpsLeadershipAccess,
      regionalTalentBusinessPartnerAccess,
    },
    levelPanel,
    roleInfoInLevelPanel: {
      talentBusinessPartnerInfo,
      muSlOpsLeadershipInfo,
      regionalTalentBusinessPartnerInfo,
    },
  } = localeResource as {
    levelPanel: {
      columns: {
        name: string;
        unit: string;
        addedBy: string;
        addedAt: string;
        actions: string;
      };
      removePeople: {
        removeAction: string;
        cancelText: string;
        okText: string;
        successMessage: string;
        confirmContent: (roleName: string) => {
          contentTips: string;
        };
      };
    };
    roleInfoInLevelPanel: {
      talentBusinessPartnerInfo: RoleInfoMessage;
      muSlOpsLeadershipInfo: RoleInfoMessage;
      regionalTalentBusinessPartnerInfo: RoleInfoMessage & { alreadyAssignedMessage: string };
    };
    roleAccessAssignedByRegionalAdmin: {
      talentBusinessPartnerAccess: UserPermissionInfoListType;
      muSlOpsLeadershipAccess: UserPermissionInfoListType;
      headOfPeopleAccess: (regionName: string) => UserPermissionInfoListType;
      regionalTalentBusinessPartnerAccess: (regionName: string) => UserPermissionInfoListType;
    };
  };

  const {
    REGIONAL_ADMIN_ADDS_REGIONAL_TBP,
    REGIONAL_ADMIN_ADDS_MSO_LEADERSHIP,
    REGIONAL_ADMIN_ADDS_TBP,
    REGIONAL_ADMIN_REMOVES_REGIONAL_TBP,
    REGIONAL_ADMIN_REMOVES_MSO_LEADERSHIP,
    REGIONAL_ADMIN_REMOVES_TBP,
  } = REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.action;

  const {
    run: runGetUnitGroupListByRegion,
    data: unitGroupList,
    loading: getUnitGroupListLoading,
  } = useRequest((unitLevelCategory: string) => getUnitsByRegion({ unitLevelCategory }), {
    formatResult: (res: UnitGroupListResponse) => res.data,
    manual: true,
  });

  const displayUnitGroupList: () => Array<DisplayGroupItem> = () => {
    const groupListArray = [] as Array<DisplayGroupItem>;
    if (unitGroupList) {
      unitGroupList.forEach(groupItem => {
        if (groupItem) {
          groupListArray.push({
            groupName: groupItem.name,
            groupMemberList:
              groupItem.content &&
              groupItem.content.map((groupMember: Unit) => {
                return {
                  id: groupMember.unitInfo.unitId,
                  displayValue: groupMember.unitInfo.name,
                  value: groupMember.unitInfo.unitId,
                };
              }),
          });
        }
      });
    }
    return groupListArray;
  };

  const handleAssignPeopleSuccess = (successMessage: string) => {
    perfMessage.success(successMessage);
    onCloseAssignPeopleModal();
    getRolesOfManagement();
    updateUserInfo();
  };

  const handleAssignPeople = async (data: FormDataModal) => {
    const membersEmails = get(data, 'searchInputValues') as string[];
    const unitId = get(data, 'dropdownInputValue');
    let successMessage = '';
    setSubmitLoading(true);
    let currentMatomoAction = '';
    try {
      switch (assignRoleName) {
        case UserRoleInfo[USER_ROLE_ENUM.REGIONAL_TALENT_BP].display:
          currentMatomoAction = REGIONAL_ADMIN_ADDS_REGIONAL_TBP;
          await postAddRegionalLevel(USER_ROLE_ENUM.REGIONAL_TALENT_BP, membersEmails);
          successMessage = regionalTalentBusinessPartnerInfo.successMessage;
          break;
        case UserRoleInfo[USER_ROLE_ENUM.MU_SL_OPS].display:
          currentMatomoAction = REGIONAL_ADMIN_ADDS_MSO_LEADERSHIP;
          await postAddUnitByRegional({
            unitId,
            membersEmails,
            roleName: USER_ROLE_ENUM.MU_SL_OPS,
          });
          successMessage = muSlOpsLeadershipInfo.successMessage;
          break;
        case UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].display:
          currentMatomoAction = REGIONAL_ADMIN_ADDS_TBP;
          await postAddUnitByRegional({
            unitId,
            membersEmails,
            roleName: USER_ROLE_ENUM.TALENT_BP,
          });
          successMessage = talentBusinessPartnerInfo.successMessage;
          break;
      }

      trackEvent({
        category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.category,
        action: currentMatomoAction,
      });
      handleAssignPeopleSuccess(successMessage);
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
    }
  };

  const openUnitLevelAssignPeopleModal = async (displayRoleName: string, roleName: string) => {
    setAssignRoleName(displayRoleName);
    onOpenAssignPeopleModal();
    try {
      await runGetUnitGroupListByRegion(roleName);
    } catch (e) {
      return false;
    }
  };

  const levelList = [
    {
      name: CLASSIFY_TITLE_KEY.REGION_LEVEL,
      roles: [
        {
          name: PanelType.REGIONAL_TALENT_BUSINESS_PARTNER,
          list: rolesOfManagement?.regionalLevelDTOList[
            UserRoleInfo[USER_ROLE_ENUM.REGIONAL_TALENT_BP].id
          ],
          hideUnit: true,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(regionalTalentBusinessPartnerAccess(regionName));
          },
          openAssignPeopleModal: () => {
            setAssignRoleName(UserRoleInfo[USER_ROLE_ENUM.REGIONAL_TALENT_BP].display);
            onOpenAssignPeopleModal();
          },
          removePeopleMatomoAction: REGIONAL_ADMIN_REMOVES_REGIONAL_TBP,
        },
      ],
    },
    {
      name: CLASSIFY_TITLE_KEY.UNIT_LEVEL,
      roles: [
        {
          name: PanelType.MU_SL_OPS_LEADERSHIP,
          list: rolesOfManagement?.unitLevelDTOList[UserRoleInfo[USER_ROLE_ENUM.MU_SL_OPS].id],
          hideUnit: false,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(muSlOpsLeadershipAccess);
          },
          openAssignPeopleModal: async () => {
            await openUnitLevelAssignPeopleModal(
              UserRoleInfo[USER_ROLE_ENUM.MU_SL_OPS].display,
              USER_ROLE_ENUM.MU_SL_OPS,
            );
          },
          removePeopleMatomoAction: REGIONAL_ADMIN_REMOVES_MSO_LEADERSHIP,
        },
        {
          name: PanelType.TALENT_BUSINESS_PARTNER,
          list: rolesOfManagement?.unitLevelDTOList[UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].id],
          hideUnit: false,
          openPermissionsModal: () => {
            setIsPermissionsModalVisible(true);
            setPermissionsModalInfo(talentBusinessPartnerAccess);
          },
          openAssignPeopleModal: async () => {
            await openUnitLevelAssignPeopleModal(
              UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].display,
              USER_ROLE_ENUM.TALENT_BP,
            );
          },
          removePeopleMatomoAction: REGIONAL_ADMIN_REMOVES_TBP,
        },
      ],
    },
  ];

  const getColumns = (
    hideUnit: boolean,
    roleName: string,
    removePeople: (userRoleId: number, roleName: string, removePeopleMatomoAction: string) => void,
    removePeopleMatomoAction: string,
  ) => {
    const columns = [
      {
        title: levelPanel.columns.name,
        dataIndex: 'name',
        key: 'name',
        width: 250,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: hideUnit
          ? false
          : (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name),
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: levelPanel.columns.unit,
        dataIndex: 'unit',
        key: 'unit',
        width: 300,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: hideUnit
          ? false
          : (a: { unit: string }, b: { unit: string }) => a.unit.localeCompare(b.unit),
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
        hidden: hideUnit,
      },
      {
        title: levelPanel.columns.addedBy,
        dataIndex: 'createBy',
        key: 'createBy',
        width: 250,
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: levelPanel.columns.addedAt,
        dataIndex: 'createAt',
        key: 'createAt',
        width: 250,
        sortDirections: DEFAULT_TABLE_COLUMN_SORT_CONFIG.sortDirections,
        sorter: hideUnit
          ? false
          : (a: { createAt: string }, b: { createAt: string }) =>
              dayjs(a.createAt).unix() - dayjs(b.createAt).unix(),
        render: (time: string) => dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: levelPanel.columns.actions,
        width: 150,
        align: 'center',
        key: 'actions',
        render: (text: string, { userRoleId }: { userRoleId: number }) => {
          return (
            <PerfTextButton
              color="red"
              className="operation-remove"
              onClick={() => {
                removePeople(userRoleId, roleName, removePeopleMatomoAction);
              }}
            >
              {levelPanel.removePeople.removeAction}
            </PerfTextButton>
          );
        },
      },
    ].filter(column => !column.hidden);
    return columns as ColumnsType<PeopleInUnitOrRegionLevel> | ColumnsType<People>;
  };

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = (name: string, count: number) => {
    const isActive = activeKey?.includes(name);
    return (
      <>
        <img className="user-filled-icon" src={UserFilled} alt="" />
        <span className="header-text">{`${name} (${count})`}</span>
        <DownOutlined className="down-icon" rotate={isActive ? 180 : 0} />
      </>
    );
  };

  const removePeople = (userRoleId: number, roleName: string, removePeopleMatomoAction: string) => {
    const { contentTips } = levelPanel.removePeople.confirmContent(roleName);
    const closeModal = perfModalConfirm({
      cancelText: levelPanel.removePeople.cancelText,
      okText: levelPanel.removePeople.okText,
      className: 'level-panel-removePeopleConfirm',
      content: (
        <div className="delete-modal-content">{contentTips && <div>{contentTips}</div>}</div>
      ),
      onOk: async () => {
        trackEvent({
          category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.category,
          action: removePeopleMatomoAction,
        });
        await deletePeopleFromRegionalLevelOrUnitLevel(userRoleId).finally(() => closeModal());
        perfMessage.success(roleName + levelPanel.removePeople.successMessage);
        getRolesOfManagement();
        updateUserInfo();
      },
      centered: true,
      onCancel: () => {},
    });
  };

  const renderPanelExtra = (
    onClickViewPermissions: () => void,
    onClickAssignPeople: () => void,
  ) => {
    return (
      <>
        <div
          className="panel-button"
          onClick={e => {
            e.stopPropagation();
            onClickViewPermissions();
          }}
        >
          <img className="icon permission-icon" src={PermissionIcon} alt="" />
          <span>View Permissions</span>
        </div>
        <div
          className="panel-button"
          onClick={e => {
            e.stopPropagation();
            onClickAssignPeople();
          }}
        >
          <PlusCircleOutlined className="icon plus-icon" />
          <span>Assign People</span>
        </div>
      </>
    );
  };

  const renderAssignModal = () => {
    const modalParams: ModalInfoParams = {
      locale: localeResource,
      visible: assignPeopleModalVisible,
      onClose: onCloseAssignPeopleModal,
      handleSubmit: handleAssignPeople,
      submitLoading,
      modalLoading: getUnitGroupListLoading,
      dropdownListData: displayUnitGroupList(),
      regionName,
    };

    const modalConfig = modalInfo(modalParams);
    const currentModalByRole = modalConfig[assignRoleName];
    const modalProps = currentModalByRole && currentModalByRole.modalInfo;
    return <AddOrEditModal {...modalProps} />;
  };

  return (
    <div className="level-panel-container">
      {levelList.map(level => (
        <div key={level.name}>
          <div className="level-name">{`${level.name}`}</div>
          <Collapse
            className="custom-collapse"
            onChange={onCollapseChange}
            activeKey={activeKey}
            ghost
          >
            {level.roles.map((role: UnitRoleType) => (
              <Panel
                className="custom-panel"
                header={renderPanelHeader(role.name, role.list?.length || 0)}
                key={role.name}
                extra={renderPanelExtra(
                  role.openPermissionsModal as () => void,
                  role.openAssignPeopleModal as () => void,
                )}
                showArrow={false}
              >
                <Table<PeopleInUnitOrRegionLevel>
                  columns={getColumns(
                    role.hideUnit,
                    role.name,
                    removePeople,
                    role.removePeopleMatomoAction,
                  )}
                  dataSource={role.list}
                  rowKey={(people: People) => people.userRoleId}
                  pagination={
                    (role.list?.length as number) > TABLE_DEFAULT_INFO.PAGE_SIZE
                      ? {
                          ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                        }
                      : false
                  }
                />
              </Panel>
            ))}
          </Collapse>
        </div>
      ))}
      <PermissionsModal
        key={permissionsModalInfo.title}
        title={permissionsModalInfo.title}
        visible={isPermissionsModalVisible}
        onCancel={() => setIsPermissionsModalVisible(false)}
        permissionList={permissionsModalInfo.permissionList}
      />

      {renderAssignModal()}
    </div>
  );
};

export default LevelPanel;
