import React from 'react';
import EmptyFeedbackIcon from '@@/assets/images/empty-feedback.svg';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import CycleSelector from '../CycleSelector';
import './index.less';

const NoCycleContainer = () => {
  const {
    feedback: { emptyReview },
  } = FeedbackLocale;
  return (
    <div className="empty-review-container">
      <CycleSelector />
      <img src={EmptyFeedbackIcon} className="empty-icon" alt="" />
      <div className="content">{emptyReview.content}</div>
      <div className="tip">{emptyReview.tip}</div>
    </div>
  );
};

export default NoCycleContainer;
