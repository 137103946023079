import React, { useMemo } from 'react';
import {
  ICycleDuration,
  IFilterInfo,
  INonEngagedCyclesData,
} from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import { Table, TableProps, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { nonEngagedCyclesTableSortColumnsName } from '@@/types/antdComponent';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_FOR_FE,
  TABLE_DEFAULT_INFO,
} from '@@/_new_src_/constants/table';
import { setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { ColumnType } from 'antd/lib/table';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import { NON_ENGAGED_CYCLES_TAB_INDEX } from '@@/_new_src_/constants/overdueAndNonEngaged';
import { SortOrder } from 'antd/es/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import { nonEngagedCycles } from '@@/_new_src_/store/nonEngagedCyclesSlice';
import './index.less';

const List = (props: {
  findCycleNonEngagedCycles: (params: IFilterInfo) => void;
  getCurrentFilterInfo: IFilterInfo;
  updateFilterInfo: (params: IFilterInfo) => void;
}) => {
  const { findCycleNonEngagedCycles, getCurrentFilterInfo, updateFilterInfo } = props;
  const { tableInfo } = getCurrentFilterInfo;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    nonEngagedCycles: { tabText, operation },
  } = nonEngagedCyclesLocale;

  const { currentTab, content, total } = useSelector(nonEngagedCycles);

  const sortDirection = SORT_DIRECTION_FOR_FE[tableInfo.sortOrder] as SortOrder;

  const handleTableChange: TableProps<INonEngagedCyclesData>['onChange'] = (
    pagination,
    _,
    sorter,
  ) => {
    const { columnKey, order } = sorter as SorterResult<INonEngagedCyclesData>;

    const currentSortColumn = columnKey
      ? nonEngagedCyclesTableSortColumnsName[
          columnKey as keyof typeof nonEngagedCyclesTableSortColumnsName
        ]
      : tableInfo.sortColumn;

    const currentSortOrder = order ? SORT_DIRECTION_FOR_BE[order] : tableInfo.sortOrder;

    const currentTableInfo = {
      sortColumn: currentSortColumn,
      sortOrder: currentSortOrder,
      pageNumber: pagination.current || TABLE_DEFAULT_INFO.PAGE_NUMBER,
      pageSize: pagination.pageSize || TABLE_DEFAULT_INFO.PAGE_SIZE,
    };
    const params = {
      ...getCurrentFilterInfo,
      tableInfo: currentTableInfo,
    };

    updateFilterInfo(params);
    findCycleNonEngagedCycles(params);
  };

  const handleSelectRow = (record: { cycleId: number; cycleVersion: number }) => {
    dispatch(setCycleVersion(record.cycleVersion));
    history.push(`${MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE}/${record.cycleId}`);
  };

  const createNonEngagedCyclesTableColumn: ColumnType<INonEngagedCyclesData>[] = [
    {
      title: "Twer's name",
      dataIndex: 'twerName',
      key: 'twerName',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        tableInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.twerName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (twerName: string) => (
        <Tooltip placement="topLeft" title={twerName}>
          {twerName}
        </Tooltip>
      ),
    },
    {
      title: "PP's name",
      dataIndex: 'performancePartnerName',
      key: 'performancePartnerName',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        tableInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.performancePartnerName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (performancePartnerName: string) => (
        <Tooltip placement="topLeft" title={performancePartnerName}>
          {performancePartnerName}
        </Tooltip>
      ),
    },
    {
      title: 'Supervisory Org',
      dataIndex: 'superOrgName',
      key: 'superOrgName',
      width: 140,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        tableInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.superOrgName
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (superOrgName: string) => (
        <Tooltip placement="topLeft" title={superOrgName}>
          {superOrgName}
        </Tooltip>
      ),
    },
    {
      title: 'Home office',
      dataIndex: 'office',
      key: 'office',
      width: 100,
      ellipsis: { showTitle: false },
      defaultSortOrder:
        tableInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.office
          ? sortDirection
          : undefined,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (office: string) => {
        return (
          <Tooltip placement="topLeft" title={office}>
            {office}
          </Tooltip>
        );
      },
    },
  ];

  const action = {
    title: 'Actions',
    key: 'actions',
    width: 190,
    ellipsis: { showTitle: false },
    render: ({ canBeUnmarked }: { canBeUnmarked: boolean }) => {
      const backgroundColor = canBeUnmarked ? '#1A73EB' : '#00000066';
      return <div style={{ color: backgroundColor }}>{operation}</div>;
    },
  };

  const cycleDurationColumn = {
    title: 'Cycle duration',
    key: 'cycleDuration',
    dataIndex: 'cycleDuration',
    width: 190,
    ellipsis: { showTitle: false },
    defaultSortOrder:
      tableInfo.sortColumn === nonEngagedCyclesTableSortColumnsName.cycleDuration
        ? sortDirection
        : undefined,
    ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
    render: (cycleDuration: ICycleDuration) => {
      const { startTime, endTime } = cycleDuration;
      return <div>{formatRangeDuration(startTime, endTime)}</div>;
    },
  };

  const createTableColumns = useMemo(() => {
    const currentColumns = createNonEngagedCyclesTableColumn;
    if (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED) {
      currentColumns.push(action);
    }

    if (currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED) {
      currentColumns.push(cycleDurationColumn);
    }

    return currentColumns;
  }, [currentTab]);

  return (
    <div className={'non-engaged-cycle-list'}>
      <div className="total-section">
        {content.length > 0 && (
          <div>
            <span className="total-two-month-overdue-twers">{`${total} ${tabText(
              currentTab,
            )}`}</span>
          </div>
        )}
      </div>
      <Table<INonEngagedCyclesData>
        columns={createTableColumns}
        dataSource={content}
        onChange={handleTableChange}
        onRow={record => {
          return {
            onClick: () => {
              handleSelectRow(record);
            },
          };
        }}
        pagination={
          total !== undefined && total > TABLE_DEFAULT_INFO.PAGE_SIZE
            ? {
                ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                total: total,
                current: tableInfo.pageNumber,
                pageSize: tableInfo.pageSize,
              }
            : false
        }
      />
    </div>
  );
};
export default List;
