import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import { Form, Select } from 'antd';
import { isEmpty, omit } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN } from '@@/common/constant/matomo';
import { ERROR_CODE } from '@@/common/constant/http';
import { SelectOptionValueType } from '@@/types/antdComponent';
import {
  customGroupMemberInfoType,
  userListTypeForSearch,
  userListTypeForSearchCountryPeopleTeam,
} from '@@/types/user';
import { IErrorType } from '@@/_new_src_/types/common';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  getUserListForCountryLeaderShipTeam,
  getUserListForCountryPeopleTeam,
} from '@@/_new_src_/api/admin';
import AutoComplete from '@@/common/component/AutoComplete/index';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal/index';
import './index.less';

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};

interface formValuesType {
  name: Array<SelectOptionValueType>;
}

interface addPeopleReturnType {
  data: {
    successfullyAddedEmailList: Array<string>;
    notAddedEmailList: Array<string>;
  };
}

export interface RegionalAdminModalProps {
  onSuccess: () => void;
  visible: boolean;
  onCancel: () => void;
  regionId: number;
  regionName: string;
  addPeopleApi: (groupId: number, addPeopleList: string[]) => Promise<addPeopleReturnType>;
  selectedGroupId: number | undefined;
  isLeaderShipTeam: boolean;
}

function AddRegionalGroupMemberModal(props: RegionalAdminModalProps) {
  const { trackEvent } = useMatomo();
  const {
    REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP,
    REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP,
  } = REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.action;

  const { onSuccess, regionId, regionName, addPeopleApi, isLeaderShipTeam, selectedGroupId } =
    props;
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();

  const { run: runGetUserListForPeopleTeam } = useRequest(
    name => getUserListForCountryPeopleTeam(name, regionId),
    {
      manual: true,
    },
  );

  const { run: runGetUserListForLeaderShipTeam } = useRequest(
    name => getUserListForCountryLeaderShipTeam(name, regionId),
    {
      manual: true,
    },
  );

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageGroups: { regionalAdminModal: locale },
    },
  } = localeResource as {
    admin: {
      manageGroups: {
        regionalAdminModal: {
          name: {
            label: string;
            placeholder: string;
            errorMessage: string;
            notFoundContent: string;
          };
          cancel: string;
          add: string;
          successMessage: string;
          errorMessage: (count: number) => string;
          addPeople: string;
          addLeadershipTeamModalTitle: (regionName: string) => string;
          addPeopleTeamModalTitle: (regionName: string) => string;
        };
      };
    };
  };

  const onSubmit = useCallback(async () => {
    const values = (await form.validateFields()) as formValuesType;
    const addPeopleEmailList = values.name.map(item => item.value);
    try {
      setLoading(true);
      trackEvent({
        category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN.category,
        action: isLeaderShipTeam
          ? REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP
          : REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP,
      });
      const {
        data: { notAddedEmailList },
      } = (selectedGroupId && (await addPeopleApi(selectedGroupId, addPeopleEmailList))) as {
        data: { successfullyAddedEmailList: string[]; notAddedEmailList: string[] };
      };
      if (notAddedEmailList.length === 0) {
        perfMessage.success(locale.successMessage);
      } else {
        perfMessage.error(locale.errorMessage(notAddedEmailList.length));
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if ((error as IErrorType).errorCode === ERROR_CODE.ADDING_ADMIN_NAME_ERROR) {
        form.setFields([
          {
            name: 'name',
            errors: [`* ${(error as IErrorType).message}.`],
            value: form.getFieldValue('name') as formValuesType,
            touched: true,
          },
        ]);
        setSubmitDisabled(true);
      }
    } finally {
      setLoading(false);
    }
  }, [addPeopleApi, form, isLeaderShipTeam, locale, onSuccess, regionName, selectedGroupId]);

  const onReset = useCallback(() => {
    form.resetFields();
    setSubmitDisabled(true);
  }, [form]);

  const onFormFieldsChange = useCallback(() => {
    const fields = Object.keys(omit(form.getFieldsValue()));
    const isDisabled =
      isEmpty(fields) ||
      !form.isFieldsTouched(fields, true) ||
      !isEmpty(form.getFieldsError(fields).filter(({ errors }) => errors.length));

    setSubmitDisabled(isDisabled);
  }, [form]);

  const renderNameOptions = useCallback(
    (searchResult: userListTypeForSearchCountryPeopleTeam, Option: typeof Select.Option) => {
      const {
        searchedPeople,
        assignedPeopleInGroup,
      }: {
        searchedPeople: userListTypeForSearch[];
        assignedPeopleInGroup: customGroupMemberInfoType[];
      } = searchResult;

      return searchedPeople?.map(({ userId, name, email }) => {
        const isAlreadyAdded: boolean = assignedPeopleInGroup
          .map(item => item.email)
          .includes(email);
        const selectedUserInfoInCurrentForm = (form.getFieldValue('name') ||
          []) as Array<SelectOptionValueType>;
        const isAlreadySelectedInCurrentForm = selectedUserInfoInCurrentForm
          .map((item: SelectOptionValueType) => item.value)
          .includes(email);
        return (
          <Option
            key={String(userId)}
            label={String(name)}
            value={String(email)}
            disabled={isAlreadySelectedInCurrentForm || isAlreadyAdded}
          >
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form],
  );

  return (
    <PerfModal
      title={
        isLeaderShipTeam
          ? locale.addLeadershipTeamModalTitle(regionName)
          : locale.addPeopleTeamModalTitle(regionName)
      }
      okText={locale.add}
      destroyOnClose
      centered
      className="add-regional-group-member-modal"
      onOk={onSubmit}
      afterClose={onReset}
      okButtonProps={{
        loading,
        disabled: submitDisabled,
      }}
      maskClosable={false}
      {...props}
    >
      <Form
        {...layout}
        form={form}
        colon={false}
        onFieldsChange={onFormFieldsChange}
        className="regional-admin-modal-form"
      >
        <Form.Item
          name="name"
          label={
            <>
              {locale.name.label}
              <span className="required-mark">*</span>
            </>
          }
          rules={[
            {
              required: true,
              message: locale.name.errorMessage,
            },
          ]}
        >
          <AutoComplete
            placeholder={locale.name.placeholder}
            notFoundContent={locale.name.notFoundContent}
            showArrow={false}
            defaultActiveFirstOption={false}
            showSearch
            allowClear={false}
            fetchApi={
              isLeaderShipTeam ? runGetUserListForLeaderShipTeam : runGetUserListForPeopleTeam
            }
            mode="multiple"
          >
            {renderNameOptions}
          </AutoComplete>
        </Form.Item>
      </Form>
    </PerfModal>
  );
}

export default memo(AddRegionalGroupMemberModal);
