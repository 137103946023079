/* eslint-disable max-lines */
import AutoComplete from '@@/common/component/AutoComplete';
import { EXPECTATION_MAX_LENGTH, LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { UserInfoContext } from '@@/context/userInfo.context';
import { SearchedPeopleInfo } from '@@/features/performance/v2/admin/common/AddOrEditModal/type';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getUserList } from '@@/_new_src_/api/user';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import FormOperationBtn from '@@/_new_src_/features/FormOperationBtn';
import useLocalStorageCycle from '@@/_new_src_/hooks/useLocalStorageCycle';
import {
  confirmModalType,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import {
  attachment,
  cycleInformation,
  isUpdateInformationSuccess,
  selfAssessmentAttachment,
  setAttachment,
  setCycleDisplayPart,
  setEditDoneCycleChanged,
  setIsUpdateInformationSuccess,
} from '@@/_new_src_/store/myCyclesSlice';
import {
  postAddInformationAsync,
  putUpdateInformationAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  ICycleFormFieldsValue,
  IInformationFormDefault,
  IMyCyclesLocale,
  ISelectorValue,
} from '@@/_new_src_/types/myCycles';
import { Form, Input, Select } from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get, isEmpty, isEqual, isNil, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CYCLE_DISPLAY_PART,
  EXPECTATION_DESCRIPTION_MAX_LENGTH,
  FORM_HANDLE_TYPE,
  HELPER_TEXT_OPTIONS,
} from '@@/_new_src_/constants/myCycles';
import {
  ICreateInformationProps,
  IUpdateInformationProps,
} from '@@/_new_src_/store/myCyclesSlice/interface';
import UploadAttachment from '@@/_new_src_/features/uploadAttachment';
import HelperButton from '../../../../components/HelperButton';
import HelperModal from '../HelperModal';
import './index.less';
import { setCurrentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';
import {
  getCycleStatus,
  getRequestToEditStatus,
  goToCycleDetailPage,
} from '@@/_new_src_/utils/feature/cycle';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import {
  doneCycleSelfAssessmentContent,
  isSelfAssessmentChanged,
  sendRequestData,
  setIsSelfAssessmentChanged,
} from '@@/_new_src_/store/cycleDetailSlice';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';
import { clearCurrentReviewState } from '@@/redux/slice/reviewSlice';
import { useRouteMatch } from 'react-router-dom';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { IPersonInfo } from '@@/_new_src_/store/cycleDetailSlice/interface';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
dayjs.extend(utc);

const CycleInformationFormV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();

  const [form] = Form.useForm();

  const { loading: updateCycleInformationLoading, success: isUpdateCycleSuccess } = useSelector(
    isUpdateInformationSuccess,
  );

  const selfAssessmentChanged = useSelector(isSelfAssessmentChanged);

  const { userId: currentUserId, currentOpeningCycleId } = useContext(UserInfoContext);

  const [expectationDescriptionText, setExpectationDescriptionText] = useState('');
  const [currentHelpOptionKey, setCurrentHelpOptionKey] = useState<string>('noHelperDisplay');
  const [isNotValidFields, setIsNotValidFields] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const isReviewHistoryDetailPage = useRouteMatch(CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL);

  const {
    myCycles: {
      cycleInformationForm: locale,
      formBtnText: operationBtnLocale,
      cycleModal: { cancelEdit: cancelEditLocale, doneCycleCancelEdit: doneCycleCancelEditLocale },
    },
  } = useSelector(selectLocaleResource) as IMyCyclesLocale;

  const currentModalType = useSelector(confirmModalType);

  const { filename, url: attachmentUrl, loading: uploadLoading } = useSelector(attachment);
  const currentAttachment = useSelector(attachment);

  const {
    performancePartnerState,
    additionalPartnersState,
    setAdditionalPartnersState,
    projectState,
    contextAttachmentState,
    contextContentState,
    setContextContentState,
    removeLocalCycle,
  } = useLocalStorageCycle();

  const cycleInfo = useSelector(cycleInformation);
  const cycleId = get(cycleInfo, 'id');
  const curCycleStatus = get(cycleInfo, 'status');
  const context = get(cycleInfo, 'context');
  const performancePartner = get(cycleInfo, 'performancePartner');
  const additionalPartners = get(cycleInfo, 'additionalPartners');

  const { status } = useSelector(sendRequestData);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(status);
  const [isEdit, setIsEdit] = useState(false);
  const [expectationDescriptionHtml, setExpectationDescriptionHtml] = useState(context?.content);
  const [defaultFormInfo, setDefaultFormInfo] = useState<IInformationFormDefault | null>(null);
  const defaultPerformancePartner = get(defaultFormInfo, 'performancePartner');

  const defaultAdditionalPartners = get(defaultFormInfo, 'additionalPartners');
  const defaultContextContent = get(defaultFormInfo, 'contextContent');
  const defaultContextAttachment = get(defaultFormInfo, 'contextAttachment');
  const assessmentContent = useSelector(doneCycleSelfAssessmentContent);
  const doneCycleSelfAssessmentAttachment = useSelector(selfAssessmentAttachment);

  const { isNewCycle, isUnreadCycle } = getCycleStatus(curCycleStatus);

  useEffect(() => {
    if (isApprovedRequestToEdit) {
      setIsEdit(!!cycleId);
    } else {
      setIsEdit(!!cycleId && !isUnreadCycle);
    }
  }, [cycleId, isApprovedRequestToEdit]);

  useEffect(() => {
    form.setFieldValue('performancePartner', get(defaultPerformancePartner, 'name', ''));
  }, [defaultPerformancePartner]);

  useEffect(() => {
    if (isEdit && cycleInfo) {
      !isApprovedRequestToEdit && removeLocalCycle();
      const contextContent = get(context, 'content') || '';
      const contextAttachment = omit(context, 'content') || null;
      setDefaultFormInfo({
        performancePartner,
        additionalPartners,
        contextContent,
        contextAttachment,
      });
    } else {
      const isNotEditDefaultInfo = {
        performancePartner: performancePartnerState || null,
        additionalPartners: additionalPartnersState || [],
        project: projectState || null,
        contextAttachment: contextAttachmentState || {
          attachmentName: '',
          attachmentStorageUrl: '',
        },
        contextContent: contextContentState || '',
      };
      setDefaultFormInfo(isNotEditDefaultInfo);
    }
  }, [isEdit]);

  const getFormAdditionalPartners = (data: Array<IPersonInfo>) => {
    if (data && data.length > 0) {
      return data.map(item => {
        return {
          value: item?.id,
          label: item?.name,
          key: item?.id,
        };
      });
    } else {
      return [];
    }
  };

  const getStorageAdditionalPartners = (data: Array<ISelectorValue>) => {
    if (data && data.length > 0) {
      return data.map(item => {
        return {
          id: item.value,
          name: item.label,
        };
      });
    } else {
      return [];
    }
  };

  const formatCurrentFormData = (type?: string) => {
    const { additionalPartners: fieldAdditionalPartners = [] } =
      form.getFieldsValue() as ICycleFormFieldsValue;
    if (type === FORM_HANDLE_TYPE.CANCEL) {
      const curAdditionalPartners = getStorageAdditionalPartners(fieldAdditionalPartners);
      const curAttachment = {
        attachmentName: filename || '',
        attachmentStorageUrl: attachmentUrl || '',
      };
      return (
        !isEqual(curAdditionalPartners, defaultAdditionalPartners) ||
        !isEqual(expectationDescriptionHtml, defaultContextContent) ||
        !isEqual(curAttachment, defaultContextAttachment)
      );
    } else {
      const currentAttachmentStorageName =
        (attachmentUrl && attachmentUrl.substring(attachmentUrl.lastIndexOf('/') + 1)) || '';

      return {
        additionalPartnerIds:
          fieldAdditionalPartners?.map((item: { label: string; value: number }) => item.value) ||
          [],
        context: expectationDescriptionHtml || '',
        contextAttachmentName: filename,
        contextAttachmentStorageName: currentAttachmentStorageName,
      } as ICreateInformationProps;
    }
  };

  const onFormFieldsChange = () => {
    const fieldKeys = Object.keys(form.getFieldsValue());
    const isNotValidFormFields = !isEmpty(
      form.getFieldsError(fieldKeys).filter(({ errors }) => errors.length),
    );
    setIsNotValidFields(isNotValidFormFields);
  };

  useEffect(() => {
    if (!isEmptyOrNil(defaultFormInfo)) {
      const initFieldsValue = {
        additionalPartners: getFormAdditionalPartners(defaultAdditionalPartners || []),
      };

      form.setFieldsValue(initFieldsValue);
      const contextAttachment = get(defaultFormInfo, 'contextAttachment');
      !isEmpty(contextAttachment) &&
        !isNil(contextAttachment) &&
        dispatch(
          setAttachment({
            type: AttachmentType.CYCLE_INFORMATION,
            data: {
              success: false,
              filename: get(contextAttachment, 'attachmentName'),
              url: get(contextAttachment, 'attachmentStorageUrl'),
            },
          }),
        );
      onFormFieldsChange();
    }
  }, [defaultFormInfo]);

  useEffect(() => {
    dispatch(
      setEditDoneCycleChanged(
        formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) || selfAssessmentChanged,
      ),
    );
    return () => {
      dispatch(setEditDoneCycleChanged(false));
    };
  }, [formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL), selfAssessmentChanged]);

  const onChangeAdditionalPartners = (value: ISelectorValue[]) => {
    const curAdditionalPartners = getStorageAdditionalPartners(value || []);
    setAdditionalPartnersState(curAdditionalPartners);
  };

  const updateExpectationHtml = (html: string) => {
    setExpectationDescriptionHtml(html);
    !isEdit && setContextContentState(html);
  };

  useEffect(() => {
    setIsChanged(formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) as boolean);
  }, [form.getFieldsValue()]);

  const handleConfirmCanelEditDoneCycle = () => {
    dispatch(setEditDoneCycleChanged(false));
    if (isReviewHistoryDetailPage) {
      window.location.reload();
      return;
    }
  };

  const handleConfirmCancel = () => {
    dispatch(setEditDoneCycleChanged(false));
    if (isEdit && isNewCycle) {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
    } else if (isEdit && isUnreadCycle) {
      window.location.reload();
    } else {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.DEFAULT_DISPLAY));
      dispatch(setCurrentCycleDetail({}));
      const goToCycleDetailPageLink = goToCycleDetailPage({
        currentOpeningCycleId,
        cycleId,
      });
      history.push(goToCycleDetailPageLink);
    }
    dispatch(setIsSelfAssessmentChanged(false));
    dispatch(clearCurrentReviewState());
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalType(''));
    formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) && removeLocalCycle();
  };

  const handleClickCancel = () => {
    if (formatCurrentFormData(FORM_HANDLE_TYPE.CANCEL) || selfAssessmentChanged) {
      dispatch(setConfirmModalStatus(true));
      if (isReviewHistoryDetailPage) {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.UNDO_CHANGE));
      } else {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE));
      }
    } else {
      if (isReviewHistoryDetailPage) {
        handleConfirmCanelEditDoneCycle();
      } else {
        handleConfirmCancel();
      }
    }
  };

  const handleSubmitCycle = () => {
    const payloadData = formatCurrentFormData(FORM_HANDLE_TYPE.SUBMIT) as ICreateInformationProps;
    if (isEdit && cycleId && !isEmpty(payloadData)) {
      let editPayloadData = {
        ...payloadData,
        cycleId,
      } as IUpdateInformationProps;
      if (isApprovedRequestToEdit) {
        editPayloadData = {
          ...editPayloadData,
          selfAssessmentDTO: {
            content: assessmentContent,
            attachmentName: doneCycleSelfAssessmentAttachment.filename,
            attachmentStorageName: doneCycleSelfAssessmentAttachment.storageName,
          },
        };
      }
      !isApprovedRequestToEdit &&
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: MY_CYCLE_LIST_API.action.TWER_EDITS_V2_CYCLE_INFORMATION,
        });
      dispatch(putUpdateInformationAsync(editPayloadData));
    } else {
      trackEvent({
        category: MY_CYCLE_LIST_API.category,
        action: MY_CYCLE_LIST_API.action.TWER_CREATES_V2_CYCLE_WITH_NEW_STATUS,
      });
      dispatch(postAddInformationAsync(payloadData));
    }
  };

  useEffect(() => {
    if (isUpdateCycleSuccess) {
      removeLocalCycle();
      dispatch(
        setAttachment({ type: AttachmentType.CYCLE_INFORMATION, data: { isInitialValue: true } }),
      );
      dispatch(setIsUpdateInformationSuccess({}));
      if (isApprovedRequestToEdit) {
        window.location.reload();
      } else {
        const goToCycleDetailPageLink = goToCycleDetailPage({
          currentOpeningCycleId,
          cycleId,
        });
        history.push(goToCycleDetailPageLink);
      }
    }
  }, [cycleInfo, isUpdateCycleSuccess]);

  const isNotValidExpectation =
    !!expectationDescriptionHtml &&
    expectationDescriptionText.trim().length > EXPECTATION_DESCRIPTION_MAX_LENGTH;

  const isDisableSave = useMemo((): boolean => {
    return (
      (isNotValidFields || isNotValidExpectation || uploadLoading || !isChanged) &&
      !selfAssessmentChanged
    );
  }, [isNotValidFields, isNotValidExpectation, uploadLoading, isChanged, selfAssessmentChanged]);

  const renderTwerOption = useCallback(
    (optionList: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const alreadySelectedAP = form
        .getFieldValue('additionalPartners')
        ?.map((item: { value: number }) => item.value) as number[];

      return optionList.map(({ userId = 0, name = '' }) => {
        const isSelf: boolean = currentUserId === userId;
        const isSelected =
          alreadySelectedAP?.includes(userId) ||
          (defaultPerformancePartner?.id as number) === userId;

        return (
          <Option key={String(userId)} label={name} value={userId} disabled={isSelf || isSelected}>
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form, currentUserId, defaultPerformancePartner],
  );

  return (
    <>
      <div className="cycle-information-wrapper-v2">
        <div className="form-title">
          <span>{locale.formTitle}</span>
        </div>
        <Form
          className={cls('cycle-information-form', 'set-review', {
            'edit-done-cycle': isApprovedRequestToEdit,
          })}
          layout="vertical"
          form={form}
          onFinish={handleSubmitCycle}
          onFieldsChange={onFormFieldsChange}
        >
          <div className={`cycle-information-form-item`}>
            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.PERFORMANCE_PARTNER_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="performancePartner"
              className="performance-partner-wrapper"
              label={
                <>
                  <div>
                    <span>{locale.performancePartner.label}</span>
                    <span className="require-mark">&nbsp;*</span>
                  </div>
                  <HelperButton
                    showButton={!isApprovedRequestToEdit}
                    helpOptionKey={HELPER_TEXT_OPTIONS.PERFORMANCE_PARTNER_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <Input disabled />
            </Form.Item>

            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.ADDITIONAL_PARTNER_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="additionalPartners"
              className="additional-partners-wrapper"
              label={
                <>
                  <div id="additionalPartners-label">
                    <span>{locale.additionalPartner.label}</span>
                    <span className="label-optional">&nbsp;{locale.optional}</span>
                  </div>
                  <HelperButton
                    showButton={!isApprovedRequestToEdit}
                    helpOptionKey={HELPER_TEXT_OPTIONS.ADDITIONAL_PARTNER_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <AutoComplete
                limitNum={LIMIT_PARTNER_NUM}
                mode="multiple"
                placeholder={isApprovedRequestToEdit ? null : locale.additionalPartner.placeholder}
                notFoundContent={locale.additionalPartner.notFoundContent}
                fetchApi={getUserList}
                onChange={onChangeAdditionalPartners}
                aria-label={'Additional partners'}
                aria-expanded="false"
                disabled={isApprovedRequestToEdit}
              >
                {(list: SearchedPeopleInfo[], Option: typeof Select.Option) =>
                  renderTwerOption(list, Option)
                }
              </AutoComplete>
            </Form.Item>
          </div>
          {currentHelpOptionKey === HELPER_TEXT_OPTIONS.CYCLE_NOTES_OR_CONTEXT_INTRO && (
            <HelperModal />
          )}
          <Form.Item
            name="expectation"
            className="expectation-description"
            label={
              <>
                <div id="expectation-label">
                  <span className="label">{locale.expectation.label}</span>
                  <span className="label-optional">&nbsp;{locale.optional}</span>
                </div>
                <HelperButton
                  showButton={!isApprovedRequestToEdit}
                  helpOptionKey={HELPER_TEXT_OPTIONS.CYCLE_NOTES_OR_CONTEXT_INTRO}
                  currentHelpOptionKey={currentHelpOptionKey}
                  setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                />
              </>
            }
          >
            <TinyMceEditor
              initialValue={defaultContextContent || ''}
              updateHtmlInfo={updateExpectationHtml}
              updateTextInfo={setExpectationDescriptionText}
              maxLength={EXPECTATION_MAX_LENGTH}
              placeholder={locale.expectation.placeholder}
              customPlaceholderStyle={`color: #484848;
            font-weight: 349;
            font-style: italic;
            font-size: 16px;`}
              height={400}
            />
          </Form.Item>
          <UploadAttachment
            attachmentType={AttachmentType.CYCLE_INFORMATION}
            defaultInfo={defaultContextAttachment}
            attachmentClassName={'information-attachment-tooltip'}
            attachmentInfo={currentAttachment}
          />
          <Form.Item>
            <FormOperationBtn
              confirmBtnText={
                isEdit
                  ? operationBtnLocale.editExpectationOrInformation
                  : operationBtnLocale.submitInformation
              }
              confirmBtnDisabled={isDisableSave}
              cancelBtnDisabled={uploadLoading}
              handleClickCancel={handleClickCancel}
              cancelBtnText={
                isReviewHistoryDetailPage
                  ? operationBtnLocale.undoModifyDoneCycle
                  : operationBtnLocale.cancel
              }
              isLoadingSubmit={updateCycleInformationLoading}
            />
          </Form.Item>
        </Form>
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE && (
          <ConfirmModal {...cancelEditLocale} handleOkFunc={handleConfirmCancel} />
        )}
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.UNDO_CHANGE && (
          <ConfirmModal
            {...doneCycleCancelEditLocale}
            handleOkFunc={handleConfirmCanelEditDoneCycle}
          />
        )}
      </div>
    </>
  );
};

export default CycleInformationFormV2;
