import { getUserList } from '@@/api/user';
import { UserPermissionInfoListType } from '@@/types/admin';
import { ModalInfoParams } from '../../type';
import { RoleInfoMessage } from '../ManageGlobalFunctionSLRolesTab/RolesPanelForGlobalFunctionSL';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';
import './index.less';

const modalInfo = ({
  locale,
  visible,
  onClose,
  handleSubmit,
  submitLoading,
  modalLoading,
  dropdownListData,
  regionName,
  handleChangeDropdownList,
  limitNumber,
}: ModalInfoParams) => {
  const {
    roleAccessAssignedByRegionalAdmin: {
      talentBusinessPartnerAccess,
      muSlOpsLeadershipAccess,
      regionalTalentBusinessPartnerAccess,
    },
    roleInfoInLevelPanel: {
      talentBusinessPartnerInfo,
      muSlOpsLeadershipInfo,
      regionalTalentBusinessPartnerInfo,
    },
    admin: {
      manageAdminRole: {
        addAdminModal: { nameNotFound },
      },
    },
  } = locale as {
    roleInfoInLevelPanel: {
      talentBusinessPartnerInfo: RoleInfoMessage;
      muSlOpsLeadershipInfo: RoleInfoMessage;
      regionalTalentBusinessPartnerInfo: RoleInfoMessage;
    };
    roleAccessAssignedByRegionalAdmin: {
      talentBusinessPartnerAccess: UserPermissionInfoListType;
      muSlOpsLeadershipAccess: UserPermissionInfoListType;
      regionalTalentBusinessPartnerAccess: (regionName: string) => UserPermissionInfoListType;
    };
    admin: {
      manageAdminRole: {
        addAdminModal: { nameNotFound: string };
      };
    };
  };

  return {
    [UserRoleInfo[USER_ROLE_ENUM.REGIONAL_TALENT_BP].display]: {
      modalInfo: {
        key: 'assign-regional-tbp-modal',
        visible: visible,
        modalTitle: regionalTalentBusinessPartnerInfo.modalTitle,
        searchInput: {
          label: regionalTalentBusinessPartnerInfo.nameLabel,
          placeholder: regionalTalentBusinessPartnerInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          errorMessage: { require: regionalTalentBusinessPartnerInfo.errorRequireName },
          notFoundText: nameNotFound,
          mode: 'multiple',
          limitNumber: limitNumber,
        },
        permissionList: regionalTalentBusinessPartnerAccess(regionName || '').permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
      },
    },
    [UserRoleInfo[USER_ROLE_ENUM.MU_SL_OPS].display]: {
      modalInfo: {
        key: 'assign-mu-sl-ops-modal',
        visible: visible,
        modalTitle: muSlOpsLeadershipInfo.modalTitle,
        loading: modalLoading,
        dropDownListInput: {
          label: muSlOpsLeadershipInfo.unitLabel,
          placeholder: muSlOpsLeadershipInfo.unitPlaceholder,
          listData: dropdownListData,
          isRequire: true,
          handleChangeDropdownList: handleChangeDropdownList,
        },
        searchInput: {
          label: muSlOpsLeadershipInfo.nameLabel,
          placeholder: muSlOpsLeadershipInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          errorMessage: { require: muSlOpsLeadershipInfo.errorRequireName },
          notFoundText: nameNotFound,
          mode: 'multiple',
          limitNumber: limitNumber,
        },
        warningTips: muSlOpsLeadershipInfo.assignPeopleModalTip,
        permissionList: muSlOpsLeadershipAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: {
            handleClick: handleSubmit,
            loading: submitLoading,
          },
        },
      },
    },
    [UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].display]: {
      modalInfo: {
        key: 'assign-tbp-modal',
        visible: visible,
        modalTitle: talentBusinessPartnerInfo.modalTitle,
        loading: modalLoading,
        dropDownListInput: {
          label: talentBusinessPartnerInfo.unitLabel,
          placeholder: talentBusinessPartnerInfo.unitPlaceholder,
          listData: dropdownListData,
          isRequire: true,
        },
        searchInput: {
          label: talentBusinessPartnerInfo.nameLabel,
          placeholder: talentBusinessPartnerInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          errorMessage: { require: talentBusinessPartnerInfo.errorRequireName },
          notFoundText: nameNotFound,
          mode: 'multiple',
          handleChangeDropdownList: handleChangeDropdownList,
          limitNumber: limitNumber,
        },
        warningTips: talentBusinessPartnerInfo.assignPeopleModalTip,
        permissionList: talentBusinessPartnerAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
      },
    },
  };
};

export default modalInfo;
