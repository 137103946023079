import { RoleList } from '@@/features/performance/v2/dashboardPage/common/RoleSelectionList';
import { getSecondSelectionData } from '@@/features/performance/v2/dashboardPage/common/GroupSelectionList';
import {
  getCycleDetailsByCycleIdV1,
  getDoneCycleAndAssessmentFromDashboardPageByCycleId,
} from '@@/api/v2/dashboard/cycles';
import { getOpenRequestCount, getRequestListBySourceCategory } from '@@/api/v2/dashboard/requests';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRoleOptionListAsync = createAsyncThunk(
  'dashboard/getRoleOptionList',
  async data => {
    return await RoleList(data);
  },
);

export const getGroupListAsync = createAsyncThunk(
  'dashboard/getGroupOptionList',
  async selectedRoleOption => {
    return await getSecondSelectionData(selectedRoleOption);
  },
);

export const getCycleDetailsV1Async = createAsyncThunk(
  'getDashboardPageCycleDetails',
  async cycleId => {
    return await getCycleDetailsByCycleIdV1(cycleId);
  },
);

export const getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync = createAsyncThunk(
  'getDoneCycleAndAssessmentFromDashboardPageByCycleId',
  async cycleId => {
    return await getDoneCycleAndAssessmentFromDashboardPageByCycleId(cycleId);
  },
);

export const getOpenRequestCountAsync = createAsyncThunk('getOpenRequestCount', async data => {
  return getOpenRequestCount(data);
});

export const getRequestListBySourceCategoryAsync = createAsyncThunk(
  'getRequestListBySourceCategory',
  async data => {
    return getRequestListBySourceCategory(data);
  },
);
