import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import history from 'common/history';
import { isEmpty } from 'lodash';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { getConfig } from './common/utils';
import MatomoPageTracker from './common/component/MatomoPageTracker';
import { ANTD_LOCALE_RESOURCES } from './locale/helper';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { LAST_USER_EMAIL } from '@@/_new_src_/constants/adminEnum';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const instance = createInstance({
  urlBase: 'https://thoughtworks.innocraft.cloud/',
  siteId: getConfig().REACT_APP_MATOMO_SITE_ID,
});

const renderRouteConfigV3 = (routes, contextPath) => {
  // Resolve route config object in React Router v3.
  const children = []; // children component list
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');

    if (item.path && item.redirect) {
      const redirectUrl = item.redirect();
      children.push(
        <Redirect
          key={newContextPath + redirectUrl}
          exact
          from={newContextPath}
          to={redirectUrl}
        />,
      );
    }
    if (item.component && !isEmpty(item.childRoutes)) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={newContextPath}
          render={props => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <Route
          key={newContextPath}
          render={props => <item.component {...props} />}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
};

const Root = props => {
  const locale = useSelector(state => state.global.locale);

  const oktaAuth = new OktaAuth({
    issuer: getConfig().REACT_APP_ISSUER,
    clientId: getConfig().REACT_APP_CLIENT_ID,
    redirectUri: window.location.origin + getConfig().REACT_APP_REDIRECT_URI,
    tokenManager: {
      expireEarlySeconds: 600,
    },
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const lastUserEmail = localStorage.getItem(LAST_USER_EMAIL);
    const { email } = await oktaAuth.getUser();
    if (lastUserEmail && lastUserEmail !== email) {
      localStorage.removeItem(LAST_USER_EMAIL);
      history.replace(toRelativeUrl(MENU_PAGE_LINK.LANDING_PAGE, window.location.origin));
    } else {
      localStorage.removeItem(LAST_USER_EMAIL);
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    }
  };

  return (
    <MatomoProvider value={instance}>
      <ConfigProvider locale={ANTD_LOCALE_RESOURCES[locale]}>
        <Router history={history}>
          <MatomoPageTracker />
          <Security
            className="performance-security"
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
          >
            {renderRouteConfigV3(props.routeConfig, '/')}
          </Security>
        </Router>
      </ConfigProvider>
    </MatomoProvider>
  );
};

Root.propTypes = {
  routeConfig: PropTypes.array.isRequired,
};

export default Root;
