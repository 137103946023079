import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Checkbox, Col, Row } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { isNil } from 'lodash';
import locale from '@@/_new_src_/local/feedback/en_US';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import { V2FeedbackStore } from '@@/_new_src_/store/V2FeedbackSlice';
import ExpectationItem from '@@/_new_src_/features/ExpectationItem';
import './index.less';

const ConnectExpectations = ({
  expectationIds,
  setExpectationIds,
}: {
  expectationIds: number[];
  setExpectationIds: (ids: number[]) => void;
}) => {
  const params = useParams();
  const { id: feedbackId } = params as { id: number };

  const {
    feedback: { expectationForm: expectationFormLocale },
  } = locale;

  const {
    feedbackDetail: { feedback },
  } = useSelector(V2FeedbackStore);

  const {
    data: { expectations },
  } = useSelector(cycleDetailData);

  const expectationList = () => {
    if (feedbackId) {
      return feedback.expectations;
    }
    return expectations;
  };

  const scrollIntoView = (id: string) => {
    setTimeout(() => {
      const elementAfter = document.getElementById(id);
      const clickedElementRectAfter = elementAfter?.getBoundingClientRect();
      const container = document.getElementById('select-area-1');
      const containerRect = container?.getBoundingClientRect();
      if (
        !isNil(clickedElementRectAfter) &&
        !isNil(containerRect) &&
        clickedElementRectAfter?.bottom > containerRect?.bottom
      ) {
        const scrollToY = clickedElementRectAfter?.height;
        container?.scrollBy({
          top: (scrollToY as number) / 2,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setExpectationIds(checkedValues.slice().sort() as number[]);
  };

  return (
    <>
      <div className="connect-expectations-icon">
        <i className="arrow-icon" />
      </div>
      <div className="connect-expectations-area" id="expectation-header">
        <div className="info-area">
          <div className="title">{expectationFormLocale.title}</div>
          <div className="content">
            <span className="description">{expectationFormLocale.describe}</span>
            <span className="count">
              {expectationFormLocale.countText(expectationIds.length, expectationList()?.length)}
            </span>
          </div>
        </div>
        <div className="select-area" id="select-area-1">
          <Checkbox.Group onChange={handleCheckboxChange} defaultValue={expectationIds}>
            {expectationList()?.map((item, index) => (
              <Row key={index}>
                <Col className="check-col">
                  <Checkbox value={item.id}></Checkbox>
                </Col>
                <Col id={item.id.toString()} onClick={() => scrollIntoView(item.id.toString())}>
                  <ExpectationItem
                    title={item.title}
                    content={item.content}
                    categoryIds={item.categoryIds}
                  />
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        </div>
      </div>
    </>
  );
};

export default ConnectExpectations;
