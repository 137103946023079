import React from 'react';
import './index.less';
import ErrorImage from '@@/assets/images/error.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FallbackComponent = ({ error }: { error: Error }) => {
  const raiseBugReport = () => {
    window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&to=summit-support@thoughtworks.com',
      '_blank',
    );
  };

  const openFAQ = () => {
    window.open(
      'https://docs.google.com/document/d/1NC9YrDsSFBTcIi6qaBOdJz6Lngz4bCB3hoLFAdgP81E/edit#heading=h.nxkwvhz0feqb',
      '_blank',
    );
  };

  const backToHome = () => {
    window.open('/performance/index', '_self');
  };
  return (
    <div className="fallback-component">
      <div className="hint-area">
        <div className="bug-message">
          <h1>Ooops!</h1>
          <span>Sorry we couldn’t serve your case due to some errors.</span>
        </div>
        <div className="links">
          <span className="title">Here are some helpful links instead:</span>
          <a onClick={backToHome}>HomePage</a>
          <a onClick={openFAQ}>FAQ</a>
          <a onClick={raiseBugReport}>Talk to us</a>
        </div>
      </div>
      <img src={ErrorImage} className="error-img" alt="" />
    </div>
  );
};

export default FallbackComponent;
