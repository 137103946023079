import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { setSignedUrl, signedUrl } from '@@/_new_src_/store/commonSlice';
import { getImageInfo, getImageRealSize } from '@@/_new_src_/utils';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { IPreviewModalProps } from '@@/_new_src_/types/features';
import { ATTACHMENT_REGEX } from '@@/_new_src_/constants/myCycles';
import { getSignedUrlAsync } from '@@/_new_src_/store/commonSlice/asyncThunk';

const PreviewModal = (props: IPreviewModalProps) => {
  const { url, title, isShow, updateIsShow } = props;
  const dispatch = useDispatch();
  const currentVisible = isShow;
  const { reject, url: currentSignedUrl } = useSelector(signedUrl);
  const [loading, setLoading] = useState(true);
  const [currentImgWidth, setCurrentImgWidth] = useState('');
  const [currentModalWidth, setCurrentModalWidth] = useState(0);

  const isImage = ATTACHMENT_REGEX.test(url);

  const closePreviewModal = () => {
    updateIsShow(false);
    dispatch(setSignedUrl({}));
  };

  useEffect(() => {
    dispatch(setSignedUrl(''));
    currentVisible && url && dispatch(getSignedUrlAsync(url));
  }, [currentVisible]);

  useEffect(() => {
    if (!isImage && currentSignedUrl && currentVisible) {
      window.open(currentSignedUrl);
      closePreviewModal();
    }
  }, [currentSignedUrl, currentVisible]);

  useEffect(() => {
    if (reject) {
      setLoading(false);
      closePreviewModal();
    }
  }, [reject]);

  const handleCancel = () => {
    closePreviewModal();
  };

  const getCurrentWidth = () => {
    getImageInfo(currentSignedUrl, (imgInfo: { width: number; height: number }) => {
      const windowClientWidth = document.documentElement.clientWidth;
      const windowClientHeight = document.documentElement.clientHeight;
      const realSize = getImageRealSize(
        windowClientWidth - 100,
        windowClientHeight - 100,
        imgInfo.width,
        imgInfo.height,
      ) as { modal: { width: number; height: number }; img: { width: string } };
      setCurrentModalWidth(realSize.modal.width);
      setCurrentImgWidth(realSize.img.width);
    });
  };

  const onLoadSuccess = () => {
    setLoading(false);
    getCurrentWidth();
  };
  const onLoadError = () => {
    setLoading(false);
  };

  return (
    <>
      {currentVisible && (
        <LoadingWrapper loading={loading}>
          <Modal
            className="preview-modal"
            open={Boolean(isImage && currentSignedUrl)}
            centered={true}
            maskClosable={false}
            closeIcon={<i className="ri-close-line" />}
            onCancel={handleCancel}
            width={currentModalWidth}
            footer={null}
          >
            {currentSignedUrl && (
              <img
                className="preview-img"
                src={currentSignedUrl}
                alt={title || ''}
                style={{
                  width: currentImgWidth,
                }}
                onLoad={onLoadSuccess}
                onError={onLoadError}
              />
            )}
          </Modal>
        </LoadingWrapper>
      )}
    </>
  );
};

export default PreviewModal;
