import React from 'react';
import cls from 'classnames';

import './index.less';

interface IProps {
  bannerClassname?: string;
  title: string;
  goToBackFun: () => void;
  onKeyPress: () => void;
  children?: React.ReactElement;
}
const GoBackBanner = (props: IProps) => {
  const { bannerClassname, title, goToBackFun, onKeyPress, children } = props;
  const goToBackPage = () => {
    goToBackFun();
  };
  return (
    <div className={cls('go-back-banner', bannerClassname)}>
      <div className="banner-wrapper">
        <div className="back" onClick={goToBackPage} tabIndex={0} onKeyPress={onKeyPress}>
          <div className="back-icon">
            <i className="ri-arrow-left-s-fill arrow-left" />
          </div>
          <span className={'back-title'}>{title}</span>
        </div>
        {children}
      </div>
    </div>
  );
};
export default GoBackBanner;
