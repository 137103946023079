import { getApiV2Path } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import {
  ALL_ACCOUNT,
  ALL_ACCOUNT_GROUPS_ID,
  ALL_ACCOUNT_UNIT,
  ALL_GROUPS_UNIT,
  ALL_PEOPLE,
  ALL_PEOPLE_UNIT,
} from '@@/common/constant/dashboard';
import { SourceCategory } from '@@/common/constant/dashboardSourceCategory';
import { omit } from 'lodash';
import { getTimeStamp, getTimezoneOffset } from '@@/_new_src_/utils/common/date';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

export const getCycleDetailsByCycleIdV1 = id => {
  return ajax.get(getApiV2Path(`/performance_reviews/${id}`));
};

export const getDoneCycleAndAssessmentFromDashboardPageByCycleId = id =>
  ajax.get(getApiV2Path(`/performance_reviews/done_review_and_assessment_update_records/${id}`));

export const getCyclesListBySourceCategory = ({
  sourceCategory,
  isAllPeople,
  groupId,
  roleId,
  filterInfoAndTableInfo,
  groupType,
  roleName,
}) => {
  let unitId = roleId;
  let accountId = groupId;
  let resourceType = isAllPeople ? 'ALL_PEOPLE' : 'ALL_GROUP';

  let currentParams = {
    ...omit(filterInfoAndTableInfo, ['reviewee', 'reviewer']),
    startTime: filterInfoAndTableInfo.startTime && getTimeStamp(filterInfoAndTableInfo.startTime),
    endTime: filterInfoAndTableInfo.endTime && getTimeStamp(filterInfoAndTableInfo.endTime),
  };

  switch (sourceCategory) {
    case SourceCategory.groupLevel:
      return ajax.get(getApiV2Path(`/performance_reviews/groups/${groupId}`), {
        params: currentParams,
      });
    case SourceCategory.unitLevel:
      if (groupType === ALL_GROUPS_UNIT || groupType === ALL_PEOPLE_UNIT) {
        unitId = groupId;
      }
      if (roleName === USER_ROLE_ENUM.TALENT_BP && groupType === ALL_ACCOUNT_UNIT) {
        resourceType = 'ALL_ACCOUNT';
      }
      currentParams = {
        ...currentParams,
        resourceType,
      };
      return ajax.get(getApiV2Path(`/performance_reviews/units/${unitId}`), {
        params: currentParams,
      });
    case SourceCategory.supervisorLevel:
      if (roleName === USER_ROLE_ENUM.GLOBAL_TALENT_BP) {
        unitId = groupId;
      }
      return ajax.get(getApiV2Path(`/performance_reviews/superOrg/${unitId}`), {
        params: {
          ...currentParams,
          resourceType,
        },
      });
    case SourceCategory.accountLevel:
      if (roleName === USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM && groupId === ALL_ACCOUNT_GROUPS_ID) {
        return ajax.get(getApiV2Path(`/performance_reviews/allAccounts`), {
          params: currentParams,
        });
      }

      currentParams = {
        ...currentParams,
        roleName,
      };
      if (roleName === USER_ROLE_ENUM.TALENT_BP || roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP) {
        currentParams = {
          ...currentParams,
          hierarchyId: unitId,
        };
      }
      return ajax.get(getApiV2Path(`/performance_reviews/accounts/${accountId}`), {
        params: currentParams,
      });
    case SourceCategory.globalLevel:
      if (roleName === USER_ROLE_ENUM.GLOBAL_TALENT_BP) {
        return ajax.get(getApiV2Path(`/performance_reviews/global`), {
          params: { ...currentParams, resourceType },
        });
      }
      break;
    default:
      if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP && groupId === ALL_ACCOUNT) {
        resourceType = 'ALL_ACCOUNT';
      }
      return ajax.get(getApiV2Path(`/performance_reviews/regions/${roleId}`), {
        params: {
          ...currentParams,
          resourceType,
        },
      });
  }
};

export const getAccountFilter = keyword => ajax.get(getApiV2Path(`/accounts/${keyword}`));

export const getProjectFilter = keyword => ajax.get(getApiV2Path(`/projects/${keyword}`));

export const exportCycleListBySourceCategory = ({
  sourceCategory,
  groupId,
  hierarchyId,
  sortAndPageInfo,
  searchFilter,
  groupType,
  roleName,
}) => {
  const isAllPeople = groupId === ALL_PEOPLE || groupType === ALL_PEOPLE_UNIT;

  let resourceType = isAllPeople ? 'ALL_PEOPLE' : 'ALL_GROUP';

  const params = {
    ...sortAndPageInfo,
    ...omit(searchFilter, 'isAllPeople'),
    timezoneOffset: getTimezoneOffset(),
    startTime: searchFilter.startTime && getTimeStamp(searchFilter.startTime),
    endTime: searchFilter.endTime && getTimeStamp(searchFilter.endTime),
  };
  if (sourceCategory === SourceCategory.globalLevel) {
    return ajax.get(getApiV2Path(`/performance_reviews/global/export`), {
      params: {
        ...params,
        resourceType,
      },
      responseType: 'blob',
    });
  }
  if (sourceCategory === SourceCategory.supervisorLevel) {
    return ajax.get(getApiV2Path(`/performance_reviews/superOrg/${hierarchyId}/export`), {
      params: {
        ...params,
        resourceType,
      },
      responseType: 'blob',
    });
  }
  if (sourceCategory === SourceCategory.groupLevel) {
    return ajax.get(getApiV2Path(`/performance_reviews/group/${groupId}/export`), {
      params: {
        ...params,
      },
      responseType: 'blob',
    });
  }
  if (sourceCategory === SourceCategory.unitLevel) {
    if (roleName === USER_ROLE_ENUM.TALENT_BP && groupId === ALL_ACCOUNT) {
      resourceType = 'ALL_ACCOUNT';
    }
    return ajax.get(getApiV2Path(`/performance_reviews/unit/${hierarchyId}/export`), {
      params: {
        ...params,
        resourceType,
      },
      responseType: 'blob',
    });
  }
  if (sourceCategory === SourceCategory.accountLevel) {
    if (roleName === USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM && groupId === ALL_ACCOUNT_GROUPS_ID) {
      return ajax.get(getApiV2Path(`/performance_reviews/allAccounts/export`), {
        params: {
          ...omit(params, ['groupId', 'roleName', 'roleId', 'roleType']),
        },
        responseType: 'blob',
      });
    } else {
      return ajax.get(getApiV2Path(`/performance_reviews/account/${groupId}/export`), {
        params: {
          ...params,
          hierarchyId,
        },
        responseType: 'blob',
      });
    }
  }
  // regionLevel:
  if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP && groupId === ALL_ACCOUNT) {
    resourceType = 'ALL_ACCOUNT';
  }
  return ajax.get(getApiV2Path(`/performance_reviews/region/${hierarchyId}/export`), {
    params: {
      ...params,
      resourceType,
    },
    responseType: 'blob',
  });
};
