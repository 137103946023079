import React from 'react';
import './index.less';
import Background from '@@/assets/images/CompletedCycleBackground.svg';
import Locale from '@@/_new_src_/local/myCycles/en_US';
import { CYCLE_AUTO_OPEN_DATE } from '@@/_new_src_/constants/common';
import { getConfig } from '@@/common/utils';

export const CompletedCycleBanner = () => {
  const {
    completedCycleBanner: { title, content },
  } = Locale;
  const currentMonth = new Date().getMonth();
  const duration = { startMonth: 5, endMonth: 8 };
  const date =
    currentMonth > duration.startMonth && currentMonth < duration.endMonth
      ? CYCLE_AUTO_OPEN_DATE.H2
      : CYCLE_AUTO_OPEN_DATE.H1;
  const baseUrl = getConfig().REACT_APP_PATHWAYS_URL || '';
  const linkToPathways = baseUrl + `/dashboard?target=me-now`;

  return (
    <div className="completed-cycle-banner">
      <div className="text-content">
        <div className="banner-title">{title}</div>
        <div
          className="banner-content"
          dangerouslySetInnerHTML={{ __html: content(date, linkToPathways) }}
        />
      </div>
      <div className="banner-image">
        <img src={Background} alt="" />
      </div>
    </div>
  );
};
