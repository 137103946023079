import { get } from 'lodash';
import React, { useEffect } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { EMPTY_CONTENT_PLACEHOLDER } from '../../constant/review';
import Attachment from '../Attachment';
import FieldBlockInfo from '../FieldBlockInfo';
import ReviewCheckInNotes from '../ReviewCheckInNotes';
import { getSignedUrl } from '@@/api/review';
import NewCycleDetail from '@@/_new_src_/page/myCycles/component/CycleDetail';
import './preview.less';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { trigAutoSurvey } from '@@/common/qualarooTrigger';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';

const RevieweeReviewPreview = ({
  pageType = '',
  operation,
  locale,
  isExpand,
  onCheckInNotesModalSuccess,
  showCreateSelfReview,
  onClickCreateSelfReview,
  isCurrentReviewDetail,
}) => {
  const {
    currentCycleDetail: {
      cycleVersion,
      isOpenCycle,
      isUnreadCycle,
      expectations = {},
      id: cycleId,
      project,
      duration,
      openBy,
      twer,
      performancePartner,
      additionalPartners = [],
      isNoneEngaged = false,
    },
  } = useSelector(cycleDetailByVersion);

  const CycleDetailRender = () => {
    const { text, attachmentUrl, attachmentName } = expectations;

    const { data: signedUrl, refresh: runAttachmentUrl } = useRequest(
      () => getSignedUrl({ attachmentUrl, cycleId, pageType }),
      {
        ready: !!attachmentUrl,
        formatResult: res => res.data,
        refreshDeps: [attachmentUrl],
      },
    );
    return (
      <div className="review-detail-preview-item-content">
        <div className="review-detail-preview-item-content-row">
          <FieldBlockInfo
            className="preview-info project"
            label={locale.project}
            content={project?.name}
          />
          <FieldBlockInfo
            className="preview-info duration "
            label={locale.duration}
            content={duration && formatRangeDuration(duration?.startTime, duration?.endTime)}
          />
          <FieldBlockInfo
            className=" preview-info role"
            label={locale.role}
            content={get(twer, 'role')}
          />
        </div>
        <div hidden={!isOpenCycle && !isExpand} className="review-detail-preview-item-expand">
          <div className="review-detail-preview-item-content-row review-detail-preview-item-reviewers">
            <FieldBlockInfo
              className="preview-info reviewer"
              label={locale.reviewer}
              content={get(performancePartner, 'name')}
            />
            <FieldBlockInfo
              className="preview-info contributor"
              label={locale.contributors}
              content={
                additionalPartners.map(({ name }) => name).join(', ') || EMPTY_CONTENT_PLACEHOLDER
              }
            />
            {isNoneEngaged && (
              <FieldBlockInfo
                className="preview-info openBy"
                label={locale.openedBy}
                content={openBy}
              />
            )}
          </div>
          <FieldBlockInfo
            className="preview-info expectation"
            collapsible={isUnreadCycle}
            label={locale.expectations}
            content={text}
            emptyPlaceholder=""
          />

          {attachmentUrl && attachmentName && (
            <Attachment
              remoteLink={
                signedUrl && signedUrl.attachmentUrl ? signedUrl.attachmentUrl : signedUrl
              }
              name={attachmentName}
              onClick={runAttachmentUrl}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // const items = [230861, 230862, 230786];
    const items = ['twer_open_cycle', 'twer_open_cycle1', 'twer_open_cycle2'];
    cycleId && cycleVersion === CYCLE_VERSION.NEW && isOpenCycle && trigAutoSurvey(items);
  }, [cycleId, cycleVersion, isOpenCycle]);

  return (
    <div className="review-detail-preview-item-container">
      {cycleId && cycleVersion && (
        <>
          {cycleVersion === CYCLE_VERSION.NEW ? <NewCycleDetail /> : CycleDetailRender()}
          {isExpand && (
            <div className="review-detail-preview-item-check-in-notes">
              {showCreateSelfReview && (
                <div className="create-self-review" onClick={onClickCreateSelfReview} tabIndex={0}>
                  <PlusCircleOutlined className="create-icon" />
                  <span>{locale.selfReviewPreview.createButtonText}</span>
                </div>
              )}
              <ReviewCheckInNotes
                operation={operation}
                onCheckInNotesModalSuccess={onCheckInNotesModalSuccess}
                isCurrentReviewDetail={isCurrentReviewDetail}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RevieweeReviewPreview;
