import React from 'react';
import locale from '@@/_new_src_/local/landing/en_US';

export const ExpectDescription = () => {
  const { expect: expectLocale } = locale;
  return (
    <div className="landing-page-expect">
      <h2 className="landing-page-expect-title">{expectLocale.title}</h2>
      <ul className="landing-page-expect-list">
        <li className="landing-page-expect-list-item landing-page-expect-list-item_left">
          <div className="landing-page-expect-list-item-line" />
          <div className="landing-page-expect-list-item-title">{expectLocale.step.title}</div>
          <div className="landing-page-expect-list-item-desc">
            {expectLocale.step.detail.map(
              ({ label, content }: { label: string; content: string }) => (
                <p key={label}>
                  <strong>{label}</strong>
                  <span>{content}</span>
                </p>
              ),
            )}
          </div>
        </li>
        <li className="landing-page-expect-list-item landing-page-expect-list-item_right">
          <div className="landing-page-expect-list-item-line" />
          <div className="landing-page-expect-list-item-title">{expectLocale.level.title}</div>
          <div className="landing-page-expect-list-item-desc">
            {Object.keys(expectLocale.level.detail).map(rating => (
              <p key={rating}>
                <strong>{rating}:</strong>
                <span>{expectLocale.level.detail[rating]}</span>
              </p>
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
};
