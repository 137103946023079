import React, { useState, useCallback, useEffect, ReactNode, useMemo } from 'react';
import cls from 'classnames';
import commonLocale from '@@/_new_src_/local/common/en_US';
import './index.less';
import { onEnter } from '@@/_new_src_/utils';

interface IProps {
  content: ReactNode;
  maxHeight?: string;
  className?: string;
  isAlwaysShowMore?: boolean;
  callback?: () => void;
}

const ElasticCollapse = (props: IProps) => {
  const {
    common: { readMore: readMoreLocale, readLess: readLessLocale },
  } = commonLocale;
  const { content, maxHeight = '100', className = '', callback, isAlwaysShowMore } = props;
  const [collapse, setCollapse] = useState(true);
  const [showOperation, setShowOperation] = useState(false);
  const contentRef = React.createRef<HTMLDivElement>();
  const MAX_HEIGHT = maxHeight;
  const [toggleByAlwaysShowMore, setToggleByAlwaysShowMore] = useState(false);

  const toggleCollapse = useCallback(() => {
    setCollapse(!collapse);
    if (callback) {
      callback();
    }
  }, [collapse]);

  useEffect(() => {
    if (content) {
      const element = contentRef.current as HTMLInputElement;
      const height = element.clientHeight;

      if (showOperation) {
        return;
      }

      if (height > +MAX_HEIGHT) {
        setShowOperation(true);
      } else if (isAlwaysShowMore && height > 0) {
        setShowOperation(true);
        setToggleByAlwaysShowMore(true);
      } else {
        setShowOperation(false);
        setCollapse(true);
      }
    } else {
      setShowOperation(false);
    }
  }, [content]);

  const realHeight = useMemo(
    () =>
      toggleByAlwaysShowMore ? 'auto' : showOperation && collapse ? MAX_HEIGHT + 'px' : 'auto',

    [content, toggleByAlwaysShowMore, collapse, showOperation],
  );

  return (
    <div className={cls('elastic-collapse-container', className)}>
      {content && (
        <>
          <div
            ref={contentRef}
            className={`global-rich-text content-wrapper`}
            style={{
              height: realHeight,
            }}
          >
            {content}
          </div>
          {collapse && showOperation && <div className="ellipsis">...</div>}
        </>
      )}
      {showOperation && (
        <div
          tabIndex={0}
          onKeyDown={e => onEnter(e, toggleCollapse)}
          className="content-toggle"
          onClick={toggleCollapse}
        >
          {collapse ? readMoreLocale : readLessLocale}
        </div>
      )}
    </div>
  );
};

export default ElasticCollapse;
