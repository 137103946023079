import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { calibrationHistories } from '@@/_new_src_/store/cycleDetailSlice';
import { getCalibrationHistoriesByCycleIdAsync } from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';
import Locale from '@@/_new_src_/local/calibrateIndicator/en_US';
import { ASSESSMENT_TYPE } from '@@/_new_src_/constants/myCycles';
import './index.less';

interface ICalibrationHistoryListProps {
  cycleId: number;
}

const CalibrationHistoryList = (props: ICalibrationHistoryListProps) => {
  const { cycleId } = props;
  const dispatch = useDispatch();
  const {
    calibrationHistoryList: { date, updatedIndicator, reason, originalIndicator },
  } = Locale;

  const { data, loading } = useSelector(calibrationHistories);

  useEffect(() => {
    dispatch(getCalibrationHistoriesByCycleIdAsync(cycleId));
  }, []);

  return (
    <LoadingWrapper loading={loading}>
      <div className="calibration-history-list">
        {data.length > 0 &&
          data.map(item => {
            return (
              <div className="calibration-info">
                <div className="item">
                  <b>{date}</b>
                  <span>
                    {dayjs.utc(item.date).local().format(dateWithYearMonthTime).toString()}
                  </span>
                </div>
                <div className="item">
                  <b>
                    {item.type === ASSESSMENT_TYPE.ASSESSMENT
                      ? originalIndicator
                      : updatedIndicator}
                  </b>
                  <span>{item.rating}</span>
                </div>
                {item.type !== ASSESSMENT_TYPE.ASSESSMENT && (
                  <div className="item">
                    <b>{reason}</b>
                    <span>{item.reason}</span>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </LoadingWrapper>
  );
};
export default CalibrationHistoryList;
