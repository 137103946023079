import React, { memo, useCallback, useEffect, useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import { filter, isEmpty } from 'lodash';

import { getUserList } from '@@/api/user';
import { getV2Regions } from '@@/api/v2User';
import AutoComplete from '@@/common/component/AutoComplete';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import { ERROR_CODE } from '@@/common/constant/http';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { SelectOptionValueType } from '@@/types/antdComponent';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import WarningText from '@@/_new_src_/components/WarningText';
import { ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { IAdminColumn } from '@@/types/admin';
import { IErrorType } from '@@/_new_src_/types/common';
import { postAddRegionalAdmin } from '@@/_new_src_/api/admin';

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};

interface VoidFuncForModal {
  (): void;
}

interface RegionOption {
  value: number;
  label: string;
  key: number;
}

interface NameOption {
  value: string;
  label: string;
  key: string;
}

interface RegionData {
  id: number;
  name: string;
}

interface AddRegionalAdminModal {
  visible: boolean;
  onSuccess: VoidFuncForModal;
  onCancel: VoidFuncForModal;
  adminList: IAdminColumn[] | undefined;
}

interface SearchedPeopleInfo {
  userId: number;
  email: string;
  name: string;
  role: string;
  grade: string;
}

interface formValuesType {
  region: SelectOptionValueType;
  name: SelectOptionValueType;
}

const AddRegionalAdminModal = (props: AddRegionalAdminModal) => {
  const { trackEvent } = useMatomo();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { onSuccess, onCancel, adminList } = props;
  const [form] = Form.useForm();
  const { globalAdminRoleInfo } = useUserRoleInfo();
  const [regionOptions, setRegionOptions] = useState<RegionOption[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageAdminRole: { addRegionalAdminModal: locale },
    },
  } = localeResource as {
    admin: {
      manageAdminRole: {
        addRegionalAdminModal: {
          title: string;
          nameLabel: string;
          regionLabel: string;
          namePlaceholder: string;
          nameNotFound: string;
          regionPlaceholder: string;
          cancel: string;
          add: string;
          successMessage: string;
          oneRegionCanOnlyHaveOneRegionalAdmin: string;
          errorMessage: string;
        };
      };
    };
  };

  useEffect(() => {
    const getRegionList: () => void = async () => {
      const { data } = (await getV2Regions()) as { data: RegionData[] };
      const options = data.map((region: RegionData) => {
        return {
          label: region.name,
          value: region.id,
          key: region.id,
        };
      }) as RegionOption[];
      setRegionOptions(options);
    };

    if (globalAdminRoleInfo?.role === USER_ROLE_ENUM.GLOBAL_ADMIN) {
      getRegionList();
    }
  }, [globalAdminRoleInfo]);

  const onReset = useCallback(() => {
    setIsInputDisabled(false);
    form.resetFields();
    setSubmitDisabled(true);
  }, [form]);

  const onClickAddButton = useCallback(async () => {
    setLoading(true);
    const values = (await form.validateFields()) as formValuesType;
    const addUserEmail = values.name.value;
    const addRegionId = values.region.value;
    try {
      trackEvent({
        category: ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.category,
        action: ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.action.SYSTEM_ADMIN_ADDS_REGIONAL_ADMIN,
      });
      await postAddRegionalAdmin(addUserEmail, Number(addRegionId));
      perfMessage.success(locale.successMessage);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if ((error as IErrorType).errorCode === ERROR_CODE.ADDING_ADMIN_NAME_ERROR) {
        onCancel();
        perfMessage.error((error as IErrorType).message);
      }
    } finally {
      setLoading(false);
    }
  }, [form, locale.successMessage, onSuccess, onCancel]);

  const renderNameOptions = useCallback(
    (list: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      return list.map(({ userId, email, name }) => {
        return (
          <Option key={String(email)} label={name} value={email}>
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [],
  );

  const onFormFieldsChange = useCallback((): void => {
    const fields = Object.keys(form.getFieldsValue());
    const isDisabled =
      isEmpty(fields) ||
      !form.isFieldsTouched(fields, true) ||
      !isEmpty(form.getFieldsError(fields).filter(({ errors }) => errors.length));
    setSubmitDisabled(isDisabled);

    const region = form.getFieldValue('region') as RegionOption | undefined;
    const name = form.getFieldValue('name') as NameOption | undefined;
    if (name === undefined && region !== undefined) {
      const selectedRegionalAdmins = filter(adminList, {
        region: region.label,
      });
      if (selectedRegionalAdmins?.length > 0) {
        form.setFieldsValue({
          name: {
            value: selectedRegionalAdmins[0].name,
            label: selectedRegionalAdmins[0].name,
            key: String(selectedRegionalAdmins[0].id),
          },
        });
        setSubmitDisabled(true);
        setIsInputDisabled(true);
      } else {
        setSubmitDisabled(true);
        setIsInputDisabled(false);
      }
    }
    if (name !== undefined && region !== undefined) {
      const selectedRegionalAdmins = filter(adminList, {
        region: region.label,
      });
      if (selectedRegionalAdmins?.length > 0) {
        form.setFieldsValue({
          name: {
            value: selectedRegionalAdmins[0].name,
            label: selectedRegionalAdmins[0].name,
            key: String(selectedRegionalAdmins[0].id),
          },
        });
        setIsInputDisabled(true);
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
        if (isInputDisabled) {
          form.setFieldsValue({ name: undefined });
        }
      }
    }
  }, [adminList, form, isInputDisabled]);

  return (
    <PerfModal
      title={locale.title}
      okText={locale.add}
      cancelText={locale.cancel}
      destroyOnClose
      centered
      className="regional-admin-modal"
      onOk={onClickAddButton}
      afterClose={onReset}
      maskClosable={false}
      okButtonProps={{
        disabled: submitDisabled,
        loading,
      }}
      {...props}
    >
      <Form
        {...layout}
        form={form}
        colon={false}
        onFieldsChange={onFormFieldsChange}
        className="regional-admin-modal-form"
      >
        <Form.Item
          name="region"
          label={
            <>
              {locale.regionLabel}
              <span className="required-mark">*</span>
            </>
          }
          rules={[
            {
              required: true,
              message: locale.errorMessage,
            },
          ]}
        >
          <Select
            size="large"
            labelInValue
            getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
            options={regionOptions}
            placeholder={locale.regionPlaceholder}
            suffixIcon={<CaretDownOutlined style={{ color: '#808285', fontSize: '16px' }} />}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={
            <>
              {locale.nameLabel}
              <span className="required-mark">*</span>
            </>
          }
          rules={[
            {
              required: true,
              message: locale.errorMessage,
            },
          ]}
        >
          <AutoComplete
            placeholder={locale.namePlaceholder}
            notFoundContent={locale.nameNotFound}
            showArrow={false}
            defaultActiveFirstOption={false}
            showSearch
            fetchApi={getUserList}
            disabled={isInputDisabled}
          >
            {renderNameOptions}
          </AutoComplete>
        </Form.Item>
      </Form>
      <WarningText
        warningText={locale.oneRegionCanOnlyHaveOneRegionalAdmin}
        className="tip-permission-section"
      />
    </PerfModal>
  );
};

export default memo(AddRegionalAdminModal);
