import { Input } from 'antd';
import cls from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import './index.less';

function PerfTextArea(props) {
  const { className, height, style, inputCount, trim, ...restProps } = props;
  const { maxLength } = restProps;
  const [value, setValue] = useState('');
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const textareaRef = React.createRef();

  const onScroll = () => {
    const element = textareaRef.current.resizableTextArea.textArea;
    setIsScrollBottom(element.scrollHeight - element.scrollTop <= element.clientHeight + 36);
  };

  const onChange = useCallback(
    e => {
      const originInputValue = e.target.value;
      const inputValue = trim ? originInputValue.trimLeft() : originInputValue;
      setValue(inputValue);
      const element = textareaRef.current.resizableTextArea.textArea;
      if (isScrollBottom) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }
      if (props.onChange) {
        props.onChange(inputValue);
      }
    },
    [props, trim, isScrollBottom, textareaRef],
  );

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  return (
    <div className="perf-textarea-wrapper">
      <Input.TextArea
        className={cls('perf-textarea', className)}
        {...restProps}
        style={{ height, ...style }}
        onChange={onChange}
        value={value}
        ref={textareaRef}
        onScroll={onScroll}
      />
      {inputCount && (
        <div className="perf-textarea-input-count-wrapper">
          <span
            className={cls('perf-textarea-input-count', {
              'perf-textarea-input-count-max-length': value.length === maxLength,
            })}
          >
            {value.length}/{maxLength}
          </span>
        </div>
      )}
    </div>
  );
}

PerfTextArea.defaultProps = {
  inputCount: false,
  trim: false,
  height: 179,
};

export default memo(PerfTextArea);
