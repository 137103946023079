import { CATEGORY_LABEL } from '@@/_new_src_/constants/myCycles';
import useLocalStorageExpectation from '@@/_new_src_/hooks/useLocalStorageExpectation';
import {
  currentUpdatedExpectation,
  setCurrentUpdatedExpectation,
  setLastExpectationCategoryIdsAfterEditing,
} from '@@/_new_src_/store/myCyclesSlice';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerformanceCategoryItem from './PerformanceCategoryItem';
import { IExpectationItem } from '@@/_new_src_/store/cycleDetailSlice/interface';

export const PerformanceCategoryList = () => {
  const dispatch = useDispatch();
  const curExp = useSelector(currentUpdatedExpectation);
  const isEdit = !!curExp?.id;
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const { expectationCategoryIdsState, setExpectationCategoryIdsState } =
    useLocalStorageExpectation();

  useEffect(() => {
    if (!isEdit && expectationCategoryIdsState) {
      setSelectedList(expectationCategoryIdsState as number[]);
    } else if (curExp) {
      setSelectedList(curExp.categoryIds);
      dispatch(setLastExpectationCategoryIdsAfterEditing(curExp?.categoryIds));
    }
  }, []);

  const handleChangeSelectedCategoryList = (categoryId: number, isSelect: boolean) => {
    if (isSelect) {
      setSelectedList(selectedList.concat(categoryId));
      // eslint-disable-next-line no-unused-expressions
      !isEdit && setExpectationCategoryIdsState(selectedList.concat(categoryId));
    } else {
      const indexOfCategory = selectedList.indexOf(categoryId);
      const newSelectedList = [...selectedList];
      newSelectedList.splice(indexOfCategory, 1);
      setSelectedList(newSelectedList);
      !isEdit && setExpectationCategoryIdsState(newSelectedList);
    }
  };

  const isSelected = (categoryId: number) => {
    if (!isEdit && expectationCategoryIdsState) {
      return (expectationCategoryIdsState as number[]).includes(categoryId);
    }
    if (curExp) {
      return curExp.categoryIds.includes(categoryId);
    }
    return false;
  };

  useEffect(() => {
    dispatch(
      setCurrentUpdatedExpectation({ ...curExp, categoryIds: selectedList } as IExpectationItem),
    );
  }, [selectedList]);

  return (
    <>
      {Object.keys(CATEGORY_LABEL)
        .sort()
        .map((categoryItem: string, index: number) => (
          <PerformanceCategoryItem
            categoryId={parseInt(categoryItem)}
            key={`category-item-${index}`}
            handleSelectCategory={handleChangeSelectedCategoryList}
            isSelected={isSelected(parseInt(categoryItem))}
          />
        ))}
    </>
  );
};

export default memo(PerformanceCategoryList);
