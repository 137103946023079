import React, { useMemo } from 'react';
import CycleInformation from '../CycleInformation';
import CycleExpectations from '../CycleExpectations';
import './index.less';
import { useSelector } from 'react-redux';
import { sendRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';
import CycleInformationForm from '../CycleInformationForm';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import CycleInformationFormV2 from '@@/_new_src_/page/myCycles/component/CycleInformationFormV2';

const CycleDetail = () => {
  const { status } = useSelector(sendRequestData);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(status);

  const {
    currentCycleDetail: { isTwer, canEdit, createBySystem },
  } = useSelector(cycleDetailByVersion) as {
    currentCycleDetail: {
      isTwer: boolean;
      canEdit: boolean;
      createBySystem: boolean;
    };
  };

  const isEditDoneCycleAndIsTwer = useMemo(() => {
    return isApprovedRequestToEdit && isTwer && canEdit;
  }, [isApprovedRequestToEdit, isTwer]);

  return (
    <div className="cycle-detail">
      <CycleExpectations />
      {isEditDoneCycleAndIsTwer ? (
        createBySystem ? (
          <CycleInformationFormV2 />
        ) : (
          <CycleInformationForm />
        )
      ) : (
        <CycleInformation />
      )}
    </div>
  );
};
export default CycleDetail;
