import { useState, useCallback } from 'react';

const usePerfModal = (): [boolean, () => void, () => void] => {
  const [visible, setVisible] = useState(false);

  const onOpenModal = useCallback(() => {
    setVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  return [visible, onOpenModal, onCloseModal];
};

export default usePerfModal;
