import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  INonEngagedCycleTableData,
  INonEngagedCyclesPageResponse,
} from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import { getNonEngagedCycles } from '@@/_new_src_/api/nonEngagedCycles';

export const getNonEngagedCyclesAsync = createAsyncThunk(
  'getNonEngagedCycles',
  async (params: INonEngagedCycleTableData) => {
    const response = await getNonEngagedCycles(params);
    return response.data as INonEngagedCyclesPageResponse;
  },
);
