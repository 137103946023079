import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteExpectation,
  getAttachmentsUrl,
  getCycleByCycleId,
  getCycleFeedbacks,
  getOngoingCycle,
  getOverdueAndNonEngagedTip,
  getPastCyclesByYear,
  getPastReviewAvailableYear,
  postAddExpectation,
  postAssessmentNonEngagedTip,
  postCancelCycle,
  postCreateCycleInformation,
  postOpenNormalCycle,
  putUpdateCycleInformation,
  putUpdateExpectation,
  uploadAttachment,
  postConfirmExpectationNonEngaged,
} from '@@/_new_src_/api/myCycles';
import {
  IAddExpectationProps,
  IAttachment,
  ICreateInformationProps,
  IOngoingResponse,
  IOverdueAndNonEngagedTip,
  IPastCycle,
  IPastYear,
  IUpdateExpectationProps,
  IUpdateInformationProps,
} from '@@/_new_src_/store/myCyclesSlice/interface';
import { RcFile } from 'antd/lib/upload';
import { IGetDetailFeedback } from '@@/_new_src_/store/V2FeedbackSlice/interface';

export const getOngoingCycleAsync = createAsyncThunk('getOngoingCycle', async () => {
  const response = await getOngoingCycle();
  return response.data as IOngoingResponse;
});

export const getCycleByCycleIdAsync = createAsyncThunk(
  'getCycleByCycleId',
  async (cycleId: number) => {
    const response = await getCycleByCycleId(cycleId);
    return response.data as IOngoingResponse;
  },
);

export const postAddInformationAsync = createAsyncThunk(
  'postAddInformation',
  async (editPayloadData: ICreateInformationProps) => {
    await postCreateCycleInformation(editPayloadData);
  },
);

export const putUpdateInformationAsync = createAsyncThunk(
  'putUpdateInformation',
  async (editPayloadData: IUpdateInformationProps) => {
    await putUpdateCycleInformation(editPayloadData);
  },
);

export const postAddExpectationAsync = createAsyncThunk(
  'postAddExpectation',
  async (addExpectationProps: IAddExpectationProps) => {
    await postAddExpectation(addExpectationProps);
  },
);

export const putUpdateExpectationAsync = createAsyncThunk(
  'putUpdateExpectation',
  async (updateExpectationProps: IUpdateExpectationProps) => {
    await putUpdateExpectation(updateExpectationProps);
  },
);

export const deleteExpectationAsync = createAsyncThunk(
  'deleteExpectation',
  async (expectationId: number) => {
    const response = await deleteExpectation(expectationId);
    return response.data as string;
  },
);

export const postUploadAttachmentAsync = createAsyncThunk(
  'postUploadAttachment',
  async (fileInfo: { file: RcFile; type: string }) => {
    const response = (await uploadAttachment(fileInfo.file)) as { data: IAttachment };
    return {
      data: response.data,
      type: fileInfo.type,
    };
  },
);

export const postCancelCycleAsync = createAsyncThunk('postCancelCycle', async (cycleId: number) => {
  await postCancelCycle(cycleId);
});

export const postOpenNormalCycleAsync = createAsyncThunk(
  'postOpenNormalCycle',
  async (cycleId: number) => {
    await postOpenNormalCycle(cycleId);
  },
);

export const getAttachmentsUrlAsync = createAsyncThunk(
  'getAttachmentsUrl',
  async (cycleId: number) => {
    const response = await getAttachmentsUrl(cycleId);
    return response.data as { attachmentUrl: string };
  },
);

export const getCycleFeedbacksAsync = createAsyncThunk(
  'getCycleFeedbacks',
  async (cycleId: number) => {
    const response = await getCycleFeedbacks(cycleId);
    return response.data as IGetDetailFeedback[];
  },
);

export const getPastCyclesByYearAsync = createAsyncThunk(
  'getPastCyclesByYear',
  async (year: string) => {
    const response = await getPastCyclesByYear(year);
    return response.data as IPastCycle[];
  },
);

export const getPastCycleAvailableYearAsync = createAsyncThunk(
  'getPastReviewAvailableYear',
  async () => {
    const response = await getPastReviewAvailableYear();
    return response.data as IPastYear;
  },
);

export const getOverdueAndNonEngagedTipAsync = createAsyncThunk(
  'getOverdueAndNonEngagedTip',
  async () => {
    const response = await getOverdueAndNonEngagedTip();
    return response.data as IOverdueAndNonEngagedTip;
  },
);

export const postAssessmentNonEngagedTipAsync = createAsyncThunk(
  'postAssessmentNonEngagedTip',
  async (cycleId: number) => {
    await postAssessmentNonEngagedTip(cycleId);
  },
);

export const postConfirmExpectationNonEngagedAsync = createAsyncThunk(
  'postConfirmExpectationNonEngaged',
  async (cycleId: number) => {
    await postConfirmExpectationNonEngaged(cycleId);
  },
);
