import smallOrangeCircle from '@@/assets/images/smallOrangeCircle.png';
import { SelectOptionValueType } from '@@/types/antdComponent';
import React from 'react';
import { SearchedPeopleInfo } from '../../common/AddOrEditModal/type';

export const orangeCircleIcon = (
  <img className="selected-member-tag-render-icon" src={smallOrangeCircle} />
);

export const onPreventMouseDown = (event: {
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  event.preventDefault();
  event.stopPropagation();
};

export const formatSearchInputList: (
  searchResult: Array<SearchedPeopleInfo>,
  selectedUsers: Array<SelectOptionValueType>,
) => Array<SearchedPeopleInfo> = (searchResult = [], selectedUsers) => {
  return searchResult?.map(
    ({ employeeId, name, email, isTbp, isInAccountMembers }: SearchedPeopleInfo) => {
      const isAllreadySelectedInCurrentForm = selectedUsers
        .map((item: SelectOptionValueType) => item.value)
        .includes(employeeId as number);

      return {
        employeeId,
        name,
        email,
        isDisabled: isAllreadySelectedInCurrentForm || isTbp,
        isInAccountMembers,
        displayLabel: isTbp
          ? `${name || ''}, ${employeeId || 0} (This is a TBP, cannot be added as CLT)`
          : `${name || ''}, ${employeeId || 0}`,
      } as SearchedPeopleInfo;
    },
  );
};
