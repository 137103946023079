import { getUserList } from '@@/api/user';
import { GFSLRoleAccess, localAdmin } from '../../../common/interface';
import { ModalInfoParams } from '../../../type';
import './index.less';
import { GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN } from '@@/common/constant/matomo';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

const {
  action: {
    GLOBAL_ROLE_ADDS_CTO,
    GLOBAL_ROLE_ADDS_GLOBAL_FUNCTION_SL_LEADERSHIP,
    GLOBAL_ROLE_ADDS_TBP,
    GLOBAL_ROLE_ADDS_GLOBAL_TBP,
  },
} = GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN as {
  action: {
    GLOBAL_ROLE_ADDS_CTO: string;
    GLOBAL_ROLE_ADDS_GLOBAL_FUNCTION_SL_LEADERSHIP: string;
    GLOBAL_ROLE_ADDS_TBP: string;
    GLOBAL_ROLE_ADDS_GLOBAL_TBP: string;
  };
};

const modalInfo = ({
  locale,
  visible,
  onClose,
  handleSubmit,
  submitLoading,
  modalLoading,
  dropdownListData,
  handleChangeDropdownList,
  limitNumber,
}: ModalInfoParams) => {
  const {
    roleAccessAssignedByGlobalFunctionServiceLine: {
      talentBusinessPartnerAccess,
      GFSLLeaderShipAccess,
      chiefTalentOfficerAccess,
      globalTalentBusinessPartnerAccess,
    },
    assignPeopleModalToSpecifiedHierarchyModal: {
      talentBusinessPartnerInfo,
      GFSLLeaderShipInfo,
      GlobalHeadOfPeople,
      GlobalTalentBusinessPartnerInfo,
    },
    admin: {
      manageAdminRole: {
        addAdminModal: { nameNotFound },
      },
    },
  } = locale as localAdmin & GFSLRoleAccess;

  return {
    [USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE]: {
      modalInfo: {
        key: 'assign-cto-modal',
        visible: visible,
        modalTitle: GlobalHeadOfPeople.modalTitle,
        searchInput: {
          label: GlobalHeadOfPeople.nameLabel,
          placeholder: GlobalHeadOfPeople.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          mode: 'multiple',
          limitNumber: limitNumber,
          errorMessage: { require: GlobalHeadOfPeople.errorRequireName },
          notFoundText: nameNotFound,
        },
        warningTips: GlobalHeadOfPeople.assignPeopleModalTip,
        permissionList: chiefTalentOfficerAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        addEventMatomo: GLOBAL_ROLE_ADDS_CTO,
      },
    },
    [USER_ROLE_ENUM.GLOBAL_TALENT_BP]: {
      modalInfo: {
        key: 'assign-global-tbp-modal',
        visible: visible,
        modalTitle: GlobalTalentBusinessPartnerInfo.modalTitle,
        searchInput: {
          label: GlobalTalentBusinessPartnerInfo.nameLabel,
          placeholder: GlobalTalentBusinessPartnerInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          mode: 'multiple',
          limitNumber: limitNumber,
          errorMessage: { require: GlobalTalentBusinessPartnerInfo.errorRequireName },
          notFoundText: nameNotFound,
        },
        warningTips: GlobalTalentBusinessPartnerInfo.assignPeopleModalTip,
        permissionList: globalTalentBusinessPartnerAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        addEventMatomo: GLOBAL_ROLE_ADDS_GLOBAL_TBP,
      },
    },

    [USER_ROLE_ENUM.GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP]: {
      modalInfo: {
        key: 'assign-leadership-modal',
        visible: visible,
        modalTitle: GFSLLeaderShipInfo.modalTitle,
        loading: modalLoading,
        dropDownListInput: {
          label: GFSLLeaderShipInfo.hierarchyLabel,
          placeholder: GFSLLeaderShipInfo.hierarchyPlaceholder,
          listData: dropdownListData,
          isRequire: true,
          handleChangeDropdownList: handleChangeDropdownList,
        },
        searchInput: {
          label: GFSLLeaderShipInfo.nameLabel,
          placeholder: GFSLLeaderShipInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          errorMessage: { require: GFSLLeaderShipInfo.errorRequireName },
          notFoundText: nameNotFound,
          mode: 'multiple',
          limitNumber: limitNumber,
        },
        permissionList: GFSLLeaderShipAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        addEventMatomo: GLOBAL_ROLE_ADDS_GLOBAL_FUNCTION_SL_LEADERSHIP,
      },
    },
    [USER_ROLE_ENUM.TALENT_BP]: {
      modalInfo: {
        key: 'assign-leadership-modal',
        visible: visible,
        modalTitle: talentBusinessPartnerInfo.modalTitle,
        loading: modalLoading,
        dropDownListInput: {
          label: talentBusinessPartnerInfo.hierarchyLabel,
          placeholder: talentBusinessPartnerInfo.hierarchyPlaceholder,
          listData: dropdownListData,
          isRequire: true,
          handleChangeDropdownList: handleChangeDropdownList,
        },
        searchInput: {
          label: talentBusinessPartnerInfo.nameLabel,
          placeholder: talentBusinessPartnerInfo.assignPeopleModalPlaceholder,
          fetchApi: getUserList,
          isRequire: true,
          errorMessage: { require: talentBusinessPartnerInfo.errorRequireName },
          notFoundText: nameNotFound,
          mode: 'multiple',
          limitNumber: limitNumber,
        },
        permissionList: talentBusinessPartnerAccess.permissionList,
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        addEventMatomo: GLOBAL_ROLE_ADDS_TBP,
      },
    },
  };
};

export default modalInfo;
