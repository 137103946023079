import React, { memo, useContext, useMemo } from 'react';
import { REVIEW_EXPECTATION_LINK } from '../../constant/review';
import { UserInfoContext } from '@@/context/userInfo.context';

const ExpectationLink = ({ linkText }) => {
  const { homeCountry } = useContext(UserInfoContext);
  const href = useMemo(
    () => REVIEW_EXPECTATION_LINK[homeCountry] || REVIEW_EXPECTATION_LINK.Other,
    [homeCountry],
  );

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>
  );
};

export default memo(ExpectationLink);
