import React from 'react';
import './index.less';
import { Button, Modal } from 'antd';

export const CommonModal = ({
  visible,
  onCloseClick,
  onClickConfirm,
  confirmText = 'OK',
  width = 846,
  content,
}) => {
  const renderFooter = () => {
    return (
      <div className="footer-btns">
        <Button type="primary" onClick={onClickConfirm}>
          {confirmText}
        </Button>
      </div>
    );
  };
  return (
    <Modal
      className="multi-partner-modal"
      centered
      width={width}
      open={visible}
      footer={renderFooter()}
      onCancel={onCloseClick}
    >
      {content}
    </Modal>
  );
};
