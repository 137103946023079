import { ajax } from '@@/common/ajax';
import { getApiV2Path } from '@@/api/utils';
import { INonEngagedCycleTableData } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';

export const getNonEngagedCycles = (params: INonEngagedCycleTableData) =>
  ajax.get(getApiV2Path('/cycles/non-engaged-cycles'), {
    params: {
      employeeIds: params.employeeIds,
      nonEngagedType: params.nonEngagedType,
      sortColumn: params.sortColumn,
      sortOrder: params.sortOrder,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    },
  });
