import React from 'react';
import { Button } from 'antd';
import type { ButtonProps } from 'antd/es/button';
import cls from 'classnames';
import './index.less';

interface IIconButtonProps extends ButtonProps {
  buttonText: string;
  iconClassName: string;
}

const IconButton = ({ buttonText, iconClassName, ...buttonProps }: IIconButtonProps) => {
  return (
    <Button {...buttonProps} className={cls('icon-button-wrapper', buttonProps.className)}>
      <i className={iconClassName} />
      <span>{buttonText}</span>
    </Button>
  );
};

export default IconButton;
