import PerfTextArea from '@@/common/component/PerfTextArea';
import { REQUEST_TO_EDIT_REASON_MAX_LENGTH } from '@@/_new_src_/constants/requestToEditStatus';
import cls from 'classnames';
import React, { useState } from 'react';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';
import { IReasonInputProps } from '@@/_new_src_/types/cycle';

import './index.less';

const ReasonInput = (props: IReasonInputProps) => {
  const dispatch = useDispatch();

  const { cancelBtnText: cancelBtnTextLocale } = cycleDetailLocale.requestToEdit;

  const {
    label,
    placeholder = '',
    onChangeReason,
    cancelBtnText = cancelBtnTextLocale,
    confirmReasonFun,
    confirmBtnText,
  } = props;

  const [isConfirmBtnDisable, setIsConfirmBtnDisable] = useState(true);

  const changeReasonFun = (reason: string) => {
    setIsConfirmBtnDisable(isEmpty(reason));
    onChangeReason(reason);
  };

  const cancelRequestToEditFun = () => {
    dispatch(setConfirmModalStatus(true));
  };

  return (
    <div className={'fill-reason-wrapper'}>
      <h4 className={'label'}>{label}</h4>
      <PerfTextArea
        maxLength={REQUEST_TO_EDIT_REASON_MAX_LENGTH}
        height={88}
        placeholder={placeholder}
        inputCount
        onChange={changeReasonFun}
      />
      <div className={'operate-btns'}>
        <button className={'cancel-btn'} onClick={cancelRequestToEditFun}>
          <i className="ri-close-circle-fill" />
          <span className={'cancel-text'}>{cancelBtnText}</span>
        </button>
        <button
          className={cls('confirm-btn', {
            'confirm-btn-disabled': isConfirmBtnDisable,
          })}
          disabled={isConfirmBtnDisable}
          onClick={confirmReasonFun}
        >
          <i className="ri-send-plane-fill" />
          <span className={'confirm-text'}>{confirmBtnText}</span>
        </button>
      </div>
    </div>
  );
};
export default ReasonInput;
