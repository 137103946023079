import { createSlice } from '@reduxjs/toolkit';
import { IState, IUserSlice } from '@@/_new_src_/store/userSlice/interface';
import { getPPListAsync, getUserInfoAsync } from '@@/_new_src_/store/userSlice/asyncThunk';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';

const initialState: IUserSlice = {
  userInfo: {
    ...INITIAL_ASYNC_STATUS,
    userId: null,
    departmentId: '',
    name: '',
    gender: '',
    homeOffice: '',
    primaryOrganizationalHome: {
      role: '',
      roleHierarchy: '',
      hierarchyId: NaN,
      hierarchyName: '',
    },
    twExperience: NaN,
    homeCountry: '',
    department: '',
    role: '',
    grade: '',
    email: '',
    hireDate: '',
    userRolesInfoInV2: [],
    userRoleInfoInV2GlobalFunctionAdmin: [],
    probationStatus: '',
    passedProbationDate: '',
    currentCycleVersion: NaN,
    currentFeedbackVersion: NaN,
    currentOpeningCycleId: NaN,
    futureCycleVersion: NaN,
    hasOverdueCycle: false,
    overdueCycleId: NaN,
    hasOverdueCycleAsPerformancePartner: false,
  },
  PPList: {
    ...INITIAL_ASYNC_STATUS,
    content: [],
    total: 0,
  },
};

// 创建模块 create a module
const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = {
        ...initialState.userInfo,
        ...action.payload,
      };
    },
    setPPList: (state, action) => {
      state.PPList = {
        ...initialState.PPList,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserInfoAsync.pending, state => {
        state.userInfo = {
          ...initialState.userInfo,
          loading: true,
        };
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.userInfo = {
          ...action.payload,
          loading: false,
          success: true,
          rejected: false,
        };
      })
      .addCase(getUserInfoAsync.rejected, state => {
        state.userInfo = {
          ...initialState.userInfo,
          loading: true,
          rejected: true,
        };
      })
      .addCase(getPPListAsync.pending, state => {
        state.PPList = {
          ...initialState.PPList,
          loading: true,
        };
      })
      .addCase(getPPListAsync.fulfilled, (state, action) => {
        state.PPList = {
          ...action.payload,
          loading: false,
          success: true,
          rejected: false,
        };
      })
      .addCase(getPPListAsync.rejected, state => {
        state.PPList = {
          ...initialState.PPList,
          loading: true,
          rejected: true,
        };
      });
  },
});

export const userStore = (state: IState) => state.user;
export const userInfo = (state: IState) => state.user.userInfo;
export const PPList = (state: IState) => state.user.PPList;

export const { setUserInfo, setPPList } = userSlice.actions;

export default userSlice.reducer;
