import { isEmpty } from 'lodash';
import {
  DEFAULT_REQUEST_PAGE_STATUS,
  V2_DASHBOARD_SEARCH_FILTER,
  V2_REQUEST_PAGE_STATUS,
} from './constants';
import { DEFAULT_TABLE_PAGINATION } from '@@/_new_src_/constants/table';

export const setDefaultSearchFilterFun = searchFilterParam => {
  let currentSearchFilter = searchFilterParam;
  if (isEmpty(searchFilterParam)) {
    try {
      currentSearchFilter =
        JSON.parse(sessionStorage.getItem(V2_DASHBOARD_SEARCH_FILTER)) || DEFAULT_TABLE_PAGINATION;
    } catch (error) {
      currentSearchFilter = DEFAULT_TABLE_PAGINATION;
    }
  }
  sessionStorage.setItem(V2_DASHBOARD_SEARCH_FILTER, JSON.stringify(currentSearchFilter));
  return currentSearchFilter;
};

export const setRequestPageStatus = requestPageStatusParam => {
  let currentRequestPageStatus = requestPageStatusParam;
  if (isEmpty(requestPageStatusParam)) {
    try {
      currentRequestPageStatus =
        JSON.parse(sessionStorage.getItem(V2_REQUEST_PAGE_STATUS)) || DEFAULT_REQUEST_PAGE_STATUS;
    } catch (error) {
      currentRequestPageStatus = DEFAULT_REQUEST_PAGE_STATUS;
    }
  }
  sessionStorage.setItem(V2_REQUEST_PAGE_STATUS, JSON.stringify(currentRequestPageStatus));
  return currentRequestPageStatus;
};
