import { getCyclesListBySourceCategory } from '@@/api/v2/dashboard/cycles';
import { ALL_PEOPLE, ALL_PEOPLE_UNIT } from '@@/common/constant/dashboard';
import { SORT_DIRECTION_FOR_BE, SORT_DIRECTION_UPPER_CASE } from '@@/_new_src_/constants/table';
import { sourceCategoryJudgement } from '@@/common/utils/dashboardUtils/sourceCategory';
import { dashboard, setSearchFilter } from '@@/redux/slice/dashboardSlice';
import { useMount, useRequest, useSessionStorageState, useUpdateEffect } from 'ahooks';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardCyclesFilter from './filter';
import DashboardCyclesList from './list';
import { get, omit } from 'lodash';
import {
  DEFAULT_CYCLES_TABLE_INFO,
  SORT_FIELD_DASHBOARD,
} from '@@/features/performance/v2/dashboardPage/common/constants';

const DashboardCycles = () => {
  const dispatch = useDispatch();
  const [isDefaultRequest, setIsDefaultRequest] = useSessionStorageState(false);

  const {
    searchFilter,
    currentRoleSelection: { roleId, roleType, roleName, roleInfo },
    currentGroupSelection: { groupId, groupType },
  } = useSelector(dashboard);

  const [searching, setSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const sourceCategory = useMemo(
    () => sourceCategoryJudgement(roleName, groupId, groupType, roleType),
    [roleName, groupId, groupType, roleType],
  );

  const isAllPeople = useMemo(
    () => groupId === ALL_PEOPLE || groupType === ALL_PEOPLE_UNIT,
    [groupId, groupType],
  );

  const {
    run: runGetReviewList,
    data: cycleList,
    mutate: setReviewList,
    loading,
  } = useRequest(
    filterInfoAndTableInfo =>
      getCyclesListBySourceCategory({
        sourceCategory,
        isAllPeople,
        groupId,
        roleId,
        filterInfoAndTableInfo,
        groupType,
        roleName,
      }),
    {
      manual: true,
      formatResult: res => res.data,
      initialData: [],
      onSuccess: () => {
        setIsSearched(true);
        setSearching(false);
      },
      onError: () => {
        setSearching(false);
      },
    },
  );

  const searchFilterWithInitialHierarchyInfoForExport = {
    groupId,
    isAllPeople: groupId === ALL_PEOPLE,
    roleType,
    roleId,
    roleName,
    sortColumn: SORT_FIELD_DASHBOARD.DEFAULT,
    sortOrder: SORT_DIRECTION_UPPER_CASE.DESC,
    ...searchFilter,
  };

  const onSearchReviewList = useCallback(
    values => {
      dispatch(setSearchFilter(values));
      return runGetReviewList(omit(values, ['account']));
    },
    [runGetReviewList],
  );

  useMount(() => {
    if (Object.keys(searchFilter).length === 0) {
      onSearchReviewList({
        ...DEFAULT_CYCLES_TABLE_INFO,
      });
    } else {
      onSearchReviewList({
        ...DEFAULT_CYCLES_TABLE_INFO,
        ...searchFilter,
      });
    }
  });

  useUpdateEffect(() => {
    setIsDefaultRequest(true);
    dispatch(setSearchFilter(DEFAULT_CYCLES_TABLE_INFO));
    setReviewList([]);
    setIsSearched(false);
    runGetReviewList(DEFAULT_CYCLES_TABLE_INFO);
  }, [groupId, roleName, roleId, roleType]);

  const onSearch = useCallback(
    values => {
      setSearching(true);
      onSearchReviewList({
        ...values,
        ...DEFAULT_CYCLES_TABLE_INFO,
        revieweeIds: values.reviewee ? values.reviewee.map(item => item.value) : undefined,
        reviewerIds: values.reviewer ? values.reviewer.map(item => item.value) : undefined,
        account: get(values, 'account', []),
        accountIdList: [get(values, 'account', []).map(accountObject => accountObject.key)],
      }).finally(() => {
        setIsDefaultRequest(false);
      });
    },
    [onSearchReviewList, setIsDefaultRequest],
  );

  const onTableChange = useCallback(
    (pagination, _, sorter) => {
      onSearchReviewList({
        ...searchFilter,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
        sortColumn:
          (sorter.column && SORT_FIELD_DASHBOARD[sorter.columnKey]) || SORT_FIELD_DASHBOARD.DEFAULT,
        sortOrder: SORT_DIRECTION_FOR_BE[sorter.order],
      });
    },
    [onSearchReviewList, searchFilter],
  );

  const onClearFilter = useCallback(() => {
    setReviewList([]);
    setIsSearched(false);
    dispatch(setSearchFilter({ ...DEFAULT_CYCLES_TABLE_INFO }));
    setIsDefaultRequest(true);
  }, [setIsDefaultRequest, setReviewList]);

  return (
    <>
      <DashboardCyclesFilter
        groupId={groupId}
        hierarchyType={roleType}
        hierarchyId={roleId}
        roleName={roleName}
        searching={searching}
        onSearch={onSearch}
        onClear={onClearFilter}
        searchFilter={searchFilter}
        groupType={groupType}
      />
      <DashboardCyclesList
        groupId={groupId}
        cycleList={cycleList}
        loading={loading}
        isSearched={isSearched}
        onTableChange={onTableChange}
        searchFilter={searchFilterWithInitialHierarchyInfoForExport}
        isDefaultRequest={isDefaultRequest}
        roleInfo={roleInfo}
        sourceCategory={sourceCategory}
        hierarchyId={roleId}
        groupType={groupType}
        roleName={roleName}
        hierarchyType={roleType}
      />
    </>
  );
};

export default memo(DashboardCycles);
