import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import feedbackReducer from './feedbackSlice';
import reviewReducer from './reviewSlice';
import revieweeReducer from './revieweeSlice';
import checkInNotesReducer from './checkInNotesSlice';
import versionReducer from './versionSlice';
import dashboardReducer from './dashboardSlice';
import globalReducer from './globalSlice';
import adminReducer from './adminSlice';

import userReducer from '@@/_new_src_/store/userSlice/index';
import myCyclesReducer from '@@/_new_src_/store/myCyclesSlice/index';
import commonReducer from '@@/_new_src_/store/commonSlice/index';
import cycleDetailByVersionReducer from './cycleDetailByVersionSlice';
import cycleDetailReducer from '@@/_new_src_/store/cycleDetailSlice';
import v2FeedbackSlice from '@@/_new_src_/store/V2FeedbackSlice';
import persistenceSlice from '@@/_new_src_/store/persistenceSlice';
import nonEngagedCyclesSlice from '@@/_new_src_/store/nonEngagedCyclesSlice';
import calibrateIndicatorsSlice from '@@/_new_src_/store/calibrateIndicatorsSlice';

export const rootReducer = combineReducers({
  feedback: feedbackReducer,
  review: reviewReducer,
  reviewee: revieweeReducer,
  checkInNotes: checkInNotesReducer,
  version: versionReducer,
  dashboard: dashboardReducer,
  global: globalReducer,
  user: userReducer,
  myCycles: myCyclesReducer,
  common: commonReducer,
  cycleDetailByVersion: cycleDetailByVersionReducer,
  cycleDetail: cycleDetailReducer,
  v2Feedback: v2FeedbackSlice,
  persistence: persistenceSlice,
  admin: adminReducer,
  nonEngagedCycles: nonEngagedCyclesSlice,
  calibrateIndicators: calibrateIndicatorsSlice,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'user',
    'dashboard',
    'myCycles',
    'common',
    'cycleDetailByVersion',
    'review',
    'cycleDetail',
    'v2Feedback',
    'admin',
    'nonEngagedCycles',
    'calibrateIndicators',
  ],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const removeReduxStorage = () => localStorage.removeItem('persist:root');
