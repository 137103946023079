import { ITableInfo } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import { nonEngagedCyclesTableSortColumnsName } from '@@/types/antdComponent';
import { SORT_DIRECTION_FOR_BE, TABLE_DEFAULT_INFO } from '@@/_new_src_/constants/table';

export enum NON_ENGAGED_CYCLES_TAB_TYPE {
  OVERDUE = 'OVERDUE',
  ASSESSMENT_NON_ENGAGED = 'ASSESSMENT_NON_ENGAGED',
  EXPECTATION_NON_ENGAGED = 'EXPECTATION_NON_ENGAGED',
}

export const NON_ENGAGED_CYCLES_TAB: Record<string, string> = {
  '0': NON_ENGAGED_CYCLES_TAB_TYPE.OVERDUE,
  '1': NON_ENGAGED_CYCLES_TAB_TYPE.ASSESSMENT_NON_ENGAGED,
  '2': NON_ENGAGED_CYCLES_TAB_TYPE.EXPECTATION_NON_ENGAGED,
};

export enum NON_ENGAGED_CYCLES_TAB_INDEX {
  OVERDUE = '0',
  ASSESSMENT_NON_ENGAGED = '1',
  EXPECTATION_NON_ENGAGED = '2',
}

export const DEFAULT_TABLE_CONFIG = {
  DEFAULT_PAGE_NUMBER: TABLE_DEFAULT_INFO.PAGE_NUMBER,
  DEFAULT_PAGE_SIZE: TABLE_DEFAULT_INFO.PAGE_SIZE,
  SORT_COLUMN_FOR_ASSESSMENT_NON_ENGAGED: nonEngagedCyclesTableSortColumnsName.actions,
  SORT_COLUMN_FOR_EXPECTATION_NON_ENGAGED: nonEngagedCyclesTableSortColumnsName.cycleDuration,
  DEFAULT_SORT_COLUMN: nonEngagedCyclesTableSortColumnsName.superOrgName,
  DEFAULT_SORT_ORDER: SORT_DIRECTION_FOR_BE.ascend,
};

export const TABLE_INFO = ({
  sortColumn,
  sortOrder,
}: {
  sortColumn?: string;
  sortOrder?: string;
}) => {
  return {
    sortColumn: sortColumn || DEFAULT_TABLE_CONFIG.DEFAULT_SORT_COLUMN,
    sortOrder: sortOrder || DEFAULT_TABLE_CONFIG.DEFAULT_SORT_ORDER,
    pageNumber: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_TABLE_CONFIG.DEFAULT_PAGE_SIZE,
  } as ITableInfo;
};

export const INITIAL_TABLE_INFO: { [type: string]: ITableInfo } = {
  OVERDUE: { ...TABLE_INFO({}) },
  ASSESSMENT_NON_ENGAGED: {
    ...TABLE_INFO({ sortColumn: DEFAULT_TABLE_CONFIG.SORT_COLUMN_FOR_ASSESSMENT_NON_ENGAGED }),
  },
  EXPECTATION_NON_ENGAGED: {
    ...TABLE_INFO({
      sortOrder: SORT_DIRECTION_FOR_BE.descend,
      sortColumn: DEFAULT_TABLE_CONFIG.SORT_COLUMN_FOR_EXPECTATION_NON_ENGAGED,
    }),
  },
};
