import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import './styles/index.less';
import routeConfig from './common/routeConfig';
import Root from './Root';
import store from './redux/store';

const renderApp = app => {
  render(app, document.getElementById('root'));
};

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser.ts');
  const startMSW = () =>
    worker.start({
      onUnhandledRequest: 'bypass',
    });
  window.__mswStart = startMSW;
  window.__mswStop = worker.stop;
}

if (process.env.NODE_ENV === 'development') {
  import('react-axe').then(axe => {
    axe?.default(React, render, 1000);
  });
}

renderApp(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <Root routeConfig={routeConfig} />
    </PersistGate>
  </Provider>,
);
