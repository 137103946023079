export const FEEDBACK_ANSWER_MAX_LENGTH = 5000;
export const FEEDBACK_REMIND_DATE = 3;
export const FEEDBACK_CONTEXT_MAX_LENGTH = 5000 as number;

export const RequestFeedbackListOptions = [
  {
    label: 'Current Cycle',
    value: 'currentReview',
  },
  {
    label: 'Past Cycles',
    value: 'pastReviews',
  },
];

export const isCurrentCyclesPage = (cycleFilterValue: string) => {
  return cycleFilterValue === RequestFeedbackListOptions[0].value;
};

export const isPastCyclesPage = (cycleFilterValue: string) => {
  return cycleFilterValue === RequestFeedbackListOptions[1].value;
};

export enum FEEDBACK_SORT_COLUMNS_NAME {
  default = 'DEFAULT',
  providerName = 'PROVIDER_NAME',
  providerRole = 'PROVIDER_ROLE',
  requestDate = 'REQUEST_DATE',
  dueDate = 'DUE_DATE',
  completionDate = 'COMPLETION_DATE',
  status = 'STATUS',
  requesterName = 'REQUESTER_NAME',
  requesterRole = 'REQUESTER_ROLE',
}

export const FeedbackStatusColorMap: { [key: string]: string } = {
  Drafted: '#47A1AD',
  Pending: '#ff7f50',
  Cancelled: '#bdbec0',
  'Not Provided': '#EE312D',
  Submitted: '#11AA58',
};

export const FeedbackStatusLabel = {
  Submitted: 'Done',
  Drafted: 'Drafted',
  Pending: 'Pending',
  Cancelled: 'Cancelled',
  'Not Provided': 'Not Provided',
} as FeedbackStatusLabel;

interface FeedbackStatusLabel {
  [key: string]: string;
}

export enum FEEDBACK_STATUS {
  SUBMITTED = 'Submitted',
  DRAFTED = 'Drafted',
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
  NOT_PROVIDED = 'Not Provided',
}

export enum FEEDBACK_TYPE {
  CLIENT_FEEDBACK = 1,
  INTERNAL_FEEDBACK = 0,
}

export enum FEEDBACK_VERSION {
  V1 = 1,
  V2 = 2,
}

export enum FEEDBACK_TAB_KEY {
  REQUEST_FEEDBACK = '0',
  GIVE_FEEDBACK = '1',
}

export enum OPERATION_TYPE {
  ADD = 'add',
  EDIT = 'edit',
  CANCEL = 'cancel',
}
