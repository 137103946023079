import { SortOrder } from 'antd/es/table/interface';

export enum TABLE_DEFAULT_INFO {
  PAGE_SIZE = 10,
  PAGE_NUMBER = 1,
  TOTAL = 0,
}

export const DEFAULT_TABLE_PAGINATION = {
  pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
  pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
};

export const DEFAULT_TABLE_PAGINATION_SIZE_CHANGER = {
  showSizeChanger: true,
  position: ['bottomCenter' as const],
};

export enum SORT_DIRECTION_UPPER_CASE {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum SORT_DIRECTION_LOWER_CASE {
  descend = 'descend',
  ascend = 'ascend',
}

export const SORT_DIRECTION_FOR_FE = {
  [SORT_DIRECTION_UPPER_CASE.DESC]: SORT_DIRECTION_LOWER_CASE.descend,
  [SORT_DIRECTION_UPPER_CASE.ASC]: SORT_DIRECTION_LOWER_CASE.ascend,
} as { [key: string]: SORT_DIRECTION_LOWER_CASE };

export const SORT_DIRECTION_FOR_BE = {
  [SORT_DIRECTION_LOWER_CASE.descend]: SORT_DIRECTION_UPPER_CASE.DESC,
  [SORT_DIRECTION_LOWER_CASE.ascend]: SORT_DIRECTION_UPPER_CASE.ASC,
} as { [key: string]: SORT_DIRECTION_UPPER_CASE };

export const DEFAULT_TABLE_COLUMN_SORT_CONFIG = {
  sorter: true,
  sortDirections: [
    SORT_DIRECTION_LOWER_CASE.descend,
    SORT_DIRECTION_LOWER_CASE.ascend,
  ] as SortOrder[],
};
