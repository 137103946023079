import { CaretUpFilled } from '@ant-design/icons';
import cls from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import EditBlueIcon from '../../../assets/images/edit-blue.svg';
import peopleRemovedImg from '../../../assets/images/people-removed.svg';
import peopleImg from '../../../assets/images/people.svg';
import { UserInfoContext } from '@@/context/userInfo.context';
import FieldBlockInfo from '../FieldBlockInfo';
import PerfTextButton from '../PerfTextButton';
import './info.less';
import { useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
dayjs.extend(utc);

const ReviewCheckInNotesInfo = props => {
  const { defaultExpand, operation, readonly, data, locale, onCheckInNotesEdit } = props;
  const { userId } = useContext(UserInfoContext);

  const {
    currentCycleDetail: { twer, performancePartner, additionalPartners = [] },
  } = useSelector(cycleDetailByVersion);

  const [isExpand, setIsExpand] = useState(defaultExpand);
  const onToggleExpand = useCallback(() => {
    setIsExpand(!isExpand);
  }, [isExpand]);

  const isCreatedBySelf = useMemo(() => userId === data.creatorId, [data.creatorId, userId]);
  const isRemovedRole = useMemo(
    () =>
      data.creatorId !== twer?.id &&
      data.creatorId !== performancePartner?.id &&
      !additionalPartners.map(i => i.id).includes(data.creatorId),
    [data.creatorId, twer?.id, additionalPartners, additionalPartners?.id],
  );
  const handleCheckInNotesEdit = useCallback(() => {
    onCheckInNotesEdit(data.id);
  }, [data.id, onCheckInNotesEdit]);

  return (
    <div className="review-check-in-notes-info-container">
      <div className={cls('review-check-in-notes-info', { 'removed-role': isRemovedRole })}>
        <div className="review-check-in-notes-info-wrapper">
          <div className="review-check-in-notes-info-item">
            <span className="review-check-in-notes-info-avatar">
              {data.creatorName.substr(0, 2).toUpperCase()}
            </span>
            <span className="review-check-in-notes-info-name" title={data.creatorName}>
              {data.creatorName}
            </span>
            <span className="review-check-in-notes-info-date">
              {dayjs.utc(data.editedTime).local().format('YYYY-MM-DD')}
            </span>
          </div>
          <div className="review-check-in-notes-info-item">
            <span className="review-check-in-notes-info-wrapper">
              <img
                alt="role"
                src={isRemovedRole ? peopleRemovedImg : peopleImg}
                className="review-check-in-notes-info-role-icon"
              />
              <span className="review-check-in-notes-info-role" title={data.creatorRole}>
                {data.creatorRole}
                {isRemovedRole && <span className="removed-tips">{locale.removedRoleTips}</span>}
              </span>
            </span>
            {operation.editNotesButton && isCreatedBySelf && !readonly && (
              <PerfTextButton
                icon={<img src={EditBlueIcon} className="edit-icon" alt="" />}
                className="review-check-in-notes-edit-button"
                onClick={handleCheckInNotesEdit}
              >
                {locale.edit}
              </PerfTextButton>
            )}
          </div>
        </div>
        <div className="review-check-in-notes-info-expand" onClick={onToggleExpand}>
          <CaretUpFilled
            rotate={isExpand ? 0 : 180}
            className="review-check-in-notes-info-expand-icon"
          />
        </div>
      </div>
      {isExpand && (
        <>
          <FieldBlockInfo
            isExpand={isExpand}
            className="checkin-info notes "
            label={locale.notes.label}
            content={data.notes}
            collapsible
          />
          {data.actions && (
            <FieldBlockInfo
              isExpand={isExpand}
              className="checkin-info actions"
              label={locale.actions.label}
              content={data.actions}
              collapsible
            />
          )}
        </>
      )}
    </div>
  );
};

ReviewCheckInNotesInfo.defaultProps = {
  defaultExpand: false,
};

export default memo(ReviewCheckInNotesInfo);
