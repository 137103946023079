import FieldBlockInfo from '@@/common/component/FieldBlockInfo';
import useLocalStorageReview from '@@/hooks/useLocalStorageReview';
import { setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { clearDoneReviewState, setIsEditReviewHistoryDetail } from '@@/redux/slice/reviewSlice';
import cls from 'classnames';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.less';
import { setCurrentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';
import RequestToEditTag from '@@/_new_src_/features/RequestToEditTag';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { CYCLE_STATUS_DESCRIPTION } from '@@/_new_src_/constants/CycleStatus';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import {
  AUTO_PUBLISH_CALIBRATING_RESULT_DATE,
  CALIBRATION_COMMUNICATION_DURATION,
} from '@@/_new_src_/constants/common';

const ReviewHistoryCard = props => {
  const { review, selectedYear } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { reviewList: locale } = useSelector(selectLocaleResource);
  const { isUnreadCycle, isDoneCycle, isCancelledCycle, isClosedCycle, isCalibratingCycle } =
    getCycleStatus(review.status);
  const { removeLocalReview } = useLocalStorageReview();
  const {
    tag: { assessmentNonEngagedTag },
  } = commonLocale;

  const gotoReviewHistoryDetail = useCallback(() => {
    const currentPathName = window.location.pathname;
    dispatch(setCycleVersion(review.cycleVersion));
    dispatch(setCurrentCycleDetail({}));
    history.push(`/performance/cycles/cycleHistoryDetail/${review.id}`, {
      windowPosition: document.documentElement.scrollTop,
      selectedYear,
      prevPage: currentPathName,
    });
  }, [history, review.id, selectedYear]);

  const onClickCard = useCallback(() => {
    dispatch(setIsEditReviewHistoryDetail(false));
    removeLocalReview();
    dispatch(clearDoneReviewState());
    gotoReviewHistoryDetail();
  }, [dispatch, gotoReviewHistoryDetail, removeLocalReview]);

  const onEnterCard = e => {
    if (e.keyCode === 13) {
      onClickCard();
    }
  };

  const renderUnreadOrDoneTag = () => {
    if (isUnreadCycle) {
      return `<div>
    ${review.assessment.rating}
    </div>
        <div class="state-content">
          <i class="ri-information-fill prompt"></i>
          <span class="unread_notification_assessment_summary">
            ${locale.assessmentSummaryUnread}
          </span>
        </div>`;
    } else {
      return `<div>
    ${review.assessment.rating}
    </div>`;
    }
  };

  const renderCancelTag = status => {
    return `<div class="state-content">${locale.reviewStatus(
      CYCLE_STATUS_DESCRIPTION[status],
    )}</div>`;
  };

  const renderCalibratingTag = isFirstHalfOfYear => {
    let communicationDuration = isFirstHalfOfYear
      ? {
          ...CALIBRATION_COMMUNICATION_DURATION.H1,
          publishTime: AUTO_PUBLISH_CALIBRATING_RESULT_DATE.H1,
        }
      : {
          ...CALIBRATION_COMMUNICATION_DURATION.H2,
          publishTime: AUTO_PUBLISH_CALIBRATING_RESULT_DATE.H2,
        };

    return `<div class="state-content">${locale.calibratingText({
      ...communicationDuration,
    })}</div>`;
  };

  const renderReviewState = useMemo(() => {
    if (isCalibratingCycle) {
      return renderCalibratingTag(review.isFirstHalfOfYear);
    } else if (isDoneCycle) {
      return renderUnreadOrDoneTag();
    } else {
      return renderCancelTag(review.status);
    }
  }, [review.status, isDoneCycle, review.isFirstHalfOfYear]);

  return (
    <div
      className={cls('review-history-card-container', {
        'done-review': isDoneCycle,
        'cancel-review': isCancelledCycle || isClosedCycle,
        'calibrating-review': isCalibratingCycle,
      })}
      key={review.id}
      onClick={onClickCard}
      tabIndex={0}
      onKeyDown={onEnterCard}
    >
      <div
        className={cls('content-section', {
          'new-cycle': review.cycleVersion === CYCLE_VERSION.NEW,
        })}
      >
        {review.cycleVersion === CYCLE_VERSION.OLD && (
          <div className={'project-content'}>
            {review.assessmentNonEngaged && (
              <div className={'assessment-non-engagement-tag'}>{assessmentNonEngagedTag}</div>
            )}
            <FieldBlockInfo
              className="history-item project"
              label={locale.project}
              content={review.project && review.project.projectName}
            />
          </div>
        )}
        <FieldBlockInfo
          className="history-item duration"
          label={locale.duration}
          content={
            review.duration &&
            formatRangeDuration(review.duration.startTime, review.duration.endTime)
          }
        />
        <div className="history-item role-and-edit-icon">
          <FieldBlockInfo
            className={cls('role', { 'edit-icon': review.canEdit })}
            label={locale.role}
            content={get(review, 'reviewee.role')}
          />
        </div>
        <div className={'review-state'}>
          {!isDoneCycle && <i className="ri-information-fill prompt" />}
          <FieldBlockInfo
            className={'history-item'}
            label={isDoneCycle ? locale.rating : undefined}
            content={renderReviewState}
          />
        </div>
        <RequestToEditTag
          isDoneCycle={isDoneCycle}
          canRequestToEdit={review.canRequestToEdit}
          canEdit={review.canEdit}
        />
      </div>
    </div>
  );
};

export default ReviewHistoryCard;
