import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { userInfo } from '@@/_new_src_/store/userSlice';
import locale from '@@/_new_src_/local/landing/en_US';
import { isNaNOrNull } from '@@/_new_src_/utils';

export const CycleButtonAndTip = () => {
  const history = useHistory();
  const { currentOpeningCycleId, loading } = useSelector(userInfo);

  const gotoReviews = useCallback(() => {
    history.push(CYCLE_PAGE_LINK.MY_CYCLES);
  }, [history]);

  return (
    <>
      <p
        className="guidance-tip"
        dangerouslySetInnerHTML={{
          __html: locale.guidanceTip,
        }}
      />
      {!loading && !isNaNOrNull(currentOpeningCycleId) && (
        <Button type="primary" className="landing-page-button" onClick={gotoReviews}>
          {locale.checkReview}
        </Button>
      )}
    </>
  );
};
