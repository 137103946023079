import React, { useMemo } from 'react';
import { CompletedCycleBanner } from '@@/_new_src_/page/myCycles/component/CompletedCycleBanner';
import Locale from '@@/_new_src_/local/myCycles/en_US';
import { useSelector } from 'react-redux';
import { overdueAndNonEngagedTip } from '@@/_new_src_/store/myCyclesSlice';
import cls from 'classnames';
import { get } from 'lodash';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { reviewState } from '@@/redux/slice/reviewSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import OverdueNotification from '@@/_new_src_/page/myCycles/component/OverdueNotification';
import './index.less';

export const CycleTip = ({ tipVisibleByPosition = true }: { tipVisibleByPosition?: boolean }) => {
  const { expectationNonEngagedCycleTipContent } = Locale;
  const { showExpectationNonEngagedCycleTips, showCompletedTips } =
    useSelector(overdueAndNonEngagedTip);
  const { currentOpeningCycleId, futureCycleVersion, hasOverdueCycle } = useSelector(userInfo);
  const {
    currentCycleDetail: { isDoneCycle, isUnreadCycle },
  } = useSelector(cycleDetailByVersion);
  const {
    historyCycleList: { data: reviewHistory },
  } = useSelector(reviewState);

  const showCompletedCycleBanner = useMemo(() => {
    const displayLogicByPosition = tipVisibleByPosition ? !isUnreadCycle : isUnreadCycle;
    if (displayLogicByPosition && showCompletedTips) {
      if (currentOpeningCycleId) {
        return isDoneCycle;
      }
      const firstReviewHistory = reviewHistory.length > 0 && reviewHistory[0];
      const firstReviewHistoryStatus = get(firstReviewHistory, 'status', '');
      const { isDoneCycle: isFirstHistoryCycleDone } = getCycleStatus(firstReviewHistoryStatus);
      return isFirstHistoryCycleDone;
    } else {
      return false;
    }
  }, [
    currentOpeningCycleId,
    reviewHistory,
    isUnreadCycle,
    showCompletedTips,
    tipVisibleByPosition,
  ]);

  const showExpectationNonEngagedTip = useMemo(() => {
    return tipVisibleByPosition && showExpectationNonEngagedCycleTips;
  }, [tipVisibleByPosition, showExpectationNonEngagedCycleTips]);

  const showOverdueNotification = useMemo(() => {
    return tipVisibleByPosition && hasOverdueCycle;
  }, [tipVisibleByPosition, hasOverdueCycle]);

  const renderExpectationNonEngagedTip = () => (
    <div className="expectation-non-engaged-tip">
      <i className="ri-error-warning-fill" />
      <div
        className="tip-text"
        dangerouslySetInnerHTML={{ __html: expectationNonEngagedCycleTipContent }}
      />
    </div>
  );

  return (
    <>
      {showOverdueNotification && <OverdueNotification />}
      {CYCLE_VERSION.NEW === futureCycleVersion && (
        <div
          className={cls({
            'cycle-tips-block': tipVisibleByPosition,
          })}
        >
          {showCompletedCycleBanner && <CompletedCycleBanner />}
          {showExpectationNonEngagedTip && renderExpectationNonEngagedTip()}
        </div>
      )}
    </>
  );
};

export default CycleTip;
