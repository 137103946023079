import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CYCLE_DISPLAY_PART, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { cycleDisplayPart } from '@@/_new_src_/store/myCyclesSlice';
import CycleStatusBar from '../CycleStatusBar';
import CycleExpectations from '../CycleExpectations';
import CycleInformation from '../CycleInformation';
import CycleHistoryList from '@@/features/performance/reviews/reviewList/CycleHistoryList';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import './index.less';
import Tip from '../../../../components/Tip';
import { IMyCyclesLocale } from '../../../../types/myCycles';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { getThDay } from '../../../../utils';
import { userInfo } from '@@/_new_src_/store/userSlice';

const CycleInfoDisplayPage = () => {
  const {
    myCycles: { draftedTip, draftedTipForProbation },
  } = useSelector(selectLocaleResource) as IMyCyclesLocale;

  const currentPart = useSelector(cycleDisplayPart);

  const {
    currentCycleDetail: { isNewCycle, duration, isProbation, createBySystem },
  } = useSelector(cycleDetailByVersion) as {
    currentCycleDetail: {
      isNewCycle: boolean;
      duration: { startTime: string; endTime: string };
      isProbation: boolean;
      createBySystem: boolean;
    };
  };
  const { futureCycleVersion } = useSelector(userInfo);

  const afterOneMonthDate = useMemo(() => {
    const date = new Date(duration?.startTime);
    const day = getThDay(date.getDate());

    const currentMonth = date.getMonth();
    date.setMonth(currentMonth + 1);
    const monthInEnglish = date.toLocaleString('en-US', { month: 'long' });

    return `${monthInEnglish} ${day}`;
  }, [duration]);

  return (
    <>
      {currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY && (
        <div className="cycle-info-display-page">
          <div className="cycle-expectation-content">
            <div className="status-bar">
              <CycleStatusBar />
            </div>
            {isNewCycle && (
              <>
                <TabPaneComponent
                  className="tabs-container"
                  tabList={['Detail']}
                  type={'card'}
                  tabBarGutter={9}
                  centered={true}
                  size={'large'}
                />
                {CYCLE_VERSION.NEW === futureCycleVersion && (
                  <Tip
                    wording={
                      !isProbation && createBySystem
                        ? draftedTip(afterOneMonthDate)
                        : draftedTipForProbation
                    }
                    className={'orange-tip'}
                  />
                )}
              </>
            )}
            <div className="cycle-expectation">
              <CycleExpectations />
            </div>
            <div className="cycle-information">
              <CycleInformation />
            </div>
          </div>
          <div className="cycle-history">
            <CycleHistoryList />
          </div>
        </div>
      )}
    </>
  );
};

export default CycleInfoDisplayPage;
