import { getPerformancePath } from '@@/common/utils';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';
import { DEFAULT_TABLE_PAGINATION, SORT_DIRECTION_UPPER_CASE } from '@@/_new_src_/constants/table';

export const DASHBOARD_CYCLES_PATH = getPerformancePath('/dashboard/cycles');
export const DASHBOARD_REQUESTS_PATH = getPerformancePath('/dashboard/requests');
export const ALL_PEOPLE_TITLE = 'All People';
export const ALL_GROUPS_TITLE = 'All Groups';
export const ALL_ACCOUNT_GROUPS_TITLE = 'All Account Groups';
export const ALL_ACCOUNT_TITLE = 'Automated Groups';
export const CUSTOMIZED_GROUPS_TITLE = 'Customized Groups';
export const SINGLE_ACCOUNT = 'Single Account';

export const V2_DASHBOARD_SEARCH_FILTER = 'V2_DASHBOARD_SEARCH_FILTER';
export const V2_REQUEST_PAGE_STATUS = 'V2_REQUEST_PAGE_STATUS';
export const DEFAULT_REQUEST_PAGE_STATUS = { list: { content: [], total: 0 }, filter: {} };

export const GLOBAL_MANAGEMENT_TEAM = 'Global Management Team';
export const GLOBAL_PEOPLE_TEAM = 'Global People Team';

export const OTHER_GLOBAL_FUNCTION = 'Other Global Functions';

export const rolesHavingAccessToRequestTab = [
  UserRoleInfo[USER_ROLE_ENUM.TALENT_BP].display,
  UserRoleInfo[USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER].display,
  UserRoleInfo[USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE].display,
  UserRoleInfo[USER_ROLE_ENUM.REGIONAL_TALENT_BP].display,
];

export const SORT_FIELD_DASHBOARD = {
  REVIEWEE_NAME: 'REVIEWEE_NAME',
  PROJECT_NAME: 'PROJECT_NAME',
  REVIEW_DURATION: 'REVIEW_DURATION',
  OVERDUE: 'OVERDUE',
  STATUS: 'STATUS',
  RATING: 'RATING',
  DEFAULT: 'DEFAULT',
  REVIEWEE: 'REVIEWEE',
  REVIEWER: 'REVIEWER',
  REQUESTER: 'REQUESTER',
  ROLE: 'ROLE',
  REQUEST_DATE: 'REQUEST_DATE',
};

export const DEFAULT_CYCLES_TABLE_INFO = {
  ...DEFAULT_TABLE_PAGINATION,
  sortColumn: SORT_FIELD_DASHBOARD.DEFAULT,
  sortOrder: SORT_DIRECTION_UPPER_CASE.DESC,
};
