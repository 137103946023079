import { Select } from 'antd';
import { get, uniqWith } from 'lodash';
import React, { useMemo } from 'react';
import './index.less';
import { IProjectSelectProps, IV2Project } from '@@/_new_src_/types/common';
import cls from 'classnames';
import { isEmptyOrNil } from '@@/_new_src_/utils';

const { Option } = Select;

const ProjectSelect = (props: IProjectSelectProps) => {
  const {
    placeholder,
    optionList,
    notFoundContent,
    value,
    bordered,
    showArrow = false,
    onChange,
    dropdownClassName = '',
    allowClear = false,
  } = props;

  const newOptionsList = useMemo(() => {
    return uniqWith(optionList, (prevOption, nextOption) => {
      return prevOption.name === nextOption.name;
    });
  }, [optionList]);

  const handleChange = (selectProject: string) => {
    const curSelectProject = !isEmptyOrNil(selectProject)
      ? (JSON.parse(selectProject) as IV2Project)
      : undefined;
    onChange && onChange(curSelectProject);
  };

  const name = get(value, 'name', '');
  return (
    <Select
      size="large"
      allowClear={allowClear}
      bordered={bordered}
      showArrow={showArrow}
      value={name}
      placeholder={placeholder}
      onChange={handleChange}
      notFoundContent={notFoundContent}
      className="project-select-wrapper"
      popupClassName={cls('project-select-dropdown', dropdownClassName)}
      aria-label={'Project input'}
      aria-expanded="false"
      suffixIcon={showArrow ? <i className="ri-arrow-down-s-fill arrow-icon" /> : null}
    >
      {newOptionsList.map((item, index) => (
        <Option key={index} value={JSON.stringify(item)}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default ProjectSelect;
