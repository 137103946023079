import React, { useEffect, useMemo } from 'react';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import { useDispatch, useSelector } from 'react-redux';

import {
  nonEngagedCycles,
  setAssessmentNonEngaged,
  setCurrentTab,
  setExpectationNonEngaged,
  setOverdue,
} from '@@/_new_src_/store/nonEngagedCyclesSlice';
import { getNonEngagedCyclesAsync } from '@@/_new_src_/store/nonEngagedCyclesSlice/asyncThunk';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import { NON_ENGAGED_CYCLES_TAB_INDEX } from '@@/_new_src_/constants/overdueAndNonEngaged';
import { IFilterInfo } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';

import Tip from '@@/_new_src_/components/Tip';
import UserInfo from '@@/_new_src_/features/UserInfo';

import FilterArea from '@@/_new_src_/page/nonEngagedCycles/component/FilterArea';
import { omit } from 'lodash';
import List from '@@/_new_src_/page/nonEngagedCycles/component/list';
import './index.less';

const NonEngagedCyclesContainer = () => {
  const dispatch = useDispatch();
  const {
    nonEngagedCycles: { tabName, expectationNonEngaged: expectationNonEngagedLocale },
  } = nonEngagedCyclesLocale;

  const { currentTab, overdue, assessmentNonEngaged, expectationNonEngaged, loading } =
    useSelector(nonEngagedCycles);

  const getCurrentFilterInfo = useMemo(() => {
    switch (currentTab) {
      case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
        return assessmentNonEngaged;
      case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
        return expectationNonEngaged;
      default:
        return overdue;
    }
  }, [currentTab, assessmentNonEngaged, expectationNonEngaged, overdue]);

  const updateFilterInfo = (info: IFilterInfo) => {
    switch (currentTab) {
      case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
        dispatch(setAssessmentNonEngaged(info));
        return;
      case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
        dispatch(setExpectationNonEngaged(info));
        return;
      default:
        dispatch(setOverdue(info));
    }
  };

  const findCycleNonEngagedCycles = (params?: IFilterInfo) => {
    const currentParams = params ? params : getCurrentFilterInfo;
    const paramsInfo = {
      ...omit(currentParams, 'selectUsers', 'tableInfo'),
      employeeIds: currentParams.selectUsers.map(item => item.value),
      ...currentParams.tableInfo,
    };
    dispatch(getNonEngagedCyclesAsync(paramsInfo));
  };

  useEffect(() => {
    findCycleNonEngagedCycles();
  }, [currentTab]);

  const changeTab = (value: string) => {
    dispatch(setCurrentTab(value));
  };

  const renderNonEngagedCyclesContainer = () => {
    return (
      <div className={'non-engaged-cycles-container'}>
        <FilterArea
          findCycleNonEngagedCycles={findCycleNonEngagedCycles}
          getCurrentFilterInfo={getCurrentFilterInfo}
          updateFilterInfo={updateFilterInfo}
        />
        {currentTab === NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED && (
          <div className={'tip-block'}>
            <Tip
              wording={expectationNonEngagedLocale.tip}
              className={'expectation-non-engaged-tip'}
            />
          </div>
        )}
        <List
          findCycleNonEngagedCycles={findCycleNonEngagedCycles}
          getCurrentFilterInfo={getCurrentFilterInfo}
          updateFilterInfo={updateFilterInfo}
        />
      </div>
    );
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className="non-engaged-cycles-page">
        <UserInfo />
        <div className="non-engaged-cycles-main">
          <TabPaneComponent
            tabList={[
              tabName.OverdueCycles,
              tabName.assessmentNonEngagedCycles,
              tabName.CyclesWithTemplateExpectations,
            ]}
            children={[
              renderNonEngagedCyclesContainer(),
              renderNonEngagedCyclesContainer(),
              renderNonEngagedCyclesContainer(),
            ]}
            activeKey={currentTab}
            updateActiveKey={changeTab}
            size={'large'}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default NonEngagedCyclesContainer;
