import React from 'react';
import './index.less';
import cls from 'classnames';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';
import { IOtherStatusProps } from '@@/_new_src_/types/cycle';

const StatusTips = (props: IOtherStatusProps) => {
  const { status, statusTips = '', statusWords = '' } = props;

  const { isOpenRequestToEdit, isApprovedRequestToEdit, isRejectedRequestToEdit } =
    getRequestToEditStatus(status);

  return (
    <div className="request-status-tips">
      {statusTips && (
        <div
          className={cls('alert-info', {
            'status-open': isOpenRequestToEdit,
            'status-approved': isApprovedRequestToEdit,
            'status-rejected': isRejectedRequestToEdit,
          })}
        >
          <i
            className={cls('', {
              'ri-information-fill icon-open': isOpenRequestToEdit,
              'ri-error-warning-fill icon-rejected': isRejectedRequestToEdit,
              'ri-checkbox-circle-fill icon-approved': isApprovedRequestToEdit,
            })}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: statusTips,
            }}
          />
        </div>
      )}
      {statusWords && <div className="wording" dangerouslySetInnerHTML={{ __html: statusWords }} />}
    </div>
  );
};

export default StatusTips;
