import React, { useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { RolesOfManagement, UnitGroup } from '@@/types/admin';
import { RolesOfManagementContext } from '@@/context/rolesOfManagement.context';
import { getRolesOfManagement } from '@@/_new_src_/api/admin';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import LevelPanel from '../LevelPanel';
import './index.less';

interface RolesOfManagementResponse {
  data: RolesOfManagement;
}

export interface UnitGroupListResponse {
  data: UnitGroup[];
}

interface ManageRegionalRolesTabProps {
  regionId: number;
  regionName: string | null;
}

const ManageRegionalRolesTab = (props: ManageRegionalRolesTabProps) => {
  const { regionId, regionName } = props;
  const { updateRolesOfManagement } = useContext(RolesOfManagementContext);
  const {
    run: runGetRolesOfManagement,
    data: rolesOfManagement,
    loading: getRolesOfManagementLoading,
  } = useRequest(() => getRolesOfManagement(), {
    formatResult: (res: RolesOfManagementResponse) => res.data,
    manual: true,
    onSuccess: () => {
      updateRolesOfManagement(rolesOfManagement);
    },
  });

  useEffect(() => {
    const get: () => void = async () => {
      await runGetRolesOfManagement();
    };
    get();
  }, [regionId, runGetRolesOfManagement]);

  return (
    <div className="manage-regional-roles-tab-container">
      <LoadingWrapper wrapperClassName="modal-loading" loading={getRolesOfManagementLoading}>
        <LevelPanel
          rolesOfManagement={rolesOfManagement as RolesOfManagement}
          regionName={regionName as string}
          getRolesOfManagement={runGetRolesOfManagement}
          regionId={regionId}
        />
      </LoadingWrapper>
    </div>
  );
};

export default ManageRegionalRolesTab;
