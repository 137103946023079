export const CREATE_CYCLE = 'create_cycle';
export const SEND_REQUEST = 'send_request';
export const SEND_V2_FEEDBACK_REQUEST = 'send_v2_feedback_request';
export const SUBMIT_FEEDBACK = 'submit_feedback';
export const WRITE_ASSESSMENT = 'write_assessment';
export const ADD_CHECK_INS = 'add_check_ins';
export const READ_FEEDBACK = 'read_feedback';
export const V2_READ_FEEDBACK = 'v2_read_feedback';
export const PP_CHECK_CYCLE = 'pp_check_cycle';
export const AP_CHECK_CYCLE = 'ap_check_cycle';
