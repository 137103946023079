import React from 'react';

export interface IUserInfoContext {
  userId: number | null;
  departmentId: string | null;
  name: string;
  gender: string | null;
  homeOffice: string;
  homeCountry: string;
  department: string | null;
  role: string;
  grade: string | null;
  email: string;
  hireDate: string;
  updateUserInfo: () => void;
  userRolesInfoInV2: Array<{
    role: string;
    roleHierarchy: string;
    hierarchyId: number;
    hierarchyName: string;
  }>;
  userRoleInfoInV2GlobalFunctionAdmin: Array<{
    role: string;
    roleHierarchy: string;
    hierarchyId: number;
    hierarchyName: string;
  }>;
  probationStatus: string;
  passedProbationDate: string | null;
  futureCycleVersion: number;
  currentCycleVersion: number;
  currentOpeningCycleId: number;
}
export const UserInfoContext = React.createContext({} as IUserInfoContext);
