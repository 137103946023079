import React, { Component } from 'react';
import { Button } from 'antd';
import NotFoundImage from './images/whale.jpg';
import './component.less';

class NotFoundPageContent extends Component {
  render() {
    return (
      <div>
        <div className="page">
          <div className="page-not-found-container">
            <div className="message">
              <h1>
                <strong>404 - YOU FOUND A SECRET PAGE!</strong>
              </h1>
              <p className="sub-message">
                {`But as it's probably`}
                <br />
                <strong>not the page you were looking for,</strong>
                <br />
                {`here's a picture of everyone's favorite whale.`}
                <br />I hope it makes up for it.
              </p>
              <a
                href="http://en.wikipedia.org/wiki/List_of_minor_The_Hitchhiker's_Guide_to_the_Galaxy_characters#Whale"
                target="blank_"
              >
                <img src={NotFoundImage} className="error-icon" alt="" />
              </a>
            </div>
            <div className="action-buttons">
              <Button className="btn-back" onClick={this.props.goBack}>
                Go back
              </Button>
              <Button className="btn-home" onClick={this.props.goHome}>
                Go to home page
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPageContent;
