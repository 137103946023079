import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProject } from '@@/_new_src_/store/commonSlice/interface';
import { getProjectListByUserId, getSignedUrl, getUserProjectList } from '@@/_new_src_/api/common';

export const getSignedUrlAsync = createAsyncThunk('getAttachmentSignedUrl', async (url: string) => {
  const response = await getSignedUrl(url);
  return response.data as string;
});

export const getProjectListAsync = createAsyncThunk('getProjectList', async () => {
  const response = (await getUserProjectList()) as { data: Array<IProject> };
  return response.data;
});

export const getProjectListByUserIdAsync = createAsyncThunk(
  'getProjectListByUserId',
  async (twerId: number) => {
    const response = (await getProjectListByUserId(twerId)) as { data: Array<IProject> };
    return response.data;
  },
);
