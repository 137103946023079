import { setVersionCheck } from '@@/api/version';
import ProductUpdateModal from '@@/common/component/ProductUpdateModal';
import { UPDATE_VERSION_TIME } from '@@/common/constant/dashboard';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  selectIsVersionCheck,
  selectIsVisibleProductUpdateModal,
  setIsVersionCheck as setIsVersionCheckAction,
  setIsVersionTooltipVisible as setIsVersionTooltipVisibleAction,
  setIsVisibleProductUpdateModal as setIsVisibleProductUpdateModalAction,
} from '@@/redux/slice/versionSlice';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const ProductUpdateModalContainer = props => {
  const {
    userInfo: { homeCountry },
  } = props;
  const isVisibleProductUpdateModal = useSelector(selectIsVisibleProductUpdateModal);
  const isVersionCheck = useSelector(selectIsVersionCheck);
  const localeResource = useSelector(selectLocaleResource);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setIsVisibleProductUpdateModalAction(false));
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    if (!isVersionCheck) {
      setVersionCheck(UPDATE_VERSION_TIME);
      dispatch(setIsVersionTooltipVisibleAction(true));
      dispatch(setIsVersionCheckAction(true));
    }
  }, [dispatch, isVersionCheck]);

  return (
    <>
      {isVisibleProductUpdateModal ? (
        <ProductUpdateModal
          locale={localeResource.ProductUpdateModal}
          isChecked={isVersionCheck}
          onClose={onClose}
          onModalClose={onCloseModal}
          homeCountry={homeCountry}
        />
      ) : (
        ''
      )}
    </>
  );
};
