import DefaultPage from './DefaultPage';
import { CustomLoginCallback } from '@@/common/component/CustomLoginCallback';
import AttachmentPage from './attachment';
import NewAttachmentPage from './newAttachmentPage';

export default {
  path: '/',
  name: 'Home',
  childRoutes: [
    {
      path: 'default-page',
      name: 'Default page',
      component: DefaultPage,
      isIndex: true,
    },
    {
      path: 'implicit/callback',
      name: 'implicitCallback',
      component: CustomLoginCallback,
      isIndex: true,
    },
    {
      path: 'attachment/:filename',
      name: 'attachment',
      component: AttachmentPage,
    },
    {
      path: 'v2/attachment/:filename',
      name: 'attachment',
      component: NewAttachmentPage,
    },
  ],
};
