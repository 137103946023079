/* eslint-disable camelcase */
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import en_US from './language/en_US';
import zh_CN from './language/zh_CN';

export const LOCALE_RESOURCES = {
  zh_CN,
  en_US,
};

export const ANTD_LOCALE_RESOURCES = {
  en_US: enUS,
  zh_CN: zhCN,
};

const DEFAULT_LOCALE = 'en_US';
const LOCALE_KEY = 'locale';

export const getLocale = () => localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;
export const setLocale = locale => localStorage.setItem(LOCALE_KEY, locale);
export const getLocaleResource = locale => LOCALE_RESOURCES[locale || getLocale()];
