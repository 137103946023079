import React from 'react';
import { useHistory } from 'react-router-dom';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import NotFoundPageContent from './component';

const NotFoundPageForRouteError = () => {
  const history = useHistory();
  function goBack() {
    history.goBack();
  }

  function goHome() {
    history.replace(MENU_PAGE_LINK.LANDING_PAGE);
  }

  return <NotFoundPageContent goBack={() => goBack()} goHome={() => goHome()} />;
};

export default NotFoundPageForRouteError;
