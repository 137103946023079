import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IAdminColumn, IAdminColumType } from '@@/types/admin';
import { UserInfo } from '@@/types/user';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { localAdmin } from '../../../common/interface';
import { ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { deleteAdminByType } from '@@/_new_src_/api/admin';

interface ISystemAdminTable {
  adminList: Array<IAdminColumn> | undefined;
  runGetAdminList: (currentAdminType: IAdminColumType) => Promise<IAdminColumn[]>;
  adminListType: IAdminColumType;
  isLoadingAdminListData: boolean;
}

const SystemAdminTable = (props: ISystemAdminTable) => {
  const { trackEvent } = useMatomo();
  const { adminList, runGetAdminList, adminListType, isLoadingAdminListData } = props;
  const { normalUserInfo, userInfo } = useUserRoleInfo();
  const { updateUserInfo } = userInfo as UserInfo;

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: { manageAdminRole: adminLocale },
  } = localeResource as localAdmin;

  const currentAdminListTypeEvent = (adminListType: string) => {
    const {
      SYSTEM_ADMIN_REMOVES_SYSTEM_ADMIN,
      SYSTEM_ADMIN_REMOVES_REGIONAL_ADMIN,
      SYSTEM_ADMIN_REMOVES_OPS_ADMIN,
    } = ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.action;

    switch (adminListType) {
      case USER_ROLE_ENUM.GLOBAL_ADMIN:
        return SYSTEM_ADMIN_REMOVES_SYSTEM_ADMIN;
      case USER_ROLE_ENUM.PS_ADMIN:
        return SYSTEM_ADMIN_REMOVES_REGIONAL_ADMIN;
      default:
        return SYSTEM_ADMIN_REMOVES_OPS_ADMIN;
    }
  };

  const onRemoveAdmin = useCallback(
    (id: number) => {
      const {
        deleteAdminConfirmModal: { cancelText, okText, content, successMessage },
      } = adminLocale;

      const closeModal = perfModalConfirm({
        cancelText,
        okText,
        content: <div className="delete-modal-content">{content}</div>,
        onOk: async () => {
          trackEvent({
            category: ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.category,
            action: currentAdminListTypeEvent(adminListType),
          });

          await deleteAdminByType(id, adminListType).finally(() => closeModal());
          perfMessage.success(successMessage(adminListType));
          await runGetAdminList(adminListType);
          updateUserInfo();
        },
        centered: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel: () => {},
      });
    },
    [adminListType, adminLocale, runGetAdminList, updateUserInfo],
  );

  const columnKeyMapping = {
    GLOBAL_ADMIN: ['name', 'addedBy', 'addedAt', 'operation'],
    PS_ADMIN: ['name', 'region', 'addedBy', 'addedAt', 'operation'],
    'OPs Admin': ['name', 'addedBy', 'addedAt', 'operation'],
  };

  const adminListColumns: ColumnsType<IAdminColumn> = [
    {
      title: adminLocale.adminTableColumn.name,
      key: 'name',
      dataIndex: 'name',
      ellipsis: { showTitle: false },
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Region',
      key: 'region',
      dataIndex: 'region',
      ellipsis: { showTitle: false },
      render: (region: string) => (
        <Tooltip placement="topLeft" title={region}>
          {region}
        </Tooltip>
      ),
    },
    {
      title: adminLocale.adminTableColumn.addedBy,
      key: 'addedBy',
      dataIndex: 'addedBy',
      render: (addedBy: string) => (
        <Tooltip placement="topLeft" title={addedBy}>
          {addedBy}
        </Tooltip>
      ),
    },
    {
      title: adminLocale.adminTableColumn.addedAt,
      key: 'addedAt',
      dataIndex: 'addedAt',
      render: (addedAt: string) => dayjs(addedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: adminLocale.adminTableColumn.actions,
      width: 150,
      key: 'operation',
      render: ({ id, userId }: { id: number; userId: number }) => {
        if (
          adminListType === USER_ROLE_ENUM.GLOBAL_ADMIN &&
          userId === normalUserInfo?.hierarchyId
        ) {
          return null;
        }
        return (
          <PerfTextButton
            color="red"
            className="operation-remove"
            onClick={() => onRemoveAdmin(id)}
          >
            {adminLocale.deleteAction}
          </PerfTextButton>
        );
      },
    },
  ].filter(i => columnKeyMapping[adminListType].includes(i.key));

  return (
    <>
      <Table
        dataSource={adminList}
        columns={adminListColumns}
        loading={isLoadingAdminListData}
        rowKey={record => record.id}
        rowClassName={(_, index) => `admin-row-${index}`}
        pagination={false}
      />
    </>
  );
};

export default memo(SystemAdminTable);
