import { PanelType } from '@@/_new_src_/constants/adminEnum';
import { GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN } from '@@/common/constant/matomo';
import { localAdmin } from '../../common/interface';
import { ModalInfoParams } from '../../type';
import {
  getUserListForGlobalFunctionServiceLineEGLT,
  getUserListForGlobalFunctionServiceLinePeopleTeam,
} from '@@/_new_src_/api/admin';
import './index.less';

const {
  action: { GLOBAL_ROLE_ADDS_MEMBER_TO_GLOBAL_PEOPLE, GLOBAL_ROLE_ADDS_MEMBER_TO_EGLT_GROUP },
} = GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN as {
  action: {
    GLOBAL_ROLE_ADDS_MEMBER_TO_GLOBAL_PEOPLE: string;
    GLOBAL_ROLE_ADDS_MEMBER_TO_EGLT_GROUP: string;
  };
};

const modalInfo: (data: ModalInfoParams) => Record<string, unknown> = ({
  locale,
  visible,
  onClose,
  handleSubmit,
  submitLoading,
  formatSearchData,
}: ModalInfoParams) => {
  const { admin } = locale as localAdmin;

  return {
    [PanelType.GLOBAL_MANAGEMENT_TEAM]: {
      modalInfo: {
        key: 'assign-eglt-modal',
        visible: visible,
        modalTitle:
          admin.manageGlobalFunctionServiceLineRole.addPeopleIntoExtendedGlobalLeadershipTeam,
        searchInput: {
          label: admin.name,
          placeholder: admin.enterGroupMemberName,
          fetchApi: getUserListForGlobalFunctionServiceLineEGLT,
          isRequire: true,
          errorMessage: { require: admin.pleaseSelectGroupMemberName },
          notFoundText: admin.nameNotFound,
          mode: 'multiple',
          formatSearchData,
        },
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        modalClassName: 'add-eglt-modal',
        addEventMatomo: GLOBAL_ROLE_ADDS_MEMBER_TO_EGLT_GROUP,
      },
    },
    [PanelType.GLOBAL_PEOPLE_TEAM]: {
      modalInfo: {
        key: 'assign-global-people-team-modal',
        visible: visible,
        modalTitle: admin.manageGlobalFunctionServiceLineRole.addPeopleIntoGlobalPeopleTeam,
        searchInput: {
          label: admin.name,
          placeholder: admin.enterGroupMemberName,
          fetchApi: getUserListForGlobalFunctionServiceLinePeopleTeam,
          isRequire: true,
          errorMessage: { require: admin.pleaseSelectGroupMemberName },
          notFoundText: admin.nameNotFound,
          mode: 'multiple',
          formatSearchData,
        },
        customButtons: {
          cancelButton: { handleClick: onClose },
          okButton: { handleClick: handleSubmit, loading: submitLoading },
        },
        addEventMatomo: GLOBAL_ROLE_ADDS_MEMBER_TO_GLOBAL_PEOPLE,
      },
    },
  };
};

export default modalInfo;
