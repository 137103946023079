import React from 'react';
import './index.less';
import { CATEGORY_LABEL } from '@@/_new_src_/constants/myCycles';
import { orderBy } from 'lodash';

interface IExpectationLabelProps {
  title: string;
  categoryIds: number[];
}

const ExpectationLabel = (props: IExpectationLabelProps) => {
  const { title, categoryIds } = props;
  const orderByName = orderBy(categoryIds, item => CATEGORY_LABEL[item].title);

  return (
    <div className="relevant-cycle-expectations">
      <div className="cycle-expectation-area">
        <div className="category-header">{title}</div>
        <div className="category-labels">
          {orderByName.map((item, index) => {
            const { iconBackGroundColor, icon, contentBackGroundColor, title } =
              CATEGORY_LABEL[item];
            return (
              <div className="label" key={index}>
                <div
                  className="label-icon"
                  style={{
                    backgroundColor: iconBackGroundColor,
                  }}
                  dangerouslySetInnerHTML={{ __html: icon }}
                />
                <div
                  className="label-content"
                  style={{
                    backgroundColor: contentBackGroundColor,
                  }}
                >
                  <div>{title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExpectationLabel;
