import React, { useEffect, useState } from 'react';
import StatusTips from '@@/_new_src_/features/StatusTips';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { dealRequestData, setDealRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';

import './index.less';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import ReasonInput from '@@/_new_src_/components/ReasonInput';
import {
  getEditRequestByRequestIdAsync,
  patchEditRequestByRequestIdAsync,
} from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { REQUEST_TO_EDIT_STATUS } from '@@/_new_src_/constants/requestToEditStatus';
import { formatTimeFun } from '@@/_new_src_/utils/common/date';

const DealRequest = () => {
  const dispatch = useDispatch();

  const {
    requestToEdit: {
      title: titleLocale,
      dealRequest: dealRequestLocale,
      dealRequest: { statusWords: statusWordsLocale, statusTips: statusTipsLocale },
      confirmModalProps: confirmModalPropsLocale,
    },
  } = cycleDetailLocale;

  const {
    id: requestId,
    status,
    requestedAt,
    rejectReason,
    rejectedAt,
    approvedAt,
    requester,
    requestReason,
    isPatchEditRequestLoading,
    isPatchEditRequestSuccess,
    isGetEditRequestLoading,
  } = useSelector(dealRequestData);

  const [currentReason, setCurrentReason] = useState('');
  const [isRejecting, setIsRejecting] = useState(false);

  useEffect(() => {
    isPatchEditRequestSuccess && requestId && dispatch(getEditRequestByRequestIdAsync(requestId));
  }, [isPatchEditRequestSuccess, requestId]);

  const { isOpenRequestToEdit, isApprovedRequestToEdit, isRejectedRequestToEdit } =
    getRequestToEditStatus(status);

  const getCurrentStatusTips = () => {
    const currentPendingTips =
      isOpenRequestToEdit && statusTipsLocale.openTips(requester, formatTimeFun(requestedAt));
    const currentApprovedTips =
      isApprovedRequestToEdit &&
      statusTipsLocale.approvedTips(
        requester,
        formatTimeFun(requestedAt),
        formatTimeFun(approvedAt),
      );
    const currentRejectedTips =
      isRejectedRequestToEdit &&
      statusTipsLocale.rejectedTips(
        requester,
        formatTimeFun(requestedAt),
        formatTimeFun(rejectedAt),
        rejectReason,
      );
    return currentPendingTips || currentApprovedTips || currentRejectedTips || '';
  };

  const getCurrentStatusWords = () => {
    return (isOpenRequestToEdit && statusWordsLocale.openStatus(requestReason)) || '';
  };

  const handleConfirmOkFunc = () => {
    dispatch(setConfirmModalStatus(false));
    setCurrentReason('');
    setIsRejecting(false);
  };

  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
  };
  const confirmModalProps = () => {
    const { title, content, cancelText, okText } = confirmModalPropsLocale;
    return {
      title: title,
      content: content,
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
      handleCancelFunc: handleConfirmCancelFunc,
    };
  };

  const getCurrentReasonFun = (reason: string) => {
    setCurrentReason(reason);
  };

  const dealRequestFun = (dealStatus: string) => {
    !isPatchEditRequestLoading &&
      requestId &&
      dealStatus &&
      dispatch(
        patchEditRequestByRequestIdAsync({
          requestId: requestId,
          status: dealStatus,
          reason: dealStatus === REQUEST_TO_EDIT_STATUS.APPROVED ? null : currentReason,
        }),
      );
  };

  const rejectRequestFun = () => {
    dealRequestFun(REQUEST_TO_EDIT_STATUS.REJECTED);
    setIsRejecting(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setDealRequestData({}));
    };
  }, []);

  return (
    <>
      {!isGetEditRequestLoading && (
        <div className={'deal-request-wrapper'}>
          <h3 className={'title'}>{titleLocale}</h3>
          <div className={'deal-request-detail'}>
            <StatusTips
              status={status}
              statusTips={getCurrentStatusTips()}
              statusWords={getCurrentStatusWords()}
            />
            {!isRejecting && isOpenRequestToEdit && (
              <div className={'btns'}>
                <button
                  className={'reject-btn'}
                  onClick={() => {
                    setIsRejecting(true);
                  }}
                >
                  <i className="ri-close-circle-line btn-icon" />
                  <span className="btn-text"> {dealRequestLocale.rejectBtn}</span>
                </button>
                <button
                  className={'approve-btn'}
                  onClick={() => {
                    dealRequestFun(REQUEST_TO_EDIT_STATUS.APPROVED);
                  }}
                >
                  <i className="ri-checkbox-circle-line btn-icon" />
                  <span className="btn-text">{dealRequestLocale.approveBtn}</span>
                </button>
              </div>
            )}

            {isRejecting && isOpenRequestToEdit && (
              <ReasonInput
                label={dealRequestLocale.label}
                onChangeReason={getCurrentReasonFun}
                confirmReasonFun={rejectRequestFun}
                confirmBtnText={dealRequestLocale.confirmBtnText}
              />
            )}

            <ConfirmModal {...confirmModalProps()} />
          </div>
        </div>
      )}
    </>
  );
};
export default DealRequest;
