import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getModulePathArray } from '@@/_new_src_/utils/common/auth';
import './index.less';

const CustomPrompt = props => {
  const { when, onCancel } = props;
  const history = useHistory();
  const { confirmLeavePageModal: locale } = useSelector(selectLocaleResource);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedLeave, setConfirmedLeave] = useState(false);

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedLeave && getModulePathArray().includes(nextLocation.pathname)) {
      setLastLocation(nextLocation);
      setIsModalVisible(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedLeave && lastLocation) {
      if (onCancel) {
        onCancel();
      }
      history.push(lastLocation);
    }
  }, [confirmedLeave, history, lastLocation, onCancel]);

  const leave = () => {
    setConfirmedLeave(true);
  };

  const stay = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        className="leave-page-modal"
        open={isModalVisible}
        closeIcon={<CloseOutlined className="close-icon" />}
        title={locale.titleText}
        autoFocusButton={null}
        onCancel={stay}
        footer={null}
        centered
      >
        <div className="content-text">{locale.contentText}</div>
        <div className="button-container">
          <Button onClick={leave}>{locale.leaveText}</Button>
          <Button type="primary" onClick={stay}>
            {locale.stayText}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CustomPrompt;
