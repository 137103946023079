import DarkTreeSelect from '@@/common/component/DarkTreeSelect';
import { ALL_ACCOUNT_UNIT, ALL_GROUPS_UNIT, ALL_PEOPLE_UNIT } from '@@/common/constant/dashboard';
import { stringify } from '@@/common/utils/qs';
import { setV2CurrentGroupId, setV2CurrentRoleInfo } from '@@/hooks/useLocalStorageV2Dashboard';
import {
  clearGroupSelection,
  getGroupList,
  setCurrentGroupSelection,
  setDisplayGroupSelect,
} from '@@/redux/slice/dashboardSlice';
import { find, isEmpty } from 'lodash';
import * as queryString from 'query-string';
import { default as React, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../reviewees/detail/index.less';
import { DASHBOARD_CYCLES_PATH, SINGLE_ACCOUNT } from '../common/constants';
import './index.less';
import { ALL_ACCOUNT_GROUPS, SINGLE_ACCOUNT_GROUPS } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const DashboardHeaderGroupSelect = () => {
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentRoleSelection = useSelector(state => state.dashboard.currentRoleSelection);
  const groupList = useSelector(state => state.dashboard.groupList);
  const displayGroupSelect = useSelector(state => state.dashboard.displayGroupSelect);

  const [currentGroupId, setCurrentGroupId] = useState('');
  const [currentGroupType, setCurrentGroupType] = useState('');

  if (isEmpty(groupList)) {
    return;
  }

  const { groupOptionList, groupKeyList } = groupList;
  const { roleId, roleName, roleInfo } = currentRoleSelection;
  const getGroupCombinedId = (groupId, groupType) => {
    if (groupType === ALL_GROUPS_UNIT) {
      return `${groupId}-_${ALL_GROUPS_UNIT}`;
    }
    if (groupType === ALL_PEOPLE_UNIT) {
      return `${groupId}-_${ALL_PEOPLE_UNIT}`;
    }
    if (groupType === ALL_ACCOUNT_UNIT) {
      return `${groupId}-_${ALL_ACCOUNT_UNIT}`;
    }
    if (groupType === SINGLE_ACCOUNT) {
      return `${groupId}-_${SINGLE_ACCOUNT}`;
    }
    return groupId;
  };

  const updateRoleAndGroupLocalStorage = (groupId, groupType, roleName) => {
    setV2CurrentGroupId(getGroupCombinedId(groupId, groupType));
    setV2CurrentRoleInfo(roleName);
  };

  const goDashboardCyclesPage = (groupId, groupType) => {
    const params = {
      selectedRole: roleInfo,
      selectedGroupId: groupId,
    };
    if (groupType) {
      params.selectedGroupType = groupType;
    }
    history.replace({
      pathname: DASHBOARD_CYCLES_PATH,
      search: stringify(params),
    });
  };

  useEffect(() => {
    dispatch(clearGroupSelection());
    if (!roleInfo) {
      return;
    }
    async function getAllGroupList() {
      try {
        dispatch(getGroupList(currentRoleSelection));
      } catch (e) {
        console.error(e);
      }
    }
    getAllGroupList().then();
  }, [roleInfo]);

  useEffect(() => {
    if (isEmpty(groupOptionList)) {
      return;
    }
    const firstItemValue = groupOptionList[0]?.value;
    if (!firstItemValue) {
      return;
    }

    let [firstGroupId, firstGroupType = ''] = firstItemValue.toString().split('-_');
    const { search } = location;
    if (!isEmpty(search)) {
      const { selectedGroupId = '', selectedGroupType = '' } = queryString.parse(search);
      if (!isEmpty(selectedGroupId)) {
        const selectedId = getGroupCombinedId(selectedGroupId, selectedGroupType);
        const selectedGroupIdExist = find(groupKeyList, id => {
          return id === selectedId;
        });
        if (selectedGroupIdExist) {
          firstGroupId = selectedGroupId;
          firstGroupType = selectedGroupType;
        }
      }
    }

    dispatch(setDisplayGroupSelect(true));
    dispatch(
      setCurrentGroupSelection({
        groupId: firstGroupId,
        groupType: firstGroupType,
      }),
    );
    const params = {
      selectedRole: roleInfo,
      selectedGroupId: firstGroupId,
    };
    if (firstGroupType) {
      params.selectedGroupType = firstGroupType;
    }
    history.replace({
      search: stringify(params),
    });
    setCurrentGroupId(firstGroupId);
    setCurrentGroupType(firstGroupType);
  }, [groupOptionList]);

  useEffect(() => {
    if (!roleId || !currentGroupId) {
      return;
    }
    window.scrollTo(0, 0);
    updateRoleAndGroupLocalStorage(currentGroupId, currentGroupType, roleName);
  }, [currentGroupId, currentGroupType, roleName]);

  const handleGroupChange = groupId => {
    const [selectedGroupId = groupId, selectedGroupType = ''] =
      groupId && groupId.toString().split('-_');
    goDashboardCyclesPage(selectedGroupId, selectedGroupType);
    dispatch(
      setCurrentGroupSelection({
        groupId: selectedGroupId,
        groupType: selectedGroupType,
      }),
    );
    if (selectedGroupType === SINGLE_ACCOUNT) {
      trackEvent({
        category: SINGLE_ACCOUNT_GROUPS.category,
        action: SINGLE_ACCOUNT_GROUPS.action,
      });
    }
    if (selectedGroupType === ALL_ACCOUNT_UNIT) {
      trackEvent({
        category: ALL_ACCOUNT_GROUPS.category,
        action: ALL_ACCOUNT_GROUPS.action,
      });
    }
    setCurrentGroupId(selectedGroupId);
    setCurrentGroupType(selectedGroupType);
  };

  const emptyGroupTreeSelect = <DarkTreeSelect value={undefined} key="dashboard-group-empty" />;

  let treeSelectAttributes = {
    treeDefaultExpandAll: false,
  };

  const treeSelectedValue = getGroupCombinedId(currentGroupId, currentGroupType);
  const groupTreeSelect = (
    <DarkTreeSelect
      key="dashboard-group-has-data"
      value={treeSelectedValue}
      treeData={groupOptionList}
      onChange={handleGroupChange}
      treeSelectAttributes={treeSelectAttributes}
    />
  );

  return (
    <div className="dashboard-header-group-select">
      {displayGroupSelect ? groupTreeSelect : emptyGroupTreeSelect}
    </div>
  );
};

export default memo(DashboardHeaderGroupSelect);
