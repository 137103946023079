import React from 'react';
import './index.less';

interface IBreadCrumbProps {
  firstLevelMenu: string;
  secondLevelMenu: string;
}

const BreadCrumb = (props: IBreadCrumbProps) => {
  const { firstLevelMenu, secondLevelMenu } = props;

  return (
    <>
      {firstLevelMenu && (
        <div className="bread-crumb">
          <div className="indicator">
            {firstLevelMenu}&nbsp;&nbsp;&gt;&nbsp;&nbsp;{secondLevelMenu}
          </div>
        </div>
      )}
    </>
  );
};
export default BreadCrumb;
