import { createSlice } from '@reduxjs/toolkit';

export const checkInNotesSlice = createSlice({
  name: 'checkInNotes',
  initialState: {
    checkInNotesModalVisibleInMyRevieweePage: undefined,
    checkInNotesModalVisibleInMyReviewPage: undefined,
    notesInMyReviewPage: undefined,
    isEditMode: undefined,
    currentCheckInNote: undefined,
  },
  reducers: {
    setCheckInNotesModalVisibleInMyRevieweePage: (state, action) => {
      state.checkInNotesModalVisibleInMyRevieweePage = action.payload;
    },
    setCheckInNotesModalVisibleInMyReviewPage: (state, action) => {
      state.checkInNotesModalVisibleInMyReviewPage = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setCurrentCheckInNote: (state, action) => {
      state.currentCheckInNote = action.payload;
    },
  },
});

export const {
  setCheckInNotesModalVisibleInMyRevieweePage,
  setCheckInNotesModalVisibleInMyReviewPage,
  setIsEditMode,
  setCurrentCheckInNote,
} = checkInNotesSlice.actions;
export const selectCheckInNotesModalVisibleInMyRevieweePage = state =>
  state.checkInNotes.checkInNotesModalVisibleInMyRevieweePage;
export const selectCheckInNotesModalVisibleInMyReviewPage = state =>
  state.checkInNotes.checkInNotesModalVisibleInMyReviewPage;
export const selectIsEditMode = state => state.checkInNotes.isEditMode;
export const selectCurrentCheckInNote = state => state.checkInNotes.currentCheckInNote;

export default checkInNotesSlice.reducer;
