import React from 'react';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { getAttachmentStorageUrl } from '@@/api/review';
import './index.less';

const ERROR_CODE_MAP = {
  403: '403 Access Denied',
  404: 'The file does not exist',
};

const AttachmentPage = () => {
  const { filename } = useParams();
  const { error, loading } = useRequest(() => getAttachmentStorageUrl(filename, false), {
    onSuccess: response => {
      window.location.href = response.data;
    },
  });

  return (
    <div className="attachment-page-container">
      <LoadingWrapper loading={loading}>
        {ERROR_CODE_MAP[error?.error.response.status]}
      </LoadingWrapper>
    </div>
  );
};

export default AttachmentPage;
