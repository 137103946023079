import React, { memo, useContext } from 'react';
import { InfoCircleFilled, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cls from 'classnames';
import './index.less';
import { map, size } from 'lodash';
import { ReviewStepsContext } from '@@/context/reviewSteps.context';
import { REVIEW_STEP_ORDER } from '../../constant/steps';

import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';

function ReviewSteps() {
  const { reviewSteps: locale } = useSelector(selectLocaleResource) || {};
  const { current, addedCheckinNotes } = useContext(ReviewStepsContext);

  if (!current) {
    return null;
  }

  const currentStepOrder = REVIEW_STEP_ORDER[current];

  return (
    <>
      <div className="review-steps">
        {map(locale, ({ label, desc }, step) => {
          const stepOrder = REVIEW_STEP_ORDER[step];

          const isLastStep = stepOrder === size(REVIEW_STEP_ORDER);
          const isActive = current === step;
          const isDone = currentStepOrder > stepOrder || (isActive && isLastStep);
          const isCheckinDone = isActive && addedCheckinNotes;
          return (
            <div
              className={cls('review-steps-item', {
                'review-steps-item-active': isActive,
                'review-steps-item-done': isDone,
                'review-steps-item-checkin-done': isCheckinDone,
              })}
              key={label}
            >
              <div className="review-steps-item-dot">
                {isDone || isCheckinDone ? <CheckOutlined /> : null}
              </div>
              <div className="review-steps-item-title">
                <span>{label}</span>
                <Tooltip
                  arrow={true}
                  getPopupContainer={trigger => trigger.parentNode}
                  title={desc}
                  placement={isLastStep ? 'topRight' : 'topLeft'}
                  overlayClassName={cls({ 'not-first-step': stepOrder !== 1 })}
                >
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </div>
          );
        })}
      </div>
      <div className="review-steps-title">
        <div className="review-steps-title-no">{currentStepOrder}</div>
        <h2 className="review-steps-title-desc">{locale[current].title}</h2>
      </div>
    </>
  );
}

export default memo(ReviewSteps);
