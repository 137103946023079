import React, { useEffect } from 'react';
import './index.less';
import locale from '../../../../local/feedback/en_US';
import HelperTabsImg from '@@/assets/images/helper-tabs.svg';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';

const HelperTabs = () => {
  const {
    feedback: { helperContent },
  } = locale;

  // 获取 ant-tabs-nav-list 元素
  useEffect(() => {
    const tabsNavList = document.querySelector('.ant-tabs-nav-list');
    tabsNavList?.setAttribute('tabindex', '-1');
  }, []);

  const helperTabs = () => {
    const helperTabLabels = [] as React.ReactElement[];
    const helperTabChildren = [] as React.ReactElement[];

    helperContent.forEach(item => {
      const labels = (
        <>
          <i className="ri-checkbox-blank-circle-fill title-icon" />
          <span tabIndex={0} className="helper-tabs-nav">
            {item.tab}
          </span>
        </>
      );

      const children = (
        <>
          <div tabIndex={-1} className="helper-tabs-title-holder">
            <i className="ri-checkbox-blank-circle-fill title-icon" />
            <span className="helper-tabs-title">{item.title}</span>
          </div>
          <div
            className={'helper-tabs-content'}
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </>
      );

      helperTabLabels.push(labels);
      helperTabChildren.push(children);
    });
    return {
      helperTabLabels: helperTabLabels,
      helperTabChildren: helperTabChildren,
    };
  };

  return (
    <>
      <div className="helper-tabs-container">
        <TabPaneComponent
          tabList={helperTabs().helperTabLabels}
          children={helperTabs().helperTabChildren}
          tabPosition={'left'}
        />
      </div>
      <div className="helper-tabs-img">
        <img src={HelperTabsImg} alt={'all about performance feedback'} />
      </div>
    </>
  );
};

export default HelperTabs;
