import History from '@@/assets/images/history.svg';
import FieldBlockInfo from '@@/common/component/FieldBlockInfo';
import PerfModal from '@@/common/component/PerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import dayjs from 'dayjs';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import { getFeedbackDetailAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import {
  feedbackDetail,
  setFeedbackDetail,
  setV1FeedbackModalInfo,
  V1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { READ_FEEDBACK } from '@@/common/constant/qualaroo';

const SubmittedFeedbackDetailModalAsRequester = () => {
  const dispatch = useDispatch();
  const {
    feedback: { submittedFeedbackDetailModalAsRequester: locale },
  } = useSelector(selectLocaleResource);

  const {
    loading: isGetFeedbackDetailLoading,
    success: isGetFeedbackDetailSuccess,
    feedback: { providerName, providerRole, content, updatedAt },
    project,
  } = useSelector(feedbackDetail);

  const {
    isSubmittedFeedbackDetailModalVisible: visible,
    feedbackId,
    isCurrentCycle,
  } = useSelector(V1FeedbackModalInfo);

  useEffect(() => {
    visible && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
  }, [visible, feedbackId]);

  const onCancel = () => {
    trigSurvey(READ_FEEDBACK);
    dispatch(setV1FeedbackModalInfo({}));
    dispatch(setFeedbackDetail({}));
  };

  return (
    <>
      {isGetFeedbackDetailSuccess && !isGetFeedbackDetailLoading && (
        <PerfModal
          title={`${locale.title}${providerName}`}
          destroyOnClose
          footer={null}
          className="perf-modal"
          visible={visible}
          onCancel={onCancel}
        >
          <div className="submitted-feedback-detail">
            <div className="role-and-project">
              <div className="role">
                <span className="bold">{locale.role}</span>
                {providerRole}
              </div>
              {project.name && (
                <div className="project">
                  <span className="bold">{locale.projectName}</span>
                  {project.name}
                </div>
              )}
            </div>
            <div>
              <div className="feedback-title-and-last-updated-at">
                <span className="bold">{locale.feedback}</span>
                {isCurrentCycle ? (
                  <div className="last-updated-at">
                    <img alt="" src={History} className="feedback-time-icon" />
                    <span>{locale.lastUpdatedAt}</span>
                    {dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                ) : (
                  <div className="last-updated-at" />
                )}
              </div>
              <FieldBlockInfo className="feedback-content" content={content} />
            </div>
          </div>
        </PerfModal>
      )}
    </>
  );
};

export default memo(SubmittedFeedbackDetailModalAsRequester);
