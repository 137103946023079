import React from 'react';

interface IReviewStepsContext {
  current: string | null;
  addedCheckinNotes: boolean;
  setCurrent: () => void;
  setAddedCheckinNotes: () => void;
}

export const ReviewStepsContext = React.createContext({
  current: null,
  addedCheckinNotes: false,
  setCurrent: () => null,
  setAddedCheckinNotes: () => null,
} as IReviewStepsContext);
