import { get, isEmpty } from 'lodash';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { getGroupManagersWithRegion } from '@@/_new_src_/api/admin';

export const getNewRoleList = (
  roleList,
  extendedGlobalLeadershipTeamInfo,
  globalPeopleTeamInfo,
) => {
  const displayOneRolesList = [
    {
      key: 'Regional Level Group Manager',
      isExist: false,
    },
    {
      key: 'GROUP_LEADER',
      isExist: false,
    },
    {
      key: 'Client Leadership Team',
      isExist: false,
    },
  ];
  const secondList = [];
  const newRoleList = [];
  if (isEmpty(roleList)) {
    return [];
  }

  const groupsForCLT = [];
  roleList.forEach(item => {
    const { role, hierarchyId, hierarchyName } = item;

    if (role === USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM) {
      groupsForCLT.push({ title: hierarchyName, value: `${hierarchyId}` });
    }

    const isRole = displayOneRolesList.every((ele, eleIndex) => {
      const { key, isExist } = ele;

      if (key === role) {
        if (isExist) {
          return false;
        }
        displayOneRolesList[eleIndex].isExist = true;
        return true;
      }
      return true;
    });
    const newItem = { ...item };
    if (role === 'Regional Level Group Manager') {
      const secondItem = { title: hierarchyName, value: hierarchyId };
      secondList.push(secondItem);
      newItem.secondList = secondList;
    }
    if (role === USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE) {
      newItem.secondList = [
        {
          title: get(extendedGlobalLeadershipTeamInfo, 'hierarchyName'),
          value: get(extendedGlobalLeadershipTeamInfo, 'hierarchyId'),
        },
        {
          title: get(globalPeopleTeamInfo, 'hierarchyName'),
          value: get(globalPeopleTeamInfo, 'hierarchyId'),
        },
      ];
    }
    if (role === USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM) {
      newItem.secondList = groupsForCLT;
    }
    if (isRole) {
      newRoleList.push(newItem);
    }
  });
  return newRoleList;
};

export const RoleList = async data => {
  const roleList = get(data, 'roles');
  const extendedGlobalLeadershipTeamInfo = get(
    data,
    'globalFunctionAdminRoleInfo.extendedGlobalLeadershipTeamInfo',
  );
  const globalPeopleTeamInfo = get(data, 'globalFunctionAdminRoleInfo.globalPeopleTeamInfo');
  const newListData = getNewRoleList(
    roleList,
    extendedGlobalLeadershipTeamInfo,
    globalPeopleTeamInfo,
  );
  const { data: regionalLevelGroupsMap } = await getGroupManagersWithRegion();
  if (isEmpty(roleList)) {
    return [];
  }

  let newRoleList = newListData.map(roleItem => {
    // name =================================

    const { hierarchyId, hierarchyName, role, roleHierarchy, secondList } = roleItem;

    const newRoleItem = {
      roleId: hierarchyId,
      roleType: roleHierarchy,
      roleName: role,
      roleInfo: `${role}-${hierarchyId}-${hierarchyName}-${roleHierarchy}`,
      displayRoleName: `${role} - ${hierarchyName}`,
      secondList,
    };
    switch (roleItem.role) {
      case USER_ROLE_ENUM.MU_SL_OPS:
        // roleAndHierarchy
        newRoleItem.displayRoleName = `Market/DEC/Service Line Leadership - ${hierarchyName}`;
        break;
      case USER_ROLE_ENUM.GROUP_LEADER:
        newRoleItem.displayRoleName = 'Group Lead';
        break;
      case USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER:
        newRoleItem.displayRoleName = `${role} - ${regionalLevelGroupsMap[hierarchyId].regionName}`;
        newRoleItem.roleId = regionalLevelGroupsMap[hierarchyId].regionId;
        break;
      case USER_ROLE_ENUM.GLOBAL_HEAD_OF_PEOPLE:
        newRoleItem.displayRoleName = `${role}`;
        newRoleItem.roleInfo = `${role}-${roleHierarchy}`;
        break;
      case USER_ROLE_ENUM.CLIENT_LEADERSHIP_TEAM:
        newRoleItem.displayRoleName = `${role}`;
        break;
      case USER_ROLE_ENUM.GLOBAL_TALENT_BP:
        newRoleItem.displayRoleName = `${role}`;
        break;

      default:
    }
    return newRoleItem;
  });
  newRoleList = newRoleList.sort((a, b) => a.displayRoleName.localeCompare(b.displayRoleName));
  return newRoleList;
};
