import React from 'react';
import locale from '@@/_new_src_/local/feedback/en_US';
import './index.less';
import { Collapse } from 'antd';
import HelperDrawer from '@@/_new_src_/components/HelperDrawer';
import AddFeedbackBanner from '../AddFeedbackBanner';
import HelperTabs from '@@/_new_src_/page/feedback/component/HelperTabs';

function MoreFeedbackButton() {
  const {
    feedback: {
      moreFeedBackButton,
      emptyFeedback: { helpButton, drawerTitle },
    },
  } = locale;
  const { Panel } = Collapse;

  return (
    <div className="more-feedback-bar">
      <Collapse bordered={false} collapsible="header">
        <Panel
          showArrow={false}
          key={1}
          header={
            <button type="button" className="more-feedback-button" tabIndex={0}>
              <i className="ri-add-circle-line" />
              <span className="more-feedback-font">{moreFeedBackButton}</span>
            </button>
          }
          extra={
            <div className="help-button">
              <HelperDrawer
                buttonContent={
                  <>
                    <i className="ri-question-line btn-icon" />
                    <span className="btn-text">{helpButton}</span>
                  </>
                }
                drawerTitle={drawerTitle}
                drawerContent={<HelperTabs />}
              />
            </div>
          }
        >
          <div className="more-feedback-add-button">
            <AddFeedbackBanner />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default MoreFeedbackButton;
