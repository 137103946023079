import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cls from 'classnames';
import ReviewSteps from '../../../common/component/ReviewSteps';
import { ReviewStepsContext } from '@@/context/reviewSteps.context';
import './root.less';
import { useDispatch, useSelector } from 'react-redux';
import { overdueAndNonEngagedTip, setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import StepBarV2 from '@@/_new_src_/page/myCycles/component/StepsBar';
import { UserInfoContext } from '@@/context/userInfo.context';
import Notification from '@@/_new_src_/components/Notification';
import locale from '@@/_new_src_/local/myCycles/en_US';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { getAssessmentNonEngagedDate } from '@@/_new_src_/utils/feature';

const ReviewsRoot = ({ children }) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(null);
  const [addedCheckinNotes, setAddedCheckinNotes] = useState(false);
  const isSetReviewPage = useRouteMatch(CYCLE_PAGE_LINK.OLD_CYCLES_FORM);
  const isReviewHistoryDetailPage = useRouteMatch(CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL);
  const { currentCycleVersion, futureCycleVersion } = useContext(UserInfoContext);

  const { hasAssessmentNonEngagedCycle, assessmentNonEngagedCycleDuration } =
    useSelector(overdueAndNonEngagedTip);
  const [showAssessmentNonEngagedTip, setShowAssessmentNonEngagedTip] = useState(
    hasAssessmentNonEngagedCycle,
  );

  useEffect(() => {
    dispatch(setCycleVersion(currentCycleVersion));
  }, []);

  const assessmentNonEngagedNotification = () => {
    const { title, content } = locale.assessmentNonEngagedNotification;

    return {
      title: title,
      content: content(getAssessmentNonEngagedDate(assessmentNonEngagedCycleDuration)),
      visible: showAssessmentNonEngagedTip,
      hideNotification: () => {
        setShowAssessmentNonEngagedTip(false);
      },
    };
  };

  const renderStatusBar = () => {
    if (isReviewHistoryDetailPage) {
      return <></>;
    }
    if (currentCycleVersion) {
      return currentCycleVersion === CYCLE_VERSION.NEW ? <StepBarV2 /> : <ReviewSteps />;
    }
    return futureCycleVersion === CYCLE_VERSION.NEW ? <StepBarV2 /> : <ReviewSteps />;
  };

  return (
    <ReviewStepsContext.Provider
      value={{
        current,
        setCurrent,
        addedCheckinNotes,
        setAddedCheckinNotes,
      }}
    >
      <div
        className={cls('reviews-root-container', {
          'reviews-root-container-set-review': isSetReviewPage,
        })}
      >
        {renderStatusBar()}
        {!isReviewHistoryDetailPage && !isSetReviewPage && (
          <Notification {...assessmentNonEngagedNotification()} />
        )}
        {children}
      </div>
    </ReviewStepsContext.Provider>
  );
};

export default ReviewsRoot;
