import { createSlice } from '@reduxjs/toolkit';
import {
  ICycleDetail,
  IDealRequest,
  IPpSaveAssessment,
  ISendRequest,
  IState,
} from '@@/_new_src_/store/cycleDetailSlice/interface';
import {
  getCalibrationHistoriesByCycleIdAsync,
  getEditRequestByRequestIdAsync,
  getRequestToEditByCycleIdAsync,
  patchAssessmentByPPAsync,
  patchEditRequestByRequestIdAsync,
  postRequestToEditAsync,
  postUnmarkAssessmentNonEngagedByTbpAsync,
  putPPSaveAssessmentAsync,
} from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import { formatInputFun, formatInputTextNode } from '@@/_new_src_/utils';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { PP_SAVE_ASSESSMENT_VALUE } from '@@/_new_src_/constants/CycleStatus';

const initialState: ICycleDetail = {
  sendRequestData: {
    isSubmitRequestLoading: false,
    isSubmitRequestSuccess: false,
    isAssessmentPatchLoading: false,
    isAssessmentPatchSuccess: false,
    isAssessmentPostLoading: false,
    isAssessmentPostSuccess: false,
    status: '',
    isGetRequestLoading: false,
    isGetRequestSuccess: false,
    id: NaN,
    requestedAt: '',
    approvedAt: '',
    rejectReason: '',
    rejectedAt: '',
  },
  doneCycleSelfAssessmentContent: '',
  isSelfAssessmentChanged: false,

  dealRequestData: {
    isGetEditRequestLoading: false,
    isPatchEditRequestLoading: false,
    isPatchEditRequestSuccess: false,

    id: NaN,
    status: '',
    requester: '',
    requestedAt: '',
    requestReason: '',
    approvedAt: '',
    rejectedAt: '',
    rejectReason: '',
    cycleId: NaN,
    performancePartner: '',
    twer: '',
    projectName: '',
    probationStatus: '',
    noneEngaged: false,
    cycleVersion: NaN,
  },

  ppSaveAssessment: {
    ...INITIAL_ASYNC_STATUS,
    operationType: PP_SAVE_ASSESSMENT_VALUE.SAVE_AS_DRAFT,
  },
  calibrationHistories: {
    data: [],
    loading: false,
    success: false,
    rejected: false,
  },
};

const cycleDetailSlice = createSlice({
  name: 'cycleDetail',
  initialState: { ...initialState },
  reducers: {
    setDoneCycleSelfAssessmentContent: (state, action) => {
      state.doneCycleSelfAssessmentContent = action.payload as string;
    },
    setIsSelfAssessmentChanged: (state, action) => {
      state.isSelfAssessmentChanged = action.payload as boolean;
    },
    resetSendRequestData: state => {
      state.sendRequestData = {
        ...initialState.sendRequestData,
      };
    },
    setDealRequestData: (state, action) => {
      state.dealRequestData = {
        ...initialState.dealRequestData,
        ...action.payload,
      } as IDealRequest;
    },
    setPpSaveAssessment: (state, action) => {
      state.ppSaveAssessment = {
        ...initialState.ppSaveAssessment,
        ...action.payload,
      } as IPpSaveAssessment;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(postRequestToEditAsync.pending, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isSubmitRequestLoading: true,
          isSubmitRequestSuccess: false,
        } as ISendRequest;
      })
      .addCase(postRequestToEditAsync.fulfilled, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isSubmitRequestLoading: false,
          isSubmitRequestSuccess: true,
        } as ISendRequest;
      })
      .addCase(postRequestToEditAsync.rejected, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isSubmitRequestLoading: false,
          isSubmitRequestSuccess: false,
        } as ISendRequest;
      })
      .addCase(getRequestToEditByCycleIdAsync.pending, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isGetRequestLoading: true,
          isGetRequestSuccess: false,
        };
      })
      .addCase(getRequestToEditByCycleIdAsync.fulfilled, (state, action) => {
        const { rejectReason = '' } = action.payload;
        state.sendRequestData = {
          ...state.sendRequestData,
          ...action.payload,
          rejectReason: formatInputFun(formatInputTextNode(rejectReason)),
          isGetRequestLoading: false,
          isGetRequestSuccess: true,
        };
      })
      .addCase(getEditRequestByRequestIdAsync.pending, state => {
        state.dealRequestData = {
          ...state.dealRequestData,
          isGetEditRequestLoading: true,
        };
      })
      .addCase(getEditRequestByRequestIdAsync.fulfilled, (state, action) => {
        const { requestReason = '', rejectReason = '' } = action.payload;
        state.dealRequestData = {
          ...state.dealRequestData,
          ...action.payload,
          requestReason: formatInputFun(formatInputTextNode(requestReason)),
          rejectReason: formatInputFun(formatInputTextNode(rejectReason)),
          isGetEditRequestLoading: false,
        };
      })
      .addCase(getEditRequestByRequestIdAsync.rejected, state => {
        state.dealRequestData = {
          ...state.dealRequestData,
          isGetEditRequestLoading: false,
        };
      })
      .addCase(patchEditRequestByRequestIdAsync.pending, state => {
        state.dealRequestData = {
          ...state.dealRequestData,
          isPatchEditRequestLoading: true,
        };
      })
      .addCase(patchEditRequestByRequestIdAsync.fulfilled, state => {
        state.dealRequestData = {
          ...state.dealRequestData,
          isPatchEditRequestLoading: false,
          isPatchEditRequestSuccess: true,
        };
      })
      .addCase(patchEditRequestByRequestIdAsync.rejected, state => {
        state.dealRequestData = {
          ...state.dealRequestData,
          isPatchEditRequestLoading: false,
        };
      })
      .addCase(patchAssessmentByPPAsync.pending, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isAssessmentPatchLoading: true,
        };
      })
      .addCase(patchAssessmentByPPAsync.fulfilled, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isAssessmentPatchLoading: false,
          isAssessmentPatchSuccess: true,
        };
      })
      .addCase(postUnmarkAssessmentNonEngagedByTbpAsync.pending, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isAssessmentPostLoading: true,
        };
      })
      .addCase(postUnmarkAssessmentNonEngagedByTbpAsync.fulfilled, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isAssessmentPostLoading: false,
          isAssessmentPostSuccess: true,
        };
      })
      .addCase(postUnmarkAssessmentNonEngagedByTbpAsync.rejected, state => {
        state.sendRequestData = {
          ...state.sendRequestData,
          isAssessmentPostLoading: false,
        };
      })
      .addCase(putPPSaveAssessmentAsync.pending, state => {
        state.ppSaveAssessment = {
          ...initialState.ppSaveAssessment,
          loading: true,
        };
      })
      .addCase(putPPSaveAssessmentAsync.fulfilled, (state, action) => {
        const { operationType } = action.meta.arg;
        state.ppSaveAssessment = {
          ...state.ppSaveAssessment,
          operationType: operationType,
          loading: false,
          success: true,
        };
      })
      .addCase(putPPSaveAssessmentAsync.rejected, state => {
        state.ppSaveAssessment = {
          ...initialState.ppSaveAssessment,
        };
      })
      .addCase(getCalibrationHistoriesByCycleIdAsync.pending, state => {
        state.calibrationHistories = {
          ...initialState.calibrationHistories,
          loading: true,
        };
      })
      .addCase(getCalibrationHistoriesByCycleIdAsync.fulfilled, (state, action) => {
        state.calibrationHistories = {
          ...initialState.calibrationHistories,
          loading: false,
          success: true,
          data: action.payload,
        };
      })
      .addCase(getCalibrationHistoriesByCycleIdAsync.rejected, state => {
        state.calibrationHistories = {
          ...initialState.calibrationHistories,
          rejected: true,
        };
      });
  },
});

export const sendRequestData = (state: IState) => state.cycleDetail.sendRequestData;
export const calibrationHistories = (state: IState) => state.cycleDetail.calibrationHistories;

export const dealRequestData = (state: IState) => state.cycleDetail.dealRequestData;
export const ppSaveAssessment = (state: IState) => state.cycleDetail.ppSaveAssessment;
export const doneCycleSelfAssessmentContent = (state: IState) =>
  state.cycleDetail.doneCycleSelfAssessmentContent;
export const isSelfAssessmentChanged = (state: IState) => state.cycleDetail.isSelfAssessmentChanged;
export const {
  resetSendRequestData,
  setDoneCycleSelfAssessmentContent,
  setIsSelfAssessmentChanged,
  setDealRequestData,
  setPpSaveAssessment,
} = cycleDetailSlice.actions;

export default cycleDetailSlice.reducer;
