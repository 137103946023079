import React, { useEffect, useState } from 'react';
import { getGivenFeedbackRequestsAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import './index.less';
import {
  FEEDBACK_SORT_COLUMNS_NAME,
  FEEDBACK_STATUS,
  FeedbackStatusLabel,
} from '@@/_new_src_/constants/feedback';
import { useDispatch, useSelector } from 'react-redux';
import locale from '@@/_new_src_/local/feedback/en_US';
import { V2FeedbackStore } from '@@/_new_src_/store/V2FeedbackSlice';
import { Checkbox } from 'antd';
import { SORT_DIRECTION_FOR_BE, TABLE_DEFAULT_INFO } from '@@/_new_src_/constants/table';
import { onEnter } from '@@/_new_src_/utils';

const GiveFeedbackHeader = () => {
  const dispatch = useDispatch();

  const {
    feedback: {
      giveFeedbackTab: {
        header: { pendingText, totalText, showPendingAndSubmittedText },
      },
    },
  } = locale;

  const {
    givenFeedbacksStore: {
      success: giveFeedbacksSuccess,
      tableConfig: { status },
      givenFeedbackRes: { total, pendingNumber },
    },
  } = useSelector(V2FeedbackStore);

  const [isShowPendingAndSubmitted, setIsShowPendingAndSubmitted] = useState(false);

  useEffect(() => {
    status.length === 3 && setIsShowPendingAndSubmitted(true);
  }, []);

  const onClickShowPendingAndSubmitted = () => {
    let status;
    if (isShowPendingAndSubmitted) {
      status = Object.keys(FeedbackStatusLabel);
    } else {
      status = [FEEDBACK_STATUS.PENDING, FEEDBACK_STATUS.DRAFTED, FEEDBACK_STATUS.SUBMITTED];
    }

    dispatch(
      getGivenFeedbackRequestsAsync({
        status: status,
        givenFeedbackSortColumn: FEEDBACK_SORT_COLUMNS_NAME.default,
        sortOrder: SORT_DIRECTION_FOR_BE.ascend,
        pageSize: TABLE_DEFAULT_INFO.PAGE_SIZE,
        pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
      }),
    );
    giveFeedbacksSuccess && setIsShowPendingAndSubmitted(!isShowPendingAndSubmitted);
  };

  return (
    <div className="feedback-total-section">
      <div className={'pending-total-count'}>
        <span className="pending-text">{`${pendingNumber} ${pendingText}`}</span>
        <span>{` / ${total} ${totalText}`}</span>
      </div>
      <div className="show-pending-submitted">
        <Checkbox
          className="show-checkbox"
          onChange={onClickShowPendingAndSubmitted}
          checked={isShowPendingAndSubmitted}
          tabIndex={0}
          aria-label={'check done request'}
          onKeyDown={e => onEnter(e, onClickShowPendingAndSubmitted)}
        >
          {showPendingAndSubmittedText}
        </Checkbox>
      </div>
    </div>
  );
};
export default GiveFeedbackHeader;
