import React, { memo, useEffect, useMemo } from 'react';
import '../../../reviewees/detail/index.less';
import './index.less';
import { get, isEmpty } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from '@@/common/utils/qs';
import cls from 'classnames';
import {
  DASHBOARD_CYCLES_PATH,
  DASHBOARD_REQUESTS_PATH,
  DEFAULT_REQUEST_PAGE_STATUS,
  rolesHavingAccessToRequestTab,
  V2_DASHBOARD_SEARCH_FILTER,
  V2_REQUEST_PAGE_STATUS,
} from '../common/constants';
import { DEFAULT_TABLE_PAGINATION } from '@@/_new_src_/constants/table';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { setRequestPageStatus, setSearchFilter } from '@@/redux/slice/dashboardSlice';
import { getOpenRequestCountAsync } from '@@/redux/slice/dashboardSlice/asyncThunk';
import { OTHER_GLOBAL_FUNCTION_ID, V2SUPERVISOR } from '@@/_new_src_/constants/adminEnum';
import { dashboardAccessibleRoles, USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

const getMenu = (locale = {}, isOtherGlobalFunction) => [
  {
    text: locale.dashboardReviews,
    path: DASHBOARD_CYCLES_PATH,
    role: isOtherGlobalFunction
      ? dashboardAccessibleRoles.filter(role => role !== USER_ROLE_ENUM.TALENT_BP)
      : dashboardAccessibleRoles,
  },
  {
    text: locale.dashboardRequests,
    path: DASHBOARD_REQUESTS_PATH,
    role: rolesHavingAccessToRequestTab,
  },
];

const DashboardHeaderMenu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const localeResource = useSelector(selectLocaleResource);
  const currentRoleSelection = useSelector(state => state.dashboard.currentRoleSelection);
  const currentGroupSelection = useSelector(state => state.dashboard.currentGroupSelection);
  const openRequest = useSelector(state => state.dashboard.openRequest);
  const { count: openRequestCount } = openRequest;
  const { groupId, groupType } = currentGroupSelection;
  const { roleName, roleInfo, roleId, roleType } = currentRoleSelection;

  const setSearchFilterFun = searchFilter => {
    dispatch(setSearchFilter(searchFilter));
    sessionStorage.setItem(V2_DASHBOARD_SEARCH_FILTER, JSON.stringify(searchFilter));
  };

  const setRequestPageStatusFun = requestPageStatus => {
    dispatch(setRequestPageStatus(requestPageStatus));
    sessionStorage.setItem(V2_REQUEST_PAGE_STATUS, JSON.stringify(requestPageStatus));
  };

  useEffect(() => {
    if (rolesHavingAccessToRequestTab.includes(roleName) && groupId) {
      dispatch(getOpenRequestCountAsync({ groupId, roleId, groupType, roleType, roleName }));
    }
  }, [roleName, groupId]);

  const handleMenuClick = async isActive => {
    if (!isActive) {
      setSearchFilterFun(DEFAULT_TABLE_PAGINATION);
      setRequestPageStatusFun(DEFAULT_REQUEST_PAGE_STATUS);
    }
  };

  const isOtherGlobalFunction = useMemo(() => {
    return roleType === V2SUPERVISOR && roleId === OTHER_GLOBAL_FUNCTION_ID;
  }, [roleType, roleId]);

  useEffect(() => {
    if (isOtherGlobalFunction && groupId) {
      history.push(
        `${DASHBOARD_REQUESTS_PATH}?${stringify({
          selectedGroupId: groupId,
          selectedRole: roleInfo,
          selectedGroupType: groupType,
        })}`,
      );
    }
  }, [isOtherGlobalFunction, groupId]);

  const renderMenuItems = (menuLocale = {}) => {
    return (
      groupId &&
      getMenu(menuLocale, isOtherGlobalFunction)
        .filter(({ role }) => !isEmpty(role) && role.includes(roleName))
        .map(({ path, text }) => {
          const isActive = isOtherGlobalFunction
            ? path === DASHBOARD_REQUESTS_PATH
            : path === pathname;
          const isRequestsMenu = text === menuLocale.dashboardRequests;
          const showIndicator = isRequestsMenu && openRequestCount > 0;
          return (
            <Link
              to={{
                pathname: path,
                search: stringify({
                  selectedGroupId: groupId,
                  selectedRole: roleInfo,
                  selectedGroupType: groupType,
                }),
              }}
              key={path}
              onClick={() => {
                handleMenuClick(isActive).then();
              }}
            >
              <div className={cls('submenu', { active: isActive })}>
                {text}
                {showIndicator && <span> ({openRequestCount})</span>}
              </div>
            </Link>
          );
        })
    );
  };
  const renderMenuItemsDom = renderMenuItems(get(localeResource, 'menu'));

  return <div className="dashboard-header-menu">{renderMenuItemsDom}</div>;
};

export default memo(DashboardHeaderMenu);
