import { createSlice } from '@reduxjs/toolkit';
import { getNonEngagedCyclesAsync } from '@@/_new_src_/store/nonEngagedCyclesSlice/asyncThunk';
import { INonEngagedCycles, IState } from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';
import {
  INITIAL_TABLE_INFO,
  NON_ENGAGED_CYCLES_TAB_INDEX,
  NON_ENGAGED_CYCLES_TAB_TYPE,
} from '@@/_new_src_/constants/overdueAndNonEngaged';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { isEmpty } from 'lodash';

const DEFAULT_TAB = NON_ENGAGED_CYCLES_TAB_INDEX.OVERDUE;

const initialState: INonEngagedCycles = {
  currentTab: DEFAULT_TAB,
  overdue: {
    nonEngagedType: NON_ENGAGED_CYCLES_TAB_TYPE.OVERDUE,
    selectUsers: [],
    tableInfo: INITIAL_TABLE_INFO[NON_ENGAGED_CYCLES_TAB_TYPE.OVERDUE],
  },
  assessmentNonEngaged: {
    nonEngagedType: NON_ENGAGED_CYCLES_TAB_TYPE.ASSESSMENT_NON_ENGAGED,
    selectUsers: [],
    tableInfo: INITIAL_TABLE_INFO[NON_ENGAGED_CYCLES_TAB_TYPE.ASSESSMENT_NON_ENGAGED],
  },
  expectationNonEngaged: {
    nonEngagedType: NON_ENGAGED_CYCLES_TAB_TYPE.EXPECTATION_NON_ENGAGED,
    selectUsers: [],
    tableInfo: INITIAL_TABLE_INFO[NON_ENGAGED_CYCLES_TAB_TYPE.EXPECTATION_NON_ENGAGED],
  },
  isEditByTbp: false,
  ...INITIAL_ASYNC_STATUS,
  content: [],
  total: 0,
};

const nonEngagedCyclesSlice = createSlice({
  name: 'nonEngagedCycles',
  initialState: { ...initialState },
  reducers: {
    setResetNonEngagedCycles: state => {
      state.currentTab = initialState.currentTab;
      state.overdue = initialState.overdue;
      state.assessmentNonEngaged = initialState.assessmentNonEngaged;
      state.expectationNonEngaged = initialState.expectationNonEngaged;
      state.isEditByTbp = initialState.isEditByTbp;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload ? action.payload : initialState.currentTab;
    },
    setOverdue: (state, action) => {
      const currentOverdue = isEmpty(action.payload) ? initialState.overdue : state.overdue;
      state.overdue = {
        ...currentOverdue,
        ...action.payload,
      };
    },
    setAssessmentNonEngaged: (state, action) => {
      const currentAssessmentNonEngaged = isEmpty(action.payload)
        ? initialState.assessmentNonEngaged
        : state.assessmentNonEngaged;
      state.assessmentNonEngaged = {
        ...currentAssessmentNonEngaged,
        ...action.payload,
      };
    },
    setExpectationNonEngaged: (state, action) => {
      const currentExpectationNonEngaged = isEmpty(action.payload)
        ? initialState.expectationNonEngaged
        : state.expectationNonEngaged;
      state.expectationNonEngaged = {
        ...currentExpectationNonEngaged,
        ...action.payload,
      };
    },
    setIsEditByTbp: (state, action) => {
      state.isEditByTbp = action.payload as boolean;
    },
  },
  extraReducers: builder => {
    builder.addCase(getNonEngagedCyclesAsync.pending, state => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getNonEngagedCyclesAsync.fulfilled, (state, action) => {
      state.content = action.payload.content;
      switch (state.currentTab) {
        case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
          state.assessmentNonEngaged.tableInfo.pageNumber = action.payload.pageNumber;
          state.assessmentNonEngaged.tableInfo.pageSize = action.payload.pageSize;
          break;
        case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
          state.expectationNonEngaged.tableInfo.pageNumber = action.payload.pageNumber;
          state.expectationNonEngaged.tableInfo.pageSize = action.payload.pageSize;
          break;
        default:
          state.overdue.tableInfo.pageNumber = action.payload.pageNumber;
          state.overdue.tableInfo.pageSize = action.payload.pageSize;
      }
      state.total = action.payload.total;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getNonEngagedCyclesAsync.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const nonEngagedCycles = (state: IState) => state.nonEngagedCycles;

export const {
  setResetNonEngagedCycles,
  setCurrentTab,
  setOverdue,
  setAssessmentNonEngaged,
  setExpectationNonEngaged,
  setIsEditByTbp,
} = nonEngagedCyclesSlice.actions;

export default nonEngagedCyclesSlice.reducer;
