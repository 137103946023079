import { ajax, errorWrapper } from '@@/common/ajax';
import {
  adminApiErrorWrapperOptions,
  getApiV2Path,
  groupApiErrorWrapperOptions,
} from '@@/api/utils';
import {
  IManageGroupListTableColumnsParams,
  ImportMembersData,
  IRegionalLevelGroupDetailParams,
  ISortParamsForAutomatedMembersColumns,
  ISortParamsForGroupMemberTableColumns,
  IUpdateRegionalGroupsParams,
  IV2AdminByTypeParams,
} from '@@/types/admin';
import { IPageParams } from '@@/_new_src_/types/common';
import { SORT_DIRECTION_FOR_BE } from '@@/_new_src_/constants/table';

export const getRegionalLevelGroups = (param: { id: number }) =>
  ajax.get(getApiV2Path(`/regions/regional-groups/${param.id}`));

export const getGroupManagersWithRegion = () =>
  ajax.get(getApiV2Path(`/regions/group-manager-region-info`));

export const postAddPeopleForGroups = (groupId: number, userEmailList: string[]) =>
  ajax.post(getApiV2Path(`/groups/${groupId}`), {
    userEmailList,
  });

export const deleteGroupMember = (groupMemberId: number) =>
  ajax.delete(getApiV2Path(`/groupMembers/${groupMemberId}`));

export const getRolesOfManagement = () =>
  ajax.get(getApiV2Path(`/regions/roles_of_management?includeUnit=true&includeRegion=true`));

export const getRolesOfManagementForGlobalFunction = () =>
  ajax.get(getApiV2Path(`/global-function/roles_of_management`));

export const getUnitsByRegion = (params: { unitLevelCategory: string }) =>
  ajax.get(getApiV2Path(`/regions/units`), { params });

export const getServiceLineByGlobalFunction = (roleName: string) =>
  ajax.get(getApiV2Path(`/global-function/functions-and-service-line-level`), {
    params: { roleName },
  });

export const postAddUnitByRegional = (params: {
  unitId: number;
  membersEmails: string[];
  roleName: string;
}) =>
  ajax.post(getApiV2Path(`/regions/units/${params.unitId}`), {
    membersEmails: params.membersEmails,
    roleName: params.roleName,
  });

export const postAddFunctionAndServiceLevel = (params: {
  superOrgId: number;
  membersEmails: string[];
  roleName: string;
}) =>
  ajax.post(
    getApiV2Path(`/global-function/functions-and-service-line-level/${params.superOrgId}`),
    {
      membersEmails: params.membersEmails,
      roleName: params.roleName,
    },
  );

export const putUpdateRegionalGroups = (data: IUpdateRegionalGroupsParams) => {
  const { groupId, groupName, groupManagers } = data;
  return errorWrapper(
    () => ajax.put(getApiV2Path(`/regional-groups/${groupId}`), { groupName, groupManagers }),
    groupApiErrorWrapperOptions,
  );
};

export const getGroupsInSpecificUnitForUnit = (
  params: IManageGroupListTableColumnsParams,
  unitId: number,
) => ajax.get(getApiV2Path(`/unit/${unitId}/groups`), { params });

export const getGroupsInSpecificUnitForGlobal = (
  params: IManageGroupListTableColumnsParams,
  unitId: number,
) => ajax.get(getApiV2Path(`/global-function/superOrg/${unitId}/groups`), { params });

export const getRegionalGroups = (regionId: number) =>
  ajax.get(getApiV2Path(`/regions/${regionId}/group-manager/regional-groups`));

export const postAddGroupInUnit = (
  { employeeIds, groupName, groupLeaders }: ImportMembersData,
  unitId: number,
) =>
  errorWrapper(() => {
    return ajax.post(getApiV2Path(`/unit/${unitId}/groups`), {
      employeeIds,
      groupName,
      groupLeaders,
    });
  }, groupApiErrorWrapperOptions);

export const postAddGroupForSuperOrg = (
  { employeeIds, groupName, groupLeaders }: ImportMembersData,
  superOrgId: number,
) =>
  errorWrapper(
    () =>
      ajax.post(getApiV2Path(`/superorg/${superOrgId}/groups`), {
        employeeIds,
        groupName,
        groupLeaders,
      }),
    groupApiErrorWrapperOptions,
  );

export const putUpdateGroupMembers = (
  { employeeIds, groupName, groupLeaders }: ImportMembersData,
  groupId: string,
) =>
  errorWrapper(
    () =>
      ajax.put(getApiV2Path(`/groups/${groupId}/groupMembers`), {
        employeeIds,
        groupName,
        groupLeaders,
      }),
    groupApiErrorWrapperOptions,
  );

export const putUpdateRegionalGroupMembers = (
  { employeeIds, groupName, groupLeaders }: ImportMembersData,
  groupId: string,
) =>
  errorWrapper(
    () =>
      ajax.put(getApiV2Path(`/regional-groups/${groupId}/members`), {
        employeeIds,
        groupName,
        groupLeaders,
      }),
    groupApiErrorWrapperOptions,
  );

export const deleteGroupInSpecificUnit = (unitId: number, groupId: number) =>
  ajax.delete(getApiV2Path(`/unit/${unitId}/groups/${groupId}`));

export const deleteGroupInSpecificSuperOrg = (superOrgId: number, groupId: number) =>
  ajax.delete(getApiV2Path(`/superOrg/${superOrgId}/groups/${groupId}`));

export const putUpdateGroup = (groupId: string, groupName: string, groupLeaders: string) =>
  errorWrapper(
    () => ajax.put(getApiV2Path(`/groups/${groupId}`), { groupName, groupLeaders }),
    groupApiErrorWrapperOptions,
  );

export const getUserListForGlobalFunctionServiceLineEGLT = (name: string) =>
  ajax.get(
    getApiV2Path(`/users/superorg/search/available_employees_for_extended_global_leadership_team`),
    {
      params: { name: name.trim() },
    },
  );

export const getUserListForGlobalFunctionServiceLinePeopleTeam = (name: string) =>
  ajax.get(getApiV2Path(`/users/superorg/search/available_employees_for_global_people_team`), {
    params: { name: name.trim() },
  });

export const getUserListForCountryPeopleTeam = (name: string, regionId: number) =>
  ajax.get(
    getApiV2Path(`/users/region/${regionId}/search/available_employees_for_country_people_team`),
    {
      params: { name: name.trim() },
    },
  );

export const getUserListForCountryLeaderShipTeam = (name: string, regionId: number) =>
  ajax.get(
    getApiV2Path(
      `/users/region/${regionId}/search/available_employees_for_country_leadership_team`,
    ),
    {
      params: { name: name.trim() },
    },
  );

export const getV2AdminByType = (params: IV2AdminByTypeParams) =>
  ajax.get(getApiV2Path(`/admins`), { params });

export const postAddGlobalAdmin = (userEmail: string[]) =>
  errorWrapper(
    () => ajax.post(getApiV2Path(`/admins/global_admins`), { userEmail: userEmail[0] }),
    adminApiErrorWrapperOptions,
  );

export const postAddRegionalAdmin = (userEmail: string, regionId: number) =>
  errorWrapper(
    () => ajax.post(getApiV2Path(`/admins/regional_admins`), { userEmail, regionId }),
    adminApiErrorWrapperOptions,
  );

export const postAddOpsAdmin = (userEmails: string[]) =>
  errorWrapper(
    () => ajax.post(getApiV2Path(`/admins/function_sl_admins`), { userEmails }),
    adminApiErrorWrapperOptions,
  );

export const postAddGlobalHOPOrTBP = (roleName: string, emails: string[]) =>
  errorWrapper(
    () => ajax.post(getApiV2Path(`/global-function/global-level `), { roleName, emails }),
    adminApiErrorWrapperOptions,
  );

export const deleteAdminByType = (id: number, type: string) =>
  ajax.delete(getApiV2Path(`/admins/${id}?adminType=${type}`));

export const postAddRegionalLevel = (regionalRoleName: string, membersEmails: string[]) =>
  ajax.post(getApiV2Path(`/regions`), {
    membersEmails,
    regionalRoleName,
  });

export const deletePeopleFromRegionalLevelOrUnitLevel = (userRoleId: number) =>
  ajax.delete(getApiV2Path(`/regions/roles_of_management/${userRoleId}`));

export const deletePeopleByGlobalFunctionSLAdmin = (userRoleId: number) =>
  ajax.delete(getApiV2Path(`/global-function/roles_of_management/${userRoleId}`));

export const deleteGroupMemberByMemberId = (memberId: number) =>
  ajax.delete(getApiV2Path(`/regional-groups/members/${memberId}`));

export const getGroupDetail = (
  currentSorter: ISortParamsForGroupMemberTableColumns,
  currentPagination: IPageParams,
  groupId: string | number,
) => {
  return ajax.get(getApiV2Path(`/groups/${groupId}`), {
    params: {
      sortColumn: currentSorter.sortColumn,
      sortOrder: currentSorter.sortOrder || SORT_DIRECTION_FOR_BE.descend,
      pageNumber: currentPagination.pageNumber,
      pageSize: currentPagination.pageSize,
    },
  });
};

export const getRegionalLevelGroupDetail = (data: IRegionalLevelGroupDetailParams) => {
  const { groupId, currentSorter, currentPagination } = data;
  return ajax.get(getApiV2Path(`/regional-groups/${groupId}/members`), {
    params: {
      sortColumnName: currentSorter.sortColumnName,
      sortOrder: currentSorter.sortOrder,
      pageNumber: currentPagination.pageNumber,
      pageSize: currentPagination.pageSize,
    },
  });
};

export const getAutomatedGroups = (
  display: number,
  HierarchyType: string,
  HierarchyId: number | string,
) => {
  return ajax.get(getApiV2Path(`/accountHierarchy`), {
    params: {
      display: display,
      hierarchyType: HierarchyType,
      hierarchyId: HierarchyId,
    },
  });
};

export const postAddAccountGroupToHideList = (
  accountIds: number[],
  hierarchyId: number | string,
  hierarchyType: string,
) =>
  ajax.post(getApiV2Path(`/accountHierarchy/hide-account-list`), {
    accountIds,
    hierarchyId,
    hierarchyType,
  });

export const postRemoveGroupFromHideList = (
  accountIds: number[],
  hierarchyId: number | string,
  hierarchyType: string,
) =>
  ajax.post(getApiV2Path(`/accountHierarchy/show-account-list`), {
    accountIds,
    hierarchyId,
    hierarchyType,
  });

export const getAutomatedManager = (accountId: number) => {
  return ajax.get(getApiV2Path(`/accountHierarchy/${accountId}/manager`));
};

export const putUpdateCLTMembers = (
  accountId: number,
  { employeeIds, lastUpdateAt }: { employeeIds: Array<number>; lastUpdateAt: number | null },
) => {
  return ajax.put(getApiV2Path(`/accountHierarchy/${accountId}/clt-member `), {
    employeeIds,
    lastUpdateAt: lastUpdateAt,
  });
};

export const getMemberByName = (name: string, accountId: number) =>
  ajax.get(getApiV2Path(`/accountHierarchy/${accountId}/search-member`), {
    params: {
      name: name.trim(),
    },
  });

export const getAutomatedMembers = (
  accountId: number | string,
  currentSorter: ISortParamsForAutomatedMembersColumns,
  currentPagination: IPageParams,
  selectRole: string,
  hierarchyId: number | string,
) => {
  return ajax.get(getApiV2Path(`/accountHierarchy/${accountId}/members`), {
    params: {
      sortColumn: currentSorter.sortColumn,
      sortOrder: currentSorter.sortOrder || SORT_DIRECTION_FOR_BE.ascend,
      pageNumber: currentPagination.pageNumber,
      pageSize: currentPagination.pageSize,
      role: selectRole,
      hierarchyId,
    },
  });
};
