import { Alert } from 'antd';
import React, { memo } from 'react';
import './index.less';

const RequestRejectedTips = ({ message, onClose }) => (
  <Alert
    showIcon
    closable
    type="error"
    className="component-request-rejected-tips"
    message={message}
    onClose={onClose}
  />
);
export default memo(RequestRejectedTips);
