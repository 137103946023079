import icon from '../assets/myCycles/category-label-6.svg';

export enum CYCLE_STEP {
  CYCLE_INFORMATION = 'cycleInformation',
  EXPECTATION_ALIGNMENT = 'expectationAlignment',
  CHECKIN_FEEDBACK = 'checkinAndFeedback',
  CALIBRATING = 'calibrating',
  GET_ASSESSMENT = 'getAssessment',
}

export const CYCLE_STEP_ORDER_FOR_PROBATION = {
  cycleInformation: 1,
  expectationAlignment: 2,
  checkinAndFeedback: 3,
  getAssessment: 4,
};

export const CYCLE_STEP_ORDER = {
  cycleInformation: 1,
  expectationAlignment: 2,
  checkinAndFeedback: 3,
  calibrating: 4,
  getAssessment: 5,
};

export enum CYCLE_NOTIFICATION_LINK {
  URL = 'https://campus.thoughtworks.net/learn/course/47635/performance-at-thoughtworks?generated_by=21582&hash=4cf301052508e86606ed3a12f6073a2b34f4f93b',
  email = 'summit-support@thoughtworks.com',
}

export enum ASSESSMENT_TYPE {
  ASSESSMENT = 0,
  CALIBRATION = 1,
  GRIEVANCE = 2,
}

export enum CYCLE_DISPLAY_PART {
  DEFAULT_DISPLAY = 'null',
  INFORMATION_FORM = 'information form',
  EXPECTATION_FORM = 'expectation form',
  CYCLE_INFO_DISPLAY = 'cycle info display',
}

export enum CYCLE_VERSION {
  OLD = 1,
  NEW = 2,
}

export enum CYCLE_STATUS_ENUM {
  NEW = 'NEW',
  OPEN = 'OPEN',
  DONE = 'DONE',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  UNREAD = 'UNREAD',
  OVERDUE = 'OVERDUE',
  CALIBRATING = 'CALIBRATING',
}

export const CATEGORY_LABEL: Record<
  number,
  {
    labelId: number;
    title: string;
    des: string;
    icon: string;
    iconBackGroundColor: string;
    contentBackGroundColor: string;
  }
> = {
  1: {
    labelId: 6,
    title: 'Values & leadership behavior',
    icon: `<i class="ri-medal-line icon" />`,
    iconBackGroundColor: '#003D4F',
    contentBackGroundColor: '#003d4f1A',
    des: '',
  },
  2: {
    labelId: 2,
    title: 'Commercial success',
    icon: `<i class="ri-line-chart-line icon"/>`,
    iconBackGroundColor: '#6b9e78',
    contentBackGroundColor: '#6b9e781A',
    des: '',
  },
  3: {
    labelId: 1,
    title: 'Awesome partner',
    icon: `<i class="ri-chat-heart-line icon"/>`,
    iconBackGroundColor: '#cc850a',
    contentBackGroundColor: '#cc850a1A',
    des: '',
  },
  4: {
    labelId: 4,
    title: 'Revolutionize tech',
    icon: `<i class="ri-file-code-line icon"/>`,
    iconBackGroundColor: '#47a1ad',
    contentBackGroundColor: '#47a1ad1A',
    des: '',
  },
  5: {
    labelId: 5,
    title: 'Social change',
    icon: `<i class="ri-service-line icon"/>`,
    iconBackGroundColor: '#f2617a',
    contentBackGroundColor: '#f2617a1A',
    des: '',
  },
  6: {
    labelId: 3,
    title: 'Diverse, vibrant community',
    icon: `<img src=${icon} alt="Diverse, Vibrant Community" class = 'icon'/>`,
    iconBackGroundColor: '#634f7d',
    contentBackGroundColor: '#634f7d1A',
    des: '',
  },
};

export const ATTACHMENT_REGEX = /^https?:\/\/.*\.(gif|png|jpg|jpeg|webp|svg)/i;

export const EXPECTATION_ATTACHMENT_ACCEPT = '.png,.jpeg,.jpg,.pdf,.PNG,.JPEG,.JPG,.PDF';

export const EXPECTATION_ATTACHMENT_STANDARD = {
  maxNameLength: 100,
  maxSize: 1024 * 1024 * 10, // KB
  accept: EXPECTATION_ATTACHMENT_ACCEPT.split(','),
};

export enum HELPER_TEXT_OPTIONS {
  PERFORMANCE_PARTNER_INTRO = 'PERFORMANCE PARTNER INTRO',
  ADDITIONAL_PARTNER_INTRO = 'ADDITIONAL PARTNER INTRO',
  CYCLE_DURATION_INTRO = 'CYCLE DURATION INTRO',
  CYCLE_NOTES_OR_CONTEXT_INTRO = 'CYCLE NOTES OR CONTEXT INTRO',
  EXPECTATION_DETAIL_INTRO = 'EXPECTATION DETAIL INTRO',
  PERFORMANCE_CATEGORY_INTRO = 'PERFORMANCE CATEGORY INTRO',
}

export const EXPECTATION_DESCRIPTION_MAX_LENGTH = 8000;

export const FORM_LABEL = {
  PERFORMANCE_PARTNER: 'performancePartner',
  ADDITIONAL_PARTNERS: 'additionalPartners',
  CYCLE_DURATION: 'duration',
  PROJECT: 'project',
  CONTEXT: 'context',
};

export const FORM_HANDLE_TYPE = {
  CANCEL: 'cancel',
  SUBMIT: 'submit',
};

export const HELPER_MODAL_LEARN_MORE_LINK = {
  PERFORMANCE_PARTNER:
    'https://docs.google.com/presentation/d/1NzvdEbE5UAJDor5g8cCSVv1nTi3B8ejR2v1O1ov_q5U/edit?pli=1#slide=id.gc3bb6c2245_0_389',
  ADDITIONAL_PARTNER:
    'https://docs.google.com/presentation/d/1NzvdEbE5UAJDor5g8cCSVv1nTi3B8ejR2v1O1ov_q5U/edit?pli=1#slide=id.gdfa740f7fc_0_25',
};

export const CYCLE_SCORE_RATING_FOR_PROBATION = {
  PASS: 'Pass',
  PASS_WITH_RISK: 'Pass with Risk',
  FAIL: 'Fail',
};

export const NO_PERFORMANCE_INDICATOR = {
  NO_PERFORMANCE_INDICATOR: 'No Performance Indicator',
};

export const CYCLE_SCORE_RATING_V1 = {
  EXCEEDS: 'Exceeds',
  SUCCEED: 'Succeed',
  DEVELOPING: 'Developing',
  NEEDS_DEVELOPMENT: 'Needs Development',
};

export const CYCLE_SCORE_RATING_DESCRIPTION_V1 = {
  [CYCLE_SCORE_RATING_V1.EXCEEDS]:
    'Consistently exceeds performance expectations with minimal support.',
  [CYCLE_SCORE_RATING_V1.SUCCEED]:
    'Consistently meets and sometimes exceeds performance expectations with occasional support.',
  [CYCLE_SCORE_RATING_V1.DEVELOPING]:
    'Sometimes meets performance expectations. Frequently needs support and is growing into expectations.',
  [CYCLE_SCORE_RATING_V1.NEEDS_DEVELOPMENT]:
    'Consistently performs below expectations. Frequently needs support and has not improved with assistance.',
};

export const EXPECTATION_TYPE = {
  NORMAL_EXPECTATION: 1,
  UT_EXPECTATION: 2,
};

export const CYCLE_SCORE_RATING = {
  FAR_EXCEEDED: 'Far exceeded expectations',
  OFTEN_EXCEEDED: 'Often exceeded expectations',
  CONSISTENTLY_MET: 'Consistently met expectations',
  SOMETIMES_MET: 'Sometimes met expectations',
  NOT_MET: 'Did not meet expectations',
};

export const CYCLE_SCORE_RATING_DESCRIPTION = {
  [CYCLE_SCORE_RATING.FAR_EXCEEDED]:
    'Has consistently performed significantly beyond expectations for the current grade level in the performance cycle across all projects and areas of work. Demonstrated behaviors that far exceeded expectations for their position. ',
  [CYCLE_SCORE_RATING.OFTEN_EXCEEDED]:
    'Has performed consistently and regularly exceeded expectations for the current grade level in the performance cycle. Demonstrated behaviors that often exceeded expectations for their position. ',
  [CYCLE_SCORE_RATING.CONSISTENTLY_MET]:
    'Has performed consistently and may have sometimes exceeded expectations for the current grade level in the performance cycle. Consistently demonstrated behaviours expected for their position. ',
  [CYCLE_SCORE_RATING.SOMETIMES_MET]:
    'Has performed inconsistently and partially met the expectations for the current grade level in the performance cycle. Inconsistently demonstrated behaviors expected for their position. ',
  [CYCLE_SCORE_RATING.NOT_MET]:
    'Has consistently performed below the expectations for the current grade level in the performance cycle. Did not demonstrate behaviors expected for their position.',
};
