import React from 'react';
import './index.less';
import commonLocale from '@@/_new_src_/local/common/en_US';

interface IRequestToEditTagProps {
  isDoneCycle: boolean;
  canRequestToEdit: boolean;
  canEdit: boolean;
  onClick?: () => void;
}

const RequestToEditTag = (props: IRequestToEditTagProps) => {
  const { isDoneCycle, canRequestToEdit, canEdit, onClick } = props;
  const {
    common: { requestToEdit: requestToEditLocale },
  } = commonLocale;

  const onClickButton = () => {
    onClick && onClick();
  };

  return (
    <>
      {isDoneCycle && (canRequestToEdit || canEdit) && (
        <div tabIndex={0} className={'request-to-edit-tag'} onClick={onClickButton}>
          <i className="ri-pencil-line"></i>
          <span style={{ fontSize: 16 }}>{requestToEditLocale}</span>
        </div>
      )}
    </>
  );
};

export default RequestToEditTag;
