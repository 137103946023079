import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import cls from 'classnames';
import DOMPurify from 'dompurify';
import { isString } from 'lodash';
import { IWarningTextProps } from '@@/_new_src_/types/features';
import './index.less';

const WarningText = (props: IWarningTextProps) => {
  const { warningText, className = '' } = props;

  return (
    <div className={cls('warning-content', className)}>
      <InfoCircleFilled className="tip-icon" />
      {isString(warningText) ? (
        <div
          className="tip-text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(warningText) }}
        />
      ) : (
        <div className="tip-text">{warningText}</div>
      )}
    </div>
  );
};
export default WarningText;
