import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import { Select } from 'antd/lib';
import { DownOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { get, isEmpty, isEqual, isNumber, pick } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  CUSTOMIZED_GROUP_MANAGEMENT,
  REGIONAL_LEVEL_GROUP_MANAGEMENT,
} from '@@/common/constant/matomo';
import { ERROR_CODE } from '@@/common/constant/http';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { SelectOptionValueType } from '@@/types/antdComponent';
import { userListTypeForSearch } from '@@/types/user';
import { ImportMemberType } from '../../type';
import {
  postAddGroupInUnit,
  putUpdateGroupMembers,
  putUpdateRegionalGroupMembers,
} from '@@/_new_src_/api/admin';
import { getUserList } from '@@/api/user';
import useDisabledForm from '@@/hooks/useDisabledForm';
import AutoComplete from '@@/common/component/AutoComplete';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import UploadFiles from '@@/common/component/UploadFile';
import { PermissionsItem } from '../PermissionsModal';
import './index.less';

interface GroupModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  unitId?: number;
  groupId?: string;
  isEdit?: boolean;
}

export interface groupLeaderInfoType {
  value: string;
  key: string;
  label: string;
}

interface formValuesType {
  groupName: string;
  groupLeaders: Array<groupLeaderInfoType>;
}

interface CreateGroupErrorType {
  code: number;
  message: string;
}

function CreateEditGroupModal(props: GroupModalProps) {
  const { trackEvent } = useMatomo();
  const { visible, onCancel, onSuccess, unitId, groupId, isEdit } = props;
  const [submitting, setSubmitting] = useState(false);

  const [employeeIds, setEmployeeIds] = useState<number[]>([]);
  const [uploadFileError, setUploadFileError] = useState<string>();

  const [createGroupError, setCreateGroupError] = useState<CreateGroupErrorType>(
    {} as CreateGroupErrorType,
  );
  const { form, isDisabled } = useDisabledForm();
  const [activeKey, setActiveKey] = useState<string | string[]>();

  const { Panel } = Collapse;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: {
      manageGroupsOfTBP: { groupModal: locale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        groupModal: {
          uploadFileErrorMessage: string;
          groupLeads: string;
          title: string;
          editTitle: string;
          okText: string;
          editOkText: string;
          cancelText: string;
          groupName: {
            label: string;
            placeholder: string;
            requiredMessage: string;
          };
          groupLead: {
            label: string;
            placeholder: string;
            requiredMessage: string;
            notFoundContent: string;
          };
          successMessage: (name: string) => string;
          editSuccessMessage: string;

          importListTips: string;
          showPermissions: string;
          hidePermissions: string;

          talentBusinessPartnerAccess: {
            role: string;
            accessList: string[];
            scope: {
              user: string;
              preposition: string;
              correspondingCategory: string;
            };
          };
        };
      };
    };
  };

  const getExcelData = useCallback(
    (data: Array<ImportMemberType>) => {
      for (let index = 0; index < data.length; index += 1) {
        const employeeId = get(data[index], 'Employee ID');
        if (!isNumber(employeeId)) {
          setUploadFileError(locale.uploadFileErrorMessage);
          break;
        }
      }
      const employeeIdsData = data.map((item: ImportMemberType) => get(item, 'Employee ID'));
      setEmployeeIds(employeeIdsData);
    },
    [locale.uploadFileErrorMessage],
  );

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = () => {
    const isActive = activeKey?.includes('permission-panel');
    return (
      <>
        <span className="header-text">
          {isActive ? locale.hidePermissions : locale.showPermissions}
        </span>
        <DownOutlined rotate={isActive ? 180 : 0} />
      </>
    );
  };

  const resetFormFields = useCallback(() => {
    form.resetFields();
    setFileList([]);
    setCreateGroupError({} as CreateGroupErrorType);
    setUploadFileError('');
    setActiveKey([]);
  }, [form]);

  const clearCreateGroupError = useCallback(() => {
    setCreateGroupError({} as CreateGroupErrorType);
    setUploadFileError('');
  }, []);

  const createGroupRequest = useCallback(async () => {
    const values = (await form.validateFields()) as formValuesType;
    const groupName = values.groupName.trim();
    const groupLeaders = values.groupLeaders.map(({ value }) => value).join();

    trackEvent({
      category: CUSTOMIZED_GROUP_MANAGEMENT.category,
      action: CUSTOMIZED_GROUP_MANAGEMENT.action.TBP_CREATES_CUSTOMIZED_GROUP,
    });

    if (unitId) {
      await postAddGroupInUnit({ employeeIds, groupName, groupLeaders }, unitId);
      perfMessage.success(locale.successMessage(groupName));
    }
  }, [employeeIds, form, locale, unitId]);

  const editGroupRequest = useCallback(async () => {
    if (unitId) {
      trackEvent({
        category: CUSTOMIZED_GROUP_MANAGEMENT.category,
        action: CUSTOMIZED_GROUP_MANAGEMENT.action.TBP_IMPORTS_MEMBER,
      });
      await putUpdateGroupMembers({ employeeIds }, groupId || '');
    } else {
      trackEvent({
        category: REGIONAL_LEVEL_GROUP_MANAGEMENT.category,
        action: REGIONAL_LEVEL_GROUP_MANAGEMENT.action.IMPORTS_GROUP_MEMBER,
      });
      await putUpdateRegionalGroupMembers({ employeeIds }, groupId || '');
    }
    perfMessage.success(locale.editSuccessMessage);
  }, [employeeIds, groupId, locale.editSuccessMessage, unitId]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      if (isEdit) {
        await editGroupRequest();
      } else {
        await createGroupRequest();
      }
      if (onCancel) {
        onCancel();
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const { errorCode, message } = pick(error, ['message', 'errorCode']) as {
        errorCode: number;
        message: string;
      };
      setCreateGroupError({
        code: errorCode,
        message,
      });
    } finally {
      setSubmitting(false);
    }
  }, [createGroupRequest, editGroupRequest, isEdit, onCancel, onSuccess]);

  const renderNameOptions = useCallback(
    (list: Array<userListTypeForSearch>, Option: typeof Select.Option) => {
      return list.map(({ userId, name, email }) => {
        const selectedUserInfoInCurrentForm = (form.getFieldValue('groupLeaders') ||
          []) as Array<SelectOptionValueType>;
        const isAlreadySelectedInCurrentForm = selectedUserInfoInCurrentForm
          .map((item: SelectOptionValueType) => item.value)
          .includes(email);
        return (
          <Option
            key={String(userId)}
            label={name}
            value={email}
            disabled={isAlreadySelectedInCurrentForm}
          >
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form],
  );

  useEffect(() => {
    const fieldsMap = {
      [ERROR_CODE.INVALID_GROUP_NAME]: 'groupName',
      [ERROR_CODE.INVALID_GROUP_LEADERS]: 'groupLeaders',
    };
    const createFieldValue = (name: string) => ({
      name,
      value: form.getFieldValue(name),
      errors: [createGroupError.message],
      touched: true,
    });
    form.setFields([createFieldValue(fieldsMap[createGroupError.code])]);
  }, [createGroupError, form]);

  const getIsSaveBtnDisabled = useCallback(() => {
    return (
      isDisabled() || !fileList.length || !isEmpty(createGroupError) || !isEmpty(uploadFileError)
    );
  }, [createGroupError, fileList.length, isDisabled, uploadFileError]);

  const getPermissionRow: JSX.Element = (
    <Row>
      <div className="tip-permission-section">
        <Collapse
          className="permission-collapse"
          ghost
          onChange={onCollapseChange}
          activeKey={activeKey}
        >
          <Panel
            key="permission-panel"
            className="permission-panel"
            showArrow={false}
            header={renderPanelHeader()}
          >
            <PermissionsItem permission={locale.talentBusinessPartnerAccess} />
          </Panel>
        </Collapse>
      </div>
    </Row>
  );

  const isInvalidMembers = get(createGroupError, 'code') === ERROR_CODE.UNITY_ERROR_CODE;

  let textErrorMessage = '';
  let listEmployeeIds = '';
  if (isInvalidMembers) {
    const errorMessage = get(createGroupError, 'message');
    const lastIndexOfColon = errorMessage.lastIndexOf(':');
    if (lastIndexOfColon !== -1) {
      textErrorMessage = errorMessage.slice(0, lastIndexOfColon + 1);
      listEmployeeIds = errorMessage.slice(lastIndexOfColon + 1, errorMessage.length);
    } else {
      textErrorMessage = errorMessage;
    }
  }

  const getFileList = (file: UploadFile[]) => {
    setFileList(file);
  };

  const groupNameOnChange = useCallback(() => {
    if (createGroupError.code === ERROR_CODE.UNITY_ERROR_CODE) {
      clearCreateGroupError();
    }
  }, [clearCreateGroupError, createGroupError.code]);

  return (
    <PerfModal
      title={isEdit ? locale.editTitle : locale.title}
      visible={visible}
      style={{ top: 180 }}
      className="tbp-regional-admin-group-modal"
      forceRender
      footer={null}
      afterClose={resetFormFields}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        className="group-form"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        {isEdit || (
          <Row gutter={30}>
            <Col md={24} xs={24}>
              <Form.Item
                name="groupName"
                label={locale.groupName.label}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: locale.groupName.requiredMessage,
                  },
                ]}
              >
                <Input
                  placeholder={locale.groupName.placeholder}
                  maxLength={100}
                  onChange={groupNameOnChange}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isEdit || (
          <Row gutter={30}>
            <Col md={24} xs={24}>
              <Form.Item
                label={locale.groupLead.label}
                name="groupLeaders"
                rules={[
                  {
                    required: true,
                    message: locale.groupLead.requiredMessage,
                  },
                ]}
              >
                <AutoComplete
                  mode="multiple"
                  placeholder={locale.groupLead.placeholder}
                  notFoundContent={locale.groupLead.notFoundContent}
                  fetchApi={getUserList}
                >
                  {renderNameOptions}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!isEdit && getPermissionRow}
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item className="dragger-wrapper">
              <UploadFiles
                getFileList={getFileList}
                clearCreateGroupError={clearCreateGroupError}
                getExcelData={getExcelData}
              />
            </Form.Item>

            <Form.Item
              validateStatus={
                ERROR_CODE.UNITY_ERROR_CODE === createGroupError.code ? 'error' : 'success'
              }
            >
              {isInvalidMembers ? (
                <div className="import-members-error-msg">
                  <div>{textErrorMessage}</div>
                  <div>{listEmployeeIds} </div>
                </div>
              ) : null}

              {!isEmpty(uploadFileError) ? (
                <div className="import-members-error-msg">
                  <div>{uploadFileError}</div>
                </div>
              ) : null}
            </Form.Item>
          </Col>
        </Row>

        <div className="group-modal-footer">
          <Form.Item shouldUpdate={(prevValues, curValues) => isEqual(prevValues, curValues)}>
            {() => (
              <>
                <Button onClick={onCancel}>{locale.cancelText}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={getIsSaveBtnDisabled()}
                  loading={submitting}
                >
                  {isEdit ? locale.editOkText : locale.okText}
                </Button>
              </>
            )}
          </Form.Item>
        </div>
      </Form>
    </PerfModal>
  );
}

export default memo(CreateEditGroupModal);
