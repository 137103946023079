import './index.less';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { IDurationTime, IExpectationItem } from '@@/_new_src_/store/cycleDetailSlice/interface';
import dayjs, { Dayjs } from 'dayjs';
import type { RadioChangeEvent } from 'antd';
import { Button, Form, Input, Radio, Select, Switch } from 'antd';
import AutoComplete from '@@/common/component/AutoComplete';
import { getUserList } from '@@/api/user';
import { UserInfoContext } from '@@/context/userInfo.context';
import { SearchedPeopleInfo } from '@@/features/performance/v2/admin/common/AddOrEditModal/type';
import { IV2Project } from '@@/_new_src_/types/common';
import DatePicker from '@@/common/component/DatePicker';
import { setDueDate } from '@@/redux/slice/feedbackSlice';
import {
  FEEDBACK_CONTEXT_MAX_LENGTH,
  FEEDBACK_TYPE,
  OPERATION_TYPE,
} from '@@/_new_src_/constants/feedback';
import locale from '@@/_new_src_/local/feedback/en_US';
import cls from 'classnames';
import {
  getFeedbackDetailAsync,
  getFeedbackTemplatesAsync,
  postAddFeedbackAsync,
  putEditFeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { IAddFeedback, IEditFeedback, IInternalFeedback } from '@@/_new_src_/types/feedback';
import { dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { useHistory } from 'react-router-dom';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import {
  setFeedbackDetail,
  setInternalFeedback,
  setUpdateFeedbackInfo,
  V2FeedbackStore,
} from '@@/_new_src_/store/V2FeedbackSlice';
import perfMessage from '@@/_new_src_/components/perfMessage';
import { useParams } from 'react-router';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { REQUEST_FEEDBACK_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { SEND_V2_FEEDBACK_REQUEST } from '@@/common/constant/qualaroo';
import { getNewDateFormatByProbation } from '@@/_new_src_/utils/common/date';
import ConnectExpectations from '@@/_new_src_/page/feedback/component/ConnectExpectations';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { onEnter } from '@@/_new_src_/utils';
import { getOngoingCycleAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';

type QuestionMap = Map<number, string[]>;

const SendForm = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const [form] = Form.useForm();
  const params = useParams();
  const { id: feedbackId } = params as { id: number };

  const {
    feedback: {
      requestForm: formLocale,
      clientFeedbackForm: { successMessage, editSuccessMessage },
    },
  } = locale;

  const userInfo = useContext(UserInfoContext);

  const {
    data: { id: cycleId, project, duration, isProbation, expectations },
  } = useSelector(cycleDetailData) as {
    data: {
      id: number;
      project: IV2Project;
      duration: IDurationTime;
      isProbation: boolean;
      expectations: IExpectationItem[];
    };
  };

  const startTime = dayjs(get(duration, 'startTime')).format(
    getNewDateFormatByProbation(isProbation),
  );
  const endTime = dayjs(get(duration, 'endTime')).format(getNewDateFormatByProbation(isProbation));

  const {
    updateFeedbackInfo: {
      loading: updateFeedbackInfoLoading,
      type: updateFeedbackInfoType,
      success: updateFeedbackInfoSuccess,
      operationType,
    },
    feedbackTemplates: templates,
    feedbackDetail: { success: isGetFeedbackDetailSuccess, feedback },
  } = useSelector(V2FeedbackStore);

  const [isRequestToTextVisible, setIsRequestToTextVisible] = useState(false);
  const [isDueDateTextVisible, setIsDueDateTextVisible] = useState(false);
  const [isDueTimeChanged, setIsDueTimeChanged] = useState(false);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);
  const [isExpectationChanged, setIsExpectationChanged] = useState(false);
  const [contextText, setContextText] = useState('');
  const [contextHtml, setContextHtml] = useState('');
  const [disable, setDisable] = useState(true);
  const [itemSelected, setItemSelected] = useState(false);
  const [templateId, setTemplateId] = useState(1);
  const [addedCustomQuestions, setAddedCustomQuestions] = useState<QuestionMap>(new Map());
  const RequiredFieldKeys = [formLocale.requestFormNames, formLocale.dueTime];
  const initAddCustomQuestions = feedback.questions
    .filter(item => item.type === 1)
    .map(item => item.question);
  const isEdit = () => {
    return feedbackId;
  };
  const [expectationIds, setExpectationIds] = useState<number[]>([]);

  const [focusQuestionIndex, setFocusQuestionIndex] = useState<number>(NaN);
  const newQuestionInputRef = useRef(null);

  const initItemSelected = useMemo(() => {
    return feedback.expectations.filter(item => item.selected).length > 0;
  }, [isGetFeedbackDetailSuccess]);

  const isCustomizedQuestionTemplate = useMemo(
    () => templates[templateId]?.[0].templateId === -1,
    [templates, templateId],
  );

  const currentCustomizedQuestions = useMemo(() => {
    return addedCustomQuestions.get(templateId) || [];
  }, [addedCustomQuestions, templateId]);

  const isCustomizedQuestionFilled = useMemo(
    () =>
      (isCustomizedQuestionTemplate &&
        currentCustomizedQuestions.filter(item => item.trim() !== '').length > 0) ||
      !isCustomizedQuestionTemplate,
    [addedCustomQuestions, isCustomizedQuestionTemplate],
  );

  const isCustomQuestionChanged = useMemo(() => {
    if (isGetFeedbackDetailSuccess) {
      if (templateId !== feedback.questionTemplateId) {
        return isCustomizedQuestionTemplate
          ? currentCustomizedQuestions.filter(item => item.trim() !== '').length > 0
          : true;
      }
      const isContentChanged = !initAddCustomQuestions.every(
        (value, index) => value === addedCustomQuestions.get(templateId)?.[index],
      );
      const isLengthChanged =
        initAddCustomQuestions.length !==
        currentCustomizedQuestions.filter(item => item.trim() !== '').length;

      const isQuestionChanged = isLengthChanged || isContentChanged;

      return !isCustomizedQuestionTemplate
        ? isQuestionChanged
        : currentCustomizedQuestions.filter(item => item.trim() !== '').length > 0 &&
            isQuestionChanged;
    }
    return false;
  }, [templateId, isGetFeedbackDetailSuccess, addedCustomQuestions, isCustomizedQuestionTemplate]);

  const keys = useMemo(() => Object.keys(templates).map(Number), [templates]);

  const isShowSendBtn = () => {
    let expectationChanged: boolean;
    const isItemSelectedChanged = initItemSelected !== itemSelected;
    if (initItemSelected) {
      expectationChanged = isExpectationChanged || isItemSelectedChanged;
    } else {
      expectationChanged = isExpectationChanged && isItemSelectedChanged;
    }

    const isFieldsChanged =
      (isDueTimeChanged ||
        isCustomQuestionChanged ||
        expectationChanged ||
        contextHtml.trim() !== feedback.context) &&
      contextText.length <= FEEDBACK_CONTEXT_MAX_LENGTH;

    return !isCustomizedQuestionTemplate
      ? !isFieldsChanged
      : !(
          currentCustomizedQuestions.filter(item => item.trim() !== '').length > 0 &&
          isFieldsChanged
        );
  };

  const expectationList = () => {
    if (isEdit()) {
      return feedback.expectations;
    }
    return expectations;
  };

  const isSendDisabled = () => {
    if (isEdit()) {
      return isShowSendBtn();
    }
    return (
      !form.isFieldsTouched(RequiredFieldKeys, true) ||
      !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
      contextText.length > FEEDBACK_CONTEXT_MAX_LENGTH ||
      !isCustomizedQuestionFilled
    );
  };

  useEffect(() => {
    if (isEdit() && isGetFeedbackDetailSuccess) {
      setIsExpectationChanged(
        JSON.stringify(
          feedback.expectations
            .filter(item => item.selected)
            .map(item => item.id)
            .slice()
            .sort(),
        ) !== JSON.stringify(expectationIds),
      );
    }
  }, [isGetFeedbackDetailSuccess, expectationIds]);

  useEffect(() => {
    setDisable(isSendDisabled());
  }, [
    isTemplateChanged,
    isDueTimeChanged,
    contextText,
    isExpectationChanged,
    isCustomQuestionChanged,
    itemSelected,
    isCustomizedQuestionFilled,
  ]);

  const renderQuestions = useCallback(() => {
    return keys.map(key => {
      return (
        key === templateId &&
        !isCustomizedQuestionTemplate &&
        templates[key].map((item, index) => (
          <div key={item.id} className="question">
            <span>{`Q${index + 1}`}</span>
            <p>{item.question}</p>
          </div>
        ))
      );
    });
  }, [keys, templateId]);

  useEffect(() => {
    dispatch(getOngoingCycleAsync());
    dispatch(getFeedbackTemplatesAsync());
    isEdit() && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackDetail({}));
    };
  }, []);

  useEffect(() => {
    if (itemSelected) {
      scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [itemSelected]);

  useEffect(() => {
    if (isGetFeedbackDetailSuccess) {
      form.setFieldsValue({
        dueTime: dayjs(feedback.dueDate, 'YYYY-MM-DD'),
      });
      setTemplateId(feedback.questionTemplateId);
      setContextHtml(feedback.context);
      setExpectationIds(
        feedback.expectations
          .filter(item => item.selected)
          .map(item => item.id)
          .slice()
          .sort(),
      );
      setItemSelected(initItemSelected);
      setAddedCustomQuestions(
        new Map(addedCustomQuestions).set(feedback.questionTemplateId, initAddCustomQuestions),
      );
    } else {
      setExpectationIds(expectations?.map(item => item.id));
    }
  }, [isGetFeedbackDetailSuccess, expectations]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    currentCustomizedQuestions[Number(id)] = e.target.value as string;
    setAddedCustomQuestions(
      new Map(addedCustomQuestions).set(templateId, currentCustomizedQuestions),
    );
  };

  const emptyFields = () => {
    const formField = Object.values(form.getFieldsValue(RequiredFieldKeys));
    return formField.filter(item => isEmpty(item) || !item);
  };

  const handleCancel = () => {
    let isShowConfirmModal;
    if (isEdit()) {
      isShowConfirmModal = !isShowSendBtn();
    } else {
      isShowConfirmModal = !(emptyFields().length === RequiredFieldKeys.length) || !!contextHtml;
    }
    if (isShowConfirmModal) {
      dispatch(setConfirmModalStatus(true));
    } else {
      history.push(FEEDBACK_PAGE_LINK.INDEX);
    }
  };

  const handleSend = () => {
    const requestToNames = form.getFieldValue(formLocale.requestFormNames) as { value: number }[];
    const requestIds = requestToNames
      ? requestToNames.map(item => item.value)
      : [feedback.providerId];
    const dueDate = form.getFieldValue(formLocale.dueTime) as Dayjs;
    const internalFeedback = {
      requestToIds: requestIds,
      dueTime: dueDate.format(dateWithYearMonthTime),
      context: contextHtml.trim(),
      question: {
        templateId: templateId === 3 ? -1 : templateId,
        customizedQuestions: addedCustomQuestions
          .get(templateId)
          ?.filter(item => item.trim() !== ''),
      },
      expectations: itemSelected ? expectationIds : [],
    } as IInternalFeedback;

    if (isEdit()) {
      if (feedbackId) {
        const editFeedback = {
          feedbackId: feedbackId,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          internal: internalFeedback,
        } as IEditFeedback;
        dispatch(putEditFeedbackAsync(editFeedback));

        trackEvent({
          category: REQUEST_FEEDBACK_MANAGEMENT.category,
          action: REQUEST_FEEDBACK_MANAGEMENT.action.EDIT_INTERNAL_FEEDBACK,
        });
      }
    } else {
      if (cycleId) {
        const addFeedback = {
          cycleId: cycleId,
          type: FEEDBACK_TYPE.INTERNAL_FEEDBACK,
          internal: internalFeedback,
        } as IAddFeedback;
        dispatch(postAddFeedbackAsync(addFeedback));
        trigSurvey(SEND_V2_FEEDBACK_REQUEST);
        trackEvent({
          category: REQUEST_FEEDBACK_MANAGEMENT.category,
          action: REQUEST_FEEDBACK_MANAGEMENT.action.ADD_INTERNAL_FEEDBACK,
        });
      }
    }
  };

  const handleDelete = (e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
    const index = e.currentTarget.getAttribute('data-value');
    const newAddedCustomQuestions = currentCustomizedQuestions.slice();
    newAddedCustomQuestions.splice(Number(index), 1);
    setAddedCustomQuestions(new Map(addedCustomQuestions).set(templateId, newAddedCustomQuestions));
  };

  const handleAdd = () => {
    const newAddedCustomQuestions = currentCustomizedQuestions.slice();
    newAddedCustomQuestions.push('');
    setAddedCustomQuestions(new Map(addedCustomQuestions).set(templateId, newAddedCustomQuestions));
  };

  useEffect(() => {
    if (
      currentCustomizedQuestions[currentCustomizedQuestions.length - 1] === '' &&
      newQuestionInputRef.current
    ) {
      (newQuestionInputRef.current as HTMLInputElement)?.focus();
    }
  }, [currentCustomizedQuestions, addedCustomQuestions]);

  const handleChangeSelectTemplate = (e: RadioChangeEvent) => {
    if (feedback.questionTemplateId !== e.target.value) {
      setIsTemplateChanged(true);
    } else {
      setIsTemplateChanged(false);
    }
    setTemplateId(e.target.value);
  };

  useEffect(() => {
    if (updateFeedbackInfoType === FEEDBACK_TYPE.INTERNAL_FEEDBACK && updateFeedbackInfoSuccess) {
      if (operationType === OPERATION_TYPE.ADD) {
        perfMessage.success(editSuccessMessage);
      }
      if (operationType === OPERATION_TYPE.EDIT) {
        perfMessage.success(successMessage);
      }
      history.push(FEEDBACK_PAGE_LINK.INDEX);

      dispatch(setInternalFeedback({}));
      dispatch(setUpdateFeedbackInfo({}));
    }
  }, [updateFeedbackInfoType, updateFeedbackInfoSuccess, operationType]);

  const handleRequestToShowHelperFunc = () => {
    setIsRequestToTextVisible(!isRequestToTextVisible);
  };

  const handleDueDateShowHelperFunc = () => {
    setIsDueDateTextVisible(!isDueDateTextVisible);
  };

  const renderNameOptions = useCallback(
    (list: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      return list.map(({ userId = 0, name = '' }) => {
        const isSelf = userInfo.userId === userId;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        const alreadySelectedRequestTo = form
          .getFieldValue(formLocale.requestFormNames)
          ?.map((item: { value: number }) => item.value) as number[];
        const isSelected = alreadySelectedRequestTo?.includes(userId);
        return (
          <Option key={String(userId)} label={name} value={userId} disabled={isSelf || isSelected}>
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [userInfo.userId],
  );

  const HelperButton = ({
    handleShowHelpFunc,
    isContentVisible,
  }: {
    handleShowHelpFunc: () => void;
    isContentVisible: boolean;
  }) => {
    return (
      <div
        tabIndex={0}
        aria-label="help button"
        className={'helper-btn'}
        onClick={handleShowHelpFunc}
        onKeyDown={e => {
          onEnter(e, handleShowHelpFunc);
        }}
      >
        <span>
          {isContentVisible ? formLocale.hideHelperButtonText : formLocale.showHelperButtonText}
        </span>
        <i
          className={cls('', {
            'ri-question-fill': isContentVisible,
            'ri-question-line': !isContentVisible,
          })}
        ></i>
      </div>
    );
  };

  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
  };

  const handleConfirmOkFunc = () => {
    history.push(FEEDBACK_PAGE_LINK.INDEX);
    dispatch(setConfirmModalStatus(false));
  };

  const confirmModalProps = () => {
    const { title, content, cancelText, okText } = formLocale.confirmModalInfo;
    return {
      title: title,
      content: content,
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
      handleCancelFunc: handleConfirmCancelFunc,
    };
  };

  const handleSwitchChange = () => {
    setItemSelected(pre => !pre);
  };

  const renderAddedCustomizedQuestions = useCallback(() => {
    const templateLength = isCustomizedQuestionTemplate ? 0 : templates[templateId]?.length;
    return addedCustomQuestions.get(templateId)?.map((item, index) => {
      return (
        <div tabIndex={0} key={index} className="added-custom-question">
          <div className={'custom-question-input'}>
            <span>{`Q${templateLength + index + 1}`}</span>
            <Input
              className={'text'}
              value={item}
              id={index.toString()}
              ref={newQuestionInputRef}
              placeholder={formLocale.addedCustomizedQuestionPlaceHolder}
              onChange={event => handleChange(event)}
              onFocus={() => {
                setFocusQuestionIndex(index);
              }}
              onBlur={() => {
                setFocusQuestionIndex(NaN);
              }}
            ></Input>
          </div>
          <i
            className={cls('ri-close-line clear-icon', {
              'focus-input-style': index === focusQuestionIndex,
            })}
            data-value={index}
            tabIndex={0}
            onKeyDown={event => onEnter(event, () => handleDelete(event))}
            onClick={e => handleDelete(e)}
          ></i>
        </div>
      );
    });
  }, [addedCustomQuestions, templates, templateId, focusQuestionIndex]);

  const renderCustomizedQuestions = useCallback(() => {
    const templateLength = isCustomizedQuestionTemplate ? 0 : templates[templateId]?.length;
    return (
      <div className="add-custom-question">
        <span>{`Q${templateLength + currentCustomizedQuestions.length + 1}`}</span>
        <p
          tabIndex={0}
          aria-label={'custom question'}
          onKeyDown={e => {
            switch (e.key) {
              case 'Enter':
                handleAdd();
                break;
              case 'Escape':
                document.getElementById(String(0))?.focus();
            }
          }}
          onClick={handleAdd}
        >
          {isCustomizedQuestionTemplate && currentCustomizedQuestions.length === 0
            ? formLocale.addFirstCustomizedQuestion
            : formLocale.addCustomizedQuestion}
        </p>
      </div>
    );
  }, [templates, templateId, addedCustomQuestions]);

  return (
    <div className={'send-form-wrapper'}>
      <GoBackBanner
        title={formLocale.backToList}
        goToBackFun={handleCancel}
        onKeyPress={handleCancel}
      />
      <div className={'send-form-body'}>
        <div className={'title'} aria-label={'send performance feedback request'}>
          {formLocale.title}
        </div>
        <div className="project-info">
          <b>{formLocale.project}</b> {get(project, 'name')} | <b>{formLocale.duration}</b>
          {startTime} -{endTime}
        </div>
        <div className="form-area">
          <div className="left">
            <Form
              className={'basic-info-form'}
              form={form}
              layout={'vertical'}
              requiredMark={false}
              onFieldsChange={() => {
                setDisable(isSendDisabled());
              }}
            >
              <Form.Item
                className="request-to-form-item"
                name={formLocale.requestFormNames}
                rules={[{ required: true }]}
                label={
                  <div className={'request-to-container'}>
                    <div className={'request-to-line-and-button'}>
                      <div className={'request-to-line'}>
                        <div className={'request-to-question'}>{formLocale.nameLabel}</div>
                      </div>
                      <HelperButton
                        handleShowHelpFunc={handleRequestToShowHelperFunc}
                        isContentVisible={isRequestToTextVisible}
                      />
                    </div>
                    {isRequestToTextVisible && (
                      <div
                        className={'helper-text'}
                        dangerouslySetInnerHTML={{
                          __html: locale.feedback.requestForm.requestToHelperText,
                        }}
                      />
                    )}
                  </div>
                }
              >
                {feedback.providerName ? (
                  <Input
                    className={'non-editable-request-to'}
                    defaultValue={feedback.providerName}
                    disabled={true}
                    placeholder={formLocale.placeholder}
                  />
                ) : (
                  <AutoComplete
                    mode="multiple"
                    notFoundContent={formLocale.nameNotFound}
                    placeholder={formLocale.placeholder}
                    fetchApi={getUserList}
                  >
                    {renderNameOptions}
                  </AutoComplete>
                )}
              </Form.Item>
              <Form.Item
                className="due-time-form-item"
                name={formLocale.dueTime}
                rules={[{ required: true }]}
                label={
                  <div className={'request-to-container'}>
                    <div className={'request-to-line-and-button'}>
                      <div className={'request-to-line'}>
                        <div>{formLocale.dateLabel}</div>
                      </div>
                      <HelperButton
                        handleShowHelpFunc={handleDueDateShowHelperFunc}
                        isContentVisible={isDueDateTextVisible}
                      />
                    </div>
                    {isDueDateTextVisible && (
                      <div className={'helper-text'}>{formLocale.dueDateHelperText}</div>
                    )}
                  </div>
                }
              >
                <DatePicker
                  id="date-picker-0"
                  placeholder="Enter to select a date"
                  disabledDate={current => current < dayjs().startOf('day').add(1, 'd')}
                  showToday={false}
                  onChange={value => {
                    const lastDueTime = feedback.dueDate.substring(0, 10);
                    if (value !== null && lastDueTime !== value.format('YYYY-MM-DD').toString()) {
                      setIsDueTimeChanged(true);
                    } else {
                      setIsDueTimeChanged(false);
                    }
                    dispatch(setDueDate(value ? dayjs(value).unix() : null));
                  }}
                  suffixIcon={<i className="ri-calendar-2-line"></i>}
                />
              </Form.Item>
              <Form.Item
                className={'context-and-expectation-form'}
                name={formLocale.context}
                rules={[{ required: false }]}
                label={
                  <div className={'request-to-container'}>
                    <div className={'share-info-question'}>{formLocale.shareInfoQuestion}</div>
                    <div className={'helper-text'}>{formLocale.shareContent}</div>
                  </div>
                }
              >
                <div className={'input-block'}>
                  <TinyMceEditor
                    initialValue={feedback.context}
                    updateHtmlInfo={setContextHtml}
                    updateTextInfo={setContextText}
                    maxLength={FEEDBACK_CONTEXT_MAX_LENGTH}
                  />
                  <div className={'expectation'}>
                    <div className={'expectation-header'}>
                      <div className={'header'}>
                        <div className={'header-text'}>
                          <span className={'share-text'}>{formLocale.expectationHeader}</span>
                          {itemSelected && (
                            <span className={'item-selected'}>
                              ({expectationIds.length}/
                              {Array.isArray(expectationList()) ? expectationList().length : 0}
                              {formLocale.itemSelected}
                            </span>
                          )}
                        </div>
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          onChange={handleSwitchChange}
                          checked={itemSelected}
                        />
                      </div>
                    </div>
                    <div
                      className={'expectation-helper-text'}
                      dangerouslySetInnerHTML={{
                        __html: formLocale.expectationDetail,
                      }}
                    ></div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="questions-area">
                  <div className="questions-title">{formLocale.templateTitle}</div>
                  <div className="templates">
                    <div className="description-area">
                      <div className="description">{formLocale.templateDescription}</div>
                      <span className="select-hint">{formLocale.selectHint} </span>
                      <div className="templates-radio">
                        <Radio.Group onChange={handleChangeSelectTemplate} value={templateId}>
                          {keys.map(key => {
                            return (
                              <Radio key={key} value={key}>
                                {templates[key][0].templateName}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="template-area">
                      {renderQuestions()}
                      {renderAddedCustomizedQuestions()}
                      {renderCustomizedQuestions()}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item className={'button-block'}>
                <Button className={'cancel-btn'} onClick={handleCancel}>
                  <i className="ri-close-circle-fill cancel-icon" />
                  {formLocale.cancelText}
                </Button>
                <Button
                  className={'send-btn'}
                  loading={updateFeedbackInfoLoading}
                  disabled={disable}
                  onClick={handleSend}
                >
                  {isEdit() ? (
                    <i className="ri-save-fill add-icon" />
                  ) : (
                    <i className="ri-send-plane-fill send-icon" />
                  )}
                  {isEdit() ? formLocale.saveText : formLocale.okText}
                </Button>
              </Form.Item>
            </Form>
          </div>
          {itemSelected && (
            <div className="right">
              <ConnectExpectations
                expectationIds={expectationIds}
                setExpectationIds={setExpectationIds}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmModal {...confirmModalProps()} />
    </div>
  );
};

export default SendForm;
