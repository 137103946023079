import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  confirmModalType,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import {
  myCycles,
  setCurrentUpdatedExpectation,
  setDeleteExpectationStatus,
  setShowExpectationNonEngagedCycleTips,
} from '@@/_new_src_/store/myCyclesSlice';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import ExpectationItem from '../ExpectationItem';
import {
  deleteExpectationAsync,
  getCycleByCycleIdAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { OPERATE_EXPECTATION_IN_OPEN_CYCLE } from '@@/common/constant/matomo';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';

const ExpectationsList = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    myCycles: {
      cycleModal: { deleteExpectation },
    },
  } = localeResource as IMyCyclesLocale;

  const currentModalType = useSelector(confirmModalType);

  const {
    currentCycleId: cycleIdByStore,
    currentCycleStatus: currentStatus,
    deleteExpectationStatus: { success: deleteSuccess },
    currentUpdatedExpectation: currentExpectation,
    expectationList: expectations,
  } = useSelector(myCycles);

  const { isNewCycle, isOpenOrDraftCycle } = getCycleStatus(currentStatus);

  const params = useParams();
  const { cycleId: cycleIdByPathname } = params as { cycleId: number };

  const currentCycleId = cycleIdByPathname ? cycleIdByPathname : cycleIdByStore;

  const handleOkDeleteExpectationFunction = () => {
    const currentExpectationId = get(currentExpectation, 'id', null);
    if (currentExpectationId && +currentExpectationId > 0) {
      dispatch(setConfirmModalLoading(true));
      dispatch(deleteExpectationAsync(currentExpectationId));
      dispatch(setShowExpectationNonEngagedCycleTips(false));

      if (isOpenOrDraftCycle) {
        trackEvent({
          category: OPERATE_EXPECTATION_IN_OPEN_CYCLE.category,
          action: OPERATE_EXPECTATION_IN_OPEN_CYCLE.action.DELETE_EXPECTATION,
        });
      }
    }
  };

  const expectationsIsEmpty = isEmpty(expectations);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setDeleteExpectationStatus({}));
      dispatch(setConfirmModalStatus(false));
      dispatch(setConfirmModalLoading(false));
      currentCycleId && dispatch(getCycleByCycleIdAsync(currentCycleId));
    }
  }, [deleteSuccess]);

  const handleCancelFunction = () => {
    dispatch(setCurrentUpdatedExpectation(null));
    dispatch(setConfirmModalType(''));
  };

  const deleteConfirmModalProps = () => {
    const isLastExpectationAndNotNewCycle = !isNewCycle && expectations.length === 1;
    const currentContent = deleteExpectation.content(isLastExpectationAndNotNewCycle);
    const currentTitle = deleteExpectation.title(isLastExpectationAndNotNewCycle);
    return {
      ...deleteExpectation,
      title: currentTitle,
      content: currentContent,
      handleOkFunc: handleOkDeleteExpectationFunction,
      handleCancelFunc: handleCancelFunction,
    };
  };

  return (
    <>
      {!expectationsIsEmpty && (
        <div className="expectation-list">
          {expectations.map((item, index) => (
            <ExpectationItem key={index} expectationItem={item} />
          ))}
        </div>
      )}
      {currentModalType === CONFIRM_MODAL_TYPE_INFO.DELETE_EXPECTATION && (
        <ConfirmModal {...deleteConfirmModalProps()} />
      )}
    </>
  );
};
export default ExpectationsList;
