import { createSlice } from '@reduxjs/toolkit';

export const versionSlice = createSlice({
  name: 'version',
  initialState: {
    isVisibleProductUpdateModal: false,
    isVersionCheck: false,
    isVersionTooltipVisible: false,
  },
  reducers: {
    setIsVisibleProductUpdateModal: (state, action) => {
      state.isVisibleProductUpdateModal = action.payload;
    },
    setIsVersionCheck: (state, action) => {
      state.isVersionCheck = action.payload;
    },
    setIsVersionTooltipVisible: (state, action) => {
      state.isVersionTooltipVisible = action.payload;
    },
  },
});

export const { setIsVisibleProductUpdateModal, setIsVersionCheck, setIsVersionTooltipVisible } =
  versionSlice.actions;

export const selectIsVisibleProductUpdateModal = state => state.version.isVisibleProductUpdateModal;
export const selectIsVersionCheck = state => state.version.isVersionCheck;
export const selectIsVersionTooltipVisible = state => state.version.isVersionTooltipVisible;

export default versionSlice.reducer;
