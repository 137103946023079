export enum AdminTabKey {
  MANAGE_ADMINS = 'Manage Admins',
  MANAGE_REGIONAL_ROLES = 'Manage Regional Roles',
  MANAGE_REGIONAL_GROUPS = 'Manage Regional Groups',
  MANAGE_ROLES = 'Manage Roles',
  MANAGE_GROUPS = 'Manage Groups',
  CUSTOMIZED_GROUPS = 'Customized groups',
  AUTOMATED_GROUPS = 'Automated groups',
}

export enum CLASSIFY_TITLE_KEY {
  GLOBAL_LEVEL = 'GLOBAL LEVEL',
  FUNCTION_LEVEL = 'GLOBAL FUNCTION/MARKET/DEC/SERVICE LINE LEVEL',
  REGION_LEVEL = 'REGION LEVEL',
  UNIT_LEVEL = 'UNIT LEVEL',
  REGIONAL_LEVEL_GROUPS = 'REGIONAL LEVEL GROUPS',
}

export enum PanelType {
  TALENT_BUSINESS_PARTNER = 'Talent Business Partner',
  MU_SL_OPS_LEADERSHIP = 'Market/DEC/Service Line Leadership',
  REGIONAL_TALENT_BUSINESS_PARTNER = 'Regional Talent Business Partner',
  GLOBAL_FUNCTION_SERVICE_LINE_LEADERSHIP = 'Global Function/Market/DEC/Service Line Leadership',
  GLOBAL_HEAD_OF_PEOPLE = 'Global Head of People',
  GLOBAL_TALENT_BUSINESS_PARTNER = 'Global Talent Business Partner',
  GLOBAL_MANAGEMENT_TEAM = 'Global Management Team',
  GLOBAL_PEOPLE_TEAM = 'Global People Team',
}

export const ADMIN_TAB_INDEX = 'ADMIN_TAB_INDEX';
export const ADMIN_TABLE_PAGE = 'ADMIN_TABLE_PAGE';
export const ADMIN_TABLE_SORTER = 'ADMIN_TABLE_SORTER';
export const ADMIN_VISIBLE_STATE = 'visible';

export const TW_USER_INFO = 'USER_INFO';
export const IS_FIRST_LOGIN = 'IS_FIRST_LOGIN';
export const SHOW_RATING = 'SHOW_RATING';
export const LAST_USER_EMAIL = 'LAST_USER_EMAIL';

export const V2UNIT = 'V2UNIT';
export const V2SUPERVISOR = 'V2SUPERVISOR';
export const V2REGION = 'V2REGION';
export const V2GLOBALFUNCTION = 'V2GLOBALFUNCTION';

export const OTHER_GLOBAL_FUNCTION_ID = 13;
