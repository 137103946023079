import { ajax, ajaxWithoutRedirect } from '@@/common/ajax';
import { getApiPath, getApiV2Path } from '@@/api/utils';
import { getTimezoneOffset } from '@@/_new_src_/utils/common/date';

export const cancelReview = id =>
  ajax.put(getApiPath(`/performance-reviews/reviewee/${id}/cancel`), null, {
    params: {
      timezoneOffset: getTimezoneOffset(),
    },
  });

export const markReviewAsRead = id =>
  ajax.put(getApiPath(`/performance-reviews/reviewee/${id}/mark-as-read`));

export const getReviewDetailById = id =>
  ajax.get(getApiPath(`/performance-reviews/reviewee/${id}`));

export const getPerformancePartnerCycleDetailById = id =>
  ajax.get(getApiPath(`/performance-reviews/reviewer/${id}`));

export const updateReviewDetailById = (id, data) =>
  ajax.put(getApiPath(`/performance-reviews/reviewee/${id}`), data);

export const updateReviewByReviewer = (id, data) =>
  ajax.put(getApiPath(`/performance-reviews/reviewer/${id}`), data);

export const getMyReviewsByType = (reviewType, year) =>
  ajax.get(getApiPath('/performance-reviews/reviewee'), {
    params: { reviewType, year },
  });

export const getReviewHistoryAvailableYear = () =>
  ajax.get(getApiPath('/performance-reviews/reviewee/history-review-year'));

export const getPastReviewAvailableYear = () =>
  ajax.get(getApiPath('/performance-reviews/reviewee/past-review-year'));

export const sendEditRequest = data => ajax.post(getApiPath('/done-review-update-requests'), data);

export const readUpdatedAssessment = reviewId =>
  ajax.put(getApiPath(`/performance-reviews/reviewee/${reviewId}/read-updated-assessment`));

export const readRequestRejected = reviewId =>
  ajax.put(getApiPath(`/performance-reviews/${reviewId}/read-rejected-done-review-update-request`));

export const addCheckInNotes = data => ajax.post(getApiPath('/check-in-notes'), data);

export const updateCheckInNotes = (noteId, data) =>
  ajax.put(getApiPath(`/check-in-notes/${noteId}`), data);

export const addReviewInput = (reviewId, reviewInput) =>
  ajax.post(getApiPath(`/contributor-review-inputs`), { reviewId, reviewInput });

export const updateReviewInput = (reviewInputId, reviewInput) =>
  ajax.put(getApiPath(`/contributor-review-inputs/${reviewInputId}`), { reviewInput });

export const getLatestDoneReviewExpectation = () =>
  ajax.get(getApiPath('/performance-reviews/reviewee/latest-done-review'));

export const getSignedUrl = ({ attachmentUrl, cycleId, pageType }) => {
  let url = attachmentUrl;
  if (pageType === 'newDashboardPagePreview' && cycleId) {
    url = getApiV2Path(`/performance_reviews/cycle/attachments/${cycleId}`);
  }
  return ajaxWithoutRedirect.get(url);
};

export const getAttachmentStorageUrl = (attachmentName, fromNewDashboard, isAllPeople) =>
  ajaxWithoutRedirect.get(getApiV2Path(`/performance_reviews/attachments/${attachmentName}`), {
    params: {
      fromNewDashboard,
      isAllPeople: isAllPeople || false,
    },
  });

export const getV2AttachmentStorageUrl = (attachmentName, fromNewDashboard, isAllPeople) =>
  ajaxWithoutRedirect.get(getApiV2Path(`/performance_reviews/attachments/${attachmentName}`), {
    params: {
      fromNewDashboard,
      isAllPeople: isAllPeople || false,
    },
  });
