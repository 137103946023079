import React from 'react';
import { CommonModal } from '@@/common/component/CommonModal';
import InformationIcon from '@@/assets/images/information-yellow.svg';

const LimitMultiPartnerModal = props => {
  const { modalLocale, isVisibleModal, onCloseClick, onClickConfirm } = props;

  function renderMultiPartnerModalContent() {
    return (
      <div className="content">
        <div className="title">
          {modalLocale.title}
          <img src={InformationIcon} alt="" className="tips-icon" />
        </div>
        <div className="desc">{modalLocale.desc}</div>
      </div>
    );
  }

  return (
    <CommonModal
      confirmText={modalLocale.confirmText}
      content={renderMultiPartnerModalContent()}
      visible={isVisibleModal}
      onCloseClick={onCloseClick}
      onClickConfirm={onClickConfirm}
    />
  );
};

export default LimitMultiPartnerModal;
