import { Spin } from 'antd';
import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { REQUEST_STATUS } from '../../constant/requests';
import PerfTextButton from '../PerfTextButton';
import './index.less';
import { updateRequestStatusByRequestId } from '@@/api/v2/dashboard/requests';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import WarningText from '@@/_new_src_/components/WarningText';
import { DASHBOARD_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const RequestDetailV2 = ({
  data: { id: requestId, requester, performancePartner, status: requestStatus, requestReason },
}) => {
  const { trackEvent } = useMatomo();
  const {
    dashboardV2: {
      requests: { requestDetail: requestDetailLocale },
    },
  } = useSelector(selectLocaleResource);
  const currentRoleSelection = useSelector(state => state.dashboard.currentRoleSelection);
  const { roleName } = currentRoleSelection;

  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const handleRequest = useCallback(
    async status => {
      setLoading(true);
      trackEvent({
        category: DASHBOARD_API(roleName).category,
        action:
          status === REQUEST_STATUS.APPROVED
            ? DASHBOARD_API(roleName).action.APPROVES_EDIT_REQUEST_IN_CYCLE_DETAIL
            : DASHBOARD_API(roleName).action.REJECTS_EDIT_REQUEST_IN_CYCLE_DETAIL,
      });
      await updateRequestStatusByRequestId(requestId, status).finally(() => {
        setLoading(false);
      });
      setShowDetail(false);
    },
    [requestId],
  );
  const isRequestFromPerformancePartner = useMemo(
    () => requester === performancePartner,
    [requester, performancePartner],
  );

  useEffect(() => {
    setShowDetail(requestStatus === REQUEST_STATUS.OPEN);
  }, [requestStatus, setShowDetail]);

  const curWarningText = isRequestFromPerformancePartner
    ? requestDetailLocale.descriptionFromReviewer(requester, requestReason)
    : requestDetailLocale.descriptionFromReviewee(requester, requestReason);

  return (
    showDetail && (
      <Spin wrapperClassName="component-request-detail" spinning={loading}>
        <div className="detail-container">
          <WarningText warningText={curWarningText} />
          <div className="request-operation">
            <PerfTextButton
              className="approve"
              onClick={() => handleRequest(REQUEST_STATUS.APPROVED)}
            >
              {requestDetailLocale.approve}
            </PerfTextButton>
            <PerfTextButton
              color="red"
              className="reject"
              onClick={() => handleRequest(REQUEST_STATUS.REJECTED)}
            >
              {requestDetailLocale.reject}
            </PerfTextButton>
          </div>
        </div>
      </Spin>
    )
  );
};
RequestDetailV2.defaultProps = {
  data: {},
};
export default memo(RequestDetailV2);
