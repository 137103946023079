import * as Api from '@@/api/review';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { ACTIONS_MAX_LENGTH, NOTES_MAX_LENGTH } from '@@/common/constant/review';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { Form } from 'antd';
import { default as React, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PerfModal from '../../PerfModal';
import './index.less';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { ADD_CHECK_INS } from '@@/common/constant/qualaroo';
import {
  MY_CYCLE_LIST_API,
  TWER_I_SUPPORT_BY_AP_API,
  TWER_I_SUPPORT_BY_PP_API,
} from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 22,
  },
};

function CheckInNotesModal(props) {
  const { trackEvent } = useMatomo();
  const { cycleId, onSuccess, currentNoteInfo, isEditMode } = props;
  const [form] = Form.useForm();

  const [notesHtmlInfo, setNotesHtml] = useState(null);
  const [notesTextInfo, setNotesText] = useState(null);
  const [notesInitialValue, setNotesInitialValue] = useState(null);

  const [actionsHtmlInfo, setActionsHtml] = useState(null);
  const [actionsTextInfo, setActionsText] = useState(null);
  const [actionsInitialValue, setActionsInitialValue] = useState(null);

  const [loading, setLoading] = useState(false);
  const {
    reviewees: {
      checkInNotes: { checkInNotesModal: locale },
    },
  } = useSelector(selectLocaleResource);

  const {
    currentCycleDetail: { isTwer, isAdditionalPartners, isPerformancePartner },
  } = useSelector(cycleDetailByVersion);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (isEditMode) {
        isTwer &&
          trackEvent({
            category: MY_CYCLE_LIST_API.category,
            action: MY_CYCLE_LIST_API.action.TWER_EDITS_CHECK_IN_NOTES,
          });
        isAdditionalPartners &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_AP_API.category,
            action: TWER_I_SUPPORT_BY_AP_API.action.AP_EDITS_CHECK_IN_NOTES,
          });
        isPerformancePartner &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_PP_API.category,
            action: TWER_I_SUPPORT_BY_PP_API.action.PP_EDITS_CHECK_IN_NOTES,
          });

        await Api.updateCheckInNotes(currentNoteInfo.id, {
          actions: actionsHtmlInfo,
          notes: notesHtmlInfo,
        });
      } else {
        isTwer &&
          trackEvent({
            category: MY_CYCLE_LIST_API.category,
            action: MY_CYCLE_LIST_API.action.TWER_ADDS_CHECK_IN_NOTES,
          });
        isAdditionalPartners &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_AP_API.category,
            action: TWER_I_SUPPORT_BY_AP_API.action.AP_ADDS_CHECK_IN_NOTES,
          });
        isPerformancePartner &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_PP_API.category,
            action: TWER_I_SUPPORT_BY_PP_API.action.PP_ADDS_CHECK_IN_NOTES,
          });
        await Api.addCheckInNotes({
          actions: actionsHtmlInfo,
          notes: notesHtmlInfo,
          reviewId: cycleId,
        });
        trigSurvey(ADD_CHECK_INS);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    if (onSuccess) {
      onSuccess();
    }
  }, [form, onSuccess, isEditMode, currentNoteInfo, cycleId, notesHtmlInfo, actionsHtmlInfo]);

  const onReset = useCallback(() => {
    setNotesHtml(null);
    setNotesInitialValue(null);
    setActionsHtml(null);
    setActionsInitialValue(null);
  }, [setNotesHtml, setNotesInitialValue, setActionsHtml, setActionsInitialValue]);

  const needConfirmAfterClickCancel = useCallback(() => {
    const shouldShowConfirmModalWhenCancel =
      (currentNoteInfo && notesHtmlInfo !== null && notesHtmlInfo !== currentNoteInfo.notes) ||
      (currentNoteInfo &&
        actionsHtmlInfo !== null &&
        actionsHtmlInfo !== currentNoteInfo.actions) ||
      notesHtmlInfo ||
      actionsHtmlInfo;

    return shouldShowConfirmModalWhenCancel;
  }, [currentNoteInfo, notesHtmlInfo, notesInitialValue, actionsHtmlInfo, actionsInitialValue]);

  const disableSaveButton = useCallback(() => {
    return (
      !notesTextInfo?.trim().length ||
      (notesTextInfo && notesTextInfo.length > NOTES_MAX_LENGTH) ||
      (actionsTextInfo && actionsTextInfo?.length > ACTIONS_MAX_LENGTH)
    );
  }, [notesTextInfo, actionsTextInfo]);

  useEffect(() => {
    if (isEditMode && currentNoteInfo && notesHtmlInfo === null && actionsHtmlInfo === null) {
      setNotesInitialValue(currentNoteInfo.notes);
      setNotesHtml(currentNoteInfo.notes);
      setActionsInitialValue(currentNoteInfo.actions);
      setActionsHtml(currentNoteInfo.actions);
    }
  }, [currentNoteInfo]);

  return (
    <PerfModal
      title={isEditMode ? locale.title.edit : locale.title.add}
      okText={isEditMode ? locale.okText.edit : locale.okText.add}
      destroyOnClose
      centered
      className="check-in-notes-modal"
      onOk={onSubmit}
      okButtonProps={{
        loading,
        disabled: disableSaveButton(),
      }}
      afterClose={onReset}
      onCancel={onReset}
      maskClosable={false}
      needConfirm={needConfirmAfterClickCancel()}
      {...props}
    >
      <Form
        {...layout}
        form={form}
        colon={false}
        onFinish={onSubmit}
        className="check-in-notes-modal-form"
      >
        <Form.Item name="notes" label={locale.notes.label}>
          <TinyMceEditor
            id={'notes'}
            initialValue={notesInitialValue}
            updateHtmlInfo={setNotesHtml}
            updateTextInfo={setNotesText}
            maxLength={NOTES_MAX_LENGTH}
            placeholder={locale.notes.placeholder}
            height={265}
          />
        </Form.Item>
        <Form.Item name="actions" label={locale.actions.label}>
          <TinyMceEditor
            id={'actions'}
            initialValue={actionsInitialValue}
            updateHtmlInfo={setActionsHtml}
            updateTextInfo={setActionsText}
            maxLength={ACTIONS_MAX_LENGTH}
            placeholder={locale.actions.placeholder}
            height={265}
          />
        </Form.Item>
      </Form>
    </PerfModal>
  );
}

export default memo(CheckInNotesModal);
