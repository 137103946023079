import icon from '@@/_new_src_/assets/myCycles/category-label-6.svg';
import { ICategoryItemData, ICategoryLocaleContent } from '@@/_new_src_/types/myCycles';
import React from 'react';

export const getCategoryConfig = (locale: {
  valuesLeadership: ICategoryLocaleContent;
  commercialSuccess: ICategoryLocaleContent;
  awesomePartner: ICategoryLocaleContent;
  revolutionizeTech: ICategoryLocaleContent;
  socialChange: ICategoryLocaleContent;
  diverseVibrantCommunity: ICategoryLocaleContent;
}): Record<number, ICategoryItemData> => {
  const valuesLeadershipLocale = locale.valuesLeadership;
  const commercialSuccessLocale = locale.commercialSuccess;
  const awesomePartnerLocale = locale.awesomePartner;
  const diverseVibrantLocale = locale.diverseVibrantCommunity;
  const revolutionizeLocale = locale.revolutionizeTech;
  const socialChangeLocale = locale.socialChange;

  return {
    1: {
      title: valuesLeadershipLocale.title,
      icon: <i className="ri-medal-line icon" />,
      iconBackGroundColor: '#003D4F',
      iconBackgroundColorUnSelect: '#B8C9CD',
      contentBackGroundColor: '#003d4f1A',
      description: valuesLeadershipLocale.description,
    },
    2: {
      title: commercialSuccessLocale.title,
      icon: <i className="ri-line-chart-line icon" />,
      iconBackGroundColor: '#6b9e78',
      iconBackgroundColorUnSelect: '#D6E4DA',
      contentBackGroundColor: '#6b9e781A',
      description: commercialSuccessLocale.description,
    },
    3: {
      title: awesomePartnerLocale.title,
      icon: <i className="ri-chat-heart-line icon" />,
      iconBackGroundColor: '#cc850a',
      iconBackgroundColorUnSelect: '#F1DEBB',
      contentBackGroundColor: '#cc850a1A',
      description: awesomePartnerLocale.description,
    },
    4: {
      title: revolutionizeLocale.title,
      icon: <i className="ri-file-code-line icon" />,
      iconBackGroundColor: '#47a1ad',
      iconBackgroundColorUnSelect: '#CCE4E8',
      contentBackGroundColor: '#47a1ad1A',
      description: revolutionizeLocale.description,
    },
    5: {
      title: socialChangeLocale.title,
      icon: <i className="ri-service-line icon" />,
      iconBackGroundColor: '#f2617a',
      iconBackgroundColorUnSelect: '#FCD3DA',
      contentBackGroundColor: '#f2617a1A',
      description: socialChangeLocale.description,
    },
    6: {
      title: diverseVibrantLocale.title,
      icon: <img src={icon} alt="Diverse, Vibrant Community" className="icon" />,
      iconBackGroundColor: '#634f7d',
      iconBackgroundColorUnSelect: '#D4CEDB',
      contentBackGroundColor: '#634f7d1A',
      description: diverseVibrantLocale.description,
    },
  };
};
