import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IHelperButton, IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import { setHelperModalOptions } from '../../store/myCyclesSlice';

const HelperButton = ({
  showButton = true,
  helpOptionKey,
  currentHelpOptionKey,
  setCurrentHelpOptionKey,
}: IHelperButton) => {
  const {
    myCycles: { helpButton: locale },
  } = useSelector(selectLocaleResource) as IMyCyclesLocale;
  const dispatch = useDispatch();

  const handleDetailsHelpTip = () => {
    if (currentHelpOptionKey === helpOptionKey) {
      setCurrentHelpOptionKey('noHelperDisplay');
      dispatch(setHelperModalOptions('noHelperDisplay'));
      return;
    }
    setCurrentHelpOptionKey(helpOptionKey);
    dispatch(setHelperModalOptions(helpOptionKey));
  };

  return (
    <>
      {showButton && (
        <div className="help-button" onClick={() => handleDetailsHelpTip()}>
          <span>{currentHelpOptionKey === helpOptionKey ? locale.hideHelp : locale.showHelp}</span>
          <i
            className={
              currentHelpOptionKey === helpOptionKey ? 'ri-question-fill' : 'ri-question-line'
            }
          />
        </div>
      )}
    </>
  );
};

export default HelperButton;
