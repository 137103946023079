export interface SortParamsForRegionalAdminPageTeamColumns {
  sortColumn: NewRegionalAdminPageTableColumnsName;
  sortOrder?: 'DESC' | 'ASC' | null;
}

export interface SortParamsForManageGroupListColumns {
  sortColumn: GroupsListColumnSortColumnsName;
  sortOrder?: 'DESC' | 'ASC' | null;
}

export interface SortParamsForRegionalLevelGroup {
  sortColumnName: GroupMemberTableColumnsName;
  sortOrder?: 'DESC' | 'ASC';
}

export interface SortParamsForOverdueTwersTableColumns {
  sortColumnName: OverdueTwersTableSortColumnsName;
  sortOrder?: 'DESC' | 'ASC' | null;
}

export interface SortParamsForNoneCyclesTableColumns {
  sortColumnName: NonEngagedCyclesSortColumnsName;
  sortOrder?: 'DESC' | 'ASC' | null;
}

export interface SelectOptionValueType {
  value: string;
  key: string;
  label: string;
}

export enum NewRegionalAdminPageTableColumnsName {
  name = 'GROUP_MEMBER_NAME',
  createdBy = 'CREATED_BY',
  createdAt = 'CREATED_AT',
  default = 'DEFAULT',
}

export enum GroupMemberTableColumnsName {
  name = 'NAME',
  employeeId = 'EMPLOYEE_ID',
  createdAt = 'CREATED_AT',
  default = 'DEFAULT',
}

export enum AutomatedMemberTableColumnsName {
  name = 'NAME',
  employeeId = 'EMPLOYEE_ID',
  role = 'ROLE',
  default = 'DEFAULT',
}

export enum GroupsListColumnSortColumnsName {
  groupName = 'GROUPNAME',
  createdAt = 'CREATEDAT',
  createdBy = 'CREATEDBY',
  default = 'DEFAULT',
}

export enum OverdueTwersTableSortColumnsName {
  revieweeName = 'REVIEWEE_NAME',
  poh = 'POH',
  overdueDuration = 'OVERDUE_DURATION',
  overdueDays = 'OVERDUE',
  status = 'STATUS',
  hasOpenReview = 'HAS_OPEN_CYCLE',
  default = 'DEFAULT',
}

export enum nonEngagedCyclesTableSortColumnsName {
  twerName = 'TWER_NAME',
  performancePartnerName = 'PP_NAME',
  pohName = 'POH',
  superOrgName = 'SUPER_ORG_NAME',
  office = 'OFFICE',
  projectName = 'PROJECT_NAME',
  actions = 'ACTIONS',
  cycleDuration = 'CYCLE_DURATION',
}

export enum NonEngagedCyclesSortColumnsName {
  revieweeName = 'REVIEWEE_NAME',
  projectName = 'PROJECT_NAME',
  poh = 'POH',
  cycleDuration = 'REVIEW_DURATION',
  openBy = 'OPEN_BY',
  status = 'STATUS',
  perfomanceIndicators = 'RATING',
  default = 'DEFAULT',
}

export const AdminTableSortColumnsName = {
  ...GroupsListColumnSortColumnsName,
  ...NewRegionalAdminPageTableColumnsName,
  ...GroupMemberTableColumnsName,
};
export type AdminTableSortColumnsName = typeof AdminTableSortColumnsName;
