import { Collapse } from 'antd';
import React, { useState } from 'react';
import './index.less';
import { CATEGORY_LABEL } from '@@/_new_src_/constants/myCycles';
import { orderBy } from 'lodash';

interface IExpectationItemProps {
  title: string;
  content: string;
  categoryIds: number[];
}

const ExpectationItem = (props: IExpectationItemProps) => {
  const { title, content, categoryIds } = props;
  const { Panel } = Collapse;
  const [isActive, setIsActive] = useState(false);

  const renderPanelHeader = () => {
    const orderByName = orderBy(categoryIds, item => CATEGORY_LABEL[item].title);
    return (
      <>
        <div className="category-title">
          <span className="header-text">{title}</span>
          <div
            className="ri-arrow-down-s-line arrow-icon"
            style={{ rotate: isActive ? '180deg' : '0deg' }}
          />
        </div>
        <div className="category-labels">
          {orderByName.map((item, index) => {
            const { iconBackGroundColor, icon, contentBackGroundColor, title } =
              CATEGORY_LABEL[item];
            return (
              <div className="label" key={index}>
                <div
                  className="label-icon"
                  style={{
                    backgroundColor: iconBackGroundColor,
                  }}
                  dangerouslySetInnerHTML={{ __html: icon }}
                />
                <div
                  className="label-content"
                  style={{
                    backgroundColor: contentBackGroundColor,
                  }}
                >
                  <div>{title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="common-expectation-item">
      <Collapse
        bordered={false}
        className="expectation-collapse"
        onChange={() => {
          setIsActive(!isActive);
        }}
      >
        <Panel header={renderPanelHeader()} key={1} className="expectation-panel" showArrow={false}>
          <div className="line" />
          <div
            className={'expectation-item-content global-rich-text content'}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ExpectationItem;
