import { createSlice } from '@reduxjs/toolkit';
import { TABS_KEY, TALENT_INFORMATION_KEY } from '@@/common/constant/reviewee';

export const revieweeSlice = createSlice({
  name: 'reviewee',
  initialState: {
    showTalentInfo: false,
    reviewInput: '',
    isEditReviewInput: false,
    activeTab: TABS_KEY.REVIEW_DETAIL,
    currentReviewInputId: '',
    isEditRatingSummary: false,
    summaryState: undefined,
    selectedRating: undefined,
    isCheckedHP: undefined,
    isCheckedGC: undefined,
    isCheckedNoTalent: undefined,
  },
  reducers: {
    setShowTalentInfo: (state, action) => {
      state.showTalentInfo = action.payload;
    },
    setReviewInput: (state, action) => {
      state.reviewInput = action.payload;
    },
    setIsEditReviewInput: (state, action) => {
      state.isEditReviewInput = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCurrentReviewInputId: (state, action) => {
      state.currentReviewInputId = action.payload;
    },
    setIsEditRatingSummary: (state, action) => {
      state.isEditRatingSummary = action.payload;
    },
    setSummaryState: (state, action) => {
      state.summaryState = action.payload;
    },
    setSelectedRating: (state, action) => {
      state.selectedRating = action.payload;
    },
    setIsCheckedHP: (state, action) => {
      state.isCheckedHP = action.payload;
    },
    setIsCheckedGC: (state, action) => {
      state.isCheckedGC = action.payload;
    },
    setIsCheckedNoTalent: (state, action) => {
      state.isCheckedNoTalent = action.payload;
    },
  },
});

export const {
  setShowTalentInfo,
  setReviewInput,
  setIsEditReviewInput,
  setActiveTab,
  setCurrentReviewInputId,
  setIsEditRatingSummary,
  setSummaryState,
  setSelectedRating,
  setIsCheckedHP,
  setIsCheckedGC,
  setIsCheckedNoTalent,
} = revieweeSlice.actions;

export const selectShowTalentInfo = state => state.reviewee.showTalentInfo;
export const selectReviewInput = state => state.reviewee.reviewInput;
export const selectIsEditReviewInput = state => state.reviewee.isEditReviewInput;
export const selectActiveTab = state => state.reviewee.activeTab;
export const selectCurrentReviewInputId = state => state.reviewee.currentReviewInputId;
export const selectIsEditRatingSummary = state => state.reviewee.isEditRatingSummary;
export const selectSummaryState = state => state.reviewee.summaryState;
export const selectSelectedRating = state => state.reviewee.selectedRating;
export const selectIsCheckedHP = state => state.reviewee.isCheckedHP;
export const selectIsCheckedGC = state => state.reviewee.isCheckedGC;
export const selectIsCheckedNoTalent = state => state.reviewee.isCheckedNoTalent;
export const selectAdditionalTalentInformation = state => {
  const { isCheckedHP, isCheckedGC, isCheckedNoTalent } = state.reviewee;
  return [
    isCheckedHP && TALENT_INFORMATION_KEY.HIGH_POTENTIAL,
    isCheckedGC && TALENT_INFORMATION_KEY.POTENTIAL_FOR_GRADE_CHANGE,
    isCheckedNoTalent && TALENT_INFORMATION_KEY.NO_TALENT_INFORMATION,
  ].filter(Boolean);
};

export default revieweeSlice.reducer;
