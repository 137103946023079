import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { CYCLE_DISPLAY_PART, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import {
  isOperationCycle,
  myCycles,
  setCancelStatus,
  setCurrentCycleStatus,
  setCycleDetailData,
  setCycleDisplayPart,
  setCycleInformation,
  setExpectationList,
} from '@@/_new_src_/store/myCyclesSlice';
import {
  confirmModalType,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { Button, Tooltip } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { postCancelCycleAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  cycleDetailByVersion,
  setCurrentCycleDetail,
} from '@@/redux/slice/cycleDetailByVersionSlice';
import useLocalStorageCycle from '../../../../hooks/useLocalStorageCycle';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import CycleStatusBadge from '@@/_new_src_/features/CycleStatusBadge';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { UserInfoContext } from '@@/context/userInfo.context';
import CycleStatus from '@@/_new_src_/features/CycleStatus';
import { cycleStatusTag } from '@@/_new_src_/utils/feature/cycle';

function CycleStatusBar() {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const localeResource = useSelector(selectLocaleResource) as unknown;
  const showOperation = useSelector(isOperationCycle);

  const {
    cancelStatus: { success },
  } = useSelector(myCycles);
  const {
    currentCycleDetail: {
      id,
      status = '',
      isNoneEngaged,
      isTwer,
      isNewCycle,
      createBySystem,
      isOverdueCycle,
      isProbation,
    },
  } = useSelector(cycleDetailByVersion);
  const { futureCycleVersion } = useContext(UserInfoContext);
  const currentModalType = useSelector(confirmModalType);
  const {
    myCycles: {
      cycleStatus: { cancelCycleText, nonEngagedToolTip },
      cycleModal: { cancelCycle: cancelCycleLocale },
    },
  } = localeResource as IMyCyclesLocale;
  const {
    tag: { nonEngagedCycleByTBPTag },
  } = commonLocale;

  const { removeLocalCycle } = useLocalStorageCycle();

  const handleCancelCycle = () => {
    trackEvent({
      category: MY_CYCLE_LIST_API.category,
      action: isNewCycle
        ? MY_CYCLE_LIST_API.action.TWER_CANCELS_V2_CYCLE_WITH_THE_NEW_STATUS
        : MY_CYCLE_LIST_API.action.TWER_CANCELS_V2_CYCLE_WITH_THE_OPEN_STATUS,
    });
    dispatch(postCancelCycleAsync(id));
    dispatch(setConfirmModalLoading(true));
    removeLocalCycle();
  };
  const handleClick = () => {
    dispatch(setConfirmModalStatus(true));
    dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.CANCEL_CYCLE));
  };

  useEffect(() => {
    if (success) {
      dispatch(setConfirmModalLoading(false));
      dispatch(setCancelStatus({ loading: false, success: false }));
      dispatch(setConfirmModalStatus(false));
      dispatch(setConfirmModalType(''));
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.DEFAULT_DISPLAY));
      dispatch(setCurrentCycleStatus(''));
      dispatch(setCurrentCycleDetail({}));
      dispatch(setCycleDetailData({ data: {} }));
      dispatch(setCycleInformation({}));
      dispatch(setExpectationList([]));
      const currentPathname = window.location.pathname;
      if (currentPathname !== CYCLE_PAGE_LINK.MY_CYCLES) {
        history.push(CYCLE_PAGE_LINK.MY_CYCLES);
      }
    }
  }, [success]);
  return (
    <>
      <div className="cycle-status">
        <div className="bar-left">
          {CYCLE_VERSION.NEW === futureCycleVersion ? (
            <CycleStatusBadge
              status={
                status &&
                cycleStatusTag({
                  isOverdueCycle,
                  status: status,
                })
              }
            />
          ) : (
            <CycleStatus needSuffix={true} status={status} />
          )}

          {isNoneEngaged && (
            <div className="non-Engaged-Cycle-label">{nonEngagedCycleByTBPTag}</div>
          )}
        </div>

        {!createBySystem &&
          showOperation &&
          !isOverdueCycle &&
          ((isNoneEngaged || isProbation) && isTwer ? (
            <div className="status-button">
              <Tooltip title={isProbation ? '' : nonEngagedToolTip}>
                <Button disabled className="cancel-button" onClick={handleClick}>
                  <i className="ri-close-circle-line" />
                  <p>{cancelCycleText}</p>
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="status-button">
              <Button className="cancel-button" onClick={handleClick}>
                <i className="ri-close-circle-line" />
                <p>{cancelCycleText}</p>
              </Button>
            </div>
          ))}

        {currentModalType === CONFIRM_MODAL_TYPE_INFO.CANCEL_CYCLE && (
          <ConfirmModal
            title={cancelCycleLocale.title}
            content={cancelCycleLocale.content}
            okText={cancelCycleLocale.okText}
            cancelText={cancelCycleLocale.cancelText}
            handleOkFunc={handleCancelCycle}
          />
        )}
      </div>
    </>
  );
}

export default CycleStatusBar;
