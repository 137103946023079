import { getApiPath, getApiV2Path } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import {
  IAddExpectationProps,
  ICreateInformationProps,
  IUpdateExpectationProps,
  IUpdateInformationProps,
} from '@@/_new_src_/store/myCyclesSlice/interface';
import { RcFile } from 'antd/lib/upload';
import { omit } from 'lodash';

export const getOngoingCycle = () => ajax.get(getApiV2Path(`/ongoing-cycle`));

export const getCycleByCycleId = (cycleId: number) => ajax.get(getApiV2Path(`/cycles/${cycleId}`));

export const postCancelCycle = (cycleId: number) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/cancellation`));

export const postAddExpectation = (params: IAddExpectationProps) =>
  ajax.post(getApiV2Path(`/cycles/${params.cycleId}/expectations`), omit(params, 'cycleId'));

export const putUpdateExpectation = (params: IUpdateExpectationProps) =>
  ajax.put(getApiV2Path(`/cycles/expectations/${params.id}`), omit(params, 'id'));

export const deleteExpectation = (expectationId: number) =>
  ajax.delete(getApiV2Path(`/cycles/expectations/${expectationId}`));

export const uploadAttachment = (file: RcFile) => {
  const formData = new FormData();
  formData.append('file', file);
  return ajax.post(getApiV2Path(`/upload`), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const postCreateCycleInformation = (params: ICreateInformationProps) =>
  ajax.post(getApiV2Path('/cycles'), params);

export const putUpdateCycleInformation = (params: IUpdateInformationProps) =>
  ajax.put(getApiV2Path(`/cycles/${params.cycleId}`), omit(params, 'cycleId'));

export const postOpenNormalCycle = (cycleId: number) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/open`));

export const getAttachmentsUrl = (cycleId: number) =>
  ajax.get(getApiV2Path(`/performance_reviews/cycle/attachments/${cycleId}`));

export const getCycleFeedbacks = (cycleId: number) =>
  ajax.get(getApiV2Path(`/cycles/${cycleId}/feedbacks`));

export const getPastCyclesByYear = (year: string) =>
  ajax.get(getApiV2Path(`/cycles/${year}/pastCycles`));

export const getPastReviewAvailableYear = () =>
  ajax.get(getApiPath('/performance-reviews/reviewee/past-review-year'));

export const getOverdueAndNonEngagedTip = () =>
  ajax.get(getApiV2Path('/cycles/overdue-and-non-engaged-cycles-tips'));

export const postAssessmentNonEngagedTip = (cycleId: number) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/assessment-non-engaged-tip`));

export const postConfirmExpectationNonEngaged = (cycleId: number) =>
  ajax.post(getApiV2Path(`/cycles/${cycleId}/confirm-expectation-non-engaged`));
