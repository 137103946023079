import { DOCUMENT_LINK } from '@@/_new_src_/constants/pagePath';
import { TBP_BULK_UPLOAD_INDICATOR_DURATION } from '@@/_new_src_/constants/common';

export default {
  title: 'Bulk calibrate performance indicators',
  info: {
    describe: `Ensure the calibration discussion is completed, and you have gathered comprehensive calibrated data before proceeding with the upload. 
       </br>To support calibration and post-calibration changes, the upload feature will remain <b>available during: ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H1.startDate} - ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H1.endDate}(H1) and ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H2.startDate} - ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H2.endDate}(H2).</b>`,
    step: 'Follow these steps to upload:',
    step1: `Step1 - <a href="${DOCUMENT_LINK.TBP_BULK_UPLOAD_TEMPLATE_LINK}" target="_blank">Get template here</a>  (Spreadsheet, 10KB)`,
    step2:
      'Step2 - Fill in the template with <b>TWer’s IDs</b>, calibrated <b>indicators</b>, and <b>reasons</b> for the changes.',
    step3: 'Step3 - Upload the completed file.',
  },
  errorMessage: {
    MISS_FIELD_ERROR: `Mandatory fields are missing, please make sure each line of item covers TWer’s IDs, calibrated indicators, and reasons:`,
    INVALID_INPUT_ERROR: 'Invalid input:',
    INVALID_REASON_ERROR: `The length of one or more fields in the uploaded file does not meet the requirements. Please ensure that the 'reasons' input is between 10 and 5000 characters: `,
    DUPLICATE_EMPLOYEE_ID_ERROR: 'Some TWers are duplicated in the file:',
    GMT_EMPLOYEE_ID_ERROR: 'Some TWers are from GMT group which are excluded from calibration:',
    INVALID_EMPLOYEE_ID_ERROR: 'Invalid IDs:',
    EXIT_EMPLOYEE_ID_ERROR: 'Some TWers have exited:',
    INVALID_SCOPE_EMPLOYEE_ID_ERROR: 'Some TWers are not from your Region/POH/Function:',
    INVALID_CALIBRATING_EMPLOYEE_ID_ERROR:
      'Below TWers don’t have cycles in calibrating, please check if relevant cycles are “non-engaged”, probationary or in other special situations:',
    INVALID_FILE_ERROR:
      'Invalid file or content format. Please upload the correct file that follows the template.',
  },
  warningText: `Import <b>performance changes only</b> to avoid unnecessary modifications`,
  upload: {
    toolTip: `This feature will be available from ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H1.startDate} to ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H1.endDate} for H1 performance cycle, and from ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H2.startDate} to ${TBP_BULK_UPLOAD_INDICATOR_DURATION.H2.endDate} for H2.`,
    buttonText: 'Upload',
    successTip: (number: number) => `${number} Indicators have been updated successfully!`,
    confirmModal: {
      title: 'Are you sure to upload?',
      content: (date: string) =>
        `<div class="upload-calibration-content">Once bulk upload is confirmed, the performance <b>indicators of relevant cycles will be updated,</b> the calibration history and reasons for changes will be visible to PP, AP, TBP, Leaderships. You can update calibrated result multiple times until <b>${date}.</b> 
          <div class="notes">
            <div class="note-title">Note:</div>
            <ul class="note-list">
              <li>If you update indicators for cycles in “Calibrating” status: the cycle owner <span style="text-decoration: underline">will not be notified</span> about the indicator until the PP shares the assessment result during the communication stage.</li>
              <li>For cycles that have completed communication stage and are in “DONE” status: the cycle owner <span style="text-decoration: underline">will be notified</span> immediately after the update.</li>
            </ul>
         </div>
        </div>`,
      okBtnText: 'Yes, upload',
      cancelBtnText: 'No, cancel upload',
    },
  },

  calibrationHistoryList: {
    date: 'Date: ',
    updatedIndicator: 'Updated indicators: ',
    reason: 'Calibration reasons: ',
    originalIndicator: 'Indicator before calibration: ',
  },
};
