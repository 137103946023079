import React from 'react';
import { useSelector } from 'react-redux';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { cycleDisplayPart } from '@@/_new_src_/store/myCyclesSlice';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import CycleExpectationForm from '../CycleExpectationForm';
import { cycleDetailData, isUpdatingExpectation } from '@@/_new_src_/store/myCyclesSlice';

const CycleExpectationFormPage = () => {
  const currentPart = useSelector(cycleDisplayPart);
  const { loading } = useSelector(cycleDetailData);
  const isLoadingExpectation = useSelector(isUpdatingExpectation);
  return (
    <>
      {currentPart === CYCLE_DISPLAY_PART.EXPECTATION_FORM && (
        <div className="cycle-expectation-form-page">
          <LoadingWrapper loading={loading || isLoadingExpectation}>
            <CycleExpectationForm />
          </LoadingWrapper>
        </div>
      )}
    </>
  );
};

export default CycleExpectationFormPage;
