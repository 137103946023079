import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getRegionalGroups,
  getRegionalLevelGroupDetail,
  putUpdateRegionalGroups,
} from '@@/_new_src_/api/admin';
import { IUpdateRegionalGroupsParams, IRegionalLevelGroupDetailParams } from '@@/types/admin';

export const getRegionalLevelGroupDetailAsync = createAsyncThunk(
  'getRegionalLevelGroupDetail',
  async (data: IRegionalLevelGroupDetailParams) => {
    return await getRegionalLevelGroupDetail(data);
  },
);

export const putUpdateRegionalGroupsAsync = createAsyncThunk(
  'putUpdateRegionalGroups',
  async (data: IUpdateRegionalGroupsParams) => {
    await putUpdateRegionalGroups(data);
  },
);

export const getRegionalGroupsAsync = createAsyncThunk(
  'getRegionalGroups',
  async (regionId: number) => {
    return await getRegionalGroups(regionId);
  },
);
