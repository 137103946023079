import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import queryString from 'query-string';
import * as Api from '@@/api/review';
import useLocalStorageReview from '@@/hooks/useLocalStorageReview';
import { ReviewStepsContext } from '@@/context/reviewSteps.context';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  clearCurrentReviewState,
  getMyCycleByTypeAsync,
  reviewState,
} from '@@/redux/slice/reviewSlice';
import {
  cycleFeedbacks,
  myCycles,
  resetMyCycleSlice,
  setAttachment,
  setCycleDetailData,
  setCycleDisplayPart,
  setCycleVersion,
  deleteExpectationStatus,
} from '@@/_new_src_/store/myCyclesSlice';
import { userInfo } from '@@/_new_src_/store/userSlice';
import {
  cycleDetailByVersion,
  getCurrentCycleDetailByVersionAsync,
  setCurrentCycleDetail,
} from '@@/redux/slice/cycleDetailByVersionSlice';
import {
  getOngoingCycleAsync,
  getOverdueAndNonEngagedTipAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';

import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import RequestRejectedTips from '@@/common/component/RequestRejectedTips';
import ReviewDetailPreview from '@@/common/component/ReviewDetailPreview';
import CycleHistoryList from '@@/features/performance/reviews/reviewList/CycleHistoryList';
import CycleTip from '@@/features/performance/reviews/reviewList/Component/CycleTip';
import LimitMultiPartnerModal from './LimitMultiPartnerModal';
import { MY_CYCLE_LIST_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { CYCLE_DISPLAY_PART, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { REVIEW_STEP } from '@@/common/constant/steps';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';

import './index.less';

const ReviewList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const reviewStepsContext = useContext(ReviewStepsContext);
  const {
    reviewHistory: locale = {},
    reviewList: { multiPartnerModalContent: modalLocale = {} },
  } = useSelector(selectLocaleResource);
  const { removeLocalReview } = useLocalStorageReview();
  const { cycleDetailData } = useSelector(myCycles);
  const { loading: feedbackLoading } = useSelector(cycleFeedbacks);
  const { userId, currentCycleVersion: cycleVersion } = useSelector(userInfo);

  const {
    cyclesCycleDetailV1,
    historyCycleList: { loading: reviewHistoryLoading },
    cycleHistoryYearList: { loading: reviewHistoryAvailableYearLoading },
  } = useSelector(reviewState);

  const { loading: isDeleteExpectationLoading } = useSelector(deleteExpectationStatus);

  const {
    currentCycleDetail: {
      id: cycleId,
      cycleDetailLoading,
      additionalPartners = [],
      editRequestRejected,
      assessmentUpdated,
      checkInNotes,
      isNewCycle,
      isOpenCycle,
      isCalibratingCycle,
      isUnreadCycle,
      isOpenOrDraftCycle,
      isTwer,
      isPerformancePartner,
    },
  } = useSelector(cycleDetailByVersion);

  useEffect(() => {
    if (cycleVersion === CYCLE_VERSION.NEW && isNewCycle) {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
      dispatch(
        setAttachment({
          type: AttachmentType.CYCLE_INFORMATION,
          data: {
            success: false,
          },
        }),
      );
      history.push(CYCLE_PAGE_LINK.NEW_CYCLES_FORM);
    }
  }, [cycleVersion, isNewCycle]);

  const getCurrentDetailFunc = useCallback(() => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      dispatch(getOngoingCycleAsync());
    } else {
      dispatch(getMyCycleByTypeAsync());
    }
  }, [cycleVersion]);

  const getInitialCycle = () => {
    dispatch(setCycleVersion(cycleVersion));
    getCurrentDetailFunc();
  };

  useEffect(() => {
    getInitialCycle();
    dispatch(setCycleDetailData({ data: {} }));
    dispatch(resetMyCycleSlice());
    dispatch(getOverdueAndNonEngagedTipAsync());
  }, []);

  const getDetailCallback = data => {
    if (data.status) {
      const { gotoSetReview } = queryString.parse(location.search);
      if (isUnreadCycle) {
        removeLocalReview();
        dispatch(clearCurrentReviewState());
      }
      if (gotoSetReview === 'true') {
        removeLocalReview();
        if (!isEmpty(data.id)) {
          history.replace(`${CYCLE_PAGE_LINK.OLD_CYCLES_FORM}/${data.id}`);
        } else {
          history.replace(CYCLE_PAGE_LINK.OLD_CYCLES_FORM);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setCurrentCycleDetail({}));
    (!isNil(cyclesCycleDetailV1.data) || !isNil(cycleDetailData.data)) &&
      userId &&
      dispatch(
        getCurrentCycleDetailByVersionAsync({
          userId,
          cycleVersion,
          cycleDetailByVersion1: cyclesCycleDetailV1,
          cycleDetailByVersion2: cycleDetailData,
        }),
      );
    !isEmpty(cyclesCycleDetailV1.data) && getDetailCallback(cyclesCycleDetailV1.data);
  }, [userId, cyclesCycleDetailV1.data, cycleDetailData.data]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const closeMultiPartnerModal = useCallback(() => {
    setIsVisibleModal(false);
  }, []);

  useEffect(() => {
    setIsVisibleModal(
      isOpenOrDraftCycle && additionalPartners && additionalPartners.length > LIMIT_PARTNER_NUM,
    );
  }, [isOpenOrDraftCycle, additionalPartners]);

  const updateReviewStepsStatus = useCallback(() => {
    if (isOpenCycle) {
      reviewStepsContext.setCurrent(REVIEW_STEP.REGULAR_CHECKINS);
      if (!isEmpty(checkInNotes)) {
        reviewStepsContext.setAddedCheckinNotes(true);
      }
      return;
    }
    if (isUnreadCycle || isCalibratingCycle) {
      reviewStepsContext.setCurrent(REVIEW_STEP.PERFORMANCE_ASSESSMENT);
      return;
    }
    reviewStepsContext.setCurrent(null);
    reviewStepsContext.setAddedCheckinNotes(false);
  }, [isOpenCycle, isCalibratingCycle, checkInNotes, isUnreadCycle, reviewStepsContext]);

  useEffect(() => {
    updateReviewStepsStatus();
  }, [updateReviewStepsStatus]);

  const onReadUpdatedAssessment = useCallback(
    reviewId => () => {
      Api.readUpdatedAssessment(reviewId).then();
    },
    [],
  );
  const onReadRequestRejected = useCallback(
    reviewId => () => {
      isTwer &&
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action:
            MY_CYCLE_LIST_API.action.TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_LIST,
        });

      isPerformancePartner &&
        trackEvent({
          category: TWER_I_SUPPORT_BY_PP_API.category,
          action: TWER_I_SUPPORT_BY_PP_API.action.PP_READS_REJECT_TO_CYCLE_IN_CYCLE_LIST,
        });
      Api.readRequestRejected(reviewId).then();
    },
    [],
  );

  const AssessmentUpdatedTips = ({ reviewId }) => (
    <Alert
      showIcon
      closable
      type="info"
      className="assessment-updated-tips"
      message={locale.assessmentUpdatedTips}
      onClose={onReadUpdatedAssessment(reviewId)}
    />
  );

  const onGetCurrentReviewAndHistoryList = () => {
    getInitialCycle();
  };

  const renderReviewDetailPreviewExtra = useCallback(() => {
    const extraElement = [];
    if (editRequestRejected) {
      extraElement.push(
        <RequestRejectedTips
          key={`assessment-updated-tips-${cycleId}`}
          message={locale.requestRejectedTips}
          onClose={onReadRequestRejected(cycleId)}
        />,
      );
    }
    if (assessmentUpdated) {
      extraElement.push(
        <AssessmentUpdatedTips reviewId={cycleId} key={`request-rejected-tips-${cycleId}`} />,
      );
    }
    return extraElement;
  }, [
    locale.requestRejectedTips,
    onReadRequestRejected,
    editRequestRejected,
    assessmentUpdated,
    cycleId,
  ]);

  const onGotoEdit = () => {
    dispatch(setCycleVersion(cycleVersion));
    history.push(`${CYCLE_PAGE_LINK.OLD_CYCLES_FORM}/${cycleId}`);
  };

  const reviewListLoading = useMemo(() => {
    return (
      cycleDetailLoading ||
      reviewHistoryAvailableYearLoading ||
      reviewHistoryLoading ||
      feedbackLoading ||
      isDeleteExpectationLoading
    );
  }, [
    cycleDetailLoading,
    reviewHistoryAvailableYearLoading,
    reviewHistoryLoading,
    feedbackLoading,
    isDeleteExpectationLoading,
  ]);

  const isOngoingCycle = useMemo(() => {
    return isOpenCycle || isUnreadCycle || isCalibratingCycle;
  }, [isOpenCycle, isUnreadCycle, isCalibratingCycle]);

  return (
    <div className="review-list">
      <LoadingWrapper loading={reviewListLoading}>
        <CycleTip />
        {isOngoingCycle && (
          <ReviewDetailPreview
            showFeedbackTabThoughNoSubmittedFeedback
            key={cycleId}
            defaultExpand={isOngoingCycle}
            onCancelReviewSuccess={onGetCurrentReviewAndHistoryList}
            onMarkReviewAsReadSuccess={onGetCurrentReviewAndHistoryList}
            onSendEditRequestSuccess={getCurrentDetailFunc}
            onRevieweeReviewEditSuccess={getCurrentDetailFunc}
            onCheckInNotesModalSuccess={getCurrentDetailFunc}
            getCurrentReviewList={getCurrentDetailFunc}
            extra={isUnreadCycle && renderReviewDetailPreviewExtra()}
            isCurrentReviewDetail
          />
        )}
        <CycleTip tipVisibleByPosition={false} />
        <LimitMultiPartnerModal
          modalLocale={modalLocale}
          isVisibleModal={isVisibleModal}
          onCloseClick={closeMultiPartnerModal}
          onClickConfirm={onGotoEdit}
        />
        <CycleHistoryList />
      </LoadingWrapper>
    </div>
  );
};

export default memo(ReviewList);
