import { getApiPath, getApiV2Path } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import {
  IAddFeedback,
  IGetFeedbacksParams,
  IEditFeedback,
  IGetGiveFeedbacksParams,
  IUpdateFeedbackQuestion,
} from '../types/feedback';
import {
  IPostV1AddClientFeedback,
  IPostV1SendFeedbackRequest,
  IPutV1EditClientFeedback,
  IPutV1EditFeedbackRequest,
  IV1PostFeedbackParams,
} from '@@/_new_src_/store/V2FeedbackSlice/interface';

export const getCurrentCycleFeedbackList = (param: IGetFeedbacksParams) =>
  ajax.get(getApiV2Path(`/cycles/${param.cycleId}/basic-feedbacks`), {
    params: {
      sortColumnName: param.sortColumnName,
      sortOrder: param.sortOrder,
      pageSize: param.pageSize,
      pageNumber: param.pageNumber,
    },
  });

export const getGivenFeedbackRequests = (param: IGetGiveFeedbacksParams) =>
  ajax.get(getApiV2Path(`/feedbacks`), {
    params: {
      status: param.status,
      givenFeedbackSortColumn: param.givenFeedbackSortColumn,
      sortOrder: param.sortOrder,
      pageSize: param.pageSize,
      pageNumber: param.pageNumber,
    },
  });

export const postAddFeedback = (param: IAddFeedback) =>
  ajax.post(getApiV2Path(`/cycles/${param.cycleId}/feedbacks`), param);

export const putEditFeedback = (param: IEditFeedback) =>
  ajax.put(getApiV2Path(`/feedbacks/${param.feedbackId}`), {
    type: param.type,
    client: param.client,
    internal: param.internal,
  });

export const putCancelEditFeedback = (param: IEditFeedback) =>
  ajax.put(getApiV2Path(`/feedbacks/${param.feedbackId}/cancellation`), {
    type: param.type,
  });

export const getFeedbackTemplates = () => ajax.get(getApiV2Path('/feedback-template-questions'));

export const getFeedbackDetail = (id: number) => ajax.get(getApiV2Path(`/feedbacks/${id}`));

export const patchGivenFeedback = (id: number, params: IUpdateFeedbackQuestion) =>
  ajax.patch(getApiV2Path(`/feedbacks/${id}/questions`), params);

export const postV1Feedback = ({
  feedbackId,
  feedbackContent,
  feedbackProjectId,
  feedbackProjectName,
  feedbackProjectAccount,
  isDraft,
}: IV1PostFeedbackParams) =>
  ajax.post(getApiPath(`/feedbacks/${feedbackId}`), {
    feedbackContent,
    feedbackProjectId,
    feedbackProjectName,
    feedbackProjectAccount,
    isDraft,
  });

export const postV1AddClientFeedback = (params: IPostV1AddClientFeedback) =>
  ajax.post(getApiPath('/feedbacks/client'), params);

export const putV1EditClientFeedback = (feedbackId: number, params: IPutV1EditClientFeedback) =>
  ajax.put(getApiPath(`/feedbacks/client/${feedbackId}`), params);

export const putV1EditFeedbackRequest = ({
  feedbackId,
  dueTime,
  context,
}: IPutV1EditFeedbackRequest) =>
  ajax.put(getApiPath(`/feedbacks/internal/${feedbackId}`), { dueTime, context });

export const postV1SendFeedbackRequest = (data: IPostV1SendFeedbackRequest) =>
  ajax.post(getApiPath('/feedbacks/internal'), data);
