/* eslint-disable no-param-reassign */
import axios, { CancelToken } from 'axios';
import { get } from 'lodash';
import { getLocale, getLocaleResource } from '../locale/helper';
import perfMessage from './component/PerfMessage/perfMessage';
import { HTTP_STATUS } from './constant/http';
import history from './history';
import { getBaseUrl } from './utils';
import { stringify } from './utils/qs';
import { getUuid } from '@@/_new_src_/utils';
import { setOriginalUriAndSignIn } from '@@/_new_src_/utils/common/auth';
import { ERROR_PAGE } from '@@/_new_src_/constants/pagePath';
export const ajax = axios.create({
  baseURL: getBaseUrl(),
  paramsSerializer(params) {
    return stringify(params);
  },
});

export const ajaxWithoutRedirect = axios.create({
  baseURL: getBaseUrl(),
  paramsSerializer(params) {
    return stringify(params);
  },
});

const addTokenInHeaders = async (config, oktaAuth) => {
  let uuid = getUuid();
  const { accessToken } = await oktaAuth.tokenManager.getTokens();
  if (!accessToken) {
    setOriginalUriAndSignIn(oktaAuth);
    return {
      ...config,
      cancelToken: new CancelToken(cancel => cancel('Cancel invalid token request')),
    };
  }
  if (process.env.NODE_ENV === 'development') {
    config.headers['X-Apigateway-Api-Userinfo'] = accessToken.value.split('.')[1];
  } else {
    config.headers.Authorization = `Bearer ${accessToken.value}` || 'Bearer ';
  }
  config.headers.RequestId = uuid;
  config.headers['Accept-Language'] = getLocale();
  return config;
};

const handleResponseError = (err, oktaAuth, redirectForbidden, redirectNotFound) => {
  const { api } = getLocaleResource();
  let message = api.errorMessage;
  const updateErrorMessage = errorMessage => {
    message = errorMessage;
  };
  const hideErrorMessage = () => {
    message = '';
  };
  const reject = () =>
    Promise.reject({
      error: err,
      updateErrorMessage,
      hideErrorMessage,
    });

  if (!axios.isCancel(err)) {
    const statusCode = get(err, 'response.status');
    if (err.message === 'Network Error' || statusCode === HTTP_STATUS.UNAUTHORIZED) {
      return oktaAuth.tokenManager
        .renew('accessToken')
        .then(({ accessToken }) => {
          err.config.headers.Authorization = `Bearer ${accessToken}` || 'Bearer ';
          return axios.request(err.config);
        })
        .catch(async () => {
          setOriginalUriAndSignIn(oktaAuth);
          return reject();
        });
    }

    if (statusCode === HTTP_STATUS.NOTFOUND) {
      if (redirectNotFound) {
        history.replace(ERROR_PAGE.IS_404);
      }
      return reject();
    }

    if (statusCode === HTTP_STATUS.FORBIDDEN) {
      if (redirectForbidden) {
        history.replace(ERROR_PAGE.IS_403);
      }
      return reject();
    }

    if (statusCode < HTTP_STATUS.INTERNAL_SERVER_ERROR) {
      message = get(err, 'response.data.message', message);
    }

    setTimeout(() => {
      if (message) {
        perfMessage.error(message);
      }
    });

    return reject();
  }
  return reject();
};

export const addInterceptors = oktaAuth => {
  ajax.interceptors.request.use(
    async config => addTokenInHeaders(config, oktaAuth),
    error => Promise.reject(error),
  );
  ajax.interceptors.response.use(
    response => response,
    err => handleResponseError(err, oktaAuth, true, true),
  );
  ajaxWithoutRedirect.interceptors.request.use(
    async config => addTokenInHeaders(config, oktaAuth),
    error => Promise.reject(error),
  );
  ajaxWithoutRedirect.interceptors.response.use(
    response => response,
    err => handleResponseError(err, oktaAuth, false, false),
  );
};

export const errorWrapper = (api, options = {}) => {
  const {
    httpStatusWhiteList = [HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.FORBIDDEN],
    message,
    ignoreMessage,
  } = options;

  return new Promise((resolve, reject) => {
    api()
      .then(resolve)
      .catch(({ error, updateErrorMessage, hideErrorMessage }) => {
        const errorResponse = get(error, 'response', {});
        const { status: errorStatus, data: errorData = {} } = errorResponse;
        if (!httpStatusWhiteList.includes(errorStatus)) {
          if (message) {
            updateErrorMessage(message(getLocaleResource()));
          }
          if (ignoreMessage && ignoreMessage(errorData.errorCode)) {
            hideErrorMessage();
          }
          reject(errorData);
        }
      });
  });
};

export const sleep = (duration = 0) =>
  new Promise(res => {
    setTimeout(() => {
      res();
    }, duration);
  });
