import React from 'react';
import { useSelector } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import ArrowIcon from '@@/assets/images/arrow-landing-summit.svg';
import LandingPageBg from '@@/assets/images/landing-page-background.svg';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { CycleButtonAndTip } from './Component/CycleButtonAndTip';
import { ExpectDescription } from './Component/ExpectDescription';
import { V1ExpectDescription } from './V1Component/V1ExpectDescription';
import locale from '@@/_new_src_/local/landing/en_US';
import './index.less';

function LandingPage() {
  const { futureCycleVersion } = useSelector(userInfo);
  const onScrollToExpectSection = () => {
    smoothscroll.polyfill();
    const expectScrollToElement = document.getElementsByClassName('landing-page-expect')[0];
    window.scrollTo({
      top: window.scrollY + expectScrollToElement.getBoundingClientRect().top,
      behavior: 'smooth',
    });
  };

  return (
    <div className="landing-page">
      <img src={LandingPageBg} alt="" className="landing-page-bg" />
      <div className="landing-page-wrapper">
        <h2 className="landing-page-title">{locale.title}</h2>
        <p className="landing-page-desc">{locale.desc}</p>
        {futureCycleVersion === CYCLE_VERSION.NEW ? (
          <>
            <CycleButtonAndTip />
            <div className="landing-page-arrow" onClick={onScrollToExpectSection}>
              <img src={ArrowIcon} alt="Arrow" />
            </div>
            <ExpectDescription />
          </>
        ) : (
          <>
            <div className="landing-page-arrow" onClick={onScrollToExpectSection}>
              <img src={ArrowIcon} alt="" />
            </div>
            <V1ExpectDescription />
          </>
        )}
      </div>
    </div>
  );
}

export default LandingPage;
