import { filter } from 'lodash';
import { UserInfo } from '@@/types/user';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

export const getRoleInfo = (adminRole: USER_ROLE_ENUM, userInfo: UserInfo) => {
  const { userRolesInfoInV2 } = userInfo;
  return filter(userRolesInfoInV2, { role: adminRole });
};

export const getRegionalAdminRoleInfo = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.PS_ADMIN, userInfo);

export const getUnitTalentBusinessPartnerRoleInfos = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.TALENT_BP, userInfo);

export const getGlobalAdminRoleInfo = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.GLOBAL_ADMIN, userInfo)[0];

export const getNormalUserInfo = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.NORMAL_USER, userInfo)[0];

export const getGroupManagerInfos = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER, userInfo);

export const getRegionalLevelGroupManager = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.REGIONAL_LEVEL_GROUP_MANAGER, userInfo);

export const getOpsAdminRoleInfo = (userInfo: UserInfo) =>
  getRoleInfo(USER_ROLE_ENUM.OPS_ADMIN, userInfo)[0];
