import React from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { DOCUMENT_LINK } from '@@/_new_src_/constants/pagePath';
import UploadExcel from '@@/_new_src_/components/UploadExcel';
import './index.less';

interface PropsType {
  getFileList: (file: UploadFile[]) => void;
  getExcelData: (data: unknown[]) => void;
  clearCreateGroupError: () => void;
}

function UploadFiles(props: PropsType) {
  const { getFileList, clearCreateGroupError, getExcelData } = props;

  const localeResource = useSelector(selectLocaleResource) as unknown;

  const {
    admin: {
      manageGroupsOfTBP: { groupModal: locale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        groupModal: {
          ifYouReUploadTheFileWillReplaceThePreviousGroupMemberList: string;
          youCanOnlyAddPeopleWithoutSpecialRole: string;
          toThisAreaToUpload: string;
          aSingleExcelFile: string;
          clickOrDrag: string;
          getTemplateHere: string;
          members: {
            label: string;
            import: string;
          };
        };
      };
    };
  };

  return (
    <div className="upload-file-wrapper">
      <div className="upload-label-section">
        <div className="upload-label">{locale.members.label} </div>
        <div className="file-template">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={DOCUMENT_LINK.GROUP_MEMBER_TEMPLATE_FILE_LINK}
          >
            {locale.getTemplateHere}
          </a>
        </div>
      </div>

      <UploadExcel
        getFileList={getFileList}
        clearError={clearCreateGroupError}
        getExcelData={getExcelData}
        uploadTipTexts={[locale.youCanOnlyAddPeopleWithoutSpecialRole]}
        warningText={locale.ifYouReUploadTheFileWillReplaceThePreviousGroupMemberList}
      />
    </div>
  );
}

export default UploadFiles;
