import PerfModal from '@@/common/component/PerfModal';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import { feedbackDetail, setFeedbackDetail } from '@@/_new_src_/store/V2FeedbackSlice';
import { getFeedbackDetailAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import feedbackLocale from '@@/_new_src_/local/feedback/en_US';
import { UserInfoContext } from '@@/context/userInfo.context';
import { dateWithYearMonthDay } from '@@/_new_src_/constants/dateFormatter';
import ElasticCollapse from '@@/_new_src_/components/ElasticCollapse';
import commonLocale from '@@/_new_src_/local/common/en_US';
import {
  formatRangeDuration,
  formatTimeFun,
  getNewDateFormatByProbation,
} from '@@/_new_src_/utils/common/date';

interface IProps {
  onCancel: () => void;
  visible: boolean;
  feedbackId: number;
}

const V1DetailModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { userId } = useContext(UserInfoContext);
  const { visible, feedbackId, onCancel } = props;

  const {
    cycle: { projectName, duration, probation },
    feedback: { providerId, requestName, providerName, requestRole, dueDate, context, content },
    loading: isFeedbackDetailLoading,
  } = useSelector(feedbackDetail);

  const {
    common: { emptyPlaceholder: emptyPlaceholderLocale },
  } = commonLocale;

  const {
    feedback: {
      feedbackDetail: {
        header: headerLocale,
        project: projectLocale,
        duration: durationLocale,
        dueDate: dueDateLocale,
        contextTitle: contextTitleLocale,
        role: roleLocale,
        feedback: feedbackLabelLocale,
      },
    },
  } = feedbackLocale;

  useEffect(() => {
    visible && feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
  }, [visible, feedbackId]);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackDetail({}));
    };
  }, []);

  const isGiveFeedback = useMemo(() => {
    return (userId && +userId === +providerId) || false;
  }, [userId, providerId]);

  const getHeader = useMemo(() => {
    const curName = isGiveFeedback ? requestName : providerName;
    return headerLocale(isGiveFeedback, curName);
  }, [isGiveFeedback, requestName, providerName]);

  return (
    <>
      {!isFeedbackDetailLoading && visible && (
        <PerfModal
          loading={isFeedbackDetailLoading}
          title={getHeader}
          className="perf-modal"
          visible={visible}
          onCancel={onCancel}
          footer={null}
          destroyOnClose
        >
          <div className="v1-detail-modal">
            <div className="basic-info">
              <div className="detail-item current-project-name">
                <span className="label">{projectLocale}</span>
                {projectName}
              </div>
              <div className="detail-item review-duration">
                <span className="label">{durationLocale}</span>
                {formatRangeDuration(duration.startTime, duration.endTime, {
                  format: getNewDateFormatByProbation(probation),
                })}
              </div>
              <div className="detail-item role">
                <span className="label">{roleLocale}</span>
                {requestRole}
              </div>
              <div className="detail-item due-date">
                <span className="label">{dueDateLocale}</span>
                {formatTimeFun(dueDate, dateWithYearMonthDay)}
              </div>
            </div>
            {context && (
              <div className="detail-item context">
                <div className="label">{contextTitleLocale}</div>
                <ElasticCollapse
                  className="context-info"
                  content={<div dangerouslySetInnerHTML={{ __html: context }} />}
                />
              </div>
            )}
            <div className="detail-item feedback-info">
              <div className="label">{feedbackLabelLocale}</div>
              <ElasticCollapse
                className="context-info"
                content={
                  <div dangerouslySetInnerHTML={{ __html: content || emptyPlaceholderLocale }} />
                }
              />
            </div>
          </div>
        </PerfModal>
      )}
    </>
  );
};

export default V1DetailModal;
