import React, { useEffect, useMemo, useState } from 'react';
import locale from '@@/_new_src_/local/feedback/en_US';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import './index.less';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  FEEDBACK_ANSWER_MAX_LENGTH,
  FEEDBACK_TYPE,
  OPERATION_TYPE,
} from '@@/_new_src_/constants/feedback';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import {
  V2FeedbackStore,
  setClientFeedback,
  setFeedbackDetail,
  setUpdateFeedbackInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import {
  getFeedbackDetailAsync,
  postAddFeedbackAsync,
  putEditFeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { IAddFeedback, IEditFeedback } from '@@/_new_src_/types/feedback';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import { IV2Project } from '@@/_new_src_/types/common';
import { IDurationTime } from '@@/_new_src_/store/cycleDetailSlice/interface';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import { get } from 'lodash';
import dayjs from 'dayjs';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import perfMessage from '@@/_new_src_/components/perfMessage';
import { useParams } from 'react-router';
import { dateWithYearMonth, dateWithYearMonthTime } from '@@/_new_src_/constants/dateFormatter';
import { REQUEST_FEEDBACK_MANAGEMENT } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { formatTimeFun, getNewDateFormatByProbation } from '@@/_new_src_/utils/common/date';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { getOngoingCycleAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';

const SendClientFeedbackForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const params = useParams();
  const { id: feedbackId } = params as { id: number };

  const [form] = Form.useForm();
  const {
    feedback: {
      sendClientFeedbackForm: {
        projectTip,
        durationTip,
        backToList,
        addTitle,
        editTitle,
        cancel,
        add,
        save,
        confirmModalInfo: { title, modalContent, cancelText, okText },
      },
      clientFeedbackForm: { successMessage, editSuccessMessage },
    },
  } = locale;

  const {
    feedback: {
      clientFeedbackForm: { updatedTimeHint },
      sendClientFeedbackForm: { nameTitle, contentTitle },
    },
  } = FeedbackLocale;

  const {
    clientFeedback: clientFeedbackStore,
    clientFeedback: {
      updateClientText: clientFeedbackContentText,
      updateClientHtml: clientFeedbackContentHtml,
      clientName: clientFeedbackClientName,
    },
    feedbackDetail: {
      feedback: { providerName, content: clientContent, updatedAt },
    },
    updateFeedbackInfo: {
      loading,
      type: updateFeedbackInfoType,
      success: updateFeedbackInfoSuccess,
      operationType,
    },
  } = useSelector(V2FeedbackStore);

  const {
    data: { id: cycleId, project, duration, isProbation },
  } = useSelector(cycleDetailData) as {
    data: {
      id: number;
      project: IV2Project;
      duration: IDurationTime;
      isProbation: boolean;
    };
  };

  const [disabled, setDisabled] = useState(true);
  const [feedbackHtml, setFeedbackHtml] = useState(clientContent || '');
  const [feedbackText, setFeedbackText] = useState('');

  const isEdit = useMemo(() => feedbackId, [feedbackId]);

  const setClientContentText = (text: string) => {
    setFeedbackText(text);
  };

  const setClientContentHtml = (html: string) => {
    setFeedbackHtml(html);
  };

  useEffect(() => {
    dispatch(
      setClientFeedback({
        ...clientFeedbackStore,
        updateClientHtml: feedbackHtml,
        updateClientText: feedbackText,
      }),
    );
  }, [feedbackHtml, feedbackText]);

  const onClientNameChange = (name: string) => {
    dispatch(setClientFeedback({ ...clientFeedbackStore, clientName: name }));
  };

  const startTime = dayjs(get(duration, 'startTime')).format(
    getNewDateFormatByProbation(isProbation),
  );
  const endTime = dayjs(get(duration, 'endTime')).format(getNewDateFormatByProbation(isProbation));
  const handleConfirmOkFunc = () => {
    history.push(FEEDBACK_PAGE_LINK.INDEX);
    dispatch(setConfirmModalStatus(false));
  };

  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
  };
  const confirmModalProps = () => {
    return {
      title: title,
      content: modalContent,
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
      handleCancelFunc: handleConfirmCancelFunc,
    };
  };

  useEffect(() => {
    if (isEdit) {
      feedbackId && dispatch(getFeedbackDetailAsync(feedbackId));
    }
    dispatch(getOngoingCycleAsync());
  }, [isEdit]);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackDetail({}));
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ name: providerName });
    dispatch(setClientFeedback({ ...clientFeedbackStore, clientName: providerName }));
  }, [providerName]);

  const isAddFieldValidated = useMemo(
    () =>
      clientFeedbackContentText.length > 0 &&
      clientFeedbackClientName.length > 0 &&
      clientFeedbackContentText.length <= FEEDBACK_ANSWER_MAX_LENGTH,
    [clientFeedbackContentText, clientFeedbackClientName],
  );

  const isEditFieldChanged = useMemo(
    () => clientFeedbackContentHtml !== clientContent || providerName !== clientFeedbackClientName,
    [clientFeedbackContentHtml, clientFeedbackClientName],
  );

  const isAddFeedbackDisabled = () => {
    if (isEdit) {
      return !isEditFieldChanged || !isAddFieldValidated;
    }
    return !isAddFieldValidated;
  };

  useEffect(() => {
    setDisabled(isAddFeedbackDisabled());
  }, [clientFeedbackClientName, clientFeedbackContentText]);

  const handleCancel = () => {
    if (isEdit && isEditFieldChanged) {
      dispatch(setConfirmModalStatus(true));
      return;
    }
    if (!isEdit && (clientFeedbackContentHtml.length > 0 || clientFeedbackClientName.length > 0)) {
      dispatch(setConfirmModalStatus(true));
    } else {
      history.push(FEEDBACK_PAGE_LINK.INDEX);
    }
  };

  const handleAddOrSave = () => {
    if (isEdit) {
      if (feedbackId) {
        const editParam = {
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          feedbackId: feedbackId,
          client: { content: clientFeedbackContentHtml, requestTo: clientFeedbackClientName },
        } as IEditFeedback;
        dispatch(putEditFeedbackAsync(editParam));

        trackEvent({
          category: REQUEST_FEEDBACK_MANAGEMENT.category,
          action: REQUEST_FEEDBACK_MANAGEMENT.action.EDIT_CLIENT_FEEDBACK,
        });
      }
    } else {
      if (cycleId) {
        const addParam = {
          type: FEEDBACK_TYPE.CLIENT_FEEDBACK,
          cycleId: cycleId,
          client: { content: clientFeedbackContentHtml, requestTo: clientFeedbackClientName },
        } as IAddFeedback;
        dispatch(postAddFeedbackAsync(addParam));

        trackEvent({
          category: REQUEST_FEEDBACK_MANAGEMENT.category,
          action: REQUEST_FEEDBACK_MANAGEMENT.action.ADD_CLIENT_FEEDBACK,
        });
      }
    }
  };

  useEffect(() => {
    if (updateFeedbackInfoType === FEEDBACK_TYPE.CLIENT_FEEDBACK && updateFeedbackInfoSuccess) {
      if (operationType === OPERATION_TYPE.ADD) {
        perfMessage.success(successMessage);
      }
      if (operationType === OPERATION_TYPE.EDIT) {
        perfMessage.success(editSuccessMessage);
      }
      history.push(FEEDBACK_PAGE_LINK.INDEX);
      dispatch(setClientFeedback({}));
      dispatch(setUpdateFeedbackInfo({}));
    }
  }, [updateFeedbackInfoType, updateFeedbackInfoSuccess, operationType]);

  return (
    <div className={'send-client-feedback-form'}>
      <GoBackBanner title={backToList} goToBackFun={handleCancel} onKeyPress={handleCancel} />
      <div className="send-form-body">
        <div className={'title'} aria-label={'send client feedback form'}>
          {isEdit ? editTitle : addTitle}
        </div>
        <div className="project-info">
          <b>{projectTip}</b> {get(project, 'name')} | <b>{durationTip}</b>{' '}
          {dayjs.utc(startTime).local().format(dateWithYearMonth)} -{' '}
          {dayjs.utc(endTime).local().format(dateWithYearMonth)}
        </div>
        <Form form={form}>
          <div className="name-section">
            <label className="required-label">{nameTitle}</label>
            <Form.Item name="name">
              <Input
                maxLength={100}
                type="text"
                onChange={e => onClientNameChange(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="content-section">
            <label className="required-label">{contentTitle}</label>
            <Form.Item name="content" className="feedback-section">
              <TinyMceEditor
                initialValue={clientContent}
                updateHtmlInfo={setClientContentHtml}
                updateTextInfo={setClientContentText}
                maxLength={FEEDBACK_ANSWER_MAX_LENGTH}
              />
            </Form.Item>
            {isEdit && (
              <div className="last-updated-at">
                <i className="ri-history-line feedback-time-icon" />
                <span>
                  {updatedTimeHint}
                  {formatTimeFun(updatedAt, dateWithYearMonthTime)}
                </span>
              </div>
            )}
          </div>
          <Form.Item className={'button-block'} required={true}>
            <Button className={'cancel-btn'} onClick={handleCancel}>
              <i className="ri-close-circle-fill cancel-icon" />
              {cancel}
            </Button>
            <Button
              className={'send-btn'}
              loading={loading}
              onClick={handleAddOrSave}
              disabled={disabled}
            >
              <i className="ri-save-fill add-icon" />
              {isEdit ? save : add}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ConfirmModal {...confirmModalProps()} />
    </div>
  );
};
export default SendClientFeedbackForm;
