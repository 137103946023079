import { getUserList } from '@@/api/user';
import { UserInfoContext } from '@@/context/userInfo.context';
import { PermissionsItem } from '@@/features/performance/v2/admin/components/PermissionsModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { PermissionItem } from '@@/types/admin';
import { SelectOptionValueType } from '@@/types/antdComponent';
import { DownOutlined } from '@ant-design/icons';
import { Collapse, Form, Select } from 'antd';
import { AxiosResponse } from 'axios';
import { get, isEmpty } from 'lodash';
import React, { memo, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ERROR_CODE } from '../../constant/http';
import AutoComplete from '../AutoComplete';
import PerfModal from '../PerfModal';
import { ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';
import { IErrorType } from '@@/_new_src_/types/common';
import './index.less';

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};

interface VoidFuncForModal {
  (): void;
}

export interface AddAdminNameModalProps {
  visible: boolean;
  onSuccess: VoidFuncForModal;
  onCancel: VoidFuncForModal;
  adminType: string;
  hasPermissionTip?: boolean;
  permissionTipContent?: JSX.Element;
  numberTip?: string;
  inputMode?: string;
  inputLimitNumber?: number;
  submitApi: ((addUserEmail: string[]) => Promise<AxiosResponse<void>>) | undefined;
  modalTitle: string;
  hasPermissionList?: boolean;
  permissionList?: PermissionItem[];
}

interface SearchedPeopleInfo {
  userId: number;
  email: string;
  name: string;
  role: string;
  grade: string;
}

interface formValuesType {
  name: SelectOptionValueType;
}

const AddAdminNameModal = (props: AddAdminNameModalProps) => {
  const { trackEvent } = useMatomo();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const userInfo = useContext(UserInfoContext);
  const { onSuccess, adminType, submitApi, modalTitle } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const localeResource = useSelector(selectLocaleResource);

  const { Panel } = Collapse;

  const {
    admin: {
      manageAdminRole: { addAdminModal: locale },
    },
  } = localeResource as {
    admin: {
      manageAdminRole: {
        addAdminModal: {
          nameLabel: string;
          nameNotFound: string;
          cancel: string;
          add: string;
          title: (adminType: string) => string;
          namePlaceholder: (adminType: string) => string;
          errorMessage: (adminType: string) => string;
          showPermissions: string;
          hidePermissions: string;
        };
      };
    };
  };

  const onReset = useCallback(() => {
    form.resetFields();
    setSubmitDisabled(true);
    setActiveKey([]);
  }, [form]);

  const onClickAddButton = useCallback(async () => {
    setLoading(true);
    const values = (await form.validateFields()) as formValuesType;
    let userEmails;
    if (Array.isArray(values.name)) {
      userEmails = values.name.map(formValuesType => formValuesType.value) as string[];
    } else {
      userEmails = Array.of(values).map(formValuesType => formValuesType.name.value) as string[];
    }
    try {
      if (submitApi) {
        const { SYSTEM_ADMIN_ADDS_SYSTEM_ADMIN, SYSTEM_ADMIN_ADDS_OPS_ADMIN } =
          ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.action;
        let actionEvent = SYSTEM_ADMIN_ADDS_SYSTEM_ADMIN;
        if (adminType === USER_ROLE_ENUM.OPS_ADMIN) {
          actionEvent = SYSTEM_ADMIN_ADDS_OPS_ADMIN;
        }
        trackEvent({
          category: ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN.category,
          action: actionEvent,
        });
        await submitApi(userEmails);
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if ((error as IErrorType).errorCode === ERROR_CODE.ADDING_ADMIN_NAME_ERROR) {
        form.setFields([
          {
            name: 'name',
            errors: [`* ${(error as IErrorType).message}.`],
            value: form.getFieldValue('name') as formValuesType,
            touched: true,
          },
        ]);
      }

      setSubmitDisabled(true);
    } finally {
      setLoading(false);
    }
  }, [submitApi, adminType, form, locale, onSuccess]);

  const renderNameOptions = useCallback(
    (list: SearchedPeopleInfo[], Option: typeof Select.Option) => {
      const nameFormItem = form.getFieldValue('name') as { value: string }[];

      return list.map(({ userId, email, name }) => {
        const isSelf: boolean =
          adminType === USER_ROLE_ENUM.GLOBAL_ADMIN && userInfo.userId === userId;
        const globalFunctionServiceLineAdminIsSelected: boolean = nameFormItem
          ? adminType === USER_ROLE_ENUM.OPS_ADMIN &&
            nameFormItem.map(item => item.value).includes(email)
          : false;
        return (
          <Option
            key={String(email)}
            label={name}
            value={email}
            disabled={isSelf || globalFunctionServiceLineAdminIsSelected}
          >
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [adminType, userInfo.userId],
  );

  const onFormFieldsChange = useCallback(() => {
    const fields = Object.keys(form.getFieldsValue());
    const isDisabled =
      isEmpty(fields) ||
      !form.isFieldsTouched(fields, true) ||
      !isEmpty(form.getFieldsError(fields).filter(({ errors }) => errors.length));
    setSubmitDisabled(isDisabled);
  }, [form]);

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = () => {
    const isActive = activeKey?.includes('permission-panel');

    return (
      <>
        <span className="header-text">
          {isActive ? locale.hidePermissions : locale.showPermissions}
        </span>
        <DownOutlined rotate={isActive ? 180 : 0} />
      </>
    );
  };

  return (
    <PerfModal
      title={modalTitle}
      okText={locale.add}
      cancelText={locale.cancel}
      destroyOnClose
      centered
      className="global-admin-modal"
      onOk={onClickAddButton}
      afterClose={onReset}
      maskClosable={false}
      okButtonProps={{
        disabled: submitDisabled,
        loading,
      }}
      {...props}
    >
      <Form
        {...layout}
        form={form}
        colon={false}
        onFieldsChange={onFormFieldsChange}
        className="regional-admin-modal-form"
      >
        <Form.Item
          name="name"
          label={
            <>
              {locale.nameLabel}
              <span className="required-mark">*</span>
            </>
          }
          rules={[
            {
              required: true,
              message: locale.errorMessage(adminType),
            },
          ]}
        >
          <AutoComplete
            placeholder={locale.namePlaceholder(adminType)}
            limitNum={props.inputLimitNumber}
            mode={props.inputMode}
            notFoundContent={locale.nameNotFound}
            showArrow={false}
            defaultActiveFirstOption={false}
            showSearch
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fetchApi={getUserList}
          >
            {renderNameOptions}
          </AutoComplete>
        </Form.Item>

        <div className="tip-permission-section">
          {props.hasPermissionTip ? props.permissionTipContent : null}
          {props.hasPermissionList ? (
            <div>
              <Collapse className="permission-collapse" ghost onChange={onCollapseChange}>
                <Panel
                  key="permission-panel"
                  className="permission-panel"
                  showArrow={false}
                  header={renderPanelHeader()}
                >
                  {props.permissionList?.map(item => (
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    <PermissionsItem key={get(item, 'role')} permission={item} />
                  ))}
                </Panel>
              </Collapse>
            </div>
          ) : null}
        </div>
      </Form>
    </PerfModal>
  );
};

export default memo(AddAdminNameModal);
