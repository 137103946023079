import React, { memo, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import './index.less';
import { isEmpty } from 'lodash';

const DarkTreeSelect = props => {
  const { value, treeData, onChange, treeSelectAttributes } = props;

  const addInputAttribute = () => {
    const selectWrapper = document.getElementsByClassName('component-dark-tree-select');
    const inputWrapper =
      selectWrapper.length > 0 &&
      selectWrapper[0].getElementsByClassName('ant-select-selection-search-input');
    if (inputWrapper.length > 0) {
      inputWrapper[0].setAttribute('aria-expanded', 'false');
      inputWrapper[0].setAttribute('aria-label', 'dashboard group selection');
      inputWrapper[0].removeAttribute('aria-activedescendant');
    }
  };

  useEffect(() => {
    if (!isEmpty(treeData)) {
      addInputAttribute();
    }
  }, [value]);

  return (
    <TreeSelect
      style={{ width: '331px' }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      treeDefaultExpandAll
      popupMatchSelectWidth
      onChange={onChange}
      className="component-dark-tree-select"
      popupClassName="dark-tree-select-dropdown"
      showArrow
      suffixIcon={<CaretDownOutlined style={{ color: '#fff', fontSize: '16px' }} />}
      {...treeSelectAttributes}
    />
  );
};

export default memo(DarkTreeSelect);
