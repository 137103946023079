import React from 'react';

interface IRevieweeTableFilterContext {
  tableFilter: Record<string, unknown>;
  updateIsFromDetailPageInitialed: () => void;
  updateTableFilter: () => void;
  isFromDetailPageInitialed: boolean;
}

export const RevieweeTableFilterContext = React.createContext({
  tableFilter: {},
  isFromDetailPageInitialed: false,
} as IRevieweeTableFilterContext);
