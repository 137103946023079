import React from 'react';
import './index.less';
import { onEnter } from '@@/_new_src_/utils';

interface IProps {
  title: string;
  content: string;
  visible: boolean;
  hideNotification: () => void;
}
const Notification = (props: IProps) => {
  const { title, content, visible, hideNotification } = props;

  const handleClick = () => {
    hideNotification();
  };

  return (
    <>
      {visible && (
        <div className="bell-notification">
          <div className="information">
            <i className="ri-notification-2-line notification-icon" />
            <div className="detail">
              <h3 className="title">{title}</h3>
              <div className="description" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
          <div>
            <i
              className="ri-close-line close-button"
              tabIndex={0}
              onKeyDown={e => onEnter(e, handleClick)}
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
