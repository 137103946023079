import { ajax, errorWrapper } from '@@/common/ajax';
import { getApiPath, getApiV2Path } from '@@/api/utils';

export const getUserList = (name, isRequireExited) =>
  ajax.get(getApiPath('/users/search'), {
    params: {
      name: name.trim(),
      isRequireExited,
    },
  });

export const getUserListApiWithExited = () => name => getUserList(name, true);

export const getUserProjectList = () =>
  errorWrapper(() => ajax.get(getApiPath('/projects/mine')), {
    message: locale => locale.setReview.form.project.getListFailedMessage,
  });

export const getProjectListByUserId = userId =>
  errorWrapper(() => ajax.get(getApiPath(`/projects/others/${userId}`)), {
    message: locale => locale.setReview.form.project.getListFailedMessage,
  });

export const getUserRoles = () => ajax.get(getApiV2Path('/users/roles'));

export const getRegions = () => ajax.get(getApiPath('/regions'));
