import React, { ReactElement, ReactNode } from 'react';
import { ButtonProps, Modal } from 'antd';
import './index.less';
import cls from 'classnames';

interface INewPerfModalProps {
  show: boolean;
  onCancel: () => void;
  children: ReactElement;
  className?: string;
  onOk?: () => void;
  okText?: string;
  cancelText?: string;
  title: ReactNode;
  okButtonProps: ButtonProps;
  cancelButtonProps: ButtonProps;
}

function V2PerfModal(props: INewPerfModalProps) {
  const {
    show,
    onCancel,
    children,
    className,
    cancelText,
    okText,
    onOk,
    title,
    okButtonProps,
    cancelButtonProps,
  } = props;

  return (
    <Modal
      open={show}
      closeIcon={<i className="ri-close-line" />}
      className={cls('V2-perf-modal', className)}
      onCancel={onCancel}
      cancelText={cancelText}
      okText={okText}
      onOk={onOk}
      title={title}
      maskClosable={true}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      destroyOnClose
    >
      {children}
    </Modal>
  );
}

export default V2PerfModal;
