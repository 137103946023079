import GiveFeedbackHeader from '@@/_new_src_/page/feedback/component/GiveFeedbackHeader';
import GiveFeedbackTableList from '@@/_new_src_/page/feedback/component/GiveFeedbackTableList';
import React, { useEffect } from 'react';
import { FeedbackStatusLabel } from '@@/_new_src_/constants/feedback';
import { getGivenFeedbackRequestsAsync } from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { givenFeedbacksStore } from '@@/_new_src_/store/V2FeedbackSlice';
import Notification from '@@/_new_src_/components/Notification';
import locale from '@@/_new_src_/local/feedback/en_US';
import './index.less';
import {
  feedbackNotificationVisible,
  setFeedbackNotificationVisible,
} from '@@/_new_src_/store/persistenceSlice';
import { isEmpty } from 'lodash';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { userStore } from '@@/_new_src_/store/userSlice';

const V2GiveFeedbackTab = () => {
  const {
    Notification: { bannerTitle, bannerContent },
  } = locale;
  const dispatch = useDispatch();

  const visible = useSelector(feedbackNotificationVisible);

  const {
    userInfo: { currentCycleVersion, futureCycleVersion },
  } = useSelector(userStore);

  const {
    loading: pageLoading,
    tableConfig: { pageNumber, pageSize, sortOrder, givenFeedbackSortColumn, status },
  } = useSelector(givenFeedbacksStore);

  useEffect(() => {
    (currentCycleVersion === CYCLE_VERSION.OLD ||
      (isEmpty(currentCycleVersion) && futureCycleVersion === CYCLE_VERSION.OLD)) &&
      dispatch(setFeedbackNotificationVisible(false));
    const currentStatus = !isEmpty(status) ? status : Object.keys(FeedbackStatusLabel);
    dispatch(
      getGivenFeedbackRequestsAsync({
        status: currentStatus,
        givenFeedbackSortColumn: givenFeedbackSortColumn,
        sortOrder: sortOrder,
        pageSize: pageSize,
        pageNumber: pageNumber,
      }),
    );
  }, []);

  const hideNotification = () => {
    dispatch(setFeedbackNotificationVisible(false));
  };

  return (
    <LoadingWrapper loading={pageLoading}>
      <div className={'give-feedback-tab'}>
        <Notification
          content={bannerContent}
          title={bannerTitle}
          visible={visible}
          hideNotification={hideNotification}
        />
        <GiveFeedbackHeader />
        <GiveFeedbackTableList />
      </div>
    </LoadingWrapper>
  );
};

export default V2GiveFeedbackTab;
