import React, { useState, useCallback } from 'react';
import { DEFAULT_TABLE_PAGINATION } from '@@/_new_src_/constants/table';
import { RevieweeTableFilterContext } from '@@/context/revieweeTableFilter.context';

const REVIEWEE_TABLE_FILTER = 'REVIEWEE_TABLE_FILTER';
const defaultValue = { ...DEFAULT_TABLE_PAGINATION, reviewerType: '' };
const getDefaultSearchFilter = () => {
  try {
    return JSON.parse(sessionStorage.getItem(REVIEWEE_TABLE_FILTER)) || defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

const RevieweesRoot = ({ children }) => {
  const [tableFilter, setTableFilter] = useState(getDefaultSearchFilter);
  const [isFromDetailPageInitialed, setIsFromDetailPageInitialed] = useState(false);
  const updateTableFilter = useCallback(filter => {
    setTableFilter(filter);
    sessionStorage.setItem(REVIEWEE_TABLE_FILTER, JSON.stringify(filter));
  }, []);
  return (
    <div className="reviewees-root">
      <RevieweeTableFilterContext.Provider
        value={{
          tableFilter,
          updateTableFilter,
          isFromDetailPageInitialed,
          updateIsFromDetailPageInitialed: setIsFromDetailPageInitialed,
        }}
      >
        {children}
      </RevieweeTableFilterContext.Provider>
    </div>
  );
};

export default RevieweesRoot;
