import { Checkbox } from 'antd';
import React, { memo, useState } from 'react';
import { differenceWith, map } from 'lodash';
import PerfModal from '@@/common/component/PerfModal';
import InformationIcon from '@@/assets/images/information.svg';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';

const SubmitAssessmentModal = props => {
  const { visible, onClickSubmit, onCancel, loading } = props;

  const {
    currentCycleDetail: { additionalPartners = [], additionalPartnerAssessments = [] },
  } = useSelector(cycleDetailByVersion);

  const {
    reviewees: {
      detail: { submitAssessmentModal: locale },
    },
  } = useSelector(selectLocaleResource);
  const [isCheckSubmitAssessment, setIsCheckSubmitAssessment] = useState(false);

  const hasContributor = additionalPartners?.length > 0;
  const isContributorAllProvideInput =
    additionalPartnerAssessments?.length === additionalPartners?.length;
  const notProvideInputName = map(
    differenceWith(
      additionalPartners,
      additionalPartnerAssessments,
      (a, b) => a.id === b.additionalPartnerId,
    ),
    item => item.name,
  ).join(', ');

  const onClickCancel = () => {
    setIsCheckSubmitAssessment(false);
    onCancel();
  };

  return (
    <PerfModal
      className="submit-assessment-modal"
      visible={visible}
      title={locale.title}
      okText={locale.okText}
      cancelText={locale.cancelText}
      destroyOnClose
      centered
      okButtonProps={{
        disabled: !isCheckSubmitAssessment,
        loading,
      }}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
    >
      <div className="main-content">
        <img src={InformationIcon} alt="" className="tips-icon" />
        {locale.content.main}
      </div>
      {hasContributor && !isContributorAllProvideInput && (
        <div className="contributor-content">
          {locale.content.contributorPrefix}
          <span>{notProvideInputName}</span>
          {locale.content.contributorText}
        </div>
      )}
      <div className="reviewee-content">{locale.content.reviewee}</div>
      <Checkbox
        className="submit-checkbox"
        onChange={e => setIsCheckSubmitAssessment(e.target.checked)}
      >
        {locale.content.checkbox(hasContributor)}
      </Checkbox>
    </PerfModal>
  );
};

export default memo(SubmitAssessmentModal);
