import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddAdminNameModal from '@@/common/component/AddAdminNameModal';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import usePerfModal from '@@/hooks/usePerfModal';
import useUserRoleInfo from '@@/hooks/useUserRoleInfo';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { UserInfo } from '@@/types/user';
import WarningText from '@@/_new_src_/components/WarningText';
import { localAdmin } from '../../../common/interface';
import AddRegionalAdminModal from '../../AddRegionalAdminModal';
import { USER_ROLE_ENUM, UserRoleInfo } from '@@/_new_src_/constants/userEnum';
import { IAdminColumn, IAdminColumType } from '@@/types/admin';
import { postAddGlobalAdmin, postAddOpsAdmin } from '@@/_new_src_/api/admin';

interface ISystemAdminModal {
  adminList: Array<IAdminColumn> | undefined;
  runGetAdminList: (currentAdminType: IAdminColumType) => Promise<IAdminColumn[]>;
  adminListType: IAdminColumType;
  isOpenModal: boolean;
  setIsOpenModal: (value: boolean) => void;
}

const SystemAdminModal = (props: ISystemAdminModal) => {
  const { adminList, runGetAdminList, adminListType, isOpenModal, setIsOpenModal } = props;
  const { userInfo } = useUserRoleInfo();
  const { updateUserInfo } = userInfo as UserInfo;
  const [regionalAdminModalVisible, onOpenRegionalAdminModal, onCloseRegionalAdminModal] =
    usePerfModal();
  const [addAdminModalVisible, onOpenAddAdminModal, onCloseAddAdminModal] = usePerfModal();
  const [
    addGlobalFunctionSLAdminModalVisible,
    onOpenAddGlobalFunctionSLAdminModal,
    onCloseAddGlobalFunctionSLAdminModal,
  ] = usePerfModal();

  const localeResource = useSelector(selectLocaleResource) as unknown;
  const {
    admin: { manageAdminRole: adminLocale },
  } = localeResource as localAdmin;

  const openAssignModal = () => {
    switch (adminListType) {
      case USER_ROLE_ENUM.PS_ADMIN:
        onOpenRegionalAdminModal();
        break;
      case USER_ROLE_ENUM.OPS_ADMIN:
        if (
          Array.isArray(adminList) &&
          adminList.length >= UserRoleInfo[USER_ROLE_ENUM.OPS_ADMIN].maxNumber
        ) {
          setIsOpenModal(false);
          perfMessage.error(adminLocale.alreadyOneGlobalFunctionServiceLineAdminError);
          break;
        }
        onOpenAddGlobalFunctionSLAdminModal();
        break;
      case USER_ROLE_ENUM.GLOBAL_ADMIN:
      default:
        onOpenAddAdminModal();
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      openAssignModal();
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (
      !regionalAdminModalVisible &&
      !addAdminModalVisible &&
      !addGlobalFunctionSLAdminModalVisible
    ) {
      setIsOpenModal(false);
    }
  }, [regionalAdminModalVisible, addAdminModalVisible, addGlobalFunctionSLAdminModalVisible]);

  const regionalAdminModalOnSuccess = useCallback(() => {
    onCloseRegionalAdminModal();
    const getAdminList: () => void = async () => {
      await runGetAdminList(adminListType);
    };
    getAdminList();
    updateUserInfo();
  }, [onCloseRegionalAdminModal, updateUserInfo, runGetAdminList, adminListType]);

  const addAdminModalOnSuccess = useCallback(() => {
    if (adminListType === USER_ROLE_ENUM.OPS_ADMIN) {
      onCloseAddGlobalFunctionSLAdminModal();
    } else {
      onCloseAddAdminModal();
    }
    onCloseAddAdminModal();
    perfMessage.success(adminLocale.addAdminModal.successMessage(adminListType));
    const getAdminList: () => void = async () => {
      await runGetAdminList(adminListType);
    };
    getAdminList();
    if (adminListType !== USER_ROLE_ENUM.GLOBAL_ADMIN) {
      updateUserInfo();
    }
  }, [
    adminListType,
    onCloseAddAdminModal,
    onCloseAddGlobalFunctionSLAdminModal,
    runGetAdminList,
    updateUserInfo,
  ]);

  const addAdminApi = useCallback(() => {
    switch (adminListType) {
      case USER_ROLE_ENUM.OPS_ADMIN:
        return postAddOpsAdmin;
      case USER_ROLE_ENUM.GLOBAL_ADMIN:
      default:
        return postAddGlobalAdmin;
    }
  }, [adminListType]);

  return (
    <>
      <AddRegionalAdminModal
        visible={regionalAdminModalVisible}
        onSuccess={regionalAdminModalOnSuccess}
        onCancel={onCloseRegionalAdminModal}
        adminList={adminList}
      />
      <AddAdminNameModal
        visible={addAdminModalVisible}
        onSuccess={addAdminModalOnSuccess}
        onCancel={onCloseAddAdminModal}
        adminType={USER_ROLE_ENUM.GLOBAL_ADMIN}
        submitApi={addAdminApi()}
        modalTitle={adminLocale.addAdminModal.title(USER_ROLE_ENUM.GLOBAL_ADMIN)}
      />
      <AddAdminNameModal
        visible={addGlobalFunctionSLAdminModalVisible}
        onSuccess={addAdminModalOnSuccess}
        onCancel={onCloseAddGlobalFunctionSLAdminModal}
        adminType={USER_ROLE_ENUM.OPS_ADMIN}
        submitApi={addAdminApi()}
        hasPermissionTip
        inputMode={'multiple'}
        inputLimitNumber={
          Array.isArray(adminList)
            ? UserRoleInfo[USER_ROLE_ENUM.OPS_ADMIN].maxNumber - adminList.length
            : UserRoleInfo[USER_ROLE_ENUM.OPS_ADMIN].maxNumber
        }
        permissionTipContent={
          <WarningText warningText={adminLocale.addAdminModal.globalFunctionServiceLineAdminTip} />
        }
        modalTitle={adminLocale.addAdminModal.title(USER_ROLE_ENUM.OPS_ADMIN)}
      />
    </>
  );
};

export default memo(SystemAdminModal);
