import React, { memo, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, Input } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import {
  EXPECTATION_DETAILS_MAX_LENGTH,
  EXPECTATION_TITLE_MAX_LENGTH,
} from '@@/common/constant/review';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { MY_CYCLE_LIST_API, OPERATE_EXPECTATION_IN_OPEN_CYCLE } from '@@/common/constant/matomo';
import {
  confirmModalType,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import {
  myCycles,
  setCurrentUpdatedExpectation,
  setCycleDetailData,
  setCycleDisplayPart,
  setIsUpdateInformationSuccess,
  setLastExpectationCategoryIdsAfterEditing,
} from '@@/_new_src_/store/myCyclesSlice';
import {
  getOngoingCycleAsync,
  postAddExpectationAsync,
  putUpdateExpectationAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import { setCurrentCycleDetail } from '@@/redux/slice/cycleDetailByVersionSlice';
import { HELPER_TEXT_OPTIONS } from '../../../../constants/myCycles';
import FormOperationBtn from '@@/_new_src_/features/FormOperationBtn';
import useLocalStorageExpectation from '@@/_new_src_/hooks/useLocalStorageExpectation';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import HelperButton from '../../../../components/HelperButton';
import HelperModal from '../HelperModal';
import PerformanceCategoryList from '../PerformanceCategoryList';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import './index.less';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { getCycleStatus, goToCycleDetailPage } from '@@/_new_src_/utils/feature/cycle';
import { UserInfoContext } from '@@/context/userInfo.context';

const CycleExpectationForm = () => {
  const {
    myCycles: {
      cycleExpectationForm: locale,
      formBtnText: operationBtnLocale,
      cycleModal: { cancelEdit: cancelEditLocale },
    },
  } = (useSelector(selectLocaleResource) as IMyCyclesLocale) || {};

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { trackEvent } = useMatomo();

  const { currentOpeningCycleId } = useContext(UserInfoContext);

  const {
    cycleDisplayPart,
    currentUpdatedExpectation: expItem,
    currentCycleId: curCycleId,
    currentCycleStatus: curCycleStatus,
    lastExpectationCategoryIdsAfterEditing: lastCategoryIdsAfterEditing,
    isUpdateInformationSuccess: { success: isUpdateCycleSuccess },
    isUpdatingExpectation: isLoadingExpectation,
  } = useSelector(myCycles);

  const { isNewCycle, isOpenCycle } = getCycleStatus(curCycleStatus);

  const currentModalType = useSelector(confirmModalType);

  const [expectationInitialContent, setExpectationInitialContent] = useState(
    expItem?.content ? expItem?.content : '',
  );
  const [expectationContentHtml, setExpectationContentHtml] = useState('');
  const [expectationContentText, setExpectationContentText] = useState('');
  const [titleInput, setTitleInput] = useState(expItem?.title ? expItem?.title : '');
  const [currentHelpOptionKey, setCurrentHelpOptionKey] = useState('noHelperDisplay');
  const isEdit = !!expItem?.id;

  const {
    expectationTitleState,
    setExpectationTitleState,
    expectationDetailsState,
    setExpectationDetailsState,
    removeLocalExpectation,
  } = useLocalStorageExpectation();

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        title: expItem?.title,
      });
    } else {
      form.setFieldsValue({
        title: expectationTitleState,
      });
      setTitleInput(expectationTitleState as string);
      setExpectationInitialContent(expectationDetailsState as string);
    }
  }, [isEdit, form]);

  useEffect(() => {
    if (isUpdateCycleSuccess) {
      if (isNewCycle) {
        dispatch(getOngoingCycleAsync());
        dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
      } else if (isOpenCycle) {
        dispatch(setCycleDisplayPart(null));

        const goToCycleDetailPageLink = goToCycleDetailPage({
          currentOpeningCycleId,
          cycleId: curCycleId,
        });
        history.push(goToCycleDetailPageLink);
      }
      removeLocalExpectation();
      dispatch(setCurrentUpdatedExpectation(null));
      // eslint-disable-next-line no-unused-expressions
      !isEdit ? removeLocalExpectation() : '';
      dispatch(setIsUpdateInformationSuccess({}));
    }
  }, [isUpdateCycleSuccess]);

  const checkIfNoDataChanged = useMemo(() => {
    if (isEdit) {
      return (
        titleInput === expItem?.title &&
        isEqual(expectationContentHtml, expItem?.content) &&
        isEqual(expItem?.categoryIds, lastCategoryIdsAfterEditing)
      );
    } else {
      return titleInput === '' && expectationContentText === '' && isEmpty(expItem?.categoryIds);
    }
  }, [
    titleInput,
    expectationContentText,
    expItem,
    expectationContentHtml,
    lastCategoryIdsAfterEditing,
  ]);

  const addButtonText = () => {
    return isEdit
      ? operationBtnLocale.editExpectationOrInformation
      : operationBtnLocale.addExpectation;
  };

  const getSubmitBtnShouldBeDisabled = useCallback(() => {
    if (!form) {
      return true;
    }

    const titleIsEmptyOrExceedsMaxLength =
      !titleInput?.trim() || titleInput?.length > EXPECTATION_TITLE_MAX_LENGTH;
    const contentIsEmptyOrExceedsMaxLength =
      !expectationContentText?.trim() ||
      expectationContentText?.length > EXPECTATION_DETAILS_MAX_LENGTH;
    const categoryIsEmpty = expItem?.categoryIds.length === 0;

    return (
      titleIsEmptyOrExceedsMaxLength ||
      contentIsEmptyOrExceedsMaxLength ||
      categoryIsEmpty ||
      checkIfNoDataChanged
    );
  }, [titleInput, expectationContentText, expItem, checkIfNoDataChanged]);

  const updateExpectationContentHtml = (html: string) => {
    setExpectationContentHtml(html);
    // eslint-disable-next-line no-unused-expressions
    !isEdit && setExpectationDetailsState(html);
  };

  const updateExpectationContentText = (text: string) => {
    setExpectationContentText(text);
  };

  const handleConfirmCancelFunc = () => {
    if (isNewCycle) {
      dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY));
    } else if (isOpenCycle) {
      dispatch(setCycleDisplayPart(null));
      dispatch(setCurrentCycleDetail({}));
      dispatch(setCycleDetailData({}));

      const goToCycleDetailPageLink = goToCycleDetailPage({
        currentOpeningCycleId,
        cycleId: curCycleId,
      });

      history.push(goToCycleDetailPageLink);
    }
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalType(''));
    expItem && dispatch(setLastExpectationCategoryIdsAfterEditing(expItem.categoryIds));
    dispatch(setCurrentUpdatedExpectation(null));
    // eslint-disable-next-line no-unused-expressions
    !isEdit && removeLocalExpectation();
  };

  const handleClickCancelFunc = () => {
    if (!checkIfNoDataChanged) {
      dispatch(setConfirmModalStatus(true));
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE));
    } else {
      handleConfirmCancelFunc();
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTitleInput(e.target.value.trim());
    // eslint-disable-next-line no-unused-expressions
    !isEdit && setExpectationTitleState(e.target.value.trim());
    form.setFieldsValue({
      title: e.target.value.trimStart(),
    });
  };

  const onFinishExpectation = () => {
    if (!curCycleId) {
      return;
    }

    if (isNewCycle || isOpenCycle) {
      if (isEdit) {
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: MY_CYCLE_LIST_API.action.TWER_EDITS_V2_CYCLE_EXPECTATION,
        });
        dispatch(
          putUpdateExpectationAsync({
            id: expItem.id,
            title: titleInput,
            content: expectationContentHtml,
            categoryIds: expItem?.categoryIds,
          }),
        );
        if (isOpenCycle) {
          trackEvent({
            category: OPERATE_EXPECTATION_IN_OPEN_CYCLE.category,
            action: OPERATE_EXPECTATION_IN_OPEN_CYCLE.action.EDIT_EXPECTATION,
          });
        }
      } else {
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: MY_CYCLE_LIST_API.action.TWER_ADDS_V2_CYCLE_EXPECTATION,
        });
        dispatch(
          postAddExpectationAsync({
            cycleId: curCycleId,
            title: titleInput,
            content: expectationContentHtml,
            categoryIds: expItem?.categoryIds as number[],
          }),
        );
        if (isOpenCycle) {
          trackEvent({
            category: OPERATE_EXPECTATION_IN_OPEN_CYCLE.category,
            action: OPERATE_EXPECTATION_IN_OPEN_CYCLE.action.ADD_EXPECTATION,
          });
        }
      }
    }
  };

  return (
    <>
      {cycleDisplayPart === CYCLE_DISPLAY_PART.EXPECTATION_FORM && (
        <div className="cycle-expectation-form">
          <div className="form-title">
            <div className="circle-icon" />
            <span>{locale.formTitle}</span>
          </div>
          <Form layout="vertical" form={form} hideRequiredMark onFinish={onFinishExpectation}>
            <Form.Item
              name="title"
              className="expectation-title"
              label={
                <>
                  <span>{locale.expectationTitle.title}</span>
                  <span className="require-mark"> &nbsp;*</span>
                </>
              }
            >
              <Input
                className="expectation-title-input"
                placeholder={locale.expectationTitle.placeholder}
                maxLength={EXPECTATION_TITLE_MAX_LENGTH}
                onChange={onTitleChange}
              />
            </Form.Item>
            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.EXPECTATION_DETAIL_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="expectationDetails"
              className="expectation-details"
              label={
                <>
                  <div className="details-text">
                    <span>{locale.expectationDetails.title}</span>
                    <span className="require-mark">&nbsp;*</span>
                  </div>
                  <HelperButton
                    helpOptionKey={HELPER_TEXT_OPTIONS.EXPECTATION_DETAIL_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <TinyMceEditor
                initialValue={expectationInitialContent}
                updateHtmlInfo={updateExpectationContentHtml}
                updateTextInfo={updateExpectationContentText}
                maxLength={EXPECTATION_DETAILS_MAX_LENGTH as number}
                placeholder={locale.expectationDetails.placeholder}
                customPlaceholderStyle={
                  `color: #484848;
                  font-weight: 349;
                  font-style: italic;
                  white-space: pre-wrap;
                  font-size: 16px;` as string
                }
              />
            </Form.Item>
            {currentHelpOptionKey === HELPER_TEXT_OPTIONS.PERFORMANCE_CATEGORY_INTRO && (
              <HelperModal />
            )}
            <Form.Item
              name="category"
              className="expectation-category"
              label={
                <>
                  <div className="category-text">
                    <span>{locale.expectationCategories.title}</span>
                    <span className="require-mark">&nbsp;*</span>
                  </div>
                  <HelperButton
                    helpOptionKey={HELPER_TEXT_OPTIONS.PERFORMANCE_CATEGORY_INTRO}
                    currentHelpOptionKey={currentHelpOptionKey}
                    setCurrentHelpOptionKey={setCurrentHelpOptionKey}
                  />
                </>
              }
            >
              <PerformanceCategoryList />
            </Form.Item>
            <Form.Item>
              <FormOperationBtn
                confirmBtnText={addButtonText()}
                confirmBtnDisabled={getSubmitBtnShouldBeDisabled()}
                handleClickCancel={handleClickCancelFunc}
                isLoadingSubmit={isLoadingExpectation}
              />
            </Form.Item>
          </Form>

          {currentModalType === CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE && (
            <ConfirmModal {...cancelEditLocale} handleOkFunc={handleConfirmCancelFunc} />
          )}
        </div>
      )}
    </>
  );
};

export default memo(CycleExpectationForm);
