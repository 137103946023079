import React from 'react';
import { Button } from 'antd';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import { setFeedbackDetail, setV1FeedbackModalInfo } from '@@/_new_src_/store/V2FeedbackSlice';
import { useHistory } from 'react-router';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { cycleDetailData } from '@@/_new_src_/store/myCyclesSlice';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const AddFeedbackBanner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    feedback: {
      emptyFeedback: { sendRequest, sendButton, addButton, addReceived },
    },
  } = FeedbackLocale;

  const {
    data: { cycleVersion, id: cycleId },
  } = useSelector(cycleDetailData);

  const addReceivedFeedback = () => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      history.push(FEEDBACK_PAGE_LINK.CLIENT);
    } else {
      dispatch(
        setV1FeedbackModalInfo({
          isClientFeedbackModalVisible: true,
          cycleId: cycleId,
        }),
      );
    }
  };

  const handleSendFeedbackRequest = () => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      dispatch(setFeedbackDetail({}));
      history.push(FEEDBACK_PAGE_LINK.CREATION);
    } else {
      dispatch(
        setV1FeedbackModalInfo({
          isFeedbackRequestModalVisible: true,
          cycleId: cycleId,
        }),
      );
    }
  };
  return (
    <div className="add-feedback-banner">
      <div className="send-request">
        <div dangerouslySetInnerHTML={{ __html: sendRequest }}></div>
        <Button className="send-button" onClick={handleSendFeedbackRequest}>
          <i className="ri-send-plane-line" />
          {sendButton}
        </Button>
      </div>
      <div className="center"></div>
      <div className="add-received">
        <div dangerouslySetInnerHTML={{ __html: addReceived }} />
        <Button className="add-button" onClick={addReceivedFeedback}>
          <i className="ri-file-add-line" />
          {addButton}
        </Button>
      </div>
    </div>
  );
};

export default AddFeedbackBanner;
