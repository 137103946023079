import React from 'react';
import EmptyFeedbackIcon from '@@/assets/images/empty-feedback.svg';
import './index.less';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import Locale from '@@/_new_src_/local/cycleDetail/en_US';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const NoFeedbackContainer = () => {
  const history = useHistory();

  const {
    noFeedbackContainer: { title, gotoText, hint },
  } = Locale;

  const goToFeedbackPage = () => {
    history.push(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE);
  };

  return (
    <div className="no-feedback-container">
      <div className="icon-area">
        <img src={EmptyFeedbackIcon} className="empty-icon" alt="" />
      </div>
      <div className="no-feedback-hint">
        <span>{title}</span>
        <div className="feedback-management-area">
          <Button className="go-to-feedback-button" onClick={goToFeedbackPage}>
            {gotoText}
          </Button>
          <span>
            <i className="ri-information-fill" />
            <div dangerouslySetInnerHTML={{ __html: hint }} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoFeedbackContainer;
