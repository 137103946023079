import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import PerfTextButton from '@@/common/component/PerfTextButton';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  TABLE_DEFAULT_INFO,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
  SORT_DIRECTION_FOR_BE,
  SORT_DIRECTION_LOWER_CASE,
} from '@@/_new_src_/constants/table';
import { RolesOfManagementContext } from '@@/context/rolesOfManagement.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { GroupMemberInfoContentType, GroupResponse } from '@@/types/admin';
import {
  NewRegionalAdminPageTableColumnsName,
  SelectOptionValueType,
  SortParamsForRegionalAdminPageTeamColumns,
} from '@@/types/antdComponent';
import {
  customGroupMemberInfoType,
  userListTypeForEGLT,
  userListTypeForSearch,
} from '@@/types/user';
import { Table, Tooltip } from 'antd';
import { TableProps } from 'antd/es';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import AddOrEditModal from '../../common/AddOrEditModal';
import {
  AddOrEditModalProps,
  SearchedPeopleInfo,
  FormDataModal,
} from '../../common/AddOrEditModal/type';
import { localAdmin } from '../../common/interface';
import { ModalInfoParams } from '../../type';
import AddRegionalGroupMemberModal from './AddRegionalGroupMemberModal';
import './index.less';
import modalInfo from './modalInfo';
import {
  GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN,
  REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN,
} from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import { IPageParams } from '@@/_new_src_/types/common';
import { postAddPeopleForGroups } from '@@/_new_src_/api/admin';
import { dateWithoutSeconds } from '@@/_new_src_/constants/dateFormatter';

const createColumns = (
  locale: GroupMemberInfoContentType & { actions: string; remove: string },
  removePeople: (groupMemberId: number, userId: number) => void,
) => {
  return [
    {
      title: locale.name,
      dataIndex: 'name',
      key: 'name',
      width: 300,
      ellipsis: { showTitle: false },
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (name: string) => {
        return (
          <div className="name">
            <Tooltip className="name" placement="topLeft" title={name}>
              {name}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: locale.createdBy,
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 300,
      ellipsis: { showTitle: false },
      render: (addedBy: string) => (
        <Tooltip placement="topLeft" title={addedBy}>
          {addedBy}
        </Tooltip>
      ),
    },
    {
      title: locale.createdAt,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 300,
      ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
      render: (addedAt: number) => dayjs(addedAt).format(dateWithoutSeconds),
    },
    {
      title: locale.actions,
      dataIndex: 'actions',
      width: 120,
      render: (_text: string, { id, userId }: { id: number; userId: number }) => {
        return (
          <PerfTextButton
            color="red"
            className="operation-remove"
            onClick={() => removePeople(id, userId)}
          >
            {locale.remove}
          </PerfTextButton>
        );
      },
    },
  ];
};

export interface RefType {
  open: () => void;
}

export interface GroupsListProps {
  loading: boolean;
  listData: GroupResponse | undefined;
  getTeamMemberList: (
    SortParams: SortParamsForRegionalAdminPageTeamColumns,
    PageParams: IPageParams,
  ) => Promise<GroupResponse>;
  regionId?: number;
  selectedGroupId?: number;
  regionName?: string;
  onRef?: React.RefObject<RefType>;
  onAddEGLTPeopleTeamRef?: React.RefObject<RefType>;
  isLeaderShipTeam?: boolean;
  deleteTeamMemberApi: (groupMemberId: number) => Promise<AxiosResponse<void>>;
  correspondingRoles: string;
  activeTabKey?: string;
  selectedGroupName?: string;
  isEGLT?: boolean;
}

const GroupsMemberList = (props: GroupsListProps) => {
  const { trackEvent } = useMatomo();
  const {
    listData,
    getTeamMemberList,
    selectedGroupId,
    regionId,
    regionName,
    onRef,
    isLeaderShipTeam,
    deleteTeamMemberApi,
    onAddEGLTPeopleTeamRef,
    correspondingRoles,
    activeTabKey,
    selectedGroupName,
  } = props;

  const {
    action: {
      GLOBAL_ROLE_REMOVES_MEMBER_FROM_GLOBAL_PEOPLE,
      GLOBAL_ROLE_REMOVES_MEMBER_FROM_EGLT_GROUP,
    },
    category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
  } = GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN as {
    action: {
      GLOBAL_ROLE_REMOVES_MEMBER_FROM_GLOBAL_PEOPLE: string;
      GLOBAL_ROLE_REMOVES_MEMBER_FROM_EGLT_GROUP: string;
    };
    category: string;
  };

  const {
    action: {
      REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP,
      REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP,
    },
    category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN_CATEGORY,
  } = REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN as {
    action: {
      REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP: string;
      REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP: string;
    };
    category: string;
  };

  const [currentPagination, setCurrentPagination] = useState<IPageParams>(DEFAULT_TABLE_PAGINATION);
  const [currentSorter, setCurrentSorter] = useState<SortParamsForRegionalAdminPageTeamColumns>({
    sortColumn: NewRegionalAdminPageTableColumnsName.default,
    sortOrder: SORT_DIRECTION_FOR_BE[SORT_DIRECTION_LOWER_CASE.descend],
  });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [
    addRegionalGroupMemberModalVisible,
    onOpenRegionalAdminModal,
    onCloseAddRegionalGroupMemberModal,
  ] = usePerfModal();

  const [
    addEGLTPeopleTeamModalVisible,
    onOpenAddEGLTPeopleTeamModal,
    onCloseAddEGLTPeopleTeamModal,
  ] = usePerfModal();

  const { rolesOfManagement } = useContext(RolesOfManagementContext);
  const localeResource = useSelector(selectLocaleResource);
  const {
    admin: {
      manageGroups: { table: locale, removePeople: removePeopleLocale, regionalAdminModal },
    },
  } = localeResource as localAdmin;

  useEffect(() => {
    const getCountryLeadership: () => void = async () => {
      await getTeamMemberList(currentSorter, currentPagination);
    };
    getCountryLeadership();
  }, [activeTabKey]);

  const handleTableChange: TableProps<GroupMemberInfoContentType>['onChange'] = async (
    pagination,
    _,
    sorter,
  ) => {
    const pageNumberAndSize: IPageParams = {
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    };
    setCurrentPagination(pageNumberAndSize);
    const { columnKey, order } = sorter as SorterResult<GroupMemberInfoContentType>;
    const columnsNameAndOrder = {
      sortColumn: order
        ? NewRegionalAdminPageTableColumnsName[
            columnKey as keyof typeof NewRegionalAdminPageTableColumnsName
          ]
        : NewRegionalAdminPageTableColumnsName.default,
      sortOrder: order ? SORT_DIRECTION_FOR_BE[order] : SORT_DIRECTION_FOR_BE.descend,
    };
    setCurrentSorter(columnsNameAndOrder);
    await getTeamMemberList(columnsNameAndOrder, pageNumberAndSize);
  };

  const addRegionalGroupMemberOnSuccess = async () => {
    onCloseAddRegionalGroupMemberModal();
    const newPagination = {
      ...currentPagination,
      pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
    };
    setCurrentPagination(newPagination);
    await getTeamMemberList(currentSorter, newPagination);
  };

  const addEGLTPeopleTeamOnSuccess = async () => {
    onCloseAddEGLTPeopleTeamModal();
    const newPagination = {
      ...currentPagination,
      pageNumber: TABLE_DEFAULT_INFO.PAGE_NUMBER,
    };
    setCurrentPagination(newPagination);
    await getTeamMemberList(currentSorter, newPagination);
  };

  const getRemovePeopleMatomoEvent = (groupName: string) => {
    if (isEmptyOrNil(groupName)) {
      return '';
    }
    switch (groupName) {
      case 'COUNTRY_LEADERSHIP_GROUP':
        return {
          category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN_CATEGORY,
          action: REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP,
        };
      case 'COUNTRY_PEOPLE_TEAM_GROUP':
        return {
          category: REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN_CATEGORY,
          action: REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP,
        };
      case 'Global Management Team':
        return {
          category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
          action: GLOBAL_ROLE_REMOVES_MEMBER_FROM_EGLT_GROUP,
        };
      case 'Global People Team':
        return {
          category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
          action: GLOBAL_ROLE_REMOVES_MEMBER_FROM_GLOBAL_PEOPLE,
        };
      default:
        return '';
    }
  };

  const removePeople = (groupMemberId: number, userId: number) => {
    if (regionId) {
      const muSlOpsLeadership = rolesOfManagement?.unitLevelDTOList[11];
      const talentBusinessPartner = rolesOfManagement?.unitLevelDTOList[10];
      const isPersonInMuSlOpsLeadership = muSlOpsLeadership?.some(
        person => person.userId === userId,
      );
      const isPersonInTalentBusinessPartner = talentBusinessPartner?.some(
        person => person.userId === userId,
      );
      if (isPersonInMuSlOpsLeadership && isLeaderShipTeam) {
        perfMessage.error(removePeopleLocale.leaderShipErrorMessage);
        return;
      }
      if (isPersonInTalentBusinessPartner && !isLeaderShipTeam) {
        perfMessage.error(removePeopleLocale.talentBPErrorMessage);
        return;
      }
    }

    const closeModal = perfModalConfirm({
      cancelText: removePeopleLocale.cancelText,
      okText: removePeopleLocale.okText,
      className: 'regional-groups-member-list-removePeopleConfirm',
      content: (
        <div className="delete-modal-content">{removePeopleLocale.content(correspondingRoles)}</div>
      ),
      onOk: async () => {
        const pageNumber =
          currentPagination.pageNumber * currentPagination.pageSize >
            get(listData, 'groupMemberInfo.total', 0) &&
          currentPagination.pageNumber !== TABLE_DEFAULT_INFO.PAGE_NUMBER
            ? currentPagination.pageNumber - 1
            : currentPagination.pageNumber;
        const newPagination = { ...currentPagination, pageNumber };

        const groupName = get(listData, 'groupName', '');
        const removeEventMatomo = getRemovePeopleMatomoEvent(groupName);

        removeEventMatomo &&
          trackEvent({
            category: removeEventMatomo.category,
            action: removeEventMatomo.action,
          });
        groupMemberId && (await deleteTeamMemberApi(groupMemberId).finally(() => closeModal()));
        perfMessage.success(removePeopleLocale.successMessage);
        setCurrentPagination(newPagination);
        await getTeamMemberList(currentSorter, newPagination);
      },
      centered: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel: () => {},
    });
  };

  const handleAssignPeople = async (data: FormDataModal) => {
    try {
      setSubmitLoading(true);
      const emails = get(data, 'searchInputValues') as string[];
      const addGlobalRoleMatomo = get(data, 'addEventMatomo', '');

      addGlobalRoleMatomo &&
        trackEvent({
          category: GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN_CATEGORY,
          action: addGlobalRoleMatomo,
        });

      const {
        data: { notAddedEmailList },
      } = (selectedGroupId && (await postAddPeopleForGroups(selectedGroupId, emails))) as {
        data: { successfullyAddedEmailList: string[]; notAddedEmailList: string[] };
      };
      if (notAddedEmailList.length === 0) {
        perfMessage.success(regionalAdminModal.successMessage);
      } else {
        perfMessage.error(regionalAdminModal.errorMessage(notAddedEmailList.length));
      }
      setSubmitLoading(false);
      await addEGLTPeopleTeamOnSuccess();
    } finally {
      setSubmitLoading(false);
    }
  };

  const formatSearchInputList: (
    searchResult: userListTypeForEGLT,
    selectedUsers: Array<SelectOptionValueType>,
  ) => Array<SearchedPeopleInfo> = (searchResult, selectedUsers) => {
    const {
      searchedPeople,
      assignedPeopleInGroup,
    }: {
      searchedPeople: userListTypeForSearch[];
      assignedPeopleInGroup: customGroupMemberInfoType[];
    } = searchResult;

    return searchedPeople?.map(({ userId, name, email }) => {
      const isAlreadyAdded: boolean = assignedPeopleInGroup.map(item => item.email).includes(email);
      const isAlreadySelectedInCurrentForm = selectedUsers
        .map((item: SelectOptionValueType) => item.value)
        .includes(email);
      return {
        userId,
        name,
        email,
        isDisabled: isAlreadyAdded || isAlreadySelectedInCurrentForm,
        displayLabel: `${name}, ${userId}`,
      };
    });
  };

  useImperativeHandle(onRef, () => ({
    open: onOpenRegionalAdminModal,
  }));

  useImperativeHandle(onAddEGLTPeopleTeamRef, () => ({
    open: onOpenAddEGLTPeopleTeamModal,
  }));

  const columns: ColumnsType<GroupMemberInfoContentType> = createColumns(locale, removePeople);

  const renderAssignModal = () => {
    const modalParams: ModalInfoParams = {
      locale: localeResource,
      visible: addEGLTPeopleTeamModalVisible,
      onClose: onCloseAddEGLTPeopleTeamModal,
      handleSubmit: handleAssignPeople,
      submitLoading,
      formatSearchData: formatSearchInputList,
    };

    const modalConfig = modalInfo(modalParams);
    const currentModal = modalConfig[selectedGroupName as string] as {
      modalInfo: AddOrEditModalProps;
    };
    const modalProps = currentModal && currentModal.modalInfo;
    return <AddOrEditModal {...modalProps} />;
  };

  return (
    <div className="country-leadership-group">
      <div className="list">
        <Table<GroupMemberInfoContentType>
          columns={columns}
          dataSource={listData?.groupMemberInfo.content}
          rowKey={record => record.id}
          onChange={handleTableChange}
          pagination={
            (listData?.groupMemberInfo.total as number) > TABLE_DEFAULT_INFO.PAGE_SIZE
              ? {
                  ...DEFAULT_TABLE_PAGINATION_SIZE_CHANGER,
                  total: listData?.groupMemberInfo.total,
                  current: currentPagination.pageNumber,
                  pageSize: currentPagination.pageSize,
                }
              : false
          }
        />
      </div>
      {addRegionalGroupMemberModalVisible &&
        regionId &&
        regionName &&
        isLeaderShipTeam !== undefined && (
          <AddRegionalGroupMemberModal
            visible={addRegionalGroupMemberModalVisible}
            onSuccess={addRegionalGroupMemberOnSuccess}
            onCancel={onCloseAddRegionalGroupMemberModal}
            regionId={regionId}
            regionName={regionName}
            addPeopleApi={postAddPeopleForGroups}
            isLeaderShipTeam={isLeaderShipTeam}
            selectedGroupId={selectedGroupId}
          />
        )}

      {renderAssignModal()}
    </div>
  );
};

export default GroupsMemberList;
