import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import cls from 'classnames';
import { getCycleManagementMenu, PATH_KEY } from '@@/_new_src_/constants/userEnum';
import { hasAccessToMenuTab } from '@@/_new_src_/utils/common/auth';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { CURRENT_PAGE } from '@@/_new_src_/constants/pagePath';
import './index.less';

interface ICycleManagementOption {
  value: PATH_KEY;
  label: string;
  key: string;
  title: string;
}

interface ICycleManagementSelectProps {
  isActive: boolean;
  pathname: string;
  onChange?: () => void;
}

const CycleManagementSelect = (props: ICycleManagementSelectProps) => {
  const { isActive, pathname, onChange } = props;
  const history = useHistory();
  const userInfoSlice = useSelector(userInfo);

  const { isLandingPage } = CURRENT_PAGE(pathname);

  const options = useMemo(() => {
    return getCycleManagementMenu().filter(menu => {
      return hasAccessToMenuTab(menu.role, userInfoSlice, menu.value);
    });
  }, [userInfo]);

  const currentValue = useMemo(() => {
    let currentValue = getCycleManagementMenu()[0];
    if (isActive) {
      options.forEach((item, index) => {
        if (pathname.toLowerCase().startsWith(item.key.toLowerCase())) {
          currentValue = options[index];
          return true;
        }
      });
    }
    return currentValue;
  }, [isActive, pathname, options]);

  const onChangeCycleManagementOption = (option: ICycleManagementOption) => {
    history.push(option.key);
    onChange && onChange();
  };

  return (
    <Select
      className={cls('cycle-management-menu', {
        'cycle-management-menu-landing-page': isLandingPage,
        'cycle-management-menu-active': isActive,
      })}
      labelInValue
      value={currentValue}
      onChange={onChangeCycleManagementOption}
      popupClassName="cycle-management-menu-options"
      suffixIcon={<i className="ri-arrow-down-s-line arrow-icon" />}
      options={options}
      optionRender={option => <div className="option">{option.data.title}</div>}
    />
  );
};
export default CycleManagementSelect;
