import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import './index.less';
import { IHelperDrawerProps } from '@@/_new_src_/store/V2FeedbackSlice/interface';

const HelperDrawer = (Props: IHelperDrawerProps) => {
  const { buttonContent, drawerTitle, drawerContent } = Props;

  const [visible, setVisible] = useState(false);

  const onOpen = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <div className="helper-drawer-container">
      <Button className="help-text-button" onClick={onOpen}>
        {buttonContent}
      </Button>
      <Drawer
        title={drawerTitle}
        className="helper-drawer"
        placement="bottom"
        height={380}
        open={visible}
        onClose={onClose}
        closable={true}
        destroyOnClose={true}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};
export default HelperDrawer;
