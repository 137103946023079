export default {
  Notification: {
    bannerTitle: 'Feedback just got structured!',
    bannerContent: `We are introducing a new way to help you collect and provide feedback more structured using <b>templates, customized questions, and performance expectations.</b> During the implementation, you may notice that <b>old and new UI coexist.</b> Please be assured this won't impact your ability to utilize the feedback feature. Need support? 💬 <a class = 'mail-to'href="https://mail.google.com/mail/?view=cm&fs=1&to=summit-support@thoughtworks.com" target="_blank"><i>summit-support@thoughtworks.com</i></a>`,
  },
  feedback: {
    moreFeedBackButton: 'More feedback',
    requestedFeedback: 'feedback records',
    exportFeedback: 'Export feedback you received',
    checkFeedbackTip: 'Check how to take feedback to the next level',
    noInputFromRequester: 'No input from the requester',
    noInput: `No input`,
    tabText: {
      request: 'Request Feedback',
      give: 'Give Feedback',
    },
    feedbackSwitch: {
      indexWording: (current: number, total: number) => `${current} / ${total} Received feedback`,
    },
    emptyFeedback: {
      hint: 'Start collecting feedback for your current cycle now!',
      cycleInfo: (name: string, startTime: string, endTime: string) =>
        `Current project: ${name} | Cycle duration: ${startTime} - ${endTime}`,
      sendRequest: `If the person is a <b>Thoughtworker with access to Summit</b>, send them a feedback request.`,
      sendButton: ' Send feedback request',
      addReceived: `If the person is <b>non-Thoughtworker or cannot access Summit</b>, record their feedback here.`,
      addButton: 'Add feedback received',
      helpButton: "Feedback: Who's seeing it & How to improve",
      drawerTitle: 'All about performance feedback',
    },
    clientFeedbackForm: {
      modalTitle: 'Add feedback received',
      editTitle: 'Edit feedback received',
      cycleInfo: (name: string, startTime: string, endTime: string) =>
        `Project: ${name} | Cycle Duration: ${startTime} - ${endTime}`,
      clientName: 'Name of the provider: ',
      clientNamePlaceHolder: 'enter provider name',
      contentLabel: 'You can share the performance feedback collected outside Summit: ',
      updatedTimeHint: 'Last updated at ',
      confirmModalInfo: {
        title: 'Are you sure?',
        content: 'The content you edited will be lost.',
        cancel: 'Cancel',
        OK: 'OK',
        Add: 'Add',
        Save: 'Save',
      },
      cancelModalInfo: {
        cancelTitle: 'Are you sure?',
        cancelContent: 'Cancel this feedback if you don’t need it for your current cycle.',
        no: 'Not yet',
        yes: 'Cancel feedback',
      },
      successMessage: 'Feedback request(s) are sent successfully!',
      editSuccessMessage: 'Feedback request is saved successfully!',
      cancelSuccessMessage: 'Feedback request is cancelled successfully!',
    },
    helperContent: [
      {
        tab: 'What',
        title: 'What is performance feedback?',
        content: `In the performance process, specific feedback is collected to <b>provide input on how you perform against the expectations you have set for a specific period.</b> This type of feedback is known as performance feedback and is shared with relevant people (for more information, please check the (<a href="https://docs.google.com/document/d/e/2PACX-1vQeaqodjFy7DHf4x8T9iDIAxOjHjS_gVdwlsk_HtdNh5RWUpbPmZgO7niyOcnFyCPSiOCTS8SZTWeyT/pub" target="_blank"><i>Summit data privacy document </i></a>) involved in the performance cycle.
        `,
      },
      {
        tab: 'Why',
        title: 'Why is performance feedback important?',
        content: `Collecting feedback is an important part of checking <b>how you are performing and understanding how to improve continuously.</b>\n
        On the other hand, giving feedback to others can motivate people when they are achieving what is expected of them and <b>helps people understand their gaps and how to close them.</b>\n
        Feedback also contributes to the performance process. It can be used in the following ways:\n
        \xa0 •\xa0 Provides input for your self-assessment
        \xa0 •\xa0 Helps your Performance Partner and Additional Partner understand how you are performing
        \xa0 •\xa0 Contributes to expectation alignment of your future cycles\n
        Therefore, feedback is a valuable asset for your daily work and growth.`,
      },
      {
        tab: 'Who',
        title: 'Who should be involved?',
        content: `When collecting feedback, it is recommended to define a diverse pool of people you work with, such as team members, peers, leaders, clients, etc. Also, ask people with different points of view.\n
        During the performance process, your <b>performance partner, additional partner, Talent Business Partner, and the leadership team of your account/market unit/function/service line</b> will have visibility to your cycle information, including the feedback.
`,
      },
      {
        tab: 'How',
        title: 'How is effective feedback achieved?',
        content: `Effective feedback should include various perspectives and be FAST: <b>Fact-based behaviors, Actionable, Specific, and Timely.</b>\n
        In order to collect effective feedback, consider the following:
        \xa0 •\xa0 Send feedback requests shortly after collaborations with others
        \xa0 •\xa0 Share some context about the situation you are asking for feedback. You can also include your expectations in the context
        \xa0 •\xa0 Structure the questions in your requests and make them clear about what feedback you would like to receive
        
        To provide effective feedback, ponder the following:
        \xa0 •\xa0 Share information that is based on facts
        \xa0 •\xa0 Balance between both positive and constructive aspects
        \xa0 •\xa0 Provide actionable suggestions that help other to develop further
        `,
      },
      {
        tab: 'When',
        title: 'When should you collect feedback?',
        content: `Feedback should be timely, so <b>collecting it frequently and continuously throughout cycles</b> is the recommended guideline, but at least once a cycle is required.\n 
        Avoid only collecting feedback at the end of a cycle or year-end, as people tend to be overloaded with feedback requests at those times and you are less likely to get thoughtful feedback.`,
      },
    ],
    requestedFeedbackColumnName: {
      providerName: "Provider's name",
      providerRole: "Provider's role",
      requestedDate: 'Requested date',
      dueDate: 'Due date',
      completionDate: 'Completion date',
      status: 'Status',
      actions: 'Actions',
    },
    actions: {
      edit: 'Edit',
      cancel: 'Cancel',
      view: 'View',
    },
    requestForm: {
      title: 'Send Performance Feedback Request',
      project: 'Project:',
      duration: 'Cycle duration: ',
      nameLabel: 'Who would you like to ask for feedback?',
      placeholder: 'Enter a name or employee ID',
      requestToHelperText: `It’s recommended to involve from your <b>peers, coworkers to supervisors, managers and people, staffing team.</b><br/>Understand your strengths and weaknesses from <b>various perspectives,</b> and discover <b>things that you might not notice.</b>`,
      dueDateHelperText:
        'This is your expected due day for finishing collecting feedback, the feedback providers can still giving feedback within this request unless your performance partner submits the assessment summary.',
      showHelperButtonText: 'Show help',
      hideHelperButtonText: 'Hide help',
      dateLabel: 'When do you want to stop collecting feedback?',
      cancelText: 'Cancel',
      okText: 'Send request',
      saveText: 'Save',
      nameNotFound: 'name or ID not found',
      shareContent:
        'Clarify things like if are you asking for feedback about a specific work you did together? Or is it about all interactions you had in the cycle?',
      shareInfoQuestion: 'What information you would like to share to feedback provider(s)?',
      requestFormNames: 'requestToName',
      dueTime: 'dueTime',
      context: 'context',
      buttonBlock: 'buttonBlock',
      confirmModalInfo: {
        title: 'Cancel editing?',
        content: 'The content you edited will be lost.',
        cancelText: 'No, resume editing',
        okText: 'Yes, cancel editing',
      },
      backToList: 'Back to requested feedback list',
      nameRequireMessage: 'Please input name',
      dateRequireMessage: 'Please input date',
      contextRequireMessage: 'Please input context',
      templateTitle: 'What do you want to get from this feedback?',
      templateDescription:
        'Select a question template from below, then the feedback provider could answer base on those questions:',
      selectHint: 'Select a template:',
      expectationHeader: 'Also, share expectations with provider(s) ',
      itemSelected: ' items selected)',
      expectationDetail: `By associating feedback with expectations, the feedback provider <b> would see the expectations you selected</b>, 
         so that they could <b>more relevant</b> feedback by taking your expectations as reference.`,
      addedCustomizedQuestionPlaceHolder: 'Type your question',
      addCustomizedQuestion: 'Add another question',
      addFirstCustomizedQuestion: 'Add your first question',
      customizedQuestion: 'Add your first question',
    },
    expectationForm: {
      title: 'Cycle expectations',
      describe:
        'Below list of items were synced with your current cycle expectations. Make expectations visible or invisible to the feedback provider by selecting or deselecting them. ',
      countText: (now: number, total: number) => `(${now}/${total} items selected)`,
    },
    giveFormExpectation: {
      expectationTitle: 'Cycle expectations',
      describe:
        'Here is the list of cycle expectations that I would like to get feedback around. You could take it as reference when you providing feedback.',
    },
    viewExpectation: {
      title: 'Cycle expectations',
      description:
        'Here is the list of cycle expectations that you shared within this feedback request.',
    },
    showExpectationToggle: {
      show: 'Show related expectations',
      hide: 'Hide related expectations',
    },
    sendClientFeedbackForm: {
      backToList: 'Back to requested feedback list',
      addTitle: 'Add performance feedback received offline',
      editTitle: 'Edit performance feedback received offline',
      projectTip: 'Project:',
      durationTip: 'Cycle duration:',
      nameTitle: 'Please fill in the feedback provider: ',
      contentTitle: 'Please share the feedback collected here: ',
      cancel: 'Cancel',
      add: 'Add feedback to your cycle',
      save: 'Save',
      confirmModalInfo: {
        title: 'Cancel editing?',
        modalContent: 'The content you edited will be lost.',
        cancelText: 'No, resume editing',
        okText: 'Yes, cancel editing',
      },
    },
    emptyReview: {
      content: "You don't have a performance cycle open at the moment.",
      tip: 'Please go to "My cycles" page and open a performance cycle first.',
    },
    giveFeedbackTab: {
      title: {
        requestName: 'Requester’s name',
        requesterRole: 'Requester’s role',
        dueDate: 'Due date',
        completionDate: 'Completion date',
        status: 'Status',
        action: 'Action',
      },
      header: {
        pendingText: 'Pending requests',
        totalText: 'Total requests',
        showPendingAndSubmittedText: 'Only show pending, drafted and done requests',
      },
    },
    giveFeedbackForm: {
      feedbackForTwer: (name: string) => `Performance feedback for ${name}`,
      project: 'Project:',
      cycleDuration: 'Cycle Duration:',
      dueDate: 'Due Date:',
      cancelText: 'Cancel',
      sendText: 'Submit feedback',
      saveAsDraft: 'Or, save as draft',
      backHint: 'Back to give feedback list',
      contextTitle: 'Context',
      hideExpectation: 'Hide related expectations',
      showExpectation: 'Show related expectations',
      dueInDays: (day: number) =>
        day >= 0 ? `Due in ${day} days` : `Already ${-day} days overdue`,
      autoSaveAsDraftSuccessMessage: () => `Edits have been automatically saved.`,
      saveSuccessMessage: () => `Feedback has been saved as draft !`,
      submitSuccessMessage: (date: string) => `Feedback was submitted as ${date} !`,
    },
    confirmModalInfo: {
      title: 'Cancel feedback?',
      cancelText: 'No, keep this request',
      okText: 'Yes, cancel request',
      pendingContent:
        'By cancelling the feedback request, providers will no longer be able to provide feedback around this request. ',

      draftedContent:
        'By cancelling the feedback request, all the drafted input will be lost, and providers will no longer be able to provide feedback around this request. ',
    },
    feedbackDetail: {
      backGiveList: 'Back to give feedback list',
      backRequestList: 'Back to requested feedback list',
      header: (isGiveFeedback: boolean, name: string) =>
        `Performance feedback ${isGiveFeedback ? 'to' : 'from'} ${name}`,
      project: 'Project: ',
      duration: 'Cycle duration: ',
      dueDate: 'Due date: ',
      completionDate: 'Completion date: ',
      contextTitle: 'Context',
      role: 'Role: ',
      projectWithTwer: 'Project where you have worked with this Twer: ',
      feedback: 'Feedback: ',
    },
    clientFeedbackDetail: {
      title: 'Offline performance feedback from ',
      project: 'Project: ',
      cycleDuration: 'Cycle duration: ',
      lastUpdateAt: 'Last updated at ',
      getBackTitle: 'Back to feedback of past cycles',
      content: 'Please share the feedback collected here: ',
    },
    v1EditFeedbackModal: {
      title: 'Performance feedback for',
      editSubmittedTitle: 'Edit Feedback for',
      projectSelectLabel:
        'Select the project where you have worked with the Twer during this performance cycle:',
      contentLabel:
        'Please provide any relevant feedback about the Twer’s performance during this performance cycle:',
      selectPlaceholder: 'Select the project from the list',
      selectNotFoundContent: 'No project found',
      requiredContentMessage: '* Please provide inputs.',
      sendMessage: 'The feedback is sent successfully!',
      draftMessage: 'The feedback is saved as draft successfully!',
      draftText: 'Or, Save as Draft',
      cancelText: 'Cancel',
      okText: 'Send',
      editSubmittedText: 'Save',
      dueInDays: (day: number) =>
        day >= 0 ? `Due in ${day} days` : `Already ${-day} days overdue`,
      saveSuccessMessage: (date: string) => `Feedback was submitted as ${date} !`,
    },
  },
  pastCycles: {
    year: 'Year:',
    cycleDuration: 'Cycle Duration',
    project: 'Project',
    performancePartner: 'Performance Partner',
    feedbackListForPastReview: {
      name: 'Provider’s name',
      role: 'Provider’s role',
      requestDate: 'Requested date',
      dueDate: 'Due date',
      status: 'Status',
      completionDate: 'Completion date',
      viewLessRequests: 'View Less Requests',
      viewAllRequests: 'View All Requests',
    },
    noFeedbackForPastReview: {
      content: 'No feedback collected for this cycle.',
    },
    pastFeedbackDetailCaption: {
      title: 'Feedback From ',
      role: 'Role: ',
      projectName: 'Project: ',
      feedback: 'Feedback: ',
    },
    emptyPastCycle: {
      content: 'Performance feedback collected for past cycles will appear here.',
    },
  },
};
