import {
  ALL_ACCOUNT,
  ALL_ACCOUNT_UNIT,
  ALL_GROUPS_ID,
  ALL_GROUPS_UNIT,
  ALL_PEOPLE,
  ALL_PEOPLE_UNIT,
  V2_ACCOUNT,
  V2_SUPERVISOR,
} from '../../constant/dashboard';
import { SourceCategory } from '../../constant/dashboardSourceCategory';
import { SINGLE_ACCOUNT } from '@@/features/performance/v2/dashboardPage/common/constants';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

export const sourceCategoryJudgement = (roleName, selectedGroupId, selectedGroupType, roleType) => {
  if (roleName === USER_ROLE_ENUM.GLOBAL_TALENT_BP) {
    if (selectedGroupId === ALL_PEOPLE || selectedGroupId === ALL_GROUPS_ID) {
      return SourceCategory.globalLevel;
    }
    if (selectedGroupType === ALL_GROUPS_UNIT || selectedGroupType === ALL_PEOPLE_UNIT) {
      return SourceCategory.supervisorLevel;
    }
  }
  if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP) {
    if (selectedGroupId === ALL_PEOPLE || selectedGroupId === ALL_GROUPS_ID) {
      return SourceCategory.regionLevel;
    }
    if (selectedGroupType === ALL_GROUPS_UNIT || selectedGroupType === ALL_PEOPLE_UNIT) {
      return SourceCategory.unitLevel;
    }
    if (selectedGroupId === ALL_ACCOUNT) {
      return SourceCategory.regionLevel;
    }
    if (selectedGroupType === SINGLE_ACCOUNT) {
      return SourceCategory.accountLevel;
    }
    return SourceCategory.groupLevel;
  }
  if (roleName === USER_ROLE_ENUM.TALENT_BP) {
    if (selectedGroupType === ALL_ACCOUNT_UNIT) {
      return SourceCategory.unitLevel;
    }
    if (selectedGroupType === SINGLE_ACCOUNT) {
      return SourceCategory.accountLevel;
    }
  }

  if (roleType === V2_SUPERVISOR && (selectedGroupId === ALL_GROUPS_ID || ALL_PEOPLE)) {
    return SourceCategory.supervisorLevel;
  }

  if (roleType === V2_ACCOUNT) {
    return SourceCategory.accountLevel;
  }

  if (selectedGroupId === ALL_GROUPS_ID || selectedGroupId === ALL_PEOPLE) {
    return SourceCategory.unitLevel;
  }
  return SourceCategory.groupLevel;
};
