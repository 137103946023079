import React, { useMemo } from 'react';
import './index.less';
import { isEmpty } from 'lodash';
import locale from '@@/_new_src_/local/feedback/en_US';
import { useHistory, useParams } from 'react-router';
import { requestFeedbacks } from '@@/_new_src_/store/V2FeedbackSlice';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const FeedbackSwitcher = () => {
  const history = useHistory();
  const { id: feedbackId } = useParams<{ id: string }>();
  const {
    feedback: {
      feedbackSwitch: { indexWording },
    },
  } = locale;
  const { doneFeedbackIds } = useSelector(requestFeedbacks);
  const currentFeedbackIndex = useMemo(
    () => doneFeedbackIds?.findIndex(item => item === +feedbackId),
    [feedbackId, doneFeedbackIds],
  );

  const previousDisable = useMemo(() => currentFeedbackIndex === 0, [currentFeedbackIndex]);
  const nextDisable = useMemo(
    () => currentFeedbackIndex === doneFeedbackIds.length - 1,
    [currentFeedbackIndex],
  );
  const currentIndexInDoneFeedbackList = useMemo(
    () => currentFeedbackIndex !== -1,
    [currentFeedbackIndex],
  );

  const gotoPreviousFeedbackDetail = () => {
    history.push(`${FEEDBACK_PAGE_LINK.DETAIL}${doneFeedbackIds[currentFeedbackIndex - 1]}`);
  };

  const gotoNextFeedbackDetail = () => {
    history.push(`${FEEDBACK_PAGE_LINK.DETAIL}${doneFeedbackIds[currentFeedbackIndex + 1]}`);
  };

  return (
    <div>
      {!isEmpty(doneFeedbackIds) && currentIndexInDoneFeedbackList && (
        <div className="feedback-switcher">
          <Button
            aria-label="goToPrevious"
            disabled={previousDisable}
            onClick={gotoPreviousFeedbackDetail}
          >
            <i className="ri-arrow-left-s-line" />
          </Button>
          <span>{indexWording(currentFeedbackIndex + 1, doneFeedbackIds.length)}</span>
          <Button aria-label="goToNext" disabled={nextDisable} onClick={gotoNextFeedbackDetail}>
            <i className="ri-arrow-right-s-line" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FeedbackSwitcher;
