import React from 'react';
import AvatarImg from '@@/assets/images/avatar.jpg';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { useSelector } from 'react-redux';

import './index.less';

const UserInfo = () => {
  const { name, role } = useSelector(userInfo);

  return (
    <div className="user-info-main">
      <div className="content">
        <div className="avatar">
          <img src={AvatarImg} alt="avatar" />
        </div>
        <div>
          <div className="username">{name}</div>
          <div style={{ fontSize: 16 }}>{role}</div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
