import { getUserList } from '@@/api/user';
import AutoComplete from '@@/common/component/AutoComplete';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import PerfModal from '@@/common/component/PerfModal';
import UploadFiles from '@@/common/component/UploadFile';
import { ERROR_CODE } from '@@/common/constant/http';
import useDisabledForm from '@@/hooks/useDisabledForm';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { SelectOptionValueType } from '@@/types/antdComponent';
import { userListTypeForSearch } from '@@/types/user';
import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { get, isEmpty, isEqual, isNumber, pick } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImportMemberType } from '../../type';
import { PermissionsItem } from '../PermissionsModal';
import './index.less';
import { postAddGroupForSuperOrg } from '@@/_new_src_/api/admin';
import { Select } from 'antd/lib';

interface GroupModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  // eslint-disable-next-line react/require-default-props
  unitId?: number;
}

export interface groupLeaderInfoType {
  value: string;
  key: string;
  label: string;
}

interface formValuesType {
  groupName: string;
  groupLeaders: Array<groupLeaderInfoType>;
}

interface CreateGroupErrorType {
  code: number;
  message: string;
}

function CreateGlobalFunctionTBPGroupModal(props: GroupModalProps) {
  const { visible, onCancel, onSuccess, unitId } = props;
  const [submitting, setSubmitting] = useState(false);

  const [createGroupError, setCreateGroupError] = useState<CreateGroupErrorType>(
    {} as CreateGroupErrorType,
  );
  const { form, isDisabled } = useDisabledForm();
  const [activeKey, setActiveKey] = useState<string | string[]>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [employeeIds, setEmployeeIds] = useState<number[]>([]);
  const [uploadFileError, setUploadFileError] = useState<string>();

  const localeResource = useSelector(selectLocaleResource) as unknown;

  const { Panel } = Collapse;

  const {
    admin: {
      manageGroupsOfTBP: { groupModal: locale },
    },
  } = localeResource as {
    admin: {
      manageGroupsOfTBP: {
        groupModal: {
          uploadFileErrorMessage: string;
          globalFunctionTBPAccess: {
            role: string;
            accessList: string[];
            scope: {
              user: string;
              preposition: string;
              correspondingCategory: string;
            };
          };
          enterTheGroupName: string;
          enterGroupLeadName: string;
          groupLeads: string;
          title: string;
          editTitle: string;
          okText: string;
          editOkText: string;
          cancelText: string;
          groupName: {
            label: string;
            placeholder: string;
            requiredMessage: string;
          };
          groupLead: {
            label: string;
            placeholder: string;
            requiredMessage: string;
            notFoundContent: string;
          };
          invalidEmployeeIds: (ids: JSX.Element[], theNumberOfInvalidEmployeeIds: number) => string;
          invalidInsert: (ids: JSX.Element[]) => string;
          successMessage: (name: string) => string;
          editSuccessMessage: string;
          importListTips: string;
          showPermissions: string;
          hidePermissions: string;
        };
      };
    };
  };

  const onCollapseChange = (currentActiveKey: string | string[]) => {
    setActiveKey(currentActiveKey);
  };

  const renderPanelHeader = () => {
    const isActive = activeKey?.includes('permission-panel');
    return (
      <>
        <span className="header-text">
          {isActive ? locale.hidePermissions : locale.showPermissions}
        </span>
        <DownOutlined rotate={isActive ? 180 : 0} />
      </>
    );
  };

  const resetFormFields = useCallback(() => {
    form.resetFields();
    setFileList([]);
    setCreateGroupError({} as CreateGroupErrorType);
    setUploadFileError('');
    setActiveKey([]);
  }, [form]);

  const clearCreateGroupError = useCallback(() => {
    setCreateGroupError({} as CreateGroupErrorType);
    setUploadFileError('');
  }, []);

  const createGroupRequest = useCallback(async () => {
    const values = (await form.validateFields()) as formValuesType;
    const groupName = values.groupName.trim();
    const groupLeaders = values.groupLeaders.map(({ value }) => value).join();

    if (unitId) {
      await postAddGroupForSuperOrg({ employeeIds, groupName, groupLeaders }, unitId);
      perfMessage.success(locale.successMessage(groupName));
    }
  }, [employeeIds, form, locale, unitId]);

  const getExcelData = useCallback(
    (data: Array<ImportMemberType>) => {
      for (let index = 0; index < data.length; index += 1) {
        const employeeId = get(data[index], 'Employee ID');
        if (!isNumber(employeeId)) {
          setUploadFileError(locale.uploadFileErrorMessage);
          break;
        }
      }

      const employeeIdsData = data.map((item: ImportMemberType) => get(item, 'Employee ID'));
      setEmployeeIds(employeeIdsData);
    },
    [locale.uploadFileErrorMessage],
  );

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      await createGroupRequest();

      if (onCancel) {
        onCancel();
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const { errorCode, message } = pick(error, ['message', 'errorCode']) as {
        errorCode: number;
        message: string;
      };
      setCreateGroupError({
        code: errorCode,
        message,
      });
    } finally {
      setSubmitting(false);
    }
  }, [createGroupRequest, onCancel, onSuccess]);

  const renderNameOptions = useCallback(
    (list: Array<userListTypeForSearch>, Option: typeof Select.Option) => {
      return list.map(({ userId, name, email }) => {
        const selectedUserInfoInCurrentForm = (form.getFieldValue('groupLeaders') ||
          []) as Array<SelectOptionValueType>;
        const isAllreadySelectedInCurrentForm = selectedUserInfoInCurrentForm
          .map((item: SelectOptionValueType) => item.value)
          .includes(email);
        return (
          <Option
            key={String(userId)}
            label={name}
            value={email}
            disabled={isAllreadySelectedInCurrentForm}
          >
            {`${name}, ${userId}`}
          </Option>
        );
      });
    },
    [form],
  );

  useEffect(() => {
    const fieldsMap = {
      [ERROR_CODE.INVALID_GROUP_NAME]: 'groupName',
      [ERROR_CODE.INVALID_GROUP_LEADERS]: 'groupLeaders',
    };
    const createFieldValue = (name: string) => ({
      name,
      value: form.getFieldValue(name),
      errors: [createGroupError.message],
      touched: true,
    });
    form.setFields([createFieldValue(fieldsMap[createGroupError.code])]);
  }, [createGroupError, form]);

  const getIsSaveBtnDisabled = useCallback(() => {
    return (
      isDisabled() || !fileList.length || !isEmpty(createGroupError) || !isEmpty(uploadFileError)
    );
  }, [createGroupError, fileList.length, isDisabled, uploadFileError]);

  const getPermissionRow: JSX.Element = (
    <Row>
      <div className="tip-permission-section">
        <Collapse
          className="permission-collapse"
          ghost
          onChange={onCollapseChange}
          activeKey={activeKey}
        >
          <Panel
            key="permission-panel"
            className="permission-panel"
            showArrow={false}
            header={renderPanelHeader()}
          >
            <PermissionsItem permission={locale.globalFunctionTBPAccess} />
          </Panel>
        </Collapse>
      </div>
    </Row>
  );

  const selectDisplayRow = () => {
    return getPermissionRow;
  };

  const isInvalidMembers = get(createGroupError, 'code') === ERROR_CODE.INVALID_MEMBER;
  let textErrorMessage = '';
  let listEmployeeIds = '';
  if (isInvalidMembers) {
    const errorMessage = get(createGroupError, 'message');
    const lastIndexOfColon = errorMessage.lastIndexOf(':');
    if (lastIndexOfColon !== -1) {
      textErrorMessage = errorMessage.slice(0, lastIndexOfColon + 1);
      listEmployeeIds = errorMessage.slice(lastIndexOfColon + 1, errorMessage.length);
    } else {
      textErrorMessage = errorMessage;
    }
  }

  const getFileList = (file: UploadFile[]) => {
    setFileList(file);
  };

  const groupNameOnChange = useCallback(() => {
    if (createGroupError.code === 1206) {
      clearCreateGroupError();
    }
  }, [clearCreateGroupError, createGroupError.code]);
  return (
    <PerfModal
      title={locale.title}
      visible={visible}
      style={{ top: 180 }}
      className="tbp-global-function-create-group-modal"
      forceRender
      footer={null}
      afterClose={resetFormFields}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        className="group-form"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              name="groupName"
              label={locale.groupName.label}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: locale.groupName.requiredMessage,
                },
              ]}
            >
              <Input
                placeholder={locale.enterTheGroupName}
                maxLength={100}
                onChange={groupNameOnChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item
              label={locale.groupLeads}
              name="groupLeaders"
              rules={[
                {
                  required: true,
                  message: locale.groupLead.requiredMessage,
                },
              ]}
            >
              <AutoComplete
                mode="multiple"
                placeholder={locale.enterGroupLeadName}
                notFoundContent={locale.groupLead.notFoundContent}
                fetchApi={getUserList}
              >
                {renderNameOptions}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>

        {selectDisplayRow()}

        <Row gutter={30}>
          <Col md={24} xs={24}>
            <Form.Item className="dragger-wrapper">
              <UploadFiles
                getFileList={getFileList}
                clearCreateGroupError={clearCreateGroupError}
                getExcelData={getExcelData}
              />
            </Form.Item>

            <Form.Item
              validateStatus={
                ERROR_CODE.INVALID_MEMBER === createGroupError.code ? 'error' : 'success'
              }
            >
              {isInvalidMembers ? (
                <div className="import-members-error-msg">
                  <div>{textErrorMessage}</div>
                  <div>{listEmployeeIds} </div>
                </div>
              ) : null}
            </Form.Item>
          </Col>
        </Row>

        <div className="group-modal-footer">
          <Form.Item shouldUpdate={(prevValues, curValues) => isEqual(prevValues, curValues)}>
            {() => (
              <>
                <Button onClick={onCancel}>{locale.cancelText}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={getIsSaveBtnDisabled()}
                  loading={submitting}
                >
                  {locale.okText}
                </Button>
              </>
            )}
          </Form.Item>
        </div>
      </Form>
    </PerfModal>
  );
}

export default memo(CreateGlobalFunctionTBPGroupModal);
