import React from 'react';
import ReviewList from './reviewList';
import './index.less';

const Reviews = () => (
  <div className="page-reviews">
    <ReviewList />
  </div>
);
export default Reviews;
