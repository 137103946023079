import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardCycleDetail from '../DashboardCycleDetail';
import { useDispatch, useSelector } from 'react-redux';
import { getPerformancePath } from '@@/common/utils';
import {
  cycleDetailByVersion,
  setCurrentCycleDetail,
} from '@@/redux/slice/cycleDetailByVersionSlice';
import CycleDetailByIdLayout from '@@/features/performance/reviews/cycleDetailByIdLayout';
import { GET_CYCLE_DETAIL_API_PATH } from '@@/common/constant/review';
import { getEditRequestByRequestIdAsync } from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import { getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync } from '@@/redux/slice/dashboardSlice/asyncThunk';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import { get } from 'lodash';
import { CURRENT_PAGE } from '@@/_new_src_/constants/pagePath';

import './index.less';

const DashboardCycleDetailContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { cycleId, requestId } = useParams();
  const { isCycleManagementPage } = CURRENT_PAGE(location.pathname);
  const {
    currentCycleDetail: { isDoneCycle, twer },
  } = useSelector(cycleDetailByVersion);

  useEffect(() => {
    !!requestId && dispatch(getEditRequestByRequestIdAsync(requestId));
  }, []);

  useEffect(() => {
    isDoneCycle && dispatch(getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync(cycleId));
  }, [isDoneCycle]);

  const isFromTabReviewsList = useMemo(
    () => history.location.pathname.startsWith(getPerformancePath('/dashboard/cycles/')),
    [history.location],
  );

  const onGoBack = useCallback(() => {
    const isFromDetailPage = true;
    if (requestId) {
      history.push(getPerformancePath(`/dashboard/requests${location.search}`), {
        isFromDetailPage,
      });
      return;
    }
    const backUrl = isFromTabReviewsList
      ? getPerformancePath('/dashboard/cycles')
      : getPerformancePath('/dashboard');
    history.push(`${backUrl}${history.location.search}`, { isFromDetailPage });
    dispatch(setCurrentCycleDetail({}));
  }, [requestId, isFromTabReviewsList, history, location.search]);

  return (
    <CycleDetailByIdLayout v1ApiPath={GET_CYCLE_DETAIL_API_PATH.isPerformanceReviews}>
      <GoBackBanner
        bannerClassname={isCycleManagementPage && 'cycle-management-go-back-banner'}
        title={get(twer, 'name', '')}
        goToBackFun={onGoBack}
        onKeyPress={onGoBack}
      />
      <DashboardCycleDetail requestId={requestId} />
    </CycleDetailByIdLayout>
  );
};
export default DashboardCycleDetailContainer;
