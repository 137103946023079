export const ALL_ACCOUNT_GROUPS = {
  category: 'All account groups',
  action: 'Jump to dashboard all account',
};

export const SINGLE_ACCOUNT_GROUPS = {
  category: 'Single account groups',
  action: 'Jump to dashboard single account',
};

export const OPERATE_EXPECTATION_IN_OPEN_CYCLE = {
  category: 'Manage expectation for open cycle',
  action: {
    ADD_EXPECTATION: 'Add expectation',
    EDIT_EXPECTATION: 'Edit expectation',
    DELETE_EXPECTATION: 'Delete expectation',
  },
};

export const FEEDBACK_API = {
  category: 'Feedback operations',
  action: {
    TWER_ADDS_CLIENT_FEEDBACK: 'TWer adds client feedback',
    TWER_EDITS_CLIENT_FEEDBACK: 'TWer edits client feedback',
    TWER_DELETES_CLIENT_FEEDBACK: 'TWer deletes client feedback',
    TWER_SENDS_REQUEST_FEEDBACK: 'TWer sends feedback request',
    TWER_EDITS_REQUEST_FEEDBACK: 'TWer edits feedback request',
    TWER_CANCELS_REQUEST_FEEDBACK: 'TWer cancels feedback request',
    FEEDBACK_PROVIDER_SUBMITS_FEEDBACK: 'Feedback provider submits feedback',
  },
};

export const MY_CYCLE_LIST_API = {
  category: 'Cycle operation by TWer',
  action: {
    TWER_CREATES_SELF_ASSESSMENT: 'TWer creates self-assessment',
    TWER_EDITS_SELF_ASSESSMENT: 'TWer edits self-assessment',
    TWER_ADDS_CHECK_IN_NOTES: 'TWer adds check-in notes',
    TWER_EDITS_CHECK_IN_NOTES: 'TWer edits check-in notes',
    TWER_OPENS_V1_CYCLE: 'TWer opens v1 cycle',
    TWER_EDITS_V1_CYCLE: 'TWer edits v1 cycle',
    TWER_CREATES_V2_CYCLE_WITH_NEW_STATUS: 'TWer creates v2 cycle with new status',
    TWER_EDITS_V2_CYCLE_INFORMATION: 'TWer edits v2 cycle information',
    TWER_ADDS_V2_CYCLE_EXPECTATION: 'TWer adds v2 cycle expectation',
    TWER_EDITS_V2_CYCLE_EXPECTATION: 'TWer edits v2 cycle expectation',
    TWER_UPLOADS_V2_CYCLE_ATTACHMENT: 'TWer uploads v2 cycle attachment',
    TWER_OPENS_V2_CYCLE: 'TWer opens v2 cycle',
    TWER_CANCELS_V1_CYCLE: 'TWer cancels v1 cycle',
    TWER_CANCELS_V2_CYCLE_WITH_THE_NEW_STATUS: 'TWer cancels v2 cycle with the new status',
    TWER_CANCELS_V2_CYCLE_WITH_THE_OPEN_STATUS: 'TWer cancels v2 cycle with the open status',
    TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_LIST:
      'TWer reads reject to cycle editing request in the cycle list',
  },
};

export const MY_CYCLE_DETAIL_API = {
  category: 'Cycle editing request operation by TWer',
  action: {
    TWER_SENDS_CYCLE_EDITING_REQUEST: 'TWer sends cycle editing request',
    TWER_EDITS_DONE_CYCLE_AFTER_REQUEST_IS_APPROVED:
      'TWer edits done cycle after request is approved',
    TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_DETAIL_PAGE:
      'TWer reads reject to cycle editing request in the cycle detail page',
  },
};

export const DASHBOARD_API = role => ({
  category: 'Cycle editing request management',
  action: {
    APPROVES_EDIT_REQUEST_IN_REQUEST_LIST: `${role} approves cycle editing request in the request list page`,
    REJECTS_EDIT_REQUEST_IN_REQUEST_LIST: `${role} rejects cycle editing request in the request list page`,
    APPROVES_EDIT_REQUEST_IN_CYCLE_DETAIL: `${role} approves cycle editing request in the cycle detail page`,
    REJECTS_EDIT_REQUEST_IN_CYCLE_DETAIL: `${role} rejects cycle editing request in the cycle detail page`,
  },
});

export const TWER_I_SUPPORT_BY_PP_API = {
  category: 'Cycle operations by PP',
  action: {
    PP_READS_REJECT_TO_CYCLE_IN_CYCLE_LIST: `PP reads reject to cycle editing request in the cycle list`,
    PP_ADDS_CHECK_IN_NOTES: `PP adds check-in notes`,
    PP_EDITS_CHECK_IN_NOTES: `PP edits check-in notes`,
    PP_ADDS_DRAFT_ASSESSMENT_SUMMARY: `PP adds draft assessment summary`,
    PP_EDITS_DRAFT_ASSESSMENT_SUMMARY: `PP edits draft assessment summary`,
    PP_SUBMITS_ASSESSMENT_SUMMARY: `PP submits assessment summary`,
    PP_SENDS_CYCLE_EDITING_REQUEST: `PP sends cycle editing request`,
    PP_READS_REJECT_TO_CYCLE_IN_CYCLE_DETAIL: `PP reads reject to cycle editing request in the cycle detail page`,
    PP_EDITS_DONE_CYCLE_AFTER_REQUEST_APPROVED: `PP edits done cycle after request is approved`,
  },
};

export const TWER_I_SUPPORT_BY_AP_API = {
  category: 'Cycle operations by AP',
  action: {
    AP_ADDS_CHECK_IN_NOTES: `AP adds check-in notes`,
    AP_EDITS_CHECK_IN_NOTES: `AP edits check-in notes`,
    AP_ADDS_ASSESSMENT_INPUT: `AP adds assessment input`,
    AP_EDITS_ASSESSMENT_INPUT: `AP edits assessment input`,
  },
};

export const ADMIN_MANAGEMENT_BY_SYSTEM_ADMIN = {
  category: 'Admin management by system admin',
  action: {
    SYSTEM_ADMIN_ADDS_SYSTEM_ADMIN: `System admin adds system admin`,
    SYSTEM_ADMIN_REMOVES_SYSTEM_ADMIN: `System admin removes system admin`,
    SYSTEM_ADMIN_ADDS_REGIONAL_ADMIN: `System admin adds regional admin`,
    SYSTEM_ADMIN_REMOVES_REGIONAL_ADMIN: `System admin removers regional admin`,
    SYSTEM_ADMIN_ADDS_OPS_ADMIN: `System admin adds Ops admin`,
    SYSTEM_ADMIN_REMOVES_OPS_ADMIN: `System admin removes Ops admin`,
  },
};

export const REGIONAL_ROLE_MANAGEMENT_BY_REGIONAL_ADMIN = {
  category: 'Regional role management by regional admin',
  action: {
    REGIONAL_ADMIN_ADDS_REGIONAL_TBP: `Regional admin adds Regional TBP`,
    REGIONAL_ADMIN_REMOVES_REGIONAL_TBP: `Regional admin removes Regional TBP`,
    REGIONAL_ADMIN_ADDS_MSO_LEADERSHIP: `Regional admin adds MU/SL/OPs Leadership`,
    REGIONAL_ADMIN_REMOVES_MSO_LEADERSHIP: `Regional admin removes MU/SL/OPs Leadership`,
    REGIONAL_ADMIN_ADDS_TBP: `Regional admin adds TBP`,
    REGIONAL_ADMIN_REMOVES_TBP: `Regional admin removes TBP`,
    REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP: `Regional admin adds member to regional people team group`,
    REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_PEOPLE_TEAM_GROUP: `Regional admin removes member from regional people team group`,
    REGIONAL_ADMIN_ADDS_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP: `Regional admin adds member to regional leadership team group`,
    REGIONAL_ADMIN_REMOVES_MEMBER_TO_REGIONAL_LEADERSHIP_TEAM_GROUP: `Regional admin removes member from regional leadership team group`,
    REGIONAL_ADMIN_EDITS_REGIONAL_LEVEL_GROUP: `Regional admin edits regional level group`,
  },
};

export const GLOBAL_ROLE_MANAGEMENT_BY_GLOBAL_FSL_ADMIN = {
  category: 'Global role management by OPs Admin',
  action: {
    GLOBAL_ROLE_ADDS_CEO: `Global admin adds CEO`,
    GLOBAL_ROLE_REMOVES_CEO: `Global admin removes CEO`,
    GLOBAL_ROLE_ADDS_CTO: `Global admin adds CTO`,
    GLOBAL_ROLE_REMOVES_CTO: `Global admin removes CTO`,
    GLOBAL_ROLE_ADDS_GLOBAL_FUNCTION_SL_LEADERSHIP: `Global admin adds Global Function/SL Leadership`,
    GLOBAL_ROLE_REMOVES_GLOBAL_FUNCTION_SL_LEADERSHIP: `Global admin removes Global Function/SL Leadership`,
    GLOBAL_ROLE_ADDS_TBP: `Global admin adds TBP`,
    GLOBAL_ROLE_REMOVES_TBP: `Global admin removes TBP`,
    GLOBAL_ROLE_ADDS_MEMBER_TO_GLOBAL_PEOPLE: `Global admin adds member to global people team group`,
    GLOBAL_ROLE_REMOVES_MEMBER_FROM_GLOBAL_PEOPLE: `Global admin removes member from global people team group`,
    GLOBAL_ROLE_ADDS_MEMBER_TO_EGLT_GROUP: `Global admin adds member to EGLT group`,
    GLOBAL_ROLE_REMOVES_MEMBER_FROM_EGLT_GROUP: `Global admin removes member from EGLT group`,
    GLOBAL_ROLE_ADDS_GLOBAL_TBP: `Global admin adds Global TBP`,
    GLOBAL_ROLE_REMOVES_GLOBAL_TBP: `Global admin removes Global TBP`,
  },
};

export const CUSTOMIZED_GROUP_MANAGEMENT = {
  category: 'Customized group management',
  action: {
    TBP_CREATES_CUSTOMIZED_GROUP: `TBP creates customized group`,
    TBP_REMOVES_CUSTOMIZED_GROUP: `TBP removes customized group`,
    TBP_EDITS_NAME_AND_GROUP_LEAD: `TBP edits the name and group lead of customized group`,
    TBP_IMPORTS_MEMBER: `TBP imports member of customized group`,
    TBP_DELETES_MEMBER: `TBP deletes member of customized group`,
  },
};

export const AUTOMATED_GROUP_MANAGEMENT = {
  category: 'Automated group management',
  action: {
    TBP_HIDES_AUTOMATED_GROUP: `TBP hides automated group`,
    TBP_SHOW_AUTOMATED_GROUP: `TBP show automated group`,
    TBP_EDITS_AUTOMATED_GROUP: `TBP edits automated group`,
    TBP_EDITS_AUTOMATED_GROUP_IN_DETAIL_PAGE: `TBP edits automated group in detail page`,
  },
};

export const REGIONAL_LEVEL_GROUP_MANAGEMENT = {
  category: 'Regional level group management',
  action: {
    IMPORTS_GROUP_MEMBER: `Regional level group manager imports group member`,
    DELETE_GROUP_MEMBER: `Regional level group manager delete group member`,
  },
};

export const REQUEST_FEEDBACK_MANAGEMENT = {
  category: 'Request feedback management',
  action: {
    ADD_CLIENT_FEEDBACK: 'add client feedback',
    ADD_INTERNAL_FEEDBACK: 'add internal feedback',
    EDIT_CLIENT_FEEDBACK: 'edit client feedback',
    EDIT_INTERNAL_FEEDBACK: 'edit internal feedback',
    CANCEL_FEEDBACK: 'cancel feedback',
  },
};

export const GIVEN_FEEDBACK_MANAGEMENT = {
  category: 'Given feedback management',
  action: {
    SEND_FEEDBACK: 'send feedback',
    DRAFT_FEEDBACK: 'draft feedback',
  },
};
