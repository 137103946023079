import React from 'react';
import { RolesOfManagement } from '../types/admin';

interface RolesOfManagementContext {
  rolesOfManagement: RolesOfManagement | undefined;
  updateRolesOfManagement: (roles: RolesOfManagement | undefined) => void | (() => void);
  updateLoadedRegionsForGroupManager: () => void;
}

export const RolesOfManagementContext = React.createContext<RolesOfManagementContext>({
  rolesOfManagement: {} as RolesOfManagement,
  updateRolesOfManagement: () => {},
  updateLoadedRegionsForGroupManager: () => {
    return null;
  },
});
