import {
  CYCLE_SCORE_RATING_DESCRIPTION,
  CYCLE_SCORE_RATING_DESCRIPTION_V1,
} from '@@/_new_src_/constants/myCycles';

export default {
  title: 'How am I doing?',
  desc: `Summit is your new Thoughtworks tool to help you to learn how you are doing against expectations
        and get support with your growth journey.
        By frequently aligning expectations and assessing how are you performing,
        you can gain a clear picture of where you are in your growth journey,
        understand how to improve it and gain focus towards your challenges!`,

  getStarted: 'Get Started',
  guidanceTip: `Not familiar with the performance process? check the
      <a target="_blank" href="https://docs.google.com/presentation/d/1NzvdEbE5UAJDor5g8cCSVv1nTi3B8ejR2v1O1ov_q5U/edit#slide=id.ge0d2666f43_2_202">
        guidance
      </a>.`,
  checkReview: 'Check my cycle',
  expect: {
    title: 'What should I expect?',
    step: {
      title: 'Three Steps In Performance Process',
      detail: [
        {
          label: 'Expectation Alignment:',
          content:
            'In the beginning of the performance cycle, you will need to align expectations with your performance partner. In this conversation you should define the context of your work, share your growth expectations, understand what is expected from you and how aligned to your grade these expectations are. ',
        },
        {
          label: 'Regular Check-ins and collect feedback:',
          content:
            'Periodically during the time of the performance cycle, you will need to have regular conversations with your performance partner and collect feedback from peers and stakeholders. During the conversations, you can discuss highlights, progress and blockers about your growth and performance. You can share your own reflections, the feedback you collected, seek for feedback and ask for support. Also this is a space to understand how you are performing and realign expectations if needed. ',
        },
        {
          label: 'Assessment:',
          content:
            'At the end of a performance cycle, you and your performance partner will have a conversation to assess your performance. There are two phases in the assessment: the first one is where you reflect on how your performance cycle has been, creating a summary for yourself and for your performance partner. The second one is the assessment provided by your performance partner, this includes a performance indicator and an assessment summary. It should be generated base on your self-assessment, the assessment input from your additional partner(s), and the assessment conversations you had together.',
        },
      ],
    },
    level: {
      title: 'The Five Indicators',
      detail: CYCLE_SCORE_RATING_DESCRIPTION,
    },
  },
  v1: {
    startReview: 'Start my cycle',
    tip: 'Or let me learn more, first',
    expect: {
      title: 'What should I expect?',
      step: {
        title: 'Three Steps Performance Process',
        detail: [
          {
            label: 'Expectation Alignment:',
            content:
              'In the beginning of the performance cycle, you will need to align expectations with your performance partner(s) for a defined period of time. In this conversation you should define the context of your work, share your growth expectations, understand what is expected from you and how aligned to your grade and role these expectations are.',
          },
          {
            label: 'Regular Check-ins:',
            content:
              'Periodically during the time of the performance cycle, you will need to have regular conversations with your performance partner(s) to discuss highlights, progress and blockers about your growth and performance. You can share your own reflections, seek for feedback and ask for support. Also this is a space to understand how you are performing and realign expectations if needed.',
          },
          {
            label: 'Performance assessment:',
            content:
              'At the end of a performance cycle, you and your performance partner(s) will have a conversation to assess your performance. The performance partner(s) will summarise the conversation and define a performance indicator for that cycle. It is a joint responsibility of all involved partners to summarise and provide the performance indicator although only one partner has permission to input and submit this in Summit on behalf of all partners.',
          },
        ],
      },
      level: {
        title: 'Four Performance Indicators',
        detail: CYCLE_SCORE_RATING_DESCRIPTION_V1,
      },
    },
  },
};
