export enum ERROR_CODE {
  SUCCESS = 'SUCCESS',
  MISS_FIELD_ERROR = 'MISS_FIELD_ERROR',
  INVALID_INPUT_ERROR = 'INVALID_INPUT_ERROR',
  INVALID_REASON_ERROR = 'INVALID_REASON_ERROR',
  DUPLICATE_EMPLOYEE_ID_ERROR = 'DUPLICATE_EMPLOYEE_ID_ERROR',
  GMT_EMPLOYEE_ID_ERROR = 'GMT_EMPLOYEE_ID_ERROR',
  INVALID_EMPLOYEE_ID_ERROR = 'INVALID_EMPLOYEE_ID_ERROR',
  EXIT_EMPLOYEE_ID_ERROR = 'EXIT_EMPLOYEE_ID_ERROR',
  INVALID_SCOPE_EMPLOYEE_ID_ERROR = 'INVALID_SCOPE_EMPLOYEE_ID_ERROR',
  INVALID_CALIBRATING_EMPLOYEE_ID_ERROR = 'INVALID_CALIBRATING_EMPLOYEE_ID_ERROR',
  INVALID_FILE_ERROR = 'INVALID_FILE_ERROR',
}

export enum REASON_LENGTH {
  MIN = 10,
  MAX = 5000,
}

export const initialUploadFileError = {
  code: '',
  errorItems: [],
};
