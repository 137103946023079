import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { REVIEW_CHANGE_HISTORY_RECORD_TYPE } from '../../constant/review';
import './index.less';
dayjs.extend(utc);

const ChangeHistory = ({ list }) => {
  const {
    dashboardV2: {
      reviewDetailChangeHistory: { description },
    },
  } = useSelector(selectLocaleResource);
  return (
    <div className="component-change-history">
      {list.map(item => (
        <div className="change-history-item" key={item.id}>
          <span>{dayjs.utc(item.createdTime).format('YYYY-MM-D HH:mm:ss')}</span>
          <span>
            {`${item.requester} ${
              item.recordType === REVIEW_CHANGE_HISTORY_RECORD_TYPE.ASSESSMENT
                ? description.assessment
                : description.review
            }`}
          </span>
        </div>
      ))}
    </div>
  );
};

ChangeHistory.defaultProps = {
  list: [],
};

export default memo(ChangeHistory);
