import { useMemo, useCallback } from 'react';
import { useLocalStorageState } from 'ahooks';
import { isEmpty } from 'lodash';

export default function useLocalStorageReview() {
  const [reviewerState, setReviewerState] = useLocalStorageState(`REVIEW_REVIEWER`, {});
  const [contributorsState, setContributorsState] = useLocalStorageState(`REVIEW_CONTRIBUTORS`, {});
  const [projectState, setProjectState] = useLocalStorageState(`REVIEW_PROJECT`, {});
  const [reviewDurationState, setReviewDurationState] = useLocalStorageState(`REVIEW_DURATION`, []);
  const [expectationState, setExpectationState] = useLocalStorageState(`REVIEW_EXPECTATION`, '');

  const isLocalReviewEmpty = useMemo(() => {
    return (
      isEmpty(reviewerState) &&
      isEmpty(contributorsState) &&
      isEmpty(projectState) &&
      isEmpty(reviewDurationState) &&
      isEmpty(expectationState)
    );
  }, [contributorsState, expectationState, projectState, reviewDurationState, reviewerState]);

  const removeLocalReview = useCallback(() => {
    setReviewerState();
    setContributorsState();
    setProjectState();
    setReviewDurationState();
    setExpectationState();
  }, [
    setContributorsState,
    setExpectationState,
    setProjectState,
    setReviewDurationState,
    setReviewerState,
  ]);

  return {
    reviewerState,
    setReviewerState,
    contributorsState,
    setContributorsState,
    projectState,
    setProjectState,
    reviewDurationState,
    setReviewDurationState,
    expectationState,
    setExpectationState,
    isLocalReviewEmpty,
    removeLocalReview,
  };
}
