import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCycleDetailByVersion } from '@@/common/utils/cycleDetailUtils';

export const getCurrentCycleDetailByVersionAsync = createAsyncThunk(
  'getCurrentCycleDetailByVersionAsync',
  data => {
    return getCycleDetailByVersion(data);
  },
);

const initialState = {
  currentCycleDetail: {},
};

const cycleDetailByVersionSlice = createSlice({
  name: 'cycleDetailByVersion',
  initialState,
  reducers: {
    setCurrentCycleDetail: (state, action) => {
      state.currentCycleDetail = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCurrentCycleDetailByVersionAsync.fulfilled, (state, action) => {
      state.currentCycleDetail = action.payload;
    });
  },
});

export const { setCurrentCycleDetail } = cycleDetailByVersionSlice.actions;

export const cycleDetailByVersion = state => state.cycleDetailByVersion;
export const currentCycleDetail = state => state.cycleDetailByVersion.currentCycleDetail;

export default cycleDetailByVersionSlice.reducer;
