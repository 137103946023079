import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { PermissionItem } from '@@/types/admin';
import './index.less';

interface PermissionsModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  permissionList: PermissionItem[];
}

export const PermissionsItem = ({ permission }: { permission: PermissionItem }) => {
  const permissionRole = get(permission, 'role');
  const permissionAccessList = get(permission, 'accessList', []) as Array<string>;
  return (
    <div key={permissionRole} className="permission-section">
      {permissionRole && (
        <div className="role">
          <span className="for">{permissionRole}</span>
        </div>
      )}
      <div className="content">
        {permissionAccessList.map(item => (
          <div key={item} className="item">
            <CheckCircleFilled className="icon" />
            <span>{item}</span>
          </div>
        ))}
      </div>
      <div className="scope">
        <span className="grey">for</span>&nbsp;
        <span>{get(permission, 'scope.user')}</span>&nbsp;
        <span className="grey">{get(permission, 'scope.preposition')}</span>&nbsp;
        <span>{get(permission, 'scope.correspondingCategory')}</span>
      </div>
    </div>
  );
};

const PermissionsModal = (props: PermissionsModalProps) => {
  const { title, visible, onCancel, permissionList } = props;

  return (
    <Modal
      width={550}
      className="permissions-modal"
      closeIcon={<CloseOutlined />}
      destroyOnClose
      centered
      maskClosable={false}
      footer={null}
      title={title}
      open={visible}
      onCancel={onCancel}
    >
      {permissionList?.map((permission, index) => (
        <PermissionsItem permission={permission} key={index} />
      ))}
    </Modal>
  );
};

export default PermissionsModal;
