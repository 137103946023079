import { useMemo, useCallback } from 'react';
import { useLocalStorageState } from 'ahooks';
import { isEmpty } from 'lodash';

export default function useLocalStorageReview() {
  const [expectationTitleState, setExpectationTitleState] = useLocalStorageState(
    `EXPECTATION_TITLE`,
    '',
  );
  const [expectationDetailsState, setExpectationDetailsState] = useLocalStorageState(
    `EXPECTATION_DETAILS`,
    '',
  );
  const [expectationCategoryIdsState, setExpectationCategoryIdsState] =
    useLocalStorageState(`EXPECTATION_CATEGORY_IDS`);

  const isLocalExpectationEmpty = useMemo(() => {
    return (
      isEmpty(expectationTitleState) &&
      isEmpty(expectationDetailsState) &&
      isEmpty(expectationCategoryIdsState)
    );
  }, [expectationTitleState, expectationDetailsState, expectationCategoryIdsState]);

  const isLocalExpectationNotEmpty = useMemo(() => {
    return (
      !isEmpty(expectationTitleState) ||
      !isEmpty(expectationDetailsState) ||
      !isEmpty(expectationCategoryIdsState)
    );
  }, [expectationTitleState, expectationDetailsState, expectationCategoryIdsState]);

  const removeLocalExpectation = useCallback(() => {
    setExpectationTitleState();
    setExpectationDetailsState();
    setExpectationCategoryIdsState();
  }, [setExpectationTitleState, setExpectationDetailsState, setExpectationCategoryIdsState]);

  return {
    expectationTitleState,
    setExpectationTitleState,
    expectationDetailsState,
    setExpectationDetailsState,
    expectationCategoryIdsState,
    setExpectationCategoryIdsState,
    isLocalExpectationEmpty,
    isLocalExpectationNotEmpty,
    removeLocalExpectation,
  };
}
