import React from 'react';
import { useHistory } from 'react-router';
import EmptyRequestIcon from '@@/assets/images/empty-request.svg';
import './index.less';
import Locale from '@@/_new_src_/local/cycleDetail/en_US';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const NoDoneFeedbackContainer = () => {
  const history = useHistory();
  const {
    noDoneFeedbackContainer: { title, tip, viewText, feedbackPage },
  } = Locale;

  const goToFeedbackPage = () => {
    history.push(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE);
  };
  return (
    <div className="pending-or-cancelled-tab-content-container">
      <img src={EmptyRequestIcon} className="empty-request" alt="" />
      <div className="content">{title}</div>
      <div className="tip">{tip}</div>
      <div className="navigate-area">
        <span>
          <a onClick={goToFeedbackPage}>
            <i className="ri-eye-line" />
            {viewText}&nbsp;
          </a>
          {feedbackPage}
        </span>
      </div>
    </div>
  );
};

export default NoDoneFeedbackContainer;
