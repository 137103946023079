import { NON_ENGAGED_CYCLES_TAB_INDEX } from '@@/_new_src_/constants/overdueAndNonEngaged';

export default {
  nonEngagedCycles: {
    tabName: {
      OverdueCycles: 'Assessment overdue cycles',
      assessmentNonEngagedCycles: 'Assessment non-engaged cycles',
      CyclesWithTemplateExpectations: 'Expectation non-engaged cycles',
    },
    tabText: (index: string) => {
      switch (index) {
        case NON_ENGAGED_CYCLES_TAB_INDEX.ASSESSMENT_NON_ENGAGED:
          return 'non-engaged in assessment phase';
        case NON_ENGAGED_CYCLES_TAB_INDEX.EXPECTATION_NON_ENGAGED:
          return 'cycle(s) non-engaged in setting up expectations';
        default:
          return 'Overdue cycle(s) in total';
      }
    },
    filter: {
      title: 'Filter area',
      condition: "By Twer's name",
      placeholder: "Enter the Twer's name or ID",
      button: 'Find',
    },
    operation: 'Unmark non-engagement',
    unmarkByTbp: 'Update assessment and unmark non-engaged',
    confirmModal: {
      buttonText: {
        cancelEdit: 'No, cancel submission',
        confirmEdit: 'Submit and unmark non-engaged',
      },
      submitTitle: 'Are you sure to submit the assessment and unmark non-engagement accordingly?',
      submitText: `<p style="margin:20px 0 0; font-size: 16px;">Once submitted, this cycle will no longer carry the ‘assessment non-engaged’ identifier, and the TWer’s performance assessment will be updated based on your submission. Please make sure you that you’ve <b>aligned with the TWers</b> before proceeding.</p>
                <p style="margin: 0; font-size: 16px; font-style: italic">Note: Don’t forget to consider this change into the performance calibration analysis.</p>`,
      cancelSubmit: 'No, cancel submission',
      confirmSubmit: 'Yes, submit and unmark non-engaged',
      successMessage: 'The non-engagement is unmarked successfully!',
    },
    expectationNonEngaged: {
      tip: "TWers who haven't set up and aligned expectations on time are listed below. Once they update their expectations, their names will be removed from the list.",
    },
  },
};
