import React from 'react';
import FeedbackRequestModal from '@@/_new_src_/page/feedback/component/V1FeedbackModalSummary/FeedbackRequestModal';
import FeedbackRequestToClientsModal from '@@/_new_src_/page/feedback/component/V1FeedbackModalSummary/FeedbackRequestToClientsModal';
import WriteFeedbackModal from '@@/_new_src_/page/feedback/component/V1FeedbackModalSummary/WriteFeedbackModal';
import SubmittedFeedbackDetailModalAsRequester from '@@/_new_src_/page/feedback/component/V1FeedbackModalSummary/SubmittedFeedbackDetailModalAsRequester';
import { V1FeedbackModalInfo } from '@@/_new_src_/store/V2FeedbackSlice';
import { useSelector } from 'react-redux';

const V1FeedbackModalSummary = () => {
  const {
    isClientFeedbackModalVisible,
    isFeedbackRequestModalVisible,
    isWriteFeedbackModalVisible,
    isSubmittedFeedbackDetailModalVisible,
  } = useSelector(V1FeedbackModalInfo);

  return (
    <>
      {isFeedbackRequestModalVisible && <FeedbackRequestModal />}
      {isClientFeedbackModalVisible && <FeedbackRequestToClientsModal />}
      {isWriteFeedbackModalVisible && <WriteFeedbackModal />}
      {isSubmittedFeedbackDetailModalVisible && <SubmittedFeedbackDetailModalAsRequester />}
    </>
  );
};
export default V1FeedbackModalSummary;
