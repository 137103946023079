import React from 'react';
import { Button, Select } from 'antd';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import ClearIcon from '@@/common/component/ClearIcon';
import { getUserListApiWithExited } from '@@/api/user';
import {
  IAutoCompleteOption,
  IFilterInfo,
} from '@@/_new_src_/store/nonEngagedCyclesSlice/interface';

import AutoComplete from '@@/common/component/AutoComplete';
import './index.less';
import {
  INITIAL_TABLE_INFO,
  NON_ENGAGED_CYCLES_TAB,
} from '@@/_new_src_/constants/overdueAndNonEngaged';
import { useSelector } from 'react-redux';
import { nonEngagedCycles } from '@@/_new_src_/store/nonEngagedCyclesSlice';

const FilterArea = (props: {
  findCycleNonEngagedCycles: (filterInfo: IFilterInfo) => void;
  getCurrentFilterInfo: IFilterInfo;
  updateFilterInfo: (filterInfo: IFilterInfo) => void;
}) => {
  const { findCycleNonEngagedCycles, getCurrentFilterInfo, updateFilterInfo } = props;

  const {
    nonEngagedCycles: { filter: filterLocale },
  } = nonEngagedCyclesLocale;

  const { currentTab } = useSelector(nonEngagedCycles);

  const autoCompleteProps = {
    showArrow: false,
    showSearch: true,
    notFoundContent: null,
    clearIcon: <ClearIcon />,
  };

  const changeFilterSelect = (e: IAutoCompleteOption[]) => {
    updateFilterInfo({ ...getCurrentFilterInfo, selectUsers: e });
  };

  const onFind = () => {
    const type = NON_ENGAGED_CYCLES_TAB[currentTab];
    findCycleNonEngagedCycles({
      ...getCurrentFilterInfo,
      tableInfo: INITIAL_TABLE_INFO[type],
    });
  };

  return (
    <div className={'non-engaged-cycle-filter'}>
      <div className={'title'}>{filterLocale.title}</div>
      <div className={'sub-title'}>{filterLocale.condition}</div>
      <div className={'filter-by-twers-name'}>
        <AutoComplete
          mode="multiple"
          {...autoCompleteProps}
          placeholder={filterLocale.placeholder}
          fetchApi={getUserListApiWithExited()}
          onChange={changeFilterSelect}
          initialInputValue={getCurrentFilterInfo.selectUsers}
        >
          {(list: { userId: number; name: string }[], Option: typeof Select.Option) => {
            return list.map(({ userId, name }) => (
              <Option key={String(userId)} label={name} value={userId}>
                {`${name}, ${userId}`}
              </Option>
            ));
          }}
        </AutoComplete>
        <Button className={'find-button'} onClick={onFind}>
          <i className="ri-search-line" />
          <div>{filterLocale.button}</div>
        </Button>
      </div>
    </div>
  );
};
export default FilterArea;
