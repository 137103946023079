import { createSlice } from '@reduxjs/toolkit';
import { getLocale, getLocaleResource } from '@@/locale/helper';

const initialState = {
  locale: getLocale(),
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = globalSlice.actions;

export const selectLocaleResource = state => getLocaleResource(state.global.locale);

export default globalSlice.reducer;
