import { setDefaultSearchFilterFun } from '@@/features/performance/v2/dashboardPage/common';
import { createSlice } from '@reduxjs/toolkit';
import {
  getCycleDetailsV1Async,
  getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync,
  getGroupListAsync,
  getOpenRequestCountAsync,
  getRequestListBySourceCategoryAsync,
  getRoleOptionListAsync,
} from '@@/redux/slice/dashboardSlice/asyncThunk';

const initialState = {
  loading: false,
  childrenLoading: false,
  displayGroupSelect: false,

  currentRoleSelection: {
    roleType: '',
    roleId: '',
    roleName: '',
    roleInfo: '',
    displayRoleName: '',
    secondList: [],
  },
  currentGroupSelection: {
    groupId: '',
    groupType: '',
  },
  roleOptionList: [],
  groupList: {
    groupOptionList: [],
    groupKeyList: [],
  },

  searchFilter: {},
  openRequest: {
    count: 0,
    loading: false,
  },
  requestPageStatus: {},
  isFromDetailPageInitialed: false,

  dashboardCycleDetail: {
    data: {},
    loading: false,
  },
  changeHistory: {
    loading: false,
    data: [],
  },

  requestListData: {
    loading: false,
    success: false,
    total: 0,
    content: [],
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDisplayGroupSelect: (state, action) => {
      state.displayGroupSelect = action.payload;
    },
    setCurrentRoleSelection: (state, action) => {
      state.currentRoleSelection = action.payload;
    },
    setCurrentGroupSelection: (state, action) => {
      state.currentGroupSelection = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = setDefaultSearchFilterFun(action.payload);
    },
    setOpenRequestCount: (state, action) => {
      state.openRequest = {
        ...initialState.openRequest,
        count: action.payload && action.payload >= 0 ? action.payload : 0,
      };
    },
    setRequestPageStatus: (state, action) => {
      state.requestPageStatus = action.payload;
    },
    setIsFromDetailPageInitialed: (state, action) => {
      state.isFromDetailPageInitialed = action.payload;
    },
    clearRoleSelection: state => {
      state.currentRoleSelection = initialState.currentRoleSelection;
      state.roleOptionList = initialState.roleOptionList;
    },
    clearGroupSelection: state => {
      state.currentGroupSelection = initialState.currentGroupSelection;
      state.groupList = initialState.groupList;
    },
    setDashboardCycleDetail: (state, action) => {
      state.dashboardCycleDetail = action.payload;
    },
    setChangeHistory: (state, action) => {
      state.changeHistory = action.payload;
    },
    setRequestListData: (state, action) => {
      state.requestListData = {
        ...initialState.requestListData,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRoleOptionListAsync.fulfilled, (state, action) => {
        state.roleOptionList = action.payload;
      })
      .addCase(getGroupListAsync.fulfilled, (state, action) => {
        state.groupList = action.payload;
      })
      .addCase(getCycleDetailsV1Async.pending, state => {
        state.dashboardCycleDetail = {
          data: {},
          loading: true,
        };
      })
      .addCase(getCycleDetailsV1Async.fulfilled, (state, action) => {
        state.dashboardCycleDetail = {
          data: action.payload.data,
          loading: false,
        };
      })
      .addCase(getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync.pending, state => {
        state.changeHistory = {
          data: {},
          loading: true,
        };
      })
      .addCase(
        getDoneCycleAndAssessmentFromDashboardPageByCycleIdAsync.fulfilled,
        (state, action) => {
          state.changeHistory = {
            data: action.payload.data,
            loading: false,
          };
        },
      )
      .addCase(getOpenRequestCountAsync.pending, state => {
        state.openRequest = {
          ...initialState.openRequest,
          loading: true,
        };
      })
      .addCase(getOpenRequestCountAsync.fulfilled, (state, action) => {
        state.openRequest = {
          ...initialState.openRequest,
          count: action.payload.data,
          loading: false,
        };
      })
      .addCase(getOpenRequestCountAsync.rejected, state => {
        state.openRequest = {
          ...initialState.openRequest,
          loading: false,
        };
      })
      .addCase(getRequestListBySourceCategoryAsync.pending, state => {
        state.requestListData = {
          ...initialState.requestListData,
          loading: true,
          success: false,
        };
      })
      .addCase(getRequestListBySourceCategoryAsync.fulfilled, (state, action) => {
        state.requestListData = {
          ...initialState.requestListData,
          ...action.payload.data,
          loading: false,
          success: true,
        };
      })
      .addCase(getRequestListBySourceCategoryAsync.rejected, state => {
        state.requestListData = {
          ...initialState.requestListData,
          loading: false,
          success: false,
        };
      });
  },
});

export const {
  setLoading,
  setDisplayGroupSelect,
  setCurrentRoleSelection,
  setCurrentGroupSelection,
  setSearchFilter,
  setRequestPageStatus,
  setIsFromDetailPageInitialed,
  clearRoleSelection,
  clearGroupSelection,
  setDashboardCycleDetail,
} = dashboardSlice.actions;

export const getRoleOptionList = getRoleOptionListAsync;
export const getGroupList = getGroupListAsync;

export const dashboard = state => state.dashboard;
export const getLoading = state => state.dashboard.loading;
export const getChildrenLoading = state => state.dashboard.childrenLoading;
export const getCurrentRoleSelection = state => state.dashboard.currentRoleSelection;
export const getCurrentGroupSelection = state => state.dashboard.currentGroupSelection;
export const dashboardCycleDetail = state => state.dashboard.dashboardCycleDetail;
export const changeHistory = state => state.dashboard.changeHistory;

export const requestListData = state => state.dashboard.requestListData;

export default dashboardSlice.reducer;
