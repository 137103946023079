import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getMyReviewsByType,
  getReviewDetailById,
  getPerformancePartnerCycleDetailById,
  getReviewHistoryAvailableYear,
  markReviewAsRead,
} from '@@/api/review';
import { isEmpty } from 'lodash';
import { CYCLE_TYPE } from '@@/_new_src_/constants/CycleStatus';

export const getMyCycleByTypeAsync = createAsyncThunk('getMyCycleByTypeAsync', async () => {
  return await getMyReviewsByType(CYCLE_TYPE.CURRENT_CYCLE);
});

export const getMyHistoryCycleAsync = createAsyncThunk('getMyHistoryCycle', async data => {
  return await getMyReviewsByType(CYCLE_TYPE.HISTORY_CYCLE, data);
});

export const getCycleHistoryAvailableYearAsync = createAsyncThunk(
  'getCycleHistoryAvailableYear',
  async () => {
    return await getReviewHistoryAvailableYear();
  },
);

export const getCycleDetailByIdAsync = createAsyncThunk('getCycleDetailById', async cycleId => {
  return await getReviewDetailById(cycleId);
});

export const getPerformancePartnerCycleDetailByIdAsync = createAsyncThunk(
  'getPerformancePartnerCycleDetailById',
  async cycleId => {
    return await getPerformancePartnerCycleDetailById(cycleId);
  },
);

export const getMarkReviewAsReadAsync = createAsyncThunk('markReviewAsRead', async cycleId => {
  return await markReviewAsRead(cycleId);
});

const initialState = {
  showCurrentSelfReviewInput: false,
  currentSelfReviewText: undefined,
  showDoneSelfReviewInput: false,
  doneSelfReviewText: undefined,
  isEditReviewHistoryDetail: false,

  cyclesCycleDetailV1: {
    list: [],
    data: {},
    loading: false,
  },

  historyCycleList: {
    data: [],
    loading: false,
  },

  cycleHistoryYearList: {
    data: [],
    loading: false,
  },

  updateUnreadStatus: {
    loading: false,
    success: false,
  },
};

const reviewSlice = createSlice({
  name: 'reviewState',
  initialState,
  reducers: {
    setShowCurrentSelfReviewInput: (state, action) => {
      state.showCurrentSelfReviewInput = action.payload;
    },
    setCurrentSelfReviewText: (state, action) => {
      state.currentSelfReviewText = action.payload;
    },
    clearCurrentReviewState: state => {
      state.showCurrentSelfReviewInput = false;
      state.currentSelfReviewText = undefined;
    },
    setShowDoneSelfReviewInput: (state, action) => {
      state.showDoneSelfReviewInput = action.payload;
    },
    setDoneSelfReviewText: (state, action) => {
      state.doneSelfReviewText = action.payload;
    },
    clearDoneReviewState: state => {
      state.showDoneSelfReviewInput = false;
      state.doneSelfReviewText = undefined;
    },
    setIsEditReviewHistoryDetail: (state, action) => {
      state.isEditReviewHistoryDetail = action.payload;
    },
    setCyclesCycleDetailV1: (state, action) => {
      state.cyclesCycleDetailV1 = action.payload;
    },
    setHistoryCycleList: (state, action) => {
      state.historyCycleList = action.payload;
    },
    setCycleHistoryYear: (state, action) => {
      state.cycleHistoryYearList = action.payload;
    },
    setUpdateUnreadStatus: (state, action) => {
      state.updateUnreadStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMyCycleByTypeAsync.pending, state => {
        state.cyclesCycleDetailV1 = {
          loading: true,
          data: [],
          list: [],
        };
      })
      .addCase(getMyCycleByTypeAsync.fulfilled, (state, action) => {
        const responseData = action.payload.data;
        const firstData = !isEmpty(responseData) && responseData[0];
        state.cyclesCycleDetailV1 = {
          loading: false,
          data: firstData,
          list: responseData,
        };
      })
      .addCase(getMyHistoryCycleAsync.pending, state => {
        state.historyCycleList = {
          loading: true,
          data: {},
        };
      })
      .addCase(getMyHistoryCycleAsync.fulfilled, (state, action) => {
        state.historyCycleList = {
          loading: false,
          data: action.payload.data,
        };
      })
      .addCase(getCycleHistoryAvailableYearAsync.pending, state => {
        state.cycleHistoryYearList = {
          loading: true,
          data: [],
        };
      })
      .addCase(getCycleHistoryAvailableYearAsync.fulfilled, (state, action) => {
        state.cycleHistoryYearList = {
          loading: false,
          data: action.payload.data,
        };
      })
      .addCase(getCycleDetailByIdAsync.pending, state => {
        state.cyclesCycleDetailV1 = {
          loading: true,
          data: [],
          list: [],
        };
      })
      .addCase(getCycleDetailByIdAsync.fulfilled, (state, action) => {
        state.cyclesCycleDetailV1 = {
          loading: false,
          data: action.payload.data,
          list: [],
        };
      })
      .addCase(getPerformancePartnerCycleDetailByIdAsync.pending, state => {
        state.cyclesCycleDetailV1 = {
          loading: true,
          data: [],
          list: [],
        };
      })
      .addCase(getPerformancePartnerCycleDetailByIdAsync.fulfilled, (state, action) => {
        state.cyclesCycleDetailV1 = {
          loading: false,
          data: action.payload.data,
          list: [],
        };
      })
      .addCase(getMarkReviewAsReadAsync.pending, state => {
        state.updateUnreadStatus = {
          loading: true,
          success: false,
        };
      })
      .addCase(getMarkReviewAsReadAsync.fulfilled, state => {
        state.updateUnreadStatus = {
          loading: false,
          success: true,
        };
      });
  },
});

export const {
  setShowCurrentSelfReviewInput,
  setCurrentSelfReviewText,
  clearCurrentReviewState,
  setShowDoneSelfReviewInput,
  setDoneSelfReviewText,
  clearDoneReviewState,
  setIsEditReviewHistoryDetail,
  setCyclesCycleDetailV1,
  setHistoryCycleList,
  setCycleHistoryYear,
  setUpdateUnreadStatus,
} = reviewSlice.actions;

export const reviewState = state => state.review;

export const selectShowCurrentSelfReviewInput = state => state.review.showCurrentSelfReviewInput;

export const selectCurrentSelfReviewText = state => state.review.currentSelfReviewText;

export const selectShowDoneSelfReviewInput = state => state.review.showDoneSelfReviewInput;

export const selectDoneSelfReviewText = state => state.review.doneSelfReviewText;

export const selectIsEditReviewHistoryDetail = state => state.review.isEditReviewHistoryDetail;

export const cyclesCycleDetailV1 = state => state.review.cyclesCycleDetailV1;

export const historyCycleList = state => state.review.historyCycleList;

export const cycleHistoryYearList = state => state.review.cycleHistoryYearList;

export const updateUnreadStatus = state => state.review.updateUnreadStatus;

export default reviewSlice.reducer;
