import { createSlice } from '@reduxjs/toolkit';
import { ICommon, ISignedUrlInfo, IState } from '@@/_new_src_/store/commonSlice/interface';
import { postOpenNormalCycleAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { isArray } from 'lodash';
import { formatV2ProjectListData } from '@@/_new_src_/utils/feature';
import {
  getProjectListAsync,
  getProjectListByUserIdAsync,
  getSignedUrlAsync,
} from '@@/_new_src_/store/commonSlice/asyncThunk';

const initialState: ICommon = {
  confirmModalStatus: false,
  confirmModalLoading: false,
  confirmModalType: '',
  previewModalStatus: false,
  selfAssessmentPreviewModalStatus: false,
  signedUrl: {
    loading: false,
    success: false,
    reject: false,
    url: '',
  },
  projectList: {
    loading: false,
    list: [],
  },
};

// 创建模块 create a module
const commonSlice = createSlice({
  name: 'common',
  initialState: { ...initialState },
  reducers: {
    setConfirmModalStatus: (state, action) => {
      state.confirmModalStatus = action.payload as boolean;
    },
    setConfirmModalLoading: (state, action) => {
      state.confirmModalLoading = action.payload as boolean;
    },
    setConfirmModalType: (state, action) => {
      state.confirmModalType = action.payload as string;
    },
    setPreviewModalStatus: (state, action) => {
      state.previewModalStatus = action.payload as boolean;
    },
    setSelfAssessmentPreviewModalStatus: (state, action) => {
      state.selfAssessmentPreviewModalStatus = action.payload as boolean;
    },
    setSignedUrl: (state, action) => {
      state.signedUrl = {
        ...initialState.signedUrl,
        ...action.payload,
      } as ISignedUrlInfo;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSignedUrlAsync.pending, state => {
        state.signedUrl = {
          ...initialState.signedUrl,
          loading: true,
        };
      })
      .addCase(getSignedUrlAsync.fulfilled, (state, action) => {
        state.signedUrl = {
          ...initialState.signedUrl,
          success: true,
          url: action.payload,
        };
      })
      .addCase(getSignedUrlAsync.rejected, state => {
        state.signedUrl = {
          ...initialState.signedUrl,
          reject: true,
        };
      })
      .addCase(postOpenNormalCycleAsync.rejected, state => {
        state.confirmModalLoading = initialState.confirmModalLoading;
        state.confirmModalStatus = initialState.confirmModalStatus;
        state.confirmModalType = initialState.confirmModalType;
      })
      .addCase(getProjectListAsync.pending, state => {
        state.projectList = {
          ...initialState.projectList,
          loading: true,
        };
      })
      .addCase(getProjectListAsync.fulfilled, (state, action) => {
        state.projectList = {
          ...initialState.projectList,
          list: isArray(action.payload) ? formatV2ProjectListData(action.payload) : [],
          loading: false,
        };
      })
      .addCase(getProjectListAsync.rejected, state => {
        state.projectList = {
          ...initialState.projectList,
          loading: false,
        };
      })
      .addCase(getProjectListByUserIdAsync.pending, state => {
        state.projectList = {
          ...initialState.projectList,
          loading: true,
        };
      })
      .addCase(getProjectListByUserIdAsync.fulfilled, (state, action) => {
        state.projectList = {
          ...initialState.projectList,
          list: isArray(action.payload) ? formatV2ProjectListData(action.payload) : [],
          loading: false,
        };
      })
      .addCase(getProjectListByUserIdAsync.rejected, state => {
        state.projectList = {
          ...initialState.projectList,
          loading: false,
        };
      });
  },
});

export const common = (state: IState) => state.common;
export const confirmModalStatus = (state: IState) => state.common.confirmModalStatus;
export const confirmModalLoading = (state: IState) => state.common.confirmModalLoading;
export const confirmModalType = (state: IState) => state.common.confirmModalType;
export const previewModalStatus = (state: IState) => state.common.previewModalStatus;
export const signedUrl = (state: IState) => state.common.signedUrl;
export const selfAssessmentPreviewModalStatus = (state: IState) =>
  state.common.selfAssessmentPreviewModalStatus;
export const projectList = (state: IState) => state.common.projectList;

export const {
  setConfirmModalStatus,
  setConfirmModalLoading,
  setConfirmModalType,
  setPreviewModalStatus,
  setSignedUrl,
  setSelfAssessmentPreviewModalStatus,
} = commonSlice.actions;

export default commonSlice.reducer;
