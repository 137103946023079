import React, { useContext, useEffect } from 'react';
import { UserInfoContext } from '@@/context/userInfo.context';
import V2GiveFeedbackTab from '@@/_new_src_/page/feedback/component/V2GiveFeedbackTab';
import { FEEDBACK_TAB_KEY, RequestFeedbackListOptions } from '@@/_new_src_/constants/feedback';
import FeedbackLocale from '@@/_new_src_/local/feedback/en_US';
import {
  V2FeedbackStore,
  setActiveTabKey,
  setV1FeedbackModalInfo,
} from '@@/_new_src_/store/V2FeedbackSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { get, isEmpty } from 'lodash';
import V1FeedbackModalSummary from '@@/_new_src_/page/feedback/component/V1FeedbackModalSummary';
import RequestFeedbackTab from '@@/_new_src_/page/feedback/component/RequestFeedbackTab';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import {
  setPastCyclesPageCycleFilterValue,
  setPastCyclesPageSelectedCycle,
  setPastCyclesPagesSelectedYear,
} from '@@/_new_src_/store/myCyclesSlice';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import UserInfo from '@@/_new_src_/features/UserInfo';
import './index.less';

const V2Feedback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    feedback: { tabText: tabTextLocale },
  } = FeedbackLocale;

  const userInfo = useContext(UserInfoContext);

  const { activeTabKey } = useSelector(V2FeedbackStore);

  useEffect(() => {
    dispatch(setPastCyclesPageSelectedCycle(''));
    dispatch(setPastCyclesPageCycleFilterValue(RequestFeedbackListOptions[0].value));
    dispatch(setPastCyclesPagesSelectedYear(''));
  }, []);

  const onTabsChange = (key: string) => {
    dispatch(setActiveTabKey(key));
    localStorage.removeItem('requestFeedbackListType');
    localStorage.removeItem('selectedYear');
  };

  useEffect(() => {
    if (get(location, 'state.showRequestToColleaguesModal') === true) {
      history.replace(FEEDBACK_PAGE_LINK.INDEX, {});
      dispatch(setActiveTabKey(FEEDBACK_TAB_KEY.REQUEST_FEEDBACK));
      dispatch(
        setV1FeedbackModalInfo({
          isFeedbackRequestModalVisible: true,
          cycleId: userInfo.currentOpeningCycleId,
        }),
      );
    }
    if (get(location, 'state.showRequestToClientsModal') === true) {
      history.replace(FEEDBACK_PAGE_LINK.INDEX, {});
      dispatch(setActiveTabKey(FEEDBACK_TAB_KEY.REQUEST_FEEDBACK));
      dispatch(
        setV1FeedbackModalInfo({
          isClientFeedbackModalVisible: true,
          cycleId: userInfo.currentOpeningCycleId,
        }),
      );
    }
  }, [location, history, dispatch]);

  const tabPaneList = [
    {
      tabText: tabTextLocale.request,
      key: FEEDBACK_TAB_KEY.REQUEST_FEEDBACK,
      component: <RequestFeedbackTab />,
    },
    {
      tabText: tabTextLocale.give,
      key: FEEDBACK_TAB_KEY.GIVE_FEEDBACK,
      component: <V2GiveFeedbackTab />,
    },
  ];

  return (
    <>
      {!isEmpty(userInfo) && (
        <div className={'v2-feedback'}>
          <UserInfo />
          <div className="feedback-tab-container">
            <TabPaneComponent
              activeKey={activeTabKey}
              tabList={tabPaneList.map(item => item.tabText)}
              children={tabPaneList.map(item => item.component)}
              updateActiveKey={onTabsChange}
              size={'large'}
            />
          </div>
          <V1FeedbackModalSummary />
        </div>
      )}
    </>
  );
};

export default V2Feedback;
