import { getApiV2Path } from '@@/api/utils';
import { ajax } from '@@/common/ajax';
import {
  ALL_ACCOUNT,
  ALL_ACCOUNT_UNIT,
  ALL_GROUPS_ID,
  ALL_PEOPLE,
  ALL_PEOPLE_UNIT,
} from '@@/common/constant/dashboard';
import { SourceCategory } from '@@/common/constant/dashboardSourceCategory';
import { sourceCategoryJudgement } from '@@/common/utils/dashboardUtils/sourceCategory';
import { USER_ROLE_ENUM } from '@@/_new_src_/constants/userEnum';

export const getOpenRequestCount = ({ groupId, roleId, groupType, roleType, roleName }) => {
  const sourceCategory = sourceCategoryJudgement(roleName, groupId, groupType, roleType, roleId);
  const isAllPeople = groupId === ALL_PEOPLE || groupType === ALL_PEOPLE_UNIT;
  let resourceType = isAllPeople ? 'ALL_PEOPLE' : 'ALL_GROUP';

  if (sourceCategory === SourceCategory.supervisorLevel) {
    return ajax.get(getApiV2Path(`/supervisor/${roleId}/done-review-update-requests/count`), {
      params: { resourceType },
    });
  }

  if (sourceCategory === SourceCategory.unitLevel) {
    if (roleName === USER_ROLE_ENUM.TALENT_BP && groupId === ALL_ACCOUNT) {
      resourceType = 'ALL_ACCOUNT';
    }
    if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP) {
      return ajax.get(getApiV2Path(`/units/${groupId}/done-review-update-requests/count`), {
        params: { resourceType },
      });
    }
    return ajax.get(getApiV2Path(`/units/${roleId}/done-review-update-requests/count`), {
      params: { resourceType },
    });
  }

  if (sourceCategory === SourceCategory.accountLevel) {
    return ajax.get(getApiV2Path(`/accounts/${groupId}/done-review-update-requests/count`), {
      params: { roleName, hierarchyId: roleId },
    });
  }

  if (sourceCategory === SourceCategory.regionLevel) {
    if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP && groupId === ALL_ACCOUNT) {
      resourceType = 'ALL_ACCOUNT';
    }
    return ajax.get(getApiV2Path(`/regions/${roleId}/done-review-update-requests/count`), {
      params: { resourceType },
    });
  }

  return ajax.get(getApiV2Path(`/groups/${groupId}/done-review-update-requests/count`));
};

export const getRequestListBySourceCategory = ({
  sourceCategory,
  roleId,
  groupId,
  sortAndPaginationParams,
  groupType,
  roleName,
}) => {
  const isAllPeople = groupId === ALL_PEOPLE || groupType === ALL_PEOPLE_UNIT;
  let resourceType = isAllPeople ? 'ALL_PEOPLE' : 'ALL_GROUP';
  if (
    sourceCategory === SourceCategory.supervisorLevel &&
    (groupId === ALL_GROUPS_ID || ALL_PEOPLE)
  ) {
    return ajax.get(getApiV2Path(`/superorgs/${roleId}/done-review-update-requests`), {
      params: { ...sortAndPaginationParams, resourceType },
    });
  }
  if (sourceCategory === SourceCategory.unitLevel) {
    if (roleName === USER_ROLE_ENUM.TALENT_BP && groupType === ALL_ACCOUNT_UNIT) {
      resourceType = 'ALL_ACCOUNT';
    }
    if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP) {
      return ajax.get(getApiV2Path(`/units/${groupId}/done-review-update-requests`), {
        params: { ...sortAndPaginationParams, resourceType },
      });
    }
    return ajax.get(getApiV2Path(`/units/${roleId}/done-review-update-requests`), {
      params: { ...sortAndPaginationParams, resourceType },
    });
  }

  if (sourceCategory === SourceCategory.accountLevel) {
    return ajax.get(getApiV2Path(`/accounts/${groupId}/done-review-update-requests`), {
      params: { ...sortAndPaginationParams, roleName, hierarchyId: roleId },
    });
  }

  if (sourceCategory === SourceCategory.regionLevel) {
    if (roleName === USER_ROLE_ENUM.REGIONAL_TALENT_BP && groupType === ALL_ACCOUNT_UNIT) {
      resourceType = 'ALL_ACCOUNT';
    }
    return ajax.get(getApiV2Path(`/regions/${roleId}/done-review-update-requests`), {
      params: { ...sortAndPaginationParams, resourceType },
    });
  }

  return ajax.get(getApiV2Path(`/groups/${groupId}/done-review-update-requests`), {
    params: { ...sortAndPaginationParams, resourceType },
  });
};

export const updateRequestStatusByRequestId = (requestId, status) =>
  ajax.put(getApiV2Path(`/done-review-update-requests/${requestId}`), { status });
