import React from 'react';
import { useHistory } from 'react-router-dom';
import ForbiddenImage from '@@/assets/images/forbidden.png';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';

const ForbiddenPage = () => {
  const { forbidden: locale = {} } = useSelector(selectLocaleResource) || {};
  const history = useHistory();
  localStorage.setItem('authorityChange', true);

  function onGotoHome() {
    history.replace(MENU_PAGE_LINK.LANDING_PAGE);
  }
  return (
    <div className="forbidden-page">
      <img className="forbidden-page-image" src={ForbiddenImage} alt="forbidden" />
      <h3 className="forbidden-page-title">{locale.title}</h3>
      <p className="forbidden-page-info">{locale.info}</p>
      <p className="forbidden-page-desc">{locale.desc}</p>
      <button onClick={onGotoHome} type="button" className="forbidden-page-button">
        {locale.backToHomePage}
      </button>
    </div>
  );
};

export default ForbiddenPage;
