import React from 'react';
import locale from '@@/_new_src_/local/landing/en_US';
export const V1ExpectDescription = () => {
  const {
    v1: { expect: v1ExpectLocale },
  } = locale;

  return (
    <div className="landing-page-expect">
      <h2 className="landing-page-expect-title">{v1ExpectLocale.title}</h2>
      <ul className="landing-page-expect-list">
        <li className="landing-page-expect-list-item landing-page-expect-list-item_left">
          <div className="landing-page-expect-list-item-line" />
          <div className="landing-page-expect-list-item-title">{v1ExpectLocale.step.title}</div>
          <div className="landing-page-expect-list-item-desc">
            {v1ExpectLocale.step.detail.map(
              ({ label, content }: { label: string; content: string }) => (
                <p key={label}>
                  <strong>{label}</strong>
                  <span>{content}</span>
                </p>
              ),
            )}
          </div>
        </li>
        <li className="landing-page-expect-list-item landing-page-expect-list-item_right">
          <div className="landing-page-expect-list-item-line" />
          <div className="landing-page-expect-list-item-title">{v1ExpectLocale.level.title}</div>
          <div className="landing-page-expect-list-item-desc">
            {Object.keys(v1ExpectLocale.level.detail).map(rating => (
              <p key={rating}>
                <strong>{rating}:</strong>
                <span>{v1ExpectLocale.level.detail[rating]}</span>
              </p>
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
};
