import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPPList, getUserInfo } from '@@/_new_src_/api/user';
import {
  IGetPPListParams,
  IPPResponse,
  IUserInfoResponse,
} from '@@/_new_src_/store/userSlice/interface';

export const getUserInfoAsync = createAsyncThunk('getUserInfo', async () => {
  const response = await getUserInfo();
  return response.data as IUserInfoResponse;
});

export const getPPListAsync = createAsyncThunk('getPPList', async (data: IGetPPListParams) => {
  const response = await getPPList(data);
  return response.data as IPPResponse;
});
