export const HTTP_STATUS = Object.freeze({
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
  NOTFOUND: 404,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
});

export const ERROR_CODE = Object.freeze({
  UNITY_ERROR_CODE: 1200,
  INVALID_GROUP_NAME: 1206,
  INVALID_MEMBER: 1207,
  INVALID_GROUP_LEADERS: 1208,
  ADDING_ADMIN_NAME_ERROR: 1212,
  EDIT_ADMIN_REGION_ERROR: 1203,
  USER_INFO_NOT_FOUND: 1205,
});
